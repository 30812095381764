<div class="clswrapper">
    <header class="clsbefore_log_header">
        <nav class="navbar navbar-default">
            <div class="container">
                <!-- Brand and toggle get grouped for better mobile display -->
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-1"
                            aria-expanded="false">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <a class="navbar-brand" [routerLink]="['/signin']"><img src="assets/images/logo@2x.png" alt=""
                                                                            width="145"/></a>
                </div>

                <!-- Collect the nav links, forms, and other content for toggling -->
                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul class="nav navbar-nav navbar-right">
                        <li><a href="https://www.valisure.com/how-it-works/">How It Works <span
                                class="sr-only">(current)</span></a></li>
                        <li><a href="https://www.valisure.com/about-us/" target="_blank">About Us</a></li>
                        <li ><a href="https://www.valisure.com/faq/" target="_blank">FAQ</a></li>
                    </ul>
                </div><!-- /.navbar-collapse -->
            </div><!-- /.container-fluid -->
        </nav>
    </header>
    <main class="clsmain_container">
        <div class="forgot_form_blk">
            <div class="container">
                <h1 class="text-center">Reset Password</h1>
                <div class="forgot_form">
                    <p *ngIf="resetFormData">You have tried to use a link that has expired. Please request a new one. <a
                            [routerLink]="['/forgot']">Click here</a></p>
                    <form *ngIf="!resetFormData" [formGroup]="resetForm">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" class="form-control" name="email" formControlName="email"
                                   placeholder="" autocomplete="off">
                            <div class="alert alert-danger"
                                 *ngIf="resetForm.controls.email.hasError('required') && resetForm.controls.email.touched && resetForm.controls.email.dirty">
                                Please enter your email address.
                            </div>
                            <div class="alert alert-danger"
                                 *ngIf="resetForm.controls.email.hasError('pattern') && resetForm.controls.email.touched && resetForm.controls.email.dirty">
                                Invalid email address.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>New Password</label>
                            <div class="show-password">
                            <input [type]="showPassword ? 'text' : 'password'" class="form-control" name="password" formControlName="password"
                                   placeholder="" autocomplete="off">
                                <a (click)="toggleShowPassword()">{{showPassword ? 'Hide' : 'Show'}}</a>
                            </div>
                            <div class="alert alert-danger"
                                 *ngIf="resetForm.controls.password.hasError('required') && resetForm.controls.password.touched && resetForm.controls.password.dirty">
                                Please enter the password
                            </div>
                            <div class="alert alert-danger"
                                 *ngIf="resetForm.controls.password.hasError('pattern') && resetForm.controls.password.touched && resetForm.controls.password.dirty">
                                Please create your password with the following:  8-20 characters, at least 1 lower letter, 1 upper case letter, 1 number.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Confirm New Password</label>
                            <input type="password" class="form-control" placeholder="" formControlName="confirmPassword"
                                   name="confirmPassword" autocomplete="off">

                            <div class="alert alert-danger"
                                 *ngIf="(resetForm.controls.confirmPassword.invalid || resetForm.controls.confirmPassword.value != resetForm.controls.password.value) && resetForm.controls.confirmPassword.touched && resetForm.controls.confirmPassword.dirty">
                                Passwords don't match.
                            </div>
                        </div>
                        <div *ngIf="message" class="alert alert-warning">
                            {{message}}
                        </div>
                        <div class="form-group">
                            <button type="submit" (click)="resetPassword()" class="btn btn-default clsyellow_btn">
                                <span *ngIf="!loading">Submit</span>
                                <span *ngIf="loading"><i class="fa fa-spinner fa-spin"></i> Please wait</span>
                            </button>
                            <!-- [attr.disabled]="resetForm.invalid?'disabled':null" -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</div>
<app-footer></app-footer>
