<div class="orderHistoryDisplay">
    <a href="javascript:void(0);" class="cursor-hand viewOrderLink" (click)="viewOrderDetail()">View Order</a>
    <a href="javascript:void(0);" class="orderNumber" (click)="viewOrderDetail()">#{{order.order_number}}</a>
    <div class="orderHistoryDetails">
        <div class="line">
            <span>PLACED</span> {{order.created | date:'MM/dd/yyyy'}}
        </div>
        <div class="line">
            <span>TOTAL</span> {{order.total_amount | currency}}
        </div>
        <div class="line">
            <span>STATUS</span> {{order.status && order.status.name}}
        </div>
    </div>
</div>