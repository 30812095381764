<div class="clsuser_help_blk">
    <h1>My Account</h1>
    <div class="clsuser_help_div">
        <ul>
            <li>
                <div class="clsuser_help_box">
                    <a routerLink="/prescription">
                        <figure><img src="assets/images/start-icon@2x.png" alt="" width="50" /></figure>
                        <h5>Start New Rx</h5>
                    </a>
                </div>
            </li>
            <li>
                <div class="clsuser_help_box">
                    <a routerLink="/prescription">
                        <figure><img src="assets/images/refill-icon@2x.png" alt="" width="42" /></figure>
                        <h5>Refill Rx</h5>
                    </a>
                </div>
            </li>
            <li>
                <div class="clsuser_help_box">
                    <a routerLink="/prescription">
                        <figure><img src="assets/images/transfer-icon@2x.png" alt="" width="45" /></figure>
                        <h5>Transfer Rx</h5>
                    </a>
                </div>
            </li>
            <li>
                <div class="clsuser_help_box">
                    <a [routerLink]="['/shop/products/rx']">
                        <figure><img src="assets/images/shop-icon@2x.png" alt="" width="52" /></figure>
                        <h5>Shop Rx</h5>
                    </a>
                </div>
            </li>
        </ul>
    </div>
</div>