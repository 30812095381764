import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartComponent } from './components/cart/cart.component';
import { CartRoutingModule } from './cart-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../../auth/auth.interceptor';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, CartRoutingModule, FormsModule],
  declarations: [CartComponent],
  entryComponents: [CartComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class CartModule {}
