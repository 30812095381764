
import {OrderProduct} from "../../../../model/orders/order-product.model";
import {Component, Input, OnInit} from "@angular/core";
import { Router } from '@angular/router';
import {UserService} from "../../../../shared/user.service";
import {ProductService} from "../../../../modules/shop/services/product.service";

@Component({
    selector: "app-order-recent-purchase-display",
    templateUrl: "./order-recent-purchase-display.component.html",
    styleUrls: ["./order-recent-purchase-display.component.css"]
})
export class OrderRecentPurchaseDisplayComponent implements OnInit {

    @Input() product: OrderProduct;

    constructor(private userService: UserService, public productService: ProductService, private router: Router) {
    }

    ngOnInit() {
    }

    /**
   * Navigate to order details 
   */
    public viewOrderDetail(product) {
        this.router.navigate(['/account/orderDetail', product.order.id]);
    }


    /**
   * Navigate to product details 
    */
    public viewProductDetail(product) {
        this.router.navigate(['/shop/products', product.real.id]);
    }

    public reorderProduct(product){
        this.userService.reorderProduct(product);
    }

}
