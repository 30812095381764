<div  #hippanovember2019 class="modal fade hippanovember2019" id="hippanovember2019Modal" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="hippanovember2019">
    <div class="vertical-alignment-helper">
        <div class="modal-dialog vertical-align-center" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    We’ve Updated Our HIPAA Policy
                </div>
                <div class="modal-body">
                    <div class="hippa_content hippa_content_tablet">
                        <perfect-scrollbar class="hippa_content_block" [disabled]="scrollDisabled">
                            <p><strong>THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.</strong></p>
                            <p><strong>ValisureRX Notice of Privacy Practices</strong></p>
                            <p>This Notice of Privacy Practices (the “Notice”) is meant to inform you about the privacy and confidentiality of your protected health information and how your protected health information may be used and disclosed by ValisureRX LLC (“ValisureRX”). It also describes your rights to access and control your protected health information and certain obligations ValisureRX has regarding the use and disclosure of your protected health information.</p>
                            <p>Your “protected health information” for purposes of this Notice is information about you created or received by ValisureRX, including demographic information, that may reasonably identify you and that relates to your past, present or future physical or mental health or condition, or payment for the provision of your health care.</p>
                            <p>ValisureRX is required by law to maintain the privacy of your protected health information and to notify you in the event that there is a breach of your unsecured protected health information. ValisureRX is also required by law to provide you with this Notice of its legal duties and privacy practices with respect to your protected health information, and to abide by the terms of the Notice currently in effect. This Notice applies to ValisureRX and its employees and workforce members.</p>
                            <p>This Notice is effective November 7, 2019. ValisureRX may change the terms of this Notice at any time. The new revised Notice will apply to all of your protected health information maintained by ValisureRX. You will not automatically receive a revised Notice. If you would like to receive a copy of the then-current Notice at any time, you can obtain it online at <a href="https://www.valisure.com/hipaa-policy/">https://www.valisure.com/hipaa-policy/</a>, by contacting ValisureRX via email at <a href="mailto:info@valisure.com">info@valisure.com</a>, or by contacting ValisureRX at 5 Science Park, 1st floor, New Haven, CT 06511.</p>
                            <p><strong>How ValisureRX May Use or Disclose Your Protected Health Information</strong></p>
                            <p>The following categories describe some of the different ways that ValisureRX may use or disclose your protected health information. This Notice gives examples of how ValisureRX may use or disclose your protected health information, but not every permissible use or disclosure is listed. Even if not specifically listed below, ValisureRX may use and disclose your protected health information as permitted or required by law or as authorized by you. ValisureRX will make reasonable efforts to limit access to your protected health information to those persons or classes of persons, as appropriate, in ValisureRX’s workforce who need access to carry out their duties. In addition, if required, ValisureRX will make reasonable efforts to limit the protected health information to the minimum amount necessary to accomplish the intended purpose of any use or disclosure and to the extent such use or disclosure is limited by law.</p>
                            <p><strong>For Treatment</strong> – ValisureRX may use and disclose your protected health information to provide you with pharmacy-related treatment and services. For example, your protected health information may be used when ValisureRX reviews the prescriptions from your health care provider, when ValisureRX fills and dispenses your medications, or when ValisureRX counsels you on medications. If ValisureRX is permitted to do so, it may also disclose your protected health information to individuals or facilities that will be involved with your care and for other treatment reasons. ValisureRX may also use or disclose your protected health information in an emergency situation.</p>
                            <p><strong>For Payment</strong> – ValisureRX may use and disclose your protected health information so that it can bill and receive payment for the pharmacy-related services you receive. For billing and payment purposes, ValisureRX may disclose your health information to your payment source, including an insurance or managed care company, Medicare, Medicaid, or another third party payor, as applicable. For example, ValisureRX may need to give your health plan information about the treatment you received so your health plan will pay it or reimburse it for the treatment, or ValisureRX may contact your health plan to confirm your coverage or to request prior authorization for a proposed treatment.</p>
                            <p><strong>For Health Care Operations</strong> – ValisureRX may use and disclose your health information as necessary for its operations, such as quality assurance and improvement activities, reviewing the competence and qualifications of health care professionals, prescription-related review, legal services and auditing functions, and general administrative activities of ValisureRX. For example, ValisureRX may use your protected health information as part of its internal quality review activities.</p>
                            <p><strong>Business Associates</strong> – There may be some services involving your protected health information provided by third parties under contract with ValisureRX (known as “business associates”), such as a billing service, transcription company or legal or accounting consultants. ValisureRX may disclose your protected health information to its business associates so that they can perform the job ValisureRX has asked them to do. In addition, ValisureRX’s business associates may receive certain health information from you on behalf of ValisureRX and transfer that health information to ValisureRX. To protect your health information, ValisureRX requires its business associates to enter into a written contract that requires them to appropriately safeguard your information and report any breaches of protected health information to ValisureRX.</p>
                            <p><strong>Refill Reminders</strong> – ValisureRX may use and disclose protected health information to contact you as a reminder that you are due for a refill.</p>
                            <p><strong>Treatment Alternatives and Other Health-Related Benefits and Services</strong> – ValisureRX may use and disclose protected health information to tell you about or recommend possible treatment options or alternatives and to tell you about health related benefits, services, or medical education classes that may be of interest to you.</p>
                            <p><strong>Individuals Involved in Your Care or Payment of Your Care</strong> – Unless you object, ValisureRX may disclose your protected health information to a family member, a relative, a close friend or any other person you identify, if the information relates to the person’s involvement in your health care or payment related to your health care. If you are unable to agree or object to such a disclosure ValisureRX may disclose such information if ValisureRX determines that it is in your best interest based on their professional judgment or if ValisureRX reasonably infers that you would not object. In addition, ValisureRX may disclose your protected health information to a public or private entity authorized by law to assist in a disaster relief effort.</p>
                            <p><strong>Public Health Activities</strong> – ValisureRX may disclose your protected health information for public health purposes, including to a public health authority that is authorized by law to collect or receive such information, such as for the purpose of preventing or controlling disease, injury, or disability; reporting births, deaths or other vital statistics; reporting child abuse or neglect; for quality, safety or effectiveness of products regulated by the Food and Drug Administration;; notifying a person who may have been exposed to a disease or may be at risk of contracting or spreading a disease or condition; to an employer about a work-related illness or injury if we provide care at the employer’s request; or to provide proof of immunization to a school.</p>
                            <p><strong>Health Oversight Activities</strong> – ValisureRX may disclose your protected health information to a health oversight agency for activities authorized by law, such as audits, investigations, inspections, accreditation, licensure and disciplinary actions.<br>
                            Judicial and Administrative Proceedings – If you are involved in a lawsuit or a dispute, ValisureRX may disclose your protected health information in response to your authorization or a court or administrative order. ValisureRX may also disclose your protected health information in response to a subpoena, discovery request, or other lawful process if such disclosure is permitted by law.</p>
                            <p><strong>Law Enforcement</strong> – ValisureRX may disclose your protected health information for certain law enforcement purposes if permitted or required by law. For example, to report gunshot wounds; to report emergencies or suspicious deaths; to comply with a court order, warrant, or similar legal process; to answer certain requests for information concerning crimes; to provide evidence of criminal conduct on our premises; or for identifying or locating a fugitive, suspect, witness, or missing person.</p>
                            <p><strong>Coroners, Medical Examiners, Funeral Directors, Organ Procurement Organizations</strong> – ValisureRX may release your protected health information to a coroner, medical examiner, funeral director, or, if you are an organ donor, to an organization involved in the donation of organs and tissues.</p>
                            <p><strong>Research Purposes –</strong> Your protected health information may be used or disclosed for research purposes, but only if the use and disclosure of your information has been reviewed and approved by a special Privacy Board or Institutional Review Board, or if you provide authorization.</p>
                            <p><strong>To Avert a Serious Threat to Health or Safety</strong> – ValisureRX may use and disclose your protected health information when necessary to prevent a serious threat to your health or safety or the health or safety of the public or another person. Any disclosure, however, would be to someone able to help prevent the threat.</p>
                            <p><strong>Specialized Government Functions</strong> – If you are a member of the armed forces, ValisureRX may use and disclose your protected health information as required by military command authorities or the Department of Veterans Affairs. ValisureRX may disclose your protected health information to authorized federal officials for the conduct of lawful intelligence, counter-intelligence, and other national security activities authorized by law. ValisureRX may disclose your protected health information to authorized federal officials so they may provide protection to the President, other authorized persons or foreign heads of state or conduct special investigations. ValisureRX may also disclose protected health information to correctional facilities about an inmate at such a facility in certain circumstances.</p>
                            <p><strong>Workers’ Compensation</strong> – ValisureRX may use or disclose your protected health information as permitted by laws relating to workers’ compensation or related programs.</p>
                            <p><strong>Special Rules Regarding Disclosure of Psychiatric, Substance Use Disorder and HIV-Related Information</strong> – Generally, ValisureRX may disclose your protected health information for treatment, payment or health care operations purposes. For disclosures concerning protected health information relating to care for psychiatric conditions, substance use disorders (including drug or alcohol use), HIV related testing and treatment, or minors, special restrictions may apply. For example, ValisureRX generally may not disclose this specially protected information in response to a subpoena, warrant or other legal process unless you sign a special Authorization or a court orders the disclosure.</p>
                            <p><span style="text-decoration: underline;">Mental health information</span>. Certain mental health information may be disclosed for treatment, payment and health care operations as permitted or required by law. Otherwise, ValisureRX will only disclose such information pursuant to an authorization, court order or as otherwise required or permitted by law.<br>
                            <span style="text-decoration: underline;">Substance Use Disorder Diagnosis and Treatment Information</span>. If you are treated in a specialized substance use disorder program, any records of that treatment or otherwise pertaining to your substance use disorder are subject to heightened confidentiality and non-disclosure restrictions under Federal and state law and regulations. Generally, if ValisureRX has information concerning your participation in such programs, ValisureRX may not say to a person outside the program that you attend the program, or disclose any information identifying you as having been diagnosed with or received treatment for a substance use disorder or any information regarding your treatment, unless:<br>
                            You consent in writing;<br>
                            The disclosure is allowed by a court order; or<br>
                            The disclosure is made to medical personnel in a medical emergency or to qualified personnel for research, audit, or program evaluation, or to a qualified service organization or an entity with administrative control over a program covered under these Federal laws and regulations.</p>
                            <p>Violation of these Federal laws and regulations by ValisureRX is a crime. Suspected violations may be reported to appropriate authorities in accordance with Federal regulations. Federal law and regulations do not protect any information about a crime committed by a patient either at the substance use disorder program or against any person who works for the program or about any threat to commit such a crime. Federal laws and regulations do not protect any information about suspected child abuse or neglect from being reported under State law to appropriate State or local authorities. The Federal law and regulations governing substance use disorder treatment records can be found at 42 U.S.C. § 290dd-2 and 42 C.F.R. Part 2.</p>
                            <p><span style="text-decoration: underline;">HIV related information.</span> ValisureRX may disclose HIV related information as permitted or required by state law.<br>
                            <span style="text-decoration: underline;">Minors</span>. ValisureRX will comply with applicable state law when using or disclosing protected health information of minors.</p>
                            <p><span style="text-decoration: underline;"><strong>When ValisureRX May Not Use or Disclose Your Protected Health Information<br>
                            </strong></span>Except as described in this Notice, or as permitted by state and Federal law, ValisureRX will not use or disclose your protected health information without your written authorization.<br>
                            Your written authorization will specify particular uses or disclosures that you choose to allow. Under certain limited circumstances, ValisureRX may condition pharmacy treatment services on the provision of an authorization, such as for research related to the pharmacy services. If you do authorize ValisureRX to use or disclose your protected health information for reasons other than treatment, payment or health care operations, you may revoke your authorization in writing at any time by contacting ValisureRX’s Privacy Officer. If you revoke your authorization, ValisureRX will no longer use or disclose your protected health information for the purposes covered by the authorization, but your revocation will not affect uses or disclosures made in reliance on the authorization prior to its revocation.</p>
                            <p><strong><span style="text-decoration: underline;">Psychotherapy Notes<br>
                            </span></strong><br>
                            Although not generally applicable to ValisureRX, a signed authorization or court order is required for any use or disclosure of psychotherapy notes except to carry out certain treatment, payment, or health care operations and for use by ValisureRX for treatment, for training programs, or for defense in a legal action.</p>
                            <p><strong><span style="text-decoration: underline;">Marketing</span></strong></p>
                            <p>An authorization is not required to describe a health-related product or service provided by ValisureRX; to make communications to you regarding your treatment; or to direct or recommend alternative treatments, therapies, providers or settings of care for you. Otherwise, a signed authorization is required for the use or disclosure of your protected health information for a purpose that encourages you to purchase or use a product or service except for certain circumstances such as when the marketing communication is face-to-face or when marketing includes the distribution of a promotional gift of nominal value provided by ValisureRX.</p>
                            <p><strong><span style="text-decoration: underline;">Sale of Protected Health Information<br>
                            </span></strong><br>
                            A signed authorization is required for the use or disclosure of your protected health information in the event that ValisureRX directly or indirectly receives remuneration for such use or disclosure, except under certain circumstances as allowed by Federal or state law. For example, authorization is not needed if the purpose of the use or disclosure is for your treatment, public health activities, or providing you with a copy of your protected health information.</p>
                            <p><strong>Your Health Information Rights<br>
                            </strong><br>
                            You have the following rights with respect to your protected health information. The following briefly describes how you may exercise these rights.</p>
                            <p><strong>Right to Request Restrictions of Your Protected Health Information</strong> – You have the right to request certain restrictions or limitations on the protected health information ValisureRX uses or discloses about you. You may request a restriction or revise a restriction on the use or disclosure of your protected health information by providing a written request stating the specific restriction requested. ValisureRX is not required to agree to your requested restriction, unless it involves the disclosure of protected health information to a health plan for purposes of carrying out payment or health care operations that pertains solely to a health care item or service for which ValisureRX has been paid out of pocket in full. If ValisureRX does agree to accept your requested restriction, it will comply with your request except as needed to provide you with emergency treatment. If restricted protected health information is disclosed to a health care provider for emergency treatment, ValisureRX will request that such health care provider not further use or disclose the information. In addition, you or ValisureRX may terminate the restriction if the other party is notified in writing of the termination. Unless you agree, the termination of the restriction is only effective with respect to protected health information created or received after ValisureRX has informed you of the termination.<br>
                            <strong>Right to Receive Confidential Communications</strong> – You have the right to request a reasonable accommodation regarding how you receive communications of protected health information. You have the right to request an alternative means of communication or an alternative location where you would like to receive communications. You may submit a request in writing to ValisureRX requesting confidential communications.<br>
                            <strong>Right to Access, Inspect and Copy Your Protected Health Information</strong> – You have the right to access, inspect and obtain a copy of your protected health information that is used to make decisions about your care for as long as the protected health information is maintained by ValisureRX. To access, inspect and copy your protected health information that may be used to make decisions about you, you must submit your request in writing to ValisureRX. If you request a copy of the information, ValisureRX may charge a fee for the costs of preparing, copying, mailing or other supplies associated with your request. ValisureRX may deny, in whole or in part, your request to access, inspect and copy your protected health information under certain limited circumstances. If ValisureRX denies your request, ValisureRX will provide you with a written explanation of the reason for the denial. You may have the right to have this denial reviewed by an independent health care professional designated by ValisureRX to act as a reviewing official. This individual will not have participated in the original decision to deny your request. You may also have the right to request a review of ValisureRX’s denial of access through a court of law. All requirements, court costs and attorneys’ fees associated with a review of denial by a court are your responsibility.<br>
                            <strong>Right to Amend Your Protected Health Information</strong> – You have the right to request an amendment to your protected health information for as long as the information is maintained by or for ValisureRX. Your request must be made in writing to ValisureRX and must state the reason for the requested amendment. If ValisureRX denies your request for amendment, it will give you a written denial including the reasons for the denial and the right to submit a written statement disagreeing with the denial. ValisureRX may rebut your statement of disagreement. If you do not wish to submit a written statement disagreeing with the denial, you may request that your request for amendment and your denial be disclosed with any future disclosure of your relevant information.<br>
                            Right to Receive An Accounting of Disclosures of Protected Health Information. You have the right to request an accounting of certain disclosures of your protected health information by ValisureRX or by others on its behalf. To request an accounting of disclosures, you must submit a request in writing, stating a time period that is within six (6) years from the date of your request, or three (3) years for records maintained on an electronic health record. The first accounting provided within a twelve-month period will be free. ValisureRX may charge you a reasonable, cost-based fee for each future request for an accounting within a single twelve-month period. However, you will be given the opportunity to withdraw or modify your request for an accounting of disclosures in order to avoid or reduce the fee. As stated above, in the event ValisureRX maintains an electronic health record, an accounting of disclosures from the electronic health record related to treatment, payment or health care operations will be made only for the three (3) year period preceding the request.<br>
                            <strong>Right to Obtain A Paper Copy of Notice</strong> – You have the right to obtain a paper copy of this Notice, even if you have agreed to receive this Notice electronically. You may request a copy of this Notice at any time by contacting ValisureRX.<br>
                            <strong>Right to Complain</strong> – You may file a complaint with ValisureRX or the Secretary of the Department of Health and Human Services if you believe your privacy rights have been violated by ValisureRX. You may file a complaint with ValisureRX by notifying the ValisureRX Privacy Officer of your complaint. You will not be penalized for filing a complaint and ValisureRX will make every reasonable effort to resolve your complaint with you.</p>
                            <p><strong>Contact information for ValisureRX and its Privacy Officer:</strong></p>
                            <p>
                                <strong>Privacy Officer</strong><br>
                                <strong>ValisureRX</strong><br>
                                <strong>5 Science Park, 1st floor</strong><br>
                                <strong>New Haven, CT 06511</strong><br>
                                <strong>Phone: 1-833-497-7370<br>
                                </strong><br>
                                <strong>Email: <a href="mailto:info@valisure.com">info@valisure.com</a> </strong>
                            </p>
                        </perfect-scrollbar>                        
                    </div>
                    <div class="hippa_content hippa_content_mobile">
                            <div class="hippa_content_block_mobile" >
                                <p><strong>THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.</strong></p>
                                <p><strong>ValisureRX Notice of Privacy Practices</strong></p>
                                <p>This Notice of Privacy Practices (the “Notice”) is meant to inform you about the privacy and confidentiality of your protected health information and how your protected health information may be used and disclosed by ValisureRX LLC (“ValisureRX”). It also describes your rights to access and control your protected health information and certain obligations ValisureRX has regarding the use and disclosure of your protected health information.</p>
                                <p><a href="https://www.valisure.com/hipaa-policy/" target="_blank">Click here to read our full HIPAA policy</a></p>
                            </div>            
                        </div>
                    <div class="hippaError" *ngIf="hippaError">
                        <div class="alert alert-danger">{{hippaAgreedErrorMessage}}</div>
                    </div>
                </div>
                <div class="modal-footer ">
                    <div class="row text-left">
                        <div class="col-xs-9 text-left">
                            <div class="checkbox text-left">
                                <input type="checkbox" [(ngModel)]="hippaAgreed" id="hippaNovember2019" name="agree_hippa_november_2019">
                                <label for="hippaNovember2019"><span></span> I agree to the <a href="https://www.valisure.com/hipaa-policy/" target="_blank">HIPAA Policy</a></label>
                            </div>                    
                        </div>
                        <div class="col-xs-3 text-right">
                            <a *ngIf="!savingResponse" href="javascript:void(0)" (click)="saveHippaAgreement()" class="action poppin_semibold">Submit</a>
                            <span *ngIf="savingResponse">Loading. Please wait ... </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>