import {Component} from "@angular/core";
import {Location} from "@angular/common";
import {Router, NavigationEnd, Event} from "@angular/router";
import {environment} from "../environments/environment";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"]
})
export class AppComponent {
    title = "Valisure";
    route: string;
    rootUrl: string;
    location_val: string;
    url_getting: any;

    constructor(location: Location, router: Router) {
        router.events.subscribe((event: Event) => {

            if (event instanceof NavigationEnd) {
                document.body.dispatchEvent(new CustomEvent("NavigationEnded", {detail: {route: event.url}}));
            }

            const paths: string[] = ["signin", "signup", "forgot", "not-found", "account/reset", "shipping-policy", "return-policy"];
            const primaryChild = router.parseUrl(location.path()).root.children["primary"];

            if (primaryChild) {
                const firstSegment = primaryChild.segments[0].path;
                const secondSegment = primaryChild.segments.length > 1 ? primaryChild.segments[1].path : "";

                if (firstSegment === 'temporarily-unavailable') {
                    this.route = "doomsday";
                }
                else {
                    if (firstSegment === "account") {
                        this.route = paths.includes(`${firstSegment}/${secondSegment}`) ? "login" : "home";
                    } else {
                        this.route = paths.includes(firstSegment) ? "login" : "home";
                    }
                }
            } else {
                this.route = "home";
            }
        });
        this.url_getting = router.url;

    }

    ngOnInit() {
        this.location_val = window.location.href;
        this.rootUrl = environment.root_url;
        sessionStorage.setItem("location", this.rootUrl);
    }
}
