<div class="col-sm-4">
  <div class="clsmy_account_menus">
      <ul>
          <li [ngClass]="{'active': selectedTab=='order-history'}"><a [routerLink]="['/account/order-history']">Order History</a></li>
          <li [ngClass]="{'active': selectedTab=='saved-order'}"><a [routerLink]="['/account/saved-order']">Saved Orders</a></li>
          <li [ngClass]="{'active': selectedTab=='address'}"><a [routerLink]="['/account/address']">Addresses</a></li>
<!--           <li [ngClass]="{'active': selectedTab==''}"><a href="#">Payment Methods</a></li> -->
         <li [ngClass]="{'active': selectedTab=='request-medication'}"><a [routerLink]="['/account/request-medication']">Requested Medications</a></li> 
          <li [ngClass]="{'active': selectedTab=='profile'}"><a [routerLink]="['/account/profile']">Profile</a></li>
          <li [ngClass]="{'active': selectedTab=='signin'}"><a style="cursor: pointer" (click)="userService.logout()">Sign Out</a></li>
      </ul>
  </div>
</div>