import { OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
var AddressFormComponent = /** @class */ (function () {
    function AddressFormComponent() {
    }
    AddressFormComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(AddressFormComponent.prototype, "nickname", {
        /**
         * Get nickname
         */
        get: function () {
            return this.formGroup.get("name");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressFormComponent.prototype, "first", {
        /**
         * Get first name
         */
        get: function () {
            return this.formGroup.get("first_name");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressFormComponent.prototype, "last", {
        /**
         * Get last name
         */
        get: function () {
            return this.formGroup.get("last_name");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressFormComponent.prototype, "company", {
        /**
         * Get company
         */
        get: function () {
            return this.formGroup.get("company");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressFormComponent.prototype, "email", {
        /**
         * Get email
         */
        get: function () {
            return this.formGroup.get("email");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressFormComponent.prototype, "phone", {
        /**
         * Get phone
         */
        get: function () {
            return this.formGroup.get("phone");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressFormComponent.prototype, "street", {
        /**
         * Get street
         */
        get: function () {
            return this.formGroup.get("address_line_1");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressFormComponent.prototype, "street_2", {
        /**
         * Get street
         */
        get: function () {
            return this.formGroup.get("address_line_2");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressFormComponent.prototype, "city", {
        /**
         * Get city
         */
        get: function () {
            return this.formGroup.get("city");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressFormComponent.prototype, "state", {
        /**
         * Get state
         */
        get: function () {
            return this.formGroup.get("state_id");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressFormComponent.prototype, "zip", {
        /**
         * Get zip
         */
        get: function () {
            return this.formGroup.get("zip_code");
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Checkes if selected key is a number
     * @param event - Key event
     */
    AddressFormComponent.prototype.onlyNumberKey = function (event) {
        return event.charCode == 8 || event.charCode == 0
            ? null
            : (event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 45);
    };
    AddressFormComponent.prototype.onSuggestedAddressSelected = function (address) {
        if (address.address_line_1) {
            this.formGroup.get('address_line_1').setValue(address.address_line_1);
        }
        if (address.city) {
            this.formGroup.get('city').setValue(address.city);
        }
        if (address.state) {
            var selectedState = this.states.find(function (state) {
                return state.label === address.state;
            });
            if (selectedState) {
                this.formGroup.get('state_id').setValue(selectedState.value);
            }
        }
        if (address.zip_code) {
            this.formGroup.get('zip_code').setValue(address.zip_code);
        }
    };
    return AddressFormComponent;
}());
export { AddressFormComponent };
