<main class="clsmain_container doomsday-page">
    <div class="container">
        <p><b>Dear {{userFname}},</b></p>
        <p>
            The <a href="https://www.valisure.com" target="_blank">Valisure.com</a> store is temporarily unavailable, due to a very high volume of requests.  This is a temporary situation and we will contact you as soon as we are able to serve your needs.
        </p>
        <p>
            If you are interested in reading about the breaking news of issues regarding Valisure's discovery of widespread toxicity in the US supply of metformin, please <a href="https://www.valisure.com/blog/valisure-news/valisure-detects-high-levels-of-ndma-in-metformin/" target="_blank">click here</a>.
        </p>

        <!--
        <p>We’re very sorry that our store is temporarily unavailable due to a very high volume of requests. We will contact you as soon as we are able to serve your needs.</p>
        <p>More information on the breaking news of ranitidine’s toxicity and Valisure’s investigation on this is <a href="https://www.valisure.com/blog/uncategorized/detection-of-ndma-in-raniditine/" target="_blank">available here</a>.</p>
        -->

        <ng-template [ngIf]="doomsdayStatus.doomsday_shipping_code">
            <p>Below is a free shipping code you can use as soon as we are able to reopen our store:</p>
            <p>
                <b>{{doomsdayStatus.doomsday_shipping_code}}</b><br/>
                <span *ngIf="doomsdayStatus.doomsday_shipping_code_data">{{doomsdayStatus.doomsday_shipping_code_data.description}}</span>
            </p>            
        </ng-template>
        <!--
        <p>Again, our apologies and we’ll follow up with you as soon as we can,</p>
        -->

        <p>We apologize for the inconvenience and we will follow up with you as soon as we can.</p>

        <p><b>Valisure Team</b></p>
    </div>
</main>