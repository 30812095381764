<main class="clsmain_container clscart_section">
    <div class="clscart_blk">
        <div class="container">
            <form>
                <div class="clsuser_help_blk">
                    <h1>Shopping Cart <span>{{ cartProducts ? cartProducts.length : 0 }} item{{cartProducts.length === 1 ? '' : 's'}}</span></h1>

                    <div class="clscart_checkout_top_btn" *ngIf="cartProducts.length > 0">
                        <button type="button" (click)="navigateToCheckout()" class="btn btn-default clsyellow_btn">Checkout</button>
                    </div>
                </div>

                <div class="clscart_table" >
                    <div class="table-responsive" *ngIf="cartProducts.length > 0">
                        <table class="table" style="margin-bottom: 0px;">
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cartProduct of cartProducts;index as i">
                                    <td width="50%">
                                        <div class="media">
                                            <div class="media-left">
                                                <a href="#">
                                                    <img class="media-object" src="{{ cartProduct.product.image.full_thumbnail_url }}"
                                                        alt="{{cartProduct.product.scientific_name}}" style="width: 80px;" >
                                                </a>
                                            </div>
                                            <div class="media-body  media-middle">
                                                <h4 class="media-heading"><a [routerLink]="['/shop/products', cartProduct.product.id]">{{ cartProduct.product.scientific_name}} <span *ngIf="!cartProduct.product.rx  && cartProduct.productVariant != null">( {{ cartProduct.productVariant.quantity}} {{productService.getOptionDescriptor(cartProduct.product.type, cartProduct.productVariant.quantity)}} ) </span></a>
                                                </h4>
                                                <p *ngIf="cartProduct.product.hasOwnProperty('medication_testing')" class="medtesting_minicartdisplay" >
                                                {{cartProduct.product.medication_testing.medication_name}} {{cartProduct.product.medication_testing.dose}}
                                              </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td width="20%">
                                        <p class="clsorder_total">{{ cartProduct.quantity }} <a *ngIf="!cartProduct.product.hasOwnProperty('medication_testing')" class="carteditquantity" style="cursor: pointer" title="Edit quantity" (click)="editProductQuantity(i)"><img
                                            src="assets/images/cart_edit_quantity_icon.png" alt="" width="13" /></a></p>
                                    </td>
                                    <td width="10%">
                                        <p class="clsorder_total">
                                            {{ cartProduct.pricePerItem | currency:'USD':'symbol': (cartProduct.product.rx ? '1.3-3' : '1.2-2') }}
                                        </p>
                                    </td>
                                    <td width="10%">
                                        <p class="clsorder_total">{{ (cartProduct.price) | currency }}</p>
                                    </td>
                                    <td width="10%"><a style="cursor: pointer" (click)="removeProduct(i)"><img
                                                src="assets/images/cart_delete_icon.png" alt="" width="11" /></a></td>

                                </tr>
                                <tr>
                                    <td width="50%"></td>
                                    <td width="20%"></td>
                                    <td width="10%">Subtotal</td>
                                    <td width="10%">{{ getSubtotal() | currency }}</td>
                                    <td width="10%"></td>
    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="clscart_bottom_empty" *ngIf="cartProducts.length === 0">
                        Your cart is empty.
                    </div>
                </div>
                <div class="clscart_bottom_checkout">
                    <p class="clsaccount_top_backto_btn">
                        <a (click)="backToShopping()" style="cursor: pointer"><span class="glyphicon glyphicon-menu-left"
                                aria-hidden="true"></span>
                            Return to Shopping</a>
                    </p>
                    <div class="clscart_checkout_top_btn" *ngIf="cartProducts.length > 0">
                        <button type="button" (click)="navigateToCheckout()" class="btn btn-default clsyellow_btn">Checkout</button>
                    </div>
                </div>
            </form>

        </div>
    </div>
</main>
<div  #productEditModal class="modal fade clsproduct_added_cart_confirmation productEditModal" id="productEditModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="vertical-alignment-helper">
        <div class="modal-dialog vertical-align-center" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title" *ngIf="editProduct !== null">
                        <h2>{{editProduct.product.scientific_name}} <span *ngIf="!editProduct.product.rx  && editProduct.productVariant != null"> <br/> {{ editProduct.productVariant.quantity}} {{productService.getOptionDescriptor(editProduct.product.type, editProduct.productVariant.quantity)}} </span> </h2>


                        <h4 *ngIf="editProduct.quantity > 0">{{ editProduct.price | currency}}
                            <span *ngIf="editProductHasPricePerUnit && productService.getUnitDescriptor(editProduct.product.type)">
                                ({{ editProductPricePerUnit | currency:'USD':'symbol':'1.3-3'}} / {{productService.getUnitDescriptor(editProduct.product.type)}})
                            </span>
                        </h4>
                        
                        <div class="pd-line form-group" style="max-width: 248px;">
                            Quantity <br/>
                            <input #customPillCountInput type="number" min="1" [(ngModel)]="productQuantity" (ngModelChange)="productQuantityChanged($event)" (keypress)="numberOnly($event)" class="active form-control">
                            <div class="alert alert-danger" *ngIf="quantityError">
                                <div>Please enter the quantity</div>
                            </div>
                        </div> 
                        
                        
                    </div>
                </div>
                <div class="modal-body text-center">
                    <div class="row">
                        <div class="col-xs-6">
                            <a href="javascript:void(0)" data-dismiss="modal" class="action poppin_semibold">Cancel</a>
                        </div>
                        <div class="col-xs-6">
                            <a href="javascript:void(0)" data-dismiss="modal" (click)="updateCart()" class="action poppin_semibold">Update Quantity</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>