import { HttpHeaders, HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { CartService } from "@modules/cart/services";
import * as jwt_decode from "jwt-decode";
import { CheckoutService } from "@modules/checkout/services/checkout.service";
import { UtilsService } from "@services/utils.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../modules/cart/services/cart.service";
import * as i4 from "../modules/checkout/services/checkout.service";
var reqHeader = new HttpHeaders();
reqHeader.append("Access-Control-Allow-Origin", "*");
reqHeader.append("Access-Control-Allow-Headers", "*");
reqHeader.append("Access-Control-Allow-Methods", " GET, POST, DELETE, PUT, HEAD, OPTIONS");
reqHeader.append("Request Method", "POST, GET, PUT, DELETE, OPTIONS, HEAD");
reqHeader.append("Content-Type", "application/json, application/x-www-form-urlencoded, multipart/form-data, text/plain, charset=UTF-8");
var UserService = /** @class */ (function () {
    function UserService(http, router, cartService, checkoutService) {
        this.http = http;
        this.router = router;
        this.cartService = cartService;
        this.checkoutService = checkoutService;
        this.rootUrl = sessionStorage.getItem("location");
        this.showMed = false;
        this.sendMedication = new BehaviorSubject(this.showMed);
    }
    UserService.prototype.getProductVariant = function (product, variantId) {
        if (variantId == null)
            return null;
        if (product.real.variants.lenght == 0)
            return null;
        return product.real.variants.reduce(function (variantSearched, variant, index) {
            if (variantSearched.variantId == variant.id) {
                variantSearched.variant = variant;
            }
            return variantSearched;
        }, { variant: null, variantId: variantId }).variant;
    };
    UserService.prototype.getProductPriceRange = function (product, quantity) {
        return product.real.variants.sort(function (a, b) {
            return a.quantity - b.quantity;
        }).reduce(function (variantSearched, variant, index) {
            if (variantSearched.quantity >= variant.quantity) {
                variantSearched.variant = variant;
            }
            return variantSearched;
        }, { variant: null, quantity: quantity }).variant;
    };
    UserService.prototype.calculatePrice = function (orderProduct) {
        var recalculatedPrice = {
            productPrice: 0,
            productPricePerUnit: 0,
        };
        var selectedProductVariant = this.getProductVariant(orderProduct, orderProduct.product_variant_id);
        if (!orderProduct.real.rx) {
            if (orderProduct.real.variants.length > 0) {
                recalculatedPrice.productPrice = UtilsService.roundPrice(selectedProductVariant.price * orderProduct.quantity);
                recalculatedPrice.productPricePerUnit = selectedProductVariant.price / selectedProductVariant.quantity;
            }
            else {
                recalculatedPrice.productPrice = UtilsService.roundPrice(orderProduct.real.price * orderProduct.quantity);
            }
        }
        else {
            if (orderProduct.real.variants.length > 0) {
                var productRange = this.getProductPriceRange(orderProduct, orderProduct.quantity);
                // console.log(productRange);
                if (productRange == null) {
                    recalculatedPrice.productPrice = UtilsService.roundPrice(orderProduct.real.price * orderProduct.quantity);
                    recalculatedPrice.productPricePerUnit = orderProduct.real.price;
                }
                else {
                    recalculatedPrice.productPrice = UtilsService.roundPrice(productRange.price * orderProduct.quantity);
                    recalculatedPrice.productPricePerUnit = productRange.price;
                }
            }
            else {
                recalculatedPrice.productPrice = orderProduct.real.price * orderProduct.quantity;
                recalculatedPrice.productPricePerUnit = orderProduct.real.price;
            }
        }
        return recalculatedPrice;
    };
    UserService.prototype.processReorderProduct = function (orderProduct) {
        var recalculatedPrice = this.calculatePrice(orderProduct);
        var selectedProductVariant = this.getProductVariant(orderProduct, orderProduct.product_variant_id);
        var product = orderProduct.real;
        var productVariant = selectedProductVariant;
        var quantity = orderProduct.quantity;
        var pricePerItem = orderProduct.real.rx ? recalculatedPrice.productPricePerUnit : ((selectedProductVariant != null) ? selectedProductVariant.price : orderProduct.real.price);
        var price = recalculatedPrice.productPrice;
        this.cartService.addProduct(product, productVariant, quantity, pricePerItem, price);
        return {
            product: product,
            productVariant: productVariant,
            quantity: quantity
        };
    };
    UserService.prototype.reorderProduct = function (orderProduct) {
        this.cartService.openCartBox(this.processReorderProduct(orderProduct));
    };
    /**
     * Updates access token in local storage and clears the cart for new users
     * @param response - set local storage
     * @param tokenInfo - get token info
     */
    UserService.prototype.setToken = function (response, tokenInfo) {
        if (tokenInfo === void 0) { tokenInfo = null; }
        var previousUser = sessionStorage.getItem("userId");
        if (previousUser !== response.data.user.id) {
            this.cartService.clearCart();
            this.checkoutService.clearCart();
        }
        if (response.data.token) {
            sessionStorage.setItem("userToken", response.data.token);
        }
        if (tokenInfo && tokenInfo.admin_id) {
            sessionStorage.setItem("adminId", tokenInfo.admin_id);
            sessionStorage.setItem("userTokenExpiration", new Date(tokenInfo.exp * 1000).toString());
        }
        else {
            sessionStorage.setItem("userTokenExpiration", new Date(new Date().getTime() + 360 * 60000).toString());
        }
        sessionStorage.setItem("userFname", response.data.user.first_name);
        sessionStorage.setItem("userId", response.data.user.id);
    };
    /**
     * Returns user ID
     */
    UserService.prototype.getUserId = function () {
        return parseInt(sessionStorage.getItem("userId"), 10);
    };
    /**
     * Returns token expiration
     */
    UserService.prototype.getTokenExpiration = function () {
        return sessionStorage.getItem("userTokenExpiration");
    };
    /**
     * Returns access token
     */
    UserService.prototype.getToken = function () {
        return sessionStorage.getItem("userToken");
    };
    /**
     * Returns access token
     */
    UserService.prototype.getAdminId = function () {
        return sessionStorage.getItem("adminId");
    };
    /**
     * Checks if user is logged in based on token expiration
     */
    UserService.prototype.isLoggedIn = function () {
        return this.getToken() && (new Date() < new Date(this.getTokenExpiration()));
    };
    /**
     * get account info
     */
    UserService.prototype.impersonate = function (token) {
        var tokenInfo = jwt_decode(token);
        sessionStorage.clear();
        sessionStorage.setItem("location", this.rootUrl);
        sessionStorage.setItem("userToken", token);
        sessionStorage.setItem("userTokenExpiration", new Date(tokenInfo.exp * 1000).toString());
        return this.http.get(this.rootUrl + "/customers/info");
    };
    /**
     * Logs the user out and clears local storage
     * Redirects to signin page
     */
    UserService.prototype.logout = function (returnUrl, doRedirect) {
        if (returnUrl === void 0) { returnUrl = null; }
        if (doRedirect === void 0) { doRedirect = true; }
        sessionStorage.clear();
        sessionStorage.setItem("location", this.rootUrl);
        if (this.router.routerState.snapshot.url.indexOf('signin') === -1 && doRedirect) {
            this.router.navigate(["signin"], { queryParams: { returnUrl: returnUrl } });
        }
    };
    /**
     * API for user login
     * @param email - User email
     * @param password - User password
     */
    UserService.prototype.userAuthentication = function (email, password) {
        var body = {
            email: email,
            password: password
        };
        return this.http.post(this.rootUrl + "/login", body, {
            headers: reqHeader
        });
    };
    /**
     * API for user registration - step 1
     * @param reginfo - Registration info
     */
    UserService.prototype.userSignupStepone = function (reginfo) {
        return this.http.post(this.rootUrl + "/register", reginfo, {
            headers: reqHeader
        });
    };
    /**
     * API for user registration - step 2
     * @param reginfo - Registration info
     */
    UserService.prototype.completeProfile = function (reginfo) {
        return this.http.put(this.rootUrl + "/customers/complete-profile", reginfo, {
            headers: reqHeader
        });
    };
    /**
     * API for user registration - rx info from checkout
     * @param reginfo - Registration info
     */
    UserService.prototype.completeProfileRx = function (reginfo) {
        return this.http.put(this.rootUrl + "/customers/complete-profile-rx", reginfo, {
            headers: reqHeader
        });
    };
    /**
     * API for forgot password
     **/
    UserService.prototype.forgotPassword = function (data) {
        return this.http.post(this.rootUrl + "/forgot/password ", data, {
            headers: reqHeader
        });
    };
    /**
     * API for reset password
     */
    UserService.prototype.resetPassword = function (resetData) {
        return this.http.post(this.rootUrl + "/password/change ", resetData, {
            headers: reqHeader
        });
    };
    /**
     * API for password reset link verification
     */
    UserService.prototype.verifyResetLink = function (resetlink) {
        return this.http.post(this.rootUrl + "/password/verify-token ", resetlink, {
            headers: reqHeader
        });
    };
    /**
     * Updates medication value
     */
    UserService.prototype.setmedicationValue = function (value) {
        this.sendMedication.next(true);
    };
    /**
     * API for user details
     * @param id - User id
     */
    UserService.prototype.getUserDetails = function () {
        return this.http.get(this.rootUrl + "/customers/info");
    };
    /**
     * API for featured products
     */
    UserService.prototype.getFeaturedProductList = function () {
        return this.http.get(this.rootUrl + "/products/featured", { headers: reqHeader });
    };
    /**
     * API for recent products
     */
    UserService.prototype.getRecentProductList = function () {
        var data = {
            filters: {
                customer: sessionStorage.getItem("userId"),
            },
            options: {
                order_by: "created",
                order_type: "desc",
                per_page: 3
            }
        };
        return this.http.post(this.rootUrl + "/orders/recent", data, { headers: reqHeader });
    };
    /**
     * API for adding new medication
     * @param medicationData - Medication data
     */
    UserService.prototype.addNewMedication = function (medicationData) {
        return this.http.post(this.rootUrl + "/requested-medications/add", medicationData, { headers: reqHeader });
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient), i0.inject(i2.Router), i0.inject(i3.CartService), i0.inject(i4.CheckoutService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
