<footer class="clsmain_footer">
  <div class="clsfooter_top">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-3">
          <div class="clsfooter_logo">
              <div><img src="assets/images/reverse_logo@2x.png" alt="" width="194" /></div>
            <p>833-497-7370<br />
              <a  href="mailto:hello@valisure.com">hello@valisure.com</a></p>
          </div>
        </div>
        <div class="col-md-8 col-sm-9">
          <div class="clsfooter_menu_blk">
            <div class="row">
              <div class="col-md-3 col-sm-6">
                <h3>Company</h3>
                <ul>
                  <li><a [routerLink]="['/shop/products/rx']" >Shop RX</a></li>
                  <li><a [routerLink]="['/shop/products/otc']" >Shop OTC</a></li>
                  <!--<li><a [routerLink]="['/shop/medication-testing']" >Medication Testing</a></li> -->
                  <li><a href="{{menuItems.howItWorks}}" target="_blank">How It Works</a></li>
                  <li><a href="{{menuItems.contactUs}}" target="_blank">Contact Us</a></li>
                  <li><a href="{{menuItems.press}}" target="_blank">Press</a></li>
                </ul>
              </div>
              <div class="col-md-3 col-sm-6">
                <h3>More Info</h3>
                <ul>
                  <li><a href="{{menuItems.news}}" target="_blank">News</a></li>
                  <li><a href="{{menuItems.forDoctors}}" target="_blank">For Doctors</a></li>
                  <li><a href="{{menuItems.faq}}" target="_blank">FAQ</a></li>
                  <li><a href="{{menuItems.reportABadMed}}" target="_blank">Report a Medication Concern</a></li>
                  <li><a [routerLink]="['/shipping-policy']">Shipping Policy</a></li>
                  <li><a [routerLink]="['/return-policy']">Return & Refund Policy</a></li>
                </ul>
              </div>
              <div class="col-md-3 col-sm-6">
                <h3>Follow Us</h3>
                <ul>
                  <li><a href="{{menuItems.facebook}}" target="_blank">Facebook</a></li>
                  <li><a href="{{menuItems.twitter}}" target="_blank">Twitter</a></li>
                  <li><a href="{{menuItems.linkedIn}}" target="_blank">LinkedIn</a></li>
                </ul>
              </div>
              <div class="col-md-3 col-sm-6">
                <h3>Legal</h3>
                <ul>
                  <li><a href="{{menuItems.termsOfUse}}" target="_blank">Terms of Use</a></li>
                  <li><a href="{{menuItems.privacyPolicy}}" target="_blank">Privacy Policy</a></li>
                  <li><a href="{{menuItems.HIPAAPolicy}}" target="_blank">HIPAA Policy</a></li>
                  <li><a href="{{menuItems.internetPharmacySiteDisclosure}}" target="_blank">Internet Pharmacy Site Disclosure</a></li>
                  <li><a href="{{menuItems.cOADisclaimer}}" target="_blank">COA Disclaimer</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="clscopyright">
    <div class="container">
      <p>&#169; {{year}} Valisure - All Rights Reserved.</p>
    </div>
  </div>
</footer>