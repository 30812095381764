import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.css']
})
export class SidePanelComponent implements OnInit {
  public selectedTab: any;

  constructor(private router: Router, public userService: UserService) { }

  ngOnInit() {
    this.selectedTab = this.router.url.split('/').pop();
  }
}
