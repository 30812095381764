/*
  * SignUp functionality Step 2:
  * @ Created by : Rajasingh S
  * @ Modified by :
  * @ Created date : 14-12-2018
  * @ Modified date : 17-12-2018
  */

import {Component, OnInit} from "@angular/core";
import {FormGroup, FormBuilder, Validators, FormArray, FormControl} from "@angular/forms";
import {Router} from "@angular/router";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ElementRef} from "@angular/core";
import {DatePipe} from "@angular/common";

/*
  * To import custom ingredients:
  */
import {UserService} from "../../shared/user.service";
import {AccountService} from "app/account/account.service";
import {FormatService} from "@services/format.service";

import {SelectItem} from "primeng/api";
import {ServerResponse} from "../../model/base/server-response.model";
import {UserInsurance, UserInsuranceCardholder} from "../model/user-insurance.model";
import {User, UserData} from "../model/user.model";
import {UtilsService} from "@services/utils.service";
import {ValidationService} from "@services/validation.service";

@Component({
    selector: "app-personalinfo",
    templateUrl: "./personalinfo.component.html",
    styleUrls: ["./personalinfo.component.scss"]
})
export class PersonalinfoComponent implements OnInit {
    readonly rootUrl = sessionStorage.getItem("location");
    public title = "Tell us a little about yourself";
    public submitted = false;
    public personalinfoForm: FormGroup;
    public currentUserID: any;
    public allergyVal: String = "";
    public allergyRel: String = "";
    public allergies: any[];
    public userDetailObj: User;
    public loadingResponse: boolean = false;
    public states: any[] = [];
    public cardholderRelationship: SelectItem[];
    public insurances: UserInsurance[] = [];
    public showAddress: any = false;
    public errorMessage: string = "";
    public insuranceErrorMessage: string = "";
    public insuranceIndexToDelete: number | null = null;

    constructor(private formBuilder: FormBuilder,
                private userService: UserService,
                private http: HttpClient,
                private router: Router,
                private elem: ElementRef,
                private datePipe: DatePipe,
                private accountService: AccountService) {
        this.createForm();
        this.getCardRelationshipOptions();

        this.accountService.getStateLists().subscribe((states: any) => {
            this.states = Object.keys(states.data).map(key => {
                return {
                    label: states.data[key].name,
                    value: states.data[key].id
                };
            });
            this.states.splice(0, 0, {value: "", label: "Select State"});
        });
    }

    ngOnInit() {
        this.currentUserID = sessionStorage.getItem("userId");
        this.getUserDetailsData();
        // this.getInsuranceData();
    }

    /**
     * State ID of the selected state
     */
    get state() {
        return this.personalinfoForm.get("state_id");
    }

    /**
     * Address of the selected state
     */
    get address() {
        return this.personalinfoForm.get("address");
    }

    /**
     * Address of the selected state
     */
    get city() {
        return this.personalinfoForm.get("city");
    }

    /**
     * Zip code
     */
    get zip_code() {
        return this.personalinfoForm.get("zip");
    }

    /**
     * Insurances Form Array
     */
    get insurancesFormArray(): FormArray {
        return this.personalinfoForm.get("insurances") as FormArray;
    }

    /**
     * Initiates the form group
     */
    createForm() {
        this.personalinfoForm = this.formBuilder.group({
            dob: ["", [Validators.required, ValidationService.dateValidator]],
            address_id: "",
            allergies: ["", {
                validator: Validators.required,
                updateOn: "change"
            }],
            noallergies: ["", {updateOn: "change"}],
            address: ["", [Validators.required]],
            address_2: "",
            city: ["", [Validators.required]],
            state_id: ["", {
                updateOn: "change",
                validators: Validators.required
            }],
            zip: ["", [Validators.required, Validators.pattern('[0-9]{5}(-[0-9]{4})?')]],
            noinsurance: ["", {
                updateOn: "change"
            }],
            insurances: this.formBuilder.array([], Validators.required),
        }, {updateOn: "blur"});

        this.setDynamicValidators();
    }
    /**
     * Checkes if selected key is a number
     * @param event - Key event
     */
    onlyNumberKey(event) {
        return event.charCode == 8 || event.charCode == 0
            ? null
            : (event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 45);
    }

    getInsuranceFormGroup(data: UserInsurance = null, addValidators = true) {
        return this.formBuilder.group({
            id: [data ? data.id : ""],
            company: [data ? data.company : "", addValidators ? Validators.required : null],
            id_member: [data ? data.id_member : "", addValidators ? Validators.required : null],
            rx_grp: [data ? data.rx_grp : ""],
            rx_bin: [data ? data.rx_bin : ""],
            rx_pcn: [data ? data.rx_pcn : ""],
            cardholder_id: [data ? data.cardholder_id : "", {
                updateOn: "change",
                validators: addValidators ? Validators.required : null
            }]
        }, {updateOn: "blur"});
    }

    /**
     * Controls dynamic validators
     */
    setDynamicValidators() {
        const allergies = this.personalinfoForm.get("allergies");
        this.personalinfoForm.get("noallergies").valueChanges
            .subscribe(
                (value: boolean) => {
                    if (!value) {
                        allergies.enable();
                        allergies.setValidators([Validators.required]);
                    } else {
                        allergies.disable();
                        allergies.reset();
                        allergies.clearValidators();
                    }
                    allergies.updateValueAndValidity();
                }
            );

        this.personalinfoForm.get("noinsurance").valueChanges
            .subscribe(
                (value: boolean) => {
                    this.insuranceErrorMessage = "";
                    if (!value) {
                        this.insurancesFormArray.setValidators([Validators.required]);
                        this.insurancesFormArray.controls.forEach((insuranceFormGroup: FormGroup) => {
                            insuranceFormGroup.get("company").setValidators([Validators.required]);
                            insuranceFormGroup.get("id_member").setValidators([Validators.required]);
                            insuranceFormGroup.get("cardholder_id").setValidators([Validators.required]);
                        });
                    } else {
                        this.insurancesFormArray.clearValidators();
                        this.insurancesFormArray.controls.forEach((insuranceFormGroup: FormGroup) => {
                            insuranceFormGroup.get("company").setValidators(null);
                            insuranceFormGroup.get("id_member").setValidators(null);
                            insuranceFormGroup.get("cardholder_id").setValidators(null);

                            insuranceFormGroup.get("company").updateValueAndValidity();
                            insuranceFormGroup.get("id_member").updateValueAndValidity();
                            insuranceFormGroup.get("cardholder_id").updateValueAndValidity();
                        });
                        UtilsService.markFormGroupPristine(this.insurancesFormArray);
                    }
                    this.insurancesFormArray.updateValueAndValidity();
                }
            );

        this.insurancesFormArray.valueChanges.subscribe((value) => {
            this.insuranceErrorMessage = "";
        });
    }

    public onAddNewInsurance() {
        if (this.insurancesFormArray.length < 2) {
            this.insurancesFormArray.push(this.getInsuranceFormGroup(null, true));
        }
    }


    public onDeleteInsurance(index) {
        // this.insurancesFormArray.removeAt(index);
        this.insuranceIndexToDelete = index;
    }

    public deleteInsurance() {
        if (this.insuranceIndexToDelete !== null) {
            this.insurancesFormArray.removeAt(this.insuranceIndexToDelete);
            this.insuranceIndexToDelete = null;
        }
    }

    onSuggestedAddressSelected(address) {
        if (address.address_line_1) {
            this.personalinfoForm.get('address').setValue(address.address_line_1);
        }
        if (address.city) {
            this.personalinfoForm.get('city').setValue(address.city);
        }
        if (address.state) {
            const selectedState = this.states.find((state) => {
                return state.label === address.state;
            });
            if (selectedState) {
                this.personalinfoForm.get('state_id').setValue(selectedState.value);
            }
        }
        if (address.zip_code) {
            this.personalinfoForm.get('zip').setValue(address.zip_code);
        }

    }


    /**
     * PersonalInfo form submit, updates personal info
     */
    onSubmit() {
        this.errorMessage = "";

        if (!this.personalinfoForm.valid) {
            UtilsService.markFormGroupTouched(this.personalinfoForm);
            if (this.insurancesFormArray.invalid && !this.personalinfoForm.get("noinsurance").value) {
                this.insuranceErrorMessage = "Please complete insurance info or select \"No Insurance\"";
            }
            return;
        }

        this.loadingResponse = true;
        this.personalinfoForm.value["user_id"] = sessionStorage.getItem("userId");
        this.submitted = true;

        const personalInfo: any = Object.assign({}, this.personalinfoForm.value);

        if (this.personalinfoForm.get("noallergies").value) {
            personalInfo.allergies = "No drug allergies";
        } else {
            personalInfo.allergies = this.personalinfoForm.get("allergies").value.join(",");
        }

        personalInfo.dob = FormatService.formatDateToISO(this.personalinfoForm.get("dob").value, "/", "-");

        this.userService.completeProfile(personalInfo).subscribe((redStep2: any) => {
                this.loadingResponse = false;

                if (redStep2.status && redStep2.status.success) {
                    this.router.navigate(["/"]);
                } else {
                    if (redStep2.status) {
                        this.errorMessage = redStep2.status.message;
                    }
                }
            },
            (err: HttpErrorResponse) => {
                this.loadingResponse = false;
            });
    }

    /**
     * API for user details
     */
    getUserDetailsData() {
        this.userService.getUserDetails()
            .subscribe((response: ServerResponse<UserData>) => {
                if (response.data) {
                    this.userDetailObj = response.data.user;
                    this.personalinfoForm.patchValue({
                        "dob": this.datePipe.transform(this.userDetailObj.birth_date, "MM/dd/yyyy"),
                        "noallergies": this.userDetailObj.medical_info.allergies === "No drug allergies",
                        "noinsurance": !!this.userDetailObj.no_insurance,
                        "allergies": this.userDetailObj.medical_info.allergies && this.userDetailObj.medical_info.allergies !== "No drug allergies" ? this.userDetailObj.medical_info.allergies.split(",").filter(el => !!el) : [],
                        "address_id": this.userDetailObj.addresses && this.userDetailObj.addresses.length && this.userDetailObj.addresses[0].id ? this.userDetailObj.addresses[0].id : "",
                        "address": this.userDetailObj.addresses && this.userDetailObj.addresses.length && this.userDetailObj.addresses[0].address_line_1 ? this.userDetailObj.addresses[0].address_line_1 : "",
                        //"address_2": this.userDetailObj.addresses && this.userDetailObj.addresses.length && this.userDetailObj.addresses[0].address_line_2 ? this.userDetailObj.addresses[0].address_line_2 : "",
                        "city": this.userDetailObj.addresses && this.userDetailObj.addresses.length && this.userDetailObj.addresses[0].city ? this.userDetailObj.addresses[0].city : "",
                        "state_id": this.userDetailObj.addresses && this.userDetailObj.addresses.length && this.userDetailObj.addresses[0].state_id ? this.userDetailObj.addresses[0].state_id : "",
                        "zip": this.userDetailObj.zip_code ? this.userDetailObj.zip_code : "",

                    });

                    if (this.userDetailObj.insurances && this.userDetailObj.insurances.length) {
                        this.userDetailObj.insurances.map(insurance => {
                            this.insurancesFormArray.push(this.getInsuranceFormGroup(insurance, true));
                        });
                    } else {
                        this.insurancesFormArray.push(this.getInsuranceFormGroup(null, !this.userDetailObj.no_insurance));
                    }
                }
            });
    }

    /**
     * Fetch "insurance card relationships" options
     */
    getCardRelationshipOptions() {
        this.cardholderRelationship = [];
        this.cardholderRelationship.push({label: "Please select", value: ""});
        this.http
            .get(this.rootUrl + "/cardholder-relationship")
            .subscribe((response: ServerResponse<UserInsuranceCardholder[]>) => {
                if (response.status.success) {
                    response.data.forEach(value => {
                        this.cardholderRelationship.push({
                            label: value.name,
                            value: value.id
                        });
                    });
                }
            });
    }

}
