import { Component, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import { CartService } from '@modules/cart/services/cart.service';
import { CartProduct } from '@modules/cart/models/cart-product.model';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import {ProductService} from "@shop/services";
import {UtilsService} from "@services/utils.service";

declare var $: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})

export class CartComponent implements OnInit, OnDestroy {
  private cartProductsSubscription: Subscription;
  public cartProducts: CartProduct[];
  public editProduct: CartProduct = null;
  public editProductHasPricePerUnit = false;
  public editProductPricePerUnit = 0.00;
  public quantityError = false;
  public productQuantity = 1;

  @ViewChild("productEditModal") productEditModal: ElementRef<HTMLElement>;
  @ViewChild("customPillCountInput") customPillCountInput: ElementRef<HTMLInputElement>;

  constructor(private cartService: CartService,
              public productService: ProductService,
              private router: Router) {
    this.cartProductsSubscription = this.cartService.getCartProductsSubject().subscribe(
      (products: CartProduct[]) => {
        this.cartProducts = this.cartService.getFlattenedProducts();
      }
    );
  }

  ngOnInit() {

    this.cartService.updateCart();
  }
  public getSubtotal(): string {
    return this.cartProducts && this.cartProducts.length
        ? this.cartProducts.map(p => p.price).reduce((f, s) => f + s).toFixed(2)
        : "0.00";
  }
  ngOnDestroy() {
    if (this.cartProductsSubscription) {
      this.cartProductsSubscription.unsubscribe();
    }
  }

  /**
   * Navigate back to products
   */
  public backToShopping() {
    this.router.navigate(['shop/products/rx']);
  }

  /**
   * Navigate to checkout
   */
  public navigateToCheckout() {
    this.router.navigate(['/checkout']);
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }

  /**
   * Navigate to checkout
   */
  public editProductQuantity(cartProductId) {
    this.editProduct = this.getProductCopy({
      id : this.cartProducts[cartProductId].id,
      product : this.cartProducts[cartProductId].product,
      productVariant : this.cartProducts[cartProductId].productVariant,
      quantity : this.cartProducts[cartProductId].quantity,
      pricePerItem : this.cartProducts[cartProductId].pricePerItem,
      price : this.cartProducts[cartProductId].price,
    });

    this.editProductPricePerUnit = this.editProduct.pricePerItem;
    if (!this.editProduct.product.rx) {
      if (this.editProduct.product.variants.length > 0) {
          if (this.productService.getUnitDescriptor(this.editProduct.product.type, 1) === 'pill') {
              this.editProductHasPricePerUnit = true;
              this.editProductPricePerUnit = this.editProduct.productVariant.price / this.editProduct.productVariant.quantity;
          }
      }
    } else {
        this.editProductHasPricePerUnit = true;
    }
    this.productQuantity = this.editProduct.quantity;
    this.quantityError = false;
    $("#productEditModal").modal('show');
    setTimeout(() =>
      this.customPillCountInput.nativeElement.focus(),
    20);
    //@ViewChild("customPillCountInput") customPillCountInput: ElementRef<HTMLInputElement>;
  }

  public productQuantityChanged() {
    this.quantityError = false;
    this.calculatePrice();
    if (!(this.productQuantity && this.productQuantity > 0)) {
      this.quantityError = true;
    }
}
private getProductPriceRange(product, quantity) {

  return product.variants.sort(function (a, b) {
      return a.quantity - b.quantity;
  }).reduce(function (variantSearched, variant, index) {
      if (variantSearched.quantity >= variant.quantity) {
          variantSearched.variant = variant;
      }
      return variantSearched;
  }, {variant: null, quantity: quantity}).variant;
}
  private calculatePrice() {
    if (!this.editProduct.product.rx) {
        if (this.editProduct.product.variants.length > 0) {
            this.editProduct.price = UtilsService.roundPrice(this.editProduct.productVariant.price * this.productQuantity);
            this.editProduct.pricePerItem = this.editProduct.productVariant.price ;
            this.editProductPricePerUnit = this.editProduct.productVariant.price / this.editProduct.productVariant.quantity;
        } else {
          this.editProduct.price = UtilsService.roundPrice(this.editProduct.product.price * this.productQuantity);
          this.editProduct.pricePerItem = this.editProduct.product.price ;
          this.editProductPricePerUnit = this.editProduct.product.price;
        }
    } else {
        if (this.editProduct.product.variants.length > 0) {
            const productRange = this.getProductPriceRange(this.editProduct.product, this.productQuantity);
            //console.log(productRange);
            if (productRange == null) {
              this.editProduct.price = UtilsService.roundPrice(this.editProduct.product.price * this.productQuantity);
              this.editProduct.pricePerItem = this.editProduct.product.price;
              this.editProductPricePerUnit = this.editProduct.product.price;
            } else {
              this.editProduct.price = UtilsService.roundPrice(productRange.price * this.productQuantity);
              this.editProduct.pricePerItem = productRange.price;
              this.editProductPricePerUnit = productRange.price;
            }
        } else {
          this.editProduct.price = this.editProduct.product.price * this.productQuantity;
          this.editProduct.pricePerItem = this.editProduct.product.price;
          this.editProductPricePerUnit = this.editProduct.product.price;
        }
    }
  }
  /**
   * Remove product from cart
   * @param id - Product ID
   */
  public updateCart() {
    if (this.productQuantity && this.productQuantity > 0) {
      this.cartService.updateProduct(this.editProduct.product , this.editProduct.productVariant, this.productQuantity, this.editProduct.pricePerItem, this.editProduct.price);
      this.editProduct = null;
      this.editProductHasPricePerUnit = false;
      this.quantityError = false;
      this.productQuantity = 1;
    }else {
      this.quantityError = true;
    }
  }
  
  /**
   * Remove product from cart
   * @param id - Product ID
   */
  public removeProduct(id: number) {
    this.cartService.removeProduct(id);
  }

  private getProductCopy(product: CartProduct) {
    return product;
  }
}
