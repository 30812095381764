<main class="clsmain_container">
  <div class="clsmedication_request_section clspolicy_section">
      <div class="container">
          <ul class="clsbreadcrumb">
              <li><a [routerLink]="routePath">Home</a></li>
              <li>Shipping Policy</li>
          </ul>
          <div class="clslmedication_request_content_blk">
              <div class="row">
                  <div class="col-md-4 col-sm-5">
                      <div class="clslmedication_request_content_left">
                          <h1>Shipping Policy</h1>
                      </div>
                  </div>
                  <div class="col-md-8 col-sm-7">
                      <div class="clspolicy_content">
                          <p>All orders ship from our pharmacy in New Haven, CT. We ship orders Monday
                              through Friday. We only ship to valid addresses within the United States to
                              states that we are licensed as a pharmacy.</p>
                          <p><strong><em>Due to potential delays in shipping, any immediate needed
                                      medications should be filled at a local pharmacy.</em></strong></p>
                          <p><strong>Please note that prescription items cannot be shipped until a valid,
                                  verified prescription is on file.</strong> Please add 1 to 2 business days
                              for processing over-the-counter(OTC) orders and 3 to 4 business days for
                              processing prescription orders.</p>
                          <hr>
                          <ul>
                              <li>
                                  <p><strong>Standard Shipping (USPS) ... $4.95</strong></p>
                                  <p><em>2-8 business days - Provides Tracking Number</em></p>
                              </li>
                              <li>
                                  <p><strong>Signature Confirmation (USPS) ... $7.95</strong></p>
                                  <p><em>2-8 business days - RECOMMENDED</em></p>
                              </li>
                              <!--
                              <li>
                                  <p><strong>UPS Tracking ... $11.95</strong></p>
                                  <p><em>1-5 business days - Provides Tracking Number (no.P.O.Box or Weekend
                                          delivery)</em></p>
                              </li>
                              -->
                              <li>
                                  <p><strong>USPS Priority ... $10.00</strong></p>
                                  <p><em>1-3 business days - Provides Tracking Number (Includes P.O.Box or Weekend delivery)</em></p>
                              </li>
                              <li>
                                  <p><strong>UPS Ground ... $11.95</strong></p>
                                  <p><em>4-7 business days - Provides Tracking Number (no.P.O.Box or Weekend delivery)</em></p>
                              </li>
                              <li>
                                  <p><strong>2-Day UPS ... $39.95</strong></p>
                                  <p><em>2 business days - Provides Tracking Number (no.P.O.Box or Weekend delivery. AK and HI can take longer)</em></p>
                              </li>
                              <li>
                                  <p><strong>Next Day Air UPS ... $84.95</strong></p>
                                  <p><em>1 business day - Provides Tracking Number (no.P.O.Box or Weekend delivery. Not available for AK and HI)</em></p>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</main>
