<div class="clswrapper">
    <div class=""></div>
    <!-- Main container -->
    <main class="clsmain_container">
        <div class="clsmyaccount_section">
            <div class="container">
                <ul class="clsbreadcrumb">
                    <li><a href="javascript:void(0);" [routerLink]="['/']">Home</a></li>
                    <li><a [routerLink]="['/account/order-history']">Account</a></li>
                    <li>Profile</li>
                </ul>
                <!--<app-top-panel></app-top-panel>-->
                <div class="clsmy_account_main_content_blk">
                    <div class="clssignup_secondary_form_blk clsmy_account_main_content_blk">
                        <!--<app-side-panel></app-side-panel>-->
                        <div class="accountContainer">
                            <h1 class="accountTitle">Profile</h1>    
                            <div class="accountBox clsaccount_profile_blk">
                                <div class="clssignup_secondary_form_blk_right clsaccount_profile_blk">
                                    <div class="clssignup_step2_form clsaccount_profile_form" style="padding-bottom: 35px;">
                                        <span class="userActions poppin_semibold">
                                            <a data-toggle="modal"
                                                (click)="forgotPassword()" 
                                                data-target="#change_pswd"
                                                class="action">
                                                Change Password
                                            </a>
                                        </span>
                                        <h3>MY INFO</h3> 
                                        
                                        <form [formGroup]="personalinfoForm" (ngSubmit)="onSubmit()" (change)="profileSaved = false" method="POST">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label for="firstName">First Name</label>
                                                        <input type="text" class="form-control" name="first_name"
                                                                formControlName="first_name"
                                                            id="firstName" placeholder="Jane">
                                                        <div *ngIf="personalinfoForm.get('first_name').invalid && (personalinfoForm.get('first_name').dirty && personalinfoForm.get('first_name').touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="personalinfoForm.get('first_name').errors.required">
                                                                This field is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label for="last_name">Last Name</label>
                                                        <input type="text" class="form-control" id="last_name"
                                                                formControlName="last_name"
                                                            name="last_name" placeholder="Doe">
                                                        <div *ngIf="personalinfoForm.get('last_name').invalid && (personalinfoForm.get('last_name').dirty && personalinfoForm.get('last_name').touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="personalinfoForm.get('last_name').errors.required">
                                                                This field is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4">
                                                    <div class="form-group">
                                                        <label for="phone">Phone</label>
                                                        <p-inputMask class="clsmedication" name="phone"
                                                                        formControlName="phone" id="phone"
                                                            placeholder="" autocomplete="off" pattern="^[0-9_.-]*$"
                                                            mask="999-999-9999" placeholder="000-000-0000"></p-inputMask>

                                                        <div *ngIf="personalinfoForm.get('phone').invalid && (personalinfoForm.get('phone').dirty && personalinfoForm.get('phone').touched)"
                                                            class="alert alert-danger">
                                                            <div *ngIf="personalinfoForm.get('phone').errors.required">
                                                                This field is required
                                                            </div>
                                                            <div *ngIf="personalinfoForm.get('phone').errors.invalidPhoneNumber">
                                                                Please enter a valid phone number
                                                            </div>
                                                            <div *ngIf="personalinfoForm.get('phone').errors.used">
                                                                This phone number is being used by another user.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--
                                            <div class="form-group">
                                                <label for="desired_meds">Desired medications</label>
                                                <p-autoComplete [suggestions]="filteredProducts" id="desired_meds"
                                                    name="desired_meds" (completeMethod)="filterProducts($event)"
                                                    [forceSelection]="true" [multiple]="true"
                                                    styleClass="clsautocomplete clsmedication" [style]="{'width':'100%'}"
                                                    [inputStyle]="{'width':'100%', 'min-width': '400px'}"
                                                                formControlName="desired_meds"
                                                    addOnBlur=true placeholder="Prescription, OTC or supplement" required>
                                                </p-autoComplete>
                                                
                                                <div *ngIf="personalinfoForm.get('desired_meds').invalid && (personalinfoForm.get('desired_meds').dirty && personalinfoForm.get('desired_meds').touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="personalinfoForm.get('desired_meds').errors.required">
                                                        This field is required
                                                    </div>
                                                </div>
                                            </div>
                                            -->
                                            <div class="form-group">
                                                <label for="dob">Date of birth</label>

                                                <p-inputMask class="clsmedication" id="dob" formControlName="dob" mask="99/99/9999" placeholder="MM/DD/YYYY" >
                                                </p-inputMask>
                                                <div *ngIf="personalinfoForm.controls.dob.hasError('invalidDate')"
                                                        class="alert alert-danger">
                                                    Please enter a valid date of birth.
                                                </div>
                                                    <div
                                                            *ngIf="personalinfoForm?.controls.dob.hasError('required') &&  (personalinfoForm.controls.dob.touched && personalinfoForm.controls.dob.dirty )"
                                                            class="alert alert-danger">
                                                        This field is required for Rx.
                                                    </div>
                                            </div>


                                            <div class="form-group">
                                                <label>Drug allergies</label>
                                                <ul class="clsfeature_list">
                                                    <li>
                                                        <div class="checkbox">
                                                            <input type="checkbox" formControlName="noallergies" name="noallergies"
                                                                    id="noallergies">
                                                            <label for="noallergies">
                                                                <span></span>
                                                                No drug allergies</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <p-chips class="clschips" formControlName="allergies" addOnBlur=true></p-chips>
                                                <div
                                                        *ngIf="personalinfoForm?.controls.allergies.hasError('required') &&  (personalinfoForm.controls.allergies.touched && personalinfoForm.controls.allergies.dirty )"
                                                        class="alert alert-danger">
                                                    Required for Rx. Add allergies or check the box to confirm you have none.
                                                </div>
                                            </div>

                                            <hr />

                                            <!---------------------------------------------------------------
                                            -
                                            - INSURANCES
                                            -
                                            ---------------------------------------------------------------->


                                            <h3>Insurance Info <span>(optional)</span></h3>
                                            <div class="insurance-info">
                                                <p>
                                                    <span style="padding-right: 15px"><img alt="" src="assets/images/info_icon.png" width="18"></span>
                                                    <span>Please note: <strong>Currently we only accept a limited number of insurance providers and plans.</strong> Please speak with one of our pharmacists for more information.</span>
                                                </p>
                                            </div>

                                            <h4 *ngIf="insurancesFormArray.controls.length === 0">No Insurance</h4>

                                            <div formArrayName="insurances">

                                                <app-insurance-form
                                                        *ngFor="let insurance of insurancesFormArray.controls; let i=index"
                                                        [formGroupName]="i"
                                                        [index]="i"
                                                        [editing]="!insurancesFormArray.controls[i].get('id').value"
                                                        [insurance]="userDetailObj.insurances[i]"
                                                        [formGroup]="insurancesFormArray.controls[i]"
                                                        [cardholderRelationship]="cardholderRelationship"
                                                        [allowDelete]="true"
                                                        (onDelete)="onDeleteInsurance(i)"
                                                >
                                                </app-insurance-form>

                                                <div class="clsaddinsurance_button"
                                                        *ngIf="insurancesFormArray.controls.length < 2">
                                                    <a (click)="onAddNewInsurance()"><span>Add Insurance</span></a>
                                                </div>

                                                <div class="form-group" *ngIf="insuranceErrorMessage">
                                                    <div  class="alert alert-danger" style="margin-top: 20px">
                                                        {{ insuranceErrorMessage }}
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="form-group" *ngIf="errorMessage">
                                                <div class="alert alert-danger" style="margin-top: 20px">
                                                    {{ errorMessage }}
                                                </div>
                                            </div>

                                            <div class="btn_line" style="text-align: right">
                                                <p class="profile-saved" *ngIf="profileSaved">Profile saved</p>
                                                <a *ngIf="!loadingResponse" href="javascript:void(0)" class="action  poppin_semibold"
                                                    (click)="onSubmit()">
                                                    Save
                                                </a>
                                                <span *ngIf="loadingResponse">Loading. Please wait... </span>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
<div class="modal fade clsaddress_delete_confirm_modal clschage_pswd_modal" id="change_pswd" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel">
    <div class="vertical-alignment-helper">
        <div class="modal-dialog vertical-align-center" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                    <div class="modal-title">An email has been sent to your email address with further instructions for password reset.</div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade clsaddress_delete_confirm_modal" id="deleteInsuranceModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="vertical-alignment-helper">
        <div class="modal-dialog vertical-align-center" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title" id="myModalLabel">Are you sure you want to delete this insurance?</div>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xs-6">
                            <a href="javascript:void(0)" data-dismiss="modal" class="action poppin_semibold">No</a>
                        </div>
                        <div class="col-xs-6">
                            <a href="javascript:void(0)" data-dismiss="modal" (click)="deleteInsurance()" class="action poppin_semibold">Yes</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>