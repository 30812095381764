import {
    Component,
    DoCheck,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import * as uuid from "uuid";
import {ServerResponse} from "../../../../model/base/server-response.model";
import {AccountService} from "../../../../account/account.service";
import {UserAddressSuggestions} from "../../../../user/model/user-address-suggestions.model";
import {UserAddressSuggestion} from "../../../../user/model/user-address-suggestion.model";
import {of, Subject, Subscription} from "rxjs";
import {debounceTime, delay, distinctUntilChanged, map, mergeMap} from "rxjs/operators";
import {UserAddressSuggestionDetails} from "../../../../user/model/user-address-suggestion-details.model";
import {UserAddressSuggestionComponent} from "../../../../user/model/user-address-suggestion-component.model";

@Component({
    selector: "app-address-autocomplete",
    templateUrl: "./address-autocomplete.component.html",
    styleUrls: ["./address-autocomplete.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressAutocompleteComponent),
            multi: true
        }
    ],
})
export class AddressAutocompleteComponent implements ControlValueAccessor, DoCheck, OnInit, OnDestroy {

    @Input() value = "";
    @Output() addressSelected = new EventEmitter<any>();
    focused = false;
    loading = false;
    suggestions: UserAddressSuggestion[] = [];
    sessionToken = "";
    errorMessage = "";
    keyTyped = false;

    lookupSubject = new Subject<string>();
    lookupSubscription: Subscription;

    highlightValue: UserAddressSuggestion = null;

    get suggestionsVisible() {
        return this.focused && this.value && this.keyTyped;
    }

    constructor(private accountService: AccountService) {
        this.generateSessionToken();
    }

    private generateSessionToken() {
        this.sessionToken = uuid.v4();
    }

    ngOnInit() {
        this.lookupSubscription = this.lookupSubject.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            mergeMap(search => this.lookupKeywords())
        ).subscribe((res) => {
            this.lookupKeywordsCallback(res);
        }, (errorResponse) => {
            this.suggestions = [];
            this.errorMessage = "Could not retrieve address suggestions.";
            this.loading = false;
        });

    }

    ngOnDestroy(): void {
        this.lookupSubscription.unsubscribe();
    }

    ngDoCheck(): void {
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        if (obj !== undefined && obj !== null && typeof obj === "string") {
            this.value = obj;
        } else {
            this.value = "";
        }
    }

    propagateChange = (_: any) => {
    };
    propagateTouched = () => {
    };

    onChange(event) {
        this.propagateChange(this.value);
        this.propagateTouched();
    }

    onInputFocus() {
        this.focused = true;
    }

    onInputBlur(event) {
        /*
        setTimeout(() => {
            this.keyTyped = false;
            this.focused = false;
            this.propagateTouched();
        }, 200);

         */
        this.propagateTouched();
    }

    onComponentClick(event) {
        event.stopPropagation();
        this.focused = true;
    }

    @HostListener("focus")
    onComponentFocus() {
        //this.focused = true;
        this.suggestions = [];
    }

    @HostListener("blur")
    onComponentBlur() {
        //console.log("blur");
    }

    @HostListener("keypress", ["$event"])
    hostKeyUp($event: KeyboardEvent) {
        if ($event) {
            if ($event.keyCode === 40) { // Down
                $event.preventDefault();
                if (this.highlightValue && this.suggestions.length) {
                    const currentIndex = this.suggestions.findIndex((val) => {
                        return val.place_id === this.highlightValue.place_id;
                    });
                    if (currentIndex < this.suggestions.length - 1) {
                        this.highlightValue = this.suggestions[currentIndex + 1];
                    }
                }
            } else if ($event.keyCode === 38) { // Up
                $event.preventDefault();
                if (this.highlightValue && this.suggestions.length) {
                    const currentIndex = this.suggestions.findIndex((val) => {
                        return val.place_id === this.highlightValue.place_id;
                    });
                    if (currentIndex > 0) {
                        this.highlightValue = this.suggestions[currentIndex - 1];
                    }
                }

            } else if ($event.keyCode === 9) { // Tab or Space
                if (this.highlightValue) {
                    this.onSelectAddress(this.highlightValue);
                }
            }
        }
    }


    onKeyDown(event: KeyboardEvent) {
        if (event && ![9, 38, 40].includes(event.keyCode)) {
            //console.log("key up", this.value);

            this.keyTyped = true;
            this.loading = true;
            this.errorMessage = "";
            this.highlightValue = null;

            this.lookupSubject.next(this.value);
        }
        else {
            event.preventDefault();
            this.hostKeyUp(event);
        }
    }

    lookupKeywords() {
        return this.accountService.getAutocomplete({
            input: this.value,
            sessionToken: this.sessionToken
        });
    }

    lookupKeywordsCallback(response) {
        this.loading = false;
        if (response && response.status && response.status.success) {
            if (response.data) {
                switch (response.data.status) {
                    case "OK":
                        this.suggestions = response.data.predictions;
                        if (this.suggestions.length) {
                            this.highlightValue = this.suggestions[0];
                        }
                        break;
                    case "ZERO_RESULTS":
                        this.suggestions = [];
                        break;
                    default:
                        this.suggestions = [];
                        this.errorMessage = "Could not retrieve address suggestions.";
                }
            } else {
                this.suggestions = [];
                this.errorMessage = "Could not retrieve address suggestions.";
            }
        } else {
            this.suggestions = [];
            this.errorMessage = "Could not retrieve address suggestions.";
        }
    }


    onSelectAddress(suggestion: UserAddressSuggestion, event?: MouseEvent) {
        if (event) {
            event.stopPropagation();
        }

        this.loading = true;
        this.keyTyped = false;

        this.accountService.getAutocompleteDetails({
            place_id: suggestion.place_id,
            sessionToken: this.sessionToken
        }).subscribe((response: ServerResponse<UserAddressSuggestionDetails>) => {
            this.lookupDetailsCallback(response);
        }, (error) => {
            // Show error?
        }, () => {
            this.loading = false;
            this.generateSessionToken();
        });

    }


    lookupDetailsCallback(response: ServerResponse<UserAddressSuggestionDetails>) {
        if (response && response.status && response.status.success) {
            if (response.data) {
                switch (response.data.status) {
                    case "OK":

                        const components = response.data.result.address_components;

                        const address = {
                            address_blocks: [],
                            city_blocks: [],
                            address_line_1: "",
                            city: "",
                            state: "",
                            zip_code: ""
                        };

                        const hasCity = components.find((comp) => {
                            return comp.types.includes('locality');
                        })

                        components.forEach((component: UserAddressSuggestionComponent) => {
                            if (component.types.includes("floor")) {
                                address.address_blocks.push(component.short_name + ",");
                            }
                            if (component.types.includes("street_number")) {
                                address.address_blocks.push(component.long_name ? component.long_name : component.short_name);
                            }
                            if (component.types.includes("route")) {
                                address.address_blocks.push(component.long_name ? component.long_name : component.short_name);
                            }
                            if (component.types.includes("locality")) {
                                address.city_blocks.push(component.long_name ? component.long_name : component.short_name);
                            }
                            if (component.types.includes("neighborhood") && !hasCity && address.city_blocks.length === 0) {
                                address.city_blocks.push(component.long_name ? component.long_name : component.short_name);
                            }
                            if (component.types.includes("sublocality_level_1") && !hasCity && address.city_blocks.length === 0) {
                                address.city_blocks.push(component.long_name ? component.long_name : component.short_name);
                            }

                            if (component.types.includes("administrative_area_level_1")) {
                                address.state = component.long_name;
                            }
                            if (component.types.includes("postal_code")) {
                                address.zip_code = component.short_name;
                            }
                        });

                        address.address_line_1 = address.address_blocks.join(" ");
                        address.city = address.city_blocks.join(" ");

                        this.addressSelected.emit(address);

                        break;
                    default:
                        this.errorMessage = "Could not retrieve address details.";
                }
            } else {
                this.suggestions = [];
                this.errorMessage = "Could not retrieve address details.";
            }
        } else {
            this.suggestions = [];
            this.errorMessage = "Could not retrieve address details.";
        }
    }

}
