/**
 * Navigation links
 */
export const menuitems: MenuItems = {
  howItWorks: 'https://www.valisure.com/how-it-works/',
  aboutUs: 'https://www.valisure.com/about-us/',
  analysis: 'https://www.valisure.com/valisure-analysis/',
  forDoctors: 'https://www.valisure.com/for-doctors/',
  news: 'https://www.valisure.com/blog/valisure-news/',
  faq: 'https://www.valisure.com/faq/',
  contactUs: 'https://www.valisure.com/contact-us/',
  press: 'https://www.valisure.com/blog/valisure-news/',
  reportABadMed: 'https://www.valisure.com/report-medication-concern/',
  shippingPolicy: '/',
  returnRefundPolicy: '/ ',
  facebook: 'https://www.facebook.com/valisurellc/ ',
  twitter: 'https://twitter.com/valisure ',
  linkedIn: 'https://www.linkedin.com/company/valisure/',
  termsOfUse: 'https://www.valisure.com/terms-of-use/',
  privacyPolicy: 'https://www.valisure.com/privacy-policy/',
  HIPAAPolicy: 'https://www.valisure.com/hipaa-policy/',
  internetPharmacySiteDisclosure:
    'https://www.valisure.com/internet-pharmacy-site-disclosure/',
  cOADisclaimer: 'https://www.valisure.com/valisure-coa-disclaimer/'
};

export interface MenuItems {
  howItWorks: string;
  aboutUs: string;
  analysis: string;
  forDoctors: string;
  news: string;
  faq: string;
  contactUs: string;
  press: string;
  reportABadMed: string;
  shippingPolicy: string;
  returnRefundPolicy: string;
  facebook: string;
  twitter: string;
  linkedIn: string;
  termsOfUse: string;
  privacyPolicy: string;
  HIPAAPolicy: string;
  internetPharmacySiteDisclosure: string;
  cOADisclaimer: string;
}
