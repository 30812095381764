import { DoCheck, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";
import * as uuid from "uuid";
import { AccountService } from "../../../../account/account.service";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, mergeMap } from "rxjs/operators";
var AddressAutocompleteComponent = /** @class */ (function () {
    function AddressAutocompleteComponent(accountService) {
        this.accountService = accountService;
        this.value = "";
        this.addressSelected = new EventEmitter();
        this.focused = false;
        this.loading = false;
        this.suggestions = [];
        this.sessionToken = "";
        this.errorMessage = "";
        this.keyTyped = false;
        this.lookupSubject = new Subject();
        this.highlightValue = null;
        this.propagateChange = function (_) {
        };
        this.propagateTouched = function () {
        };
        this.generateSessionToken();
    }
    Object.defineProperty(AddressAutocompleteComponent.prototype, "suggestionsVisible", {
        get: function () {
            return this.focused && this.value && this.keyTyped;
        },
        enumerable: true,
        configurable: true
    });
    AddressAutocompleteComponent.prototype.generateSessionToken = function () {
        this.sessionToken = uuid.v4();
    };
    AddressAutocompleteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.lookupSubscription = this.lookupSubject.pipe(debounceTime(200), distinctUntilChanged(), mergeMap(function (search) { return _this.lookupKeywords(); })).subscribe(function (res) {
            _this.lookupKeywordsCallback(res);
        }, function (errorResponse) {
            _this.suggestions = [];
            _this.errorMessage = "Could not retrieve address suggestions.";
            _this.loading = false;
        });
    };
    AddressAutocompleteComponent.prototype.ngOnDestroy = function () {
        this.lookupSubscription.unsubscribe();
    };
    AddressAutocompleteComponent.prototype.ngDoCheck = function () {
    };
    AddressAutocompleteComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    AddressAutocompleteComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    AddressAutocompleteComponent.prototype.setDisabledState = function (isDisabled) {
    };
    AddressAutocompleteComponent.prototype.writeValue = function (obj) {
        if (obj !== undefined && obj !== null && typeof obj === "string") {
            this.value = obj;
        }
        else {
            this.value = "";
        }
    };
    AddressAutocompleteComponent.prototype.onChange = function (event) {
        this.propagateChange(this.value);
        this.propagateTouched();
    };
    AddressAutocompleteComponent.prototype.onInputFocus = function () {
        this.focused = true;
    };
    AddressAutocompleteComponent.prototype.onInputBlur = function (event) {
        /*
        setTimeout(() => {
            this.keyTyped = false;
            this.focused = false;
            this.propagateTouched();
        }, 200);

         */
        this.propagateTouched();
    };
    AddressAutocompleteComponent.prototype.onComponentClick = function (event) {
        event.stopPropagation();
        this.focused = true;
    };
    AddressAutocompleteComponent.prototype.onComponentFocus = function () {
        //this.focused = true;
        this.suggestions = [];
    };
    AddressAutocompleteComponent.prototype.onComponentBlur = function () {
        //console.log("blur");
    };
    AddressAutocompleteComponent.prototype.hostKeyUp = function ($event) {
        var _this = this;
        if ($event) {
            if ($event.keyCode === 40) { // Down
                $event.preventDefault();
                if (this.highlightValue && this.suggestions.length) {
                    var currentIndex = this.suggestions.findIndex(function (val) {
                        return val.place_id === _this.highlightValue.place_id;
                    });
                    if (currentIndex < this.suggestions.length - 1) {
                        this.highlightValue = this.suggestions[currentIndex + 1];
                    }
                }
            }
            else if ($event.keyCode === 38) { // Up
                $event.preventDefault();
                if (this.highlightValue && this.suggestions.length) {
                    var currentIndex = this.suggestions.findIndex(function (val) {
                        return val.place_id === _this.highlightValue.place_id;
                    });
                    if (currentIndex > 0) {
                        this.highlightValue = this.suggestions[currentIndex - 1];
                    }
                }
            }
            else if ($event.keyCode === 9) { // Tab or Space
                if (this.highlightValue) {
                    this.onSelectAddress(this.highlightValue);
                }
            }
        }
    };
    AddressAutocompleteComponent.prototype.onKeyDown = function (event) {
        if (event && ![9, 38, 40].includes(event.keyCode)) {
            //console.log("key up", this.value);
            this.keyTyped = true;
            this.loading = true;
            this.errorMessage = "";
            this.highlightValue = null;
            this.lookupSubject.next(this.value);
        }
        else {
            event.preventDefault();
            this.hostKeyUp(event);
        }
    };
    AddressAutocompleteComponent.prototype.lookupKeywords = function () {
        return this.accountService.getAutocomplete({
            input: this.value,
            sessionToken: this.sessionToken
        });
    };
    AddressAutocompleteComponent.prototype.lookupKeywordsCallback = function (response) {
        this.loading = false;
        if (response && response.status && response.status.success) {
            if (response.data) {
                switch (response.data.status) {
                    case "OK":
                        this.suggestions = response.data.predictions;
                        if (this.suggestions.length) {
                            this.highlightValue = this.suggestions[0];
                        }
                        break;
                    case "ZERO_RESULTS":
                        this.suggestions = [];
                        break;
                    default:
                        this.suggestions = [];
                        this.errorMessage = "Could not retrieve address suggestions.";
                }
            }
            else {
                this.suggestions = [];
                this.errorMessage = "Could not retrieve address suggestions.";
            }
        }
        else {
            this.suggestions = [];
            this.errorMessage = "Could not retrieve address suggestions.";
        }
    };
    AddressAutocompleteComponent.prototype.onSelectAddress = function (suggestion, event) {
        var _this = this;
        if (event) {
            event.stopPropagation();
        }
        this.loading = true;
        this.keyTyped = false;
        this.accountService.getAutocompleteDetails({
            place_id: suggestion.place_id,
            sessionToken: this.sessionToken
        }).subscribe(function (response) {
            _this.lookupDetailsCallback(response);
        }, function (error) {
            // Show error?
        }, function () {
            _this.loading = false;
            _this.generateSessionToken();
        });
    };
    AddressAutocompleteComponent.prototype.lookupDetailsCallback = function (response) {
        if (response && response.status && response.status.success) {
            if (response.data) {
                switch (response.data.status) {
                    case "OK":
                        var components = response.data.result.address_components;
                        var address_1 = {
                            address_blocks: [],
                            city_blocks: [],
                            address_line_1: "",
                            city: "",
                            state: "",
                            zip_code: ""
                        };
                        var hasCity_1 = components.find(function (comp) {
                            return comp.types.includes('locality');
                        });
                        components.forEach(function (component) {
                            if (component.types.includes("floor")) {
                                address_1.address_blocks.push(component.short_name + ",");
                            }
                            if (component.types.includes("street_number")) {
                                address_1.address_blocks.push(component.long_name ? component.long_name : component.short_name);
                            }
                            if (component.types.includes("route")) {
                                address_1.address_blocks.push(component.long_name ? component.long_name : component.short_name);
                            }
                            if (component.types.includes("locality")) {
                                address_1.city_blocks.push(component.long_name ? component.long_name : component.short_name);
                            }
                            if (component.types.includes("neighborhood") && !hasCity_1 && address_1.city_blocks.length === 0) {
                                address_1.city_blocks.push(component.long_name ? component.long_name : component.short_name);
                            }
                            if (component.types.includes("sublocality_level_1") && !hasCity_1 && address_1.city_blocks.length === 0) {
                                address_1.city_blocks.push(component.long_name ? component.long_name : component.short_name);
                            }
                            if (component.types.includes("administrative_area_level_1")) {
                                address_1.state = component.long_name;
                            }
                            if (component.types.includes("postal_code")) {
                                address_1.zip_code = component.short_name;
                            }
                        });
                        address_1.address_line_1 = address_1.address_blocks.join(" ");
                        address_1.city = address_1.city_blocks.join(" ");
                        this.addressSelected.emit(address_1);
                        break;
                    default:
                        this.errorMessage = "Could not retrieve address details.";
                }
            }
            else {
                this.suggestions = [];
                this.errorMessage = "Could not retrieve address details.";
            }
        }
        else {
            this.suggestions = [];
            this.errorMessage = "Could not retrieve address details.";
        }
    };
    return AddressAutocompleteComponent;
}());
export { AddressAutocompleteComponent };
