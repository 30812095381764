<div class="orderHistoryDisplay">
    <a *ngIf="product.real.active && product.medication_testing === null" href="javascript:void(0);" class="cursor-hand viewOrderLink" (click)="reorderProduct(product)">Reorder</a>
    <a *ngIf="product.real.active" href="javascript:void(0);" class="orderNumber" (click)="viewProductDetail(product)">{{product.quantity}} x {{product.scientific_name}} 
        <span *ngIf="!product.real.rx && product.variant_quantity">({{product.variant_quantity}} {{productService.getOptionDescriptor(product.real.type, product.variant_quantity)}})</span>
    </a>
    <span *ngIf="!product.real.active" class="orderNumber" >{{product.quantity}} x {{product.scientific_name}} <span *ngIf="!product.real.rx && product.variant_quantity">({{product.variant_quantity}} {{productService.getOptionDescriptor(product.real.type, product.variant_quantity)}})</span></span>
    <div class="orderHistoryDetails">
        <div class="line">
            <span>ORDER</span>  <a href="javascript:void(0);" class="action" (click)="viewOrderDetail(product)">{{product.order.order_number}}</a>
        </div>
        <div class="line">
            <span>PLACED</span> {{product.order.created | date:'MM/dd/yyyy'}}
        </div>
        <div class="line">
            <span>TOTAL</span> {{product.subtotal | currency}}
        </div>
        <div class="line">
            <span>STATUS</span> {{product.order.status && product.order.status.name}}
        </div>
    </div>
</div>