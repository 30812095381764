import { Router } from '@angular/router';
import { UserService } from '../shared/user.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../shared/user.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, userService) {
        this.router = router;
        this.userService = userService;
    }
    /**
     * Verifies user access to requested app route
     */
    AuthGuard.prototype.canActivate = function (next, state) {
        if (this.userService.isLoggedIn()) {
            return true;
        }
        else {
            if (sessionStorage.getItem('userTokenExpiration')) {
                sessionStorage.setItem('timeout', 'true');
            }
            this.userService.logout(state.url);
            return false;
        }
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.Router), i0.inject(i2.UserService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
