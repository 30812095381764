<div class="clswrapper">
    <!-- Main container -->
        <header class="clsbefore_log_header">
            <nav class="navbar navbar-default">
                <div class="container">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="navbar-header">
                        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1"
                            aria-expanded="false">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                        <a class="navbar-brand" [routerLink]="['/signin']"><img  src="assets/images/logo@2x.png" alt="" width="150" /></a>
                    </div>

                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul class="nav navbar-nav navbar-right">
                            <li><a href="https://www.valisure.com/how-it-works/" target="_blank">How It Works <span class="sr-only">(current)</span></a></li>
                            <li><a href="https://www.valisure.com/about-us/" target="_blank">About Us</a></li>
                            <li ><a href="https://www.valisure.com/faq/" target="_blank">FAQ</a></li>
                        </ul>
                    </div><!-- /.navbar-collapse -->
                </div><!-- /.container-fluid -->
            </nav>
        </header>
        <!-- Main container -->
    <main class="clsmain_container">  
      <div class="forgot_form_blk">
        <div class="container">
          <h1 class="text-center">Forgot Password</h1>
          <div class="forgot_form">
            <form [formGroup]="forgotForm">
              <div class="form-group">
                <label for="email">Email Address</label>
                <input type="email" class="form-control" id="email" name="email" placeholder="" autocomplete="off" formControlName="email">
                  <div class="alert alert-danger" *ngIf="forgotForm.controls.email.invalid && forgotForm.controls.email.touched && forgotForm.controls.email.dirty">
                      Invalid email address.
                  </div>
                <div *ngIf="message" class="alert alert-warning">
                     {{message}}
                </div>
              </div>
              <div class="form-group">
                <button type="submit" (click)="forgotPassword()" class="btn btn-default clsyellow_btn">
                    <span *ngIf="!loading">Submit</span>
                    <span *ngIf="loading"><i class="fa fa-spinner fa-spin"></i> Please wait</span>
                </button>
                  <!-- [attr.disabled]="!forgotPasswordForm.form.valid?'disabled':null" -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </div>
  <app-footer></app-footer>