<div class="address-form-group "
    [formGroup]="formGroup">
    <div class="row form-group ">
        <div class="col-xs-12 nickname form-field">
            <label for="reference">Address nickname</label>
            <input type="text" class="form-control" formControlName="name"
                placeholder="Enter a reference name (e.g. My Office)"
                id="reference">
            <div *ngIf="nickname.invalid && (nickname.dirty && nickname.touched)" class="alert alert-danger">
                <div *ngIf="nickname.errors.required">
                    Address nickname is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group forhr no-margin-bottom">
        <div class="col-xs-12">
            <hr/>
        </div>
    </div>
    <div class="row form-group ">
        <div class="col-md-6 first form-field">
            <label for="first">First</label>
            <input type="text" class="form-control" id="first" formControlName="first_name" placeholder="Jane" required>
            <div *ngIf="first.invalid && (first.dirty && first.touched)" class="alert alert-danger">
                <div *ngIf="first.errors.required">
                    First name is required.
                </div>
            </div>
        </div>
        <div class="col-md-6 last form-field">
            <label for="last">Last</label>
            <input type="text" class="form-control" id="last" formControlName="last_name" placeholder="Smith" required>
            <div *ngIf="last.invalid && (last.dirty && last.touched)" class="alert alert-danger">
                <div *ngIf="last.errors.required">
                    Last name is required.
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group ">
        <div class="col-md-6 phone form-field">
            <label for="phone">Phone</label>
    
            <p-inputMask class="clsmedication" name="phone"
                            formControlName="phone" id="phone"
                            placeholder="" autocomplete="off" required pattern="^[0-9_.-]*$"
                            mask="999-999-9999" placeholder="000-000-0000"></p-inputMask>
            <div *ngIf="phone.invalid && (phone.dirty && phone.touched)" class="alert alert-danger">
                <div *ngIf="phone.errors.required">
                    Phone is required.
                </div>
                <div *ngIf="phone.errors.invalidPhoneNumber">
                    Please enter a valid phone number.
                </div>
            </div>
        </div>
        <div class="col-md-6 company form-field">
            <label for="company">Company</label>
            <input type="text" class="form-control" id="company" formControlName="company">
        </div>
    </div>
    <!--
    <div class="col-xs-6 email form-field">
        <label for="email">Email</label>
        <input type="email" class="form-control" id="email" formControlName="email" >
        <div *ngIf="email.invalid && (email.dirty && email.touched)" class="alert alert-danger">
            <div *ngIf="email.errors.required">
                Email is required.
            </div>
            <div *ngIf="email.errors.invalidEmailAddress">
                Please enter a valid email address.
            </div>
        </div>
    </div>
    
    -->
    <div class="row form-group ">
        <div class="col-xs-12 street form-field">
            <label for="street">Street</label>
            <app-address-autocomplete
                    id="street"
                    [formControl]="street"
                    required
                    (addressSelected)="onSuggestedAddressSelected($event)"
            ></app-address-autocomplete>
            <div class="text-info">Please continue typing until your address appears</div>

            <div *ngIf="street.invalid && (street.dirty && street.touched)" class="alert alert-danger">
                <div *ngIf="street.errors.required">
                    Street is required.
                </div>
            </div>
        </div>
    </div>

    <!--
    <div class="col-xs-12 street form-field">
        <label for="street_2">Mailing Address 2 <span>(optional)</span></label>
        <input type="text" class="form-control" id="street_2" formControlName="address_line_2">
    </div>
    -->
    <div class="row form-group ">
        <div class="col-xs-12 col-lg-6 city form-field">
            <label for="city">City</label>
            <input type="text" class="form-control" id="city" formControlName="city" required>
            <div *ngIf="city.invalid && (city.dirty && city.touched)" class="alert alert-danger">
                <div *ngIf="city.errors.required">
                    City is required.
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-lg-3 state form-field">
            <label for="state">State</label>

            <p-dropdown scrollHeight="300px" [options]="states"
                        id="state"
                        name="state"
                        class="valisure_select"
                        formControlName="state_id"></p-dropdown>

            <div *ngIf="state.invalid && (state.dirty && state.touched)" class="alert alert-danger">
                <div *ngIf="state.errors.required">
                    State is required.
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-lg-3 zip form-field">
            <label for="zip">Zip</label>
            <input type="text" maxlength="10"required class="form-control" id="zip"  (keypress)="onlyNumberKey($event)"
                formControlName="zip_code">
            <div *ngIf="zip.invalid && (zip.dirty && zip.touched)" class="alert alert-danger">
                <div *ngIf="zip.errors.required">
                    Zip code is required.
                </div>
                <div *ngIf="zip.errors.pattern">
                    Invalid zip code. 00000 or 00000-0000
                </div>
            </div>
        </div>
    </div>
</div>