/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./order-recent-purchase-display.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./order-recent-purchase-display.component";
import * as i4 from "../../../../shared/user.service";
import * as i5 from "../../../shop/services/product.service";
import * as i6 from "@angular/router";
var styles_OrderRecentPurchaseDisplayComponent = [i0.styles];
var RenderType_OrderRecentPurchaseDisplayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderRecentPurchaseDisplayComponent, data: {} });
export { RenderType_OrderRecentPurchaseDisplayComponent as RenderType_OrderRecentPurchaseDisplayComponent };
function View_OrderRecentPurchaseDisplayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "cursor-hand viewOrderLink"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reorderProduct(_co.product) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Reorder"]))], null, null); }
function View_OrderRecentPurchaseDisplayComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", " ", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.product.variant_quantity; var currVal_1 = _co.productService.getOptionDescriptor(_co.product.real.type, _co.product.variant_quantity); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_OrderRecentPurchaseDisplayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "orderNumber"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewProductDetail(_co.product) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", " x ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderRecentPurchaseDisplayComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (!_co.product.real.rx && _co.product.variant_quantity); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.product.quantity; var currVal_1 = _co.product.scientific_name; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_OrderRecentPurchaseDisplayComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", " ", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.product.variant_quantity; var currVal_1 = _co.productService.getOptionDescriptor(_co.product.real.type, _co.product.variant_quantity); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_OrderRecentPurchaseDisplayComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "orderNumber"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " x ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderRecentPurchaseDisplayComponent_5)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (!_co.product.real.rx && _co.product.variant_quantity); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.product.quantity; var currVal_1 = _co.product.scientific_name; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_OrderRecentPurchaseDisplayComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 26, "div", [["class", "orderHistoryDisplay"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderRecentPurchaseDisplayComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderRecentPurchaseDisplayComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderRecentPurchaseDisplayComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 19, "div", [["class", "orderHistoryDetails"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ORDER"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [["class", "action"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewOrderDetail(_co.product) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PLACED"])), (_l()(), i1.ɵted(18, null, [" ", " "])), i1.ɵppd(19, 2), (_l()(), i1.ɵeld(20, 0, null, null, 4, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["TOTAL"])), (_l()(), i1.ɵted(23, null, [" ", " "])), i1.ɵppd(24, 1), (_l()(), i1.ɵeld(25, 0, null, null, 3, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["STATUS"])), (_l()(), i1.ɵted(28, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.product.real.active && (_co.product.medication_testing === null)); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.product.real.active; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.product.real.active; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.product.order.order_number; _ck(_v, 14, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 18, 0, _ck(_v, 19, 0, i1.ɵnov(_v, 0), _co.product.order.created, "MM/dd/yyyy")); _ck(_v, 18, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 23, 0, _ck(_v, 24, 0, i1.ɵnov(_v, 1), _co.product.subtotal)); _ck(_v, 23, 0, currVal_5); var currVal_6 = (_co.product.order.status && _co.product.order.status.name); _ck(_v, 28, 0, currVal_6); }); }
export function View_OrderRecentPurchaseDisplayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-order-recent-purchase-display", [], null, null, null, View_OrderRecentPurchaseDisplayComponent_0, RenderType_OrderRecentPurchaseDisplayComponent)), i1.ɵdid(1, 114688, null, 0, i3.OrderRecentPurchaseDisplayComponent, [i4.UserService, i5.ProductService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrderRecentPurchaseDisplayComponentNgFactory = i1.ɵccf("app-order-recent-purchase-display", i3.OrderRecentPurchaseDisplayComponent, View_OrderRecentPurchaseDisplayComponent_Host_0, { product: "product" }, {}, []);
export { OrderRecentPurchaseDisplayComponentNgFactory as OrderRecentPurchaseDisplayComponentNgFactory };
