<div class="clswrapper">
  <div class="clsheader_top_bg" *ngIf="route == 'home' || route === 'doomsday'"></div>
  <app-header *ngIf="route === 'home'"></app-header>
  <app-doomsday-header *ngIf="route === 'doomsday'"></app-doomsday-header>

  <router-outlet></router-outlet>

  <app-footer *ngIf="route === 'home'"></app-footer>
  <app-doomsday-footer *ngIf="route === 'doomsday'"></app-doomsday-footer>
</div>
