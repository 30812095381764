import {Injectable, Injector} from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../shared/user.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  /**
   * Verifies user access to requested app route
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    if (this.userService.isLoggedIn()) {
      return true;
    } else {
      if (sessionStorage.getItem('userTokenExpiration')) {
        sessionStorage.setItem('timeout', 'true');
      }
      this.userService.logout(state.url);
      return false;
    }
  }
}
