import {Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, DoCheck} from "@angular/core";

import {
    FormGroup,
    FormBuilder,
    Validators
} from "@angular/forms";

import {Http} from "@angular/http";
import {Router} from "@angular/router";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "../../shared/user.service";
import {HttpErrorResponse} from "@angular/common/http";

import {ValidationService} from "../../shared/validation.service";
import drugList from "../../drug-list";
import {SelectItem} from "primeng/api";
import {Subscription} from "rxjs";

import {map} from "rxjs/operators";
import {FormatService} from "@services/format.service";
import {environment} from "../../../environments/environment";

declare var gtag: any;
declare var dataLayer: any;


@Component({
    selector: "app-signup",
    templateUrl: "./signup.component.html",
    styleUrls: ["./signup.component.scss"]
})
export class SignupComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {
    private productsSubscription: Subscription;
    private routeSubscription: Subscription;

    private ip: string;

    readonly rootUrl = sessionStorage.getItem("location");
    public title = "app";
    public loginerror = false;
    public submitted = false;
    public loading = false;
    public signupForm: FormGroup;
    public refrredOptions: SelectItem[];
    public desired_meds_val: String = "";
    public desired_meds_rel: String = "";
    public disableForm = false;
    public message: any = {};
    public products: String[] = [];
    public filteredProducts: String[] = [];
    public loadingResponse: boolean = false;
    public innerWidth: any;
    public displayTooltip: boolean = false;
    public tooltipTop: number = 0;
    public tooltipLeft: number = 0;
    public showPassword = false;

    @ViewChild("piwikid") piwikid: ElementRef;
    @ViewChild("mkwid") mkwid: ElementRef;

    constructor(private formBuilder: FormBuilder,
                private userService: UserService,
                private http: Http,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
        this.getReferredOptions();
    }

    getReferredOptions() {
        this.refrredOptions = [];
        this.refrredOptions.push({label: "Please select", value: ""});
        this.http
            .get(this.rootUrl + "/referred-by")
            .pipe(map(response => response.json()))
            .subscribe(response => {
                if (response.status.success) {
                    response.data.forEach(value => {
                        this.refrredOptions.push({
                            label: value.name,
                            value: value.id
                        });
                    });
                }
            });
    }

    ngOnInit() {
        this.http
            .get("https://api.ipify.org/?format=json")
            .pipe(map(response => response.json()))
            .subscribe(response => {
                if (response) {
                    this.ip = response.ip;
                }
            });
        this.createForm();
        this.products = drugList.map(product => product.scientific_name);
        this.filteredProducts = this.products;
        this.innerWidth = window.innerWidth;
        if (window.innerWidth < 600) {
            document.getElementById("signup").scrollIntoView(true);
        }
    }

    ngAfterViewInit() {
        this.routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
            if (parseInt(params["caprx"], 10) === 1) {
                this.signupForm.get("is_capitalrx").setValue("yes");
            }
        });


        if (environment.production && typeof dataLayer !== 'undefined' && dataLayer && typeof dataLayer.push === 'function') {
            setTimeout(() => {
                dataLayer.push({'event': 'signUp'});
            });
        }
    }

    ngOnDestroy() {
        if (this.productsSubscription) {
            this.productsSubscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    /**
     * Initiates the form group
     */
    private createForm() {
        this.signupForm = this.formBuilder.group({
            first_name: ["", Validators.required],
            last_name: ["", Validators.required],
            email: ["", [Validators.required, ValidationService.emailValidator]],
            password: ["", [Validators.required, ValidationService.passwordValidator]],
            phone: ["", [Validators.required, ValidationService.phoneValidator]],
            zip: ["", [Validators.required, Validators.pattern('[0-9]{5}(-[0-9]{4})?')]],
            desired_meds: ["", {
                updateOn: "change",
                // validators: Validators.required
            }],
            referred_by: ["", {
                updateOn: "change",
                validators: Validators.required
            }],
            piwikid: "",
            mkwid: "",
            is_capitalrx: ["no", {updateOn: "change"}],
            /*
            purchase_new : [false, {
                updateOn: "change",
                validators : [Validators.required, ValidationService.interestedInValidator]
            }],
            test_existing : [false, {
                updateOn: "change",
                validators : [Validators.required, ValidationService.interestedInValidator]
            }],
             */
            agree : [false, {
                updateOn: "change",
                validators : [Validators.required, ValidationService.termsValidator]
            }],
        }, {updateOn: "blur"});
    }

    onInterestChange() {

        const purchaseNewControl = this.signupForm.get('purchase_new');
        const testExistingControl = this.signupForm.get('test_existing');

        if (purchaseNewControl.value) {
            testExistingControl.setValidators(null);
        } else {
            testExistingControl.setValidators([Validators.required, ValidationService.interestedInValidator]);
        }
        testExistingControl.updateValueAndValidity();

        if (testExistingControl.value) {
            purchaseNewControl.setValidators(null);
        }
        else {
            purchaseNewControl.setValidators([Validators.required, ValidationService.interestedInValidator]);
        }
        purchaseNewControl.updateValueAndValidity();

    }

    ngDoCheck() {
        /**
         * Update piwikid and mkwid fields on the model when they get changed from external sources (eg: ad script)
         */
        setTimeout(() => {
            const piwikid = this.piwikid.nativeElement.getAttribute('value');
            if (this.signupForm.get('piwikid').value !== piwikid) {
                this.signupForm.get('piwikid').setValue(piwikid);
            }
            const mkwid = this.mkwid.nativeElement.getAttribute('value');
            if (this.signupForm.get('mkwid').value !== mkwid) {
                this.signupForm.get('mkwid').setValue(mkwid);
            }
        });
    }

    public toggleShowPassword() {
       this.showPassword = !this.showPassword;
    }

    /**
     * Registers event on GTM
     */
    private gtag_report_conversion(url = undefined) {
        var callback = function () {
            if (typeof (url) !== "undefined") {
                window.location = url;
            }
        };

        if (environment.production && gtag) {
            gtag('event', 'conversion', {
                'send_to': 'AW-1070593910/KlXlCJTK35QBEPbuv_4D',
                'event_callback': callback
            });
        }

        return false;
    }

    /**
     * Displays tooltip on mouse position
     */
    public showTooltip(event: any) {
        this.displayTooltip = true;

        this.tooltipTop = event.pageY + 30;
        this.tooltipLeft = event.pageX - 203;
    }

    /**
     * Hides tooltip
     */
    public hideTooltip() {
        this.displayTooltip = false;
    }

    /**
     * Filters products based on passed query event
     * @param event - Filter query event
     */
    public filterProducts(event) {
        const query = event.query;
        this.filteredProducts = this.filterProduct(query, this.products);
    }

    /**
     * Filters passed products based on passed query
     * @param query - Condition to filter by
     * @param products - Products to filter
     */
    private filterProduct(query, products: any[]): any[] {
        const filtered: any[] = [];
        products.forEach(
            product =>
                product.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
                filtered.push(product)
        );
        if (!filtered.length) {
            filtered.push(query);
        }
        return filtered;
    }

    /**
     * Checkes if selected key is a number
     * @param event - Key event
     */
    onlyNumberKey(event) {
        return event.charCode === 8 || event.charCode === 0
            ? null
            : (event.charCode >= 48 && event.charCode <= 57) || (event.charCode === 45);
    }

    /**
     * Signup form submit, creates new user
     */
    async onSubmit() {
        if (!this.loadingResponse) {

            if (!this.signupForm.valid) {
                for (const controlKey in this.signupForm.controls) {
                    this.signupForm.controls[controlKey].markAsTouched();
                    this.signupForm.controls[controlKey].markAsDirty();
                }
                return;
            }

            this.loadingResponse = true;
            this.submitted = true;

            const personalInfo = this.signupForm.value;

            if (this.signupForm.get("desired_meds") && this.signupForm.get("desired_meds").value) {
                personalInfo.desired_meds = this.signupForm.get("desired_meds").value.join(',');
            }
            personalInfo.phone = FormatService.formatPhoneForSave(this.signupForm.get('phone').value);

            this.userService
                .userSignupStepone({ip: this.ip, ...personalInfo})
                .subscribe(
                    (reginfo: any) => {
                        this.loadingResponse = false;
                        this.gtag_report_conversion();
                        if (reginfo.status.success) {
                            this.userService
                                .userAuthentication(reginfo.data.email, this.signupForm.value.password)
                                .subscribe(
                                    (result: any) => {
                                        if (result.status.success) {

                                            if (environment.production && typeof dataLayer !== 'undefined' && dataLayer && typeof dataLayer.push === 'function') {
                                                setTimeout(() => {
                                                    dataLayer.push({'event': 'signUpValid'});
                                                });
                                            }

                                            this.userService.setToken(result);
                                            this.router.navigate(["/personal-info"]);
                                        } else {
                                            this.router.navigateByUrl("/signup");
                                        }
                                    },
                                    (err: HttpErrorResponse) => {
                                    }
                                );
                        } else {
                            this.loadingResponse = false;
                            this.message = reginfo.status.errors;

                            if (reginfo.status.message) {
                                Object.assign(this.message, {generic: reginfo.status.message});
                            }
                        }
                    },
                    (err: HttpErrorResponse) => {
                    }
                );
        }
    }
}
