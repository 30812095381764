<section class="clssign_in_section">

    <div class="clssign_in_content_blk">
        <header class="clssignin_header">
            <nav class="navbar navbar-default">
                <div class="container-fluid" [ngClass]="{'change':active}" id="container-id">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="navbar-header">
                        <button type="button" class="navbar-toggle collapsed" (click)="activeCollapse()"
                                data-toggle="collapse" data-target="#bs-example-navbar-collapse-1"
                                aria-expanded="false">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar bar1"></span>
                            <span class="icon-bar bar2"></span>
                            <span class="icon-bar bar3"></span>
                        </button>
                        <a class="navbar-brand" href=""><img src="assets/images/logo@2x.png" alt="" width="150"/></a>
                    </div>

                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul class="nav navbar-nav navbar-right">
                            <li><a href="{{howItWorks}}" target="_blank">How It Works <span
                                    class="sr-only">(current)</span></a></li>
                            <li><a href="{{aboutUs}}" target="_blank">About Us</a></li>
                            <li><a href="{{faq}}" target="_blank">FAQ</a></li>
                        </ul>

                    </div><!-- /.navbar-collapse -->
                </div><!-- /.container-fluid -->
            </nav>
        </header>
        <div class="clssign_in_left_content">
            <h1>Let’s get started</h1>
            <div *ngIf="!doomsdayStatus.doomsday_enabled" class="homescrrentext">
                <ol class="homescreenlist numbers">
                    <li>Sign up on this page.</li>
                    <li>Order your meds.</li>
                    <li>We ship to you.</li>
                </ol>
                <p>Questions? Call 833-497-7370</p>
                <p>Prescribers: </p>
                <ul class="homescreenlist bullet">
                    <li>Call 833-497-7370 </li>
                    <li>Or e-prescribe at NPI# 1740771021.</li>
                </ul>

                <a href="#" class="homescreenvideo" (click)="setUpVideo()" data-toggle="modal" data-target=".clsvideo_modal">
                    <img src="assets/images/avocado-video-image.png" />
                    <span class="tint"></span>
                    <img class="play-icon" src="assets/images/video-play.png" />
                    <span class="text">See How America's First Analytical Pharmacy Works.</span>
                </a>


                <!--
                <p>Alternate Method: </p>

                <ol class="homescreenlist bull">
                    <li>Ask your doctor/staff to call prescription in to Valisure at <span class="nowrap">833-497-7370</span> or e-prescribe at NPI #1740771021.</li>
                    <li>We will contact you to create your account.</li>
                </ol>
                -->
            </div>
            <p *ngIf="doomsdayStatus.doomsday_enabled">Please know that we are experiencing a very high volume of requests and may have limited availability in our store. Signing up will ensure we are able to stay in touch with you and service your needs as soon as we are able.</p>
            <!--
            <p *ngIf="!doomsdayStatus.doomsday_enabled">We are the only pharmacy that chemically tests every batch of
                every medication we sell. Delivered to your door and no additional cost. </p>
            <p *ngIf="doomsdayStatus.doomsday_enabled">Please know that we are experiencing a very high volume of requests and may have limited availability in our store. Signing up will ensure we are able to stay in touch with you and service your needs as soon as we are able.</p>
            <div class="media" *ngIf="!doomsdayStatus.doomsday_enabled">
                <div class="media-left">
                    <a href="#" data-toggle="modal" data-target=".clsvideo_modal">
                        <img class="media-object" src="assets/images/video@2x.png" width="133" alt=""/>
                    </a>
                </div>
                <div class="media-body media-middle">
                    <a href="#" data-toggle="modal" data-target=".clsvideo_modal">
                        <p>Watch Video</p>
                        <h4 class="media-heading">How Valisure Works</h4>
                    </a>
                </div>
            </div>
            -->
        </div>
    </div>


    <div class="sign_in_form_blk ">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs">

            <li role="presentation" routerLinkActive='active'><a routerLink='/signup' aria-controls="/signup">Sign
                Up</a></li>
            <li role="presentation" [ngClass]="customActive" routerLinkActive='active'><a routerLink='/signin'
                                                                                          aria-controls="signin">Sign
                In</a></li>
        </ul>
        <div class="tab-content">
            <router-outlet></router-outlet>
        </div>
    </div>


</section>
<app-footer></app-footer>
<!-- Modal -->
<div class="modal fade clsvideo_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" (click)="stopVideo()">
    <button type="button" class="close" data-dismiss="modal" (click)="stopVideo()" aria-label="Close"><span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <iframe width="100%" height="100%" *ngIf="videoSrc" [src]="videoSrc" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>