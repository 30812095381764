import {Component, OnInit, OnDestroy, HostListener, Renderer2} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {menuitems, MenuItems} from "@services/menuitems";

declare var $: any;

@Component({
    selector: "app-doomsday-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.css"]
})
export class DoomsdayHeaderComponent implements OnInit, OnDestroy {

    public title = "Valisure";
    public menuItems: MenuItems;

    public rxMenuOpen = false;

    public innerWidth: any;
    public active: boolean = false;

    constructor(private router: Router,
                private renderer: Renderer2) {

        this.menuItems = menuitems;
    }

    ngOnInit() {
        this.innerWidth = window.innerWidth;
        this.addJsToElement("./../assets/js/mobileCustom.js").onload = () => {
        };
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    ngOnDestroy() {

    }

    public showRxMenu() {
        this.rxMenuOpen = true;
    }

    public hideRxMenu() {
        this.rxMenuOpen = false;
    }

    /**
     * Close navigation menu
     */
    public closeNav() {
        $("#navbar-hamburger").removeClass("hidden");
        $("#navbar-close").addClass("hidden");
        $(".clsmobile_navbar").removeClass("in");
    }

    /**
     * Async add asset to project
     * @param src - Asset source url
     */
    addJsToElement(src: string): HTMLScriptElement {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        this.renderer.appendChild(document.body, script);
        return script;
    }

    /**
     * Toggle navigation menu
     */
    activeCollapse() {
        if (this.innerWidth < 600) {
            this.active = !this.active;
        }
    }
}
