import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CartService } from "@modules/cart/services/cart.service";
import { UtilsService } from "@services/utils.service";
import * as i0 from "@angular/core";
import * as i1 from "../modules/cart/services/cart.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common/http";
var reqHeader = new HttpHeaders();
reqHeader.append('Access-Control-Allow-Origin', '*');
reqHeader.append('Access-Control-Allow-Headers', '*');
reqHeader.append('Access-Control-Allow-Methods', ' GET, POST, DELETE, PUT, HEAD, OPTIONS');
reqHeader.append('Request Method', 'POST, GET, PUT, DELETE, OPTIONS, HEAD');
reqHeader.append('Content-Type', 'application/json, application/x-www-form-urlencoded, multipart/form-data, text/plain, charset=UTF-8');
var AccountService = /** @class */ (function () {
    function AccountService(cartService, router, http) {
        this.cartService = cartService;
        this.router = router;
        this.http = http;
        this.rootUrl = sessionStorage.getItem('location');
    }
    AccountService.prototype.getProductVariant = function (product, variantId) {
        if (variantId == null)
            return null;
        if (product.real.variants.length === 0)
            return null;
        return product.real.variants.reduce(function (variantSearched, variant, index) {
            if (variantSearched.variantId == variant.id) {
                variantSearched.variant = variant;
            }
            return variantSearched;
        }, { variant: null, variantId: variantId }).variant;
    };
    AccountService.prototype.getProductPriceRange = function (product, quantity) {
        return product.real.variants.sort(function (a, b) {
            return a.quantity - b.quantity;
        }).reduce(function (variantSearched, variant, index) {
            if (variantSearched.quantity >= variant.quantity) {
                variantSearched.variant = variant;
            }
            return variantSearched;
        }, { variant: null, quantity: quantity }).variant;
    };
    AccountService.prototype.calculatePrice = function (orderProduct) {
        var recalculatedPrice = {
            productPrice: 0,
            productPricePerUnit: 0,
        };
        var selectedProductVariant = this.getProductVariant(orderProduct, orderProduct.product_variant_id);
        if (!orderProduct.real.rx) {
            if (orderProduct.real.variants.length > 0) {
                recalculatedPrice.productPrice = UtilsService.roundPrice(selectedProductVariant.price * orderProduct.quantity);
                recalculatedPrice.productPricePerUnit = selectedProductVariant.price / selectedProductVariant.quantity;
            }
            else {
                recalculatedPrice.productPrice = UtilsService.roundPrice(orderProduct.real.price * orderProduct.quantity);
            }
        }
        else {
            if (orderProduct.real.variants.length > 0) {
                var productRange = this.getProductPriceRange(orderProduct, orderProduct.quantity);
                // console.log(productRange);
                if (productRange == null) {
                    recalculatedPrice.productPrice = UtilsService.roundPrice(orderProduct.real.price * orderProduct.quantity);
                    recalculatedPrice.productPricePerUnit = orderProduct.real.price;
                }
                else {
                    recalculatedPrice.productPrice = UtilsService.roundPrice(productRange.price * orderProduct.quantity);
                    recalculatedPrice.productPricePerUnit = productRange.price;
                }
            }
            else {
                recalculatedPrice.productPrice = orderProduct.real.price * orderProduct.quantity;
                recalculatedPrice.productPricePerUnit = orderProduct.real.price;
            }
        }
        return recalculatedPrice;
    };
    AccountService.prototype.processReorderProduct = function (orderProduct) {
        var recalculatedPrice = this.calculatePrice(orderProduct);
        var selectedProductVariant = this.getProductVariant(orderProduct, orderProduct.product_variant_id);
        var product = orderProduct.real;
        var productVariant = selectedProductVariant;
        var quantity = orderProduct.quantity;
        var pricePerItem = orderProduct.real.rx ? recalculatedPrice.productPricePerUnit : ((selectedProductVariant != null) ? selectedProductVariant.price : orderProduct.real.price);
        var price = recalculatedPrice.productPrice;
        this.cartService.addProduct(product, productVariant, quantity, pricePerItem, price);
        return {
            product: product,
            productVariant: productVariant,
            quantity: quantity
        };
    };
    AccountService.prototype.reorderProduct = function (orderProduct) {
        this.cartService.openCartBox(this.processReorderProduct(orderProduct));
    };
    AccountService.prototype.reorderOrder = function (orderProducts) {
        if (orderProducts.length === 1) {
            this.cartService.openCartBox(this.processReorderProduct(orderProducts[0]));
        }
        else {
            for (var i = 0; i < orderProducts.length; i++) {
                this.processReorderProduct(orderProducts[i]);
                this.cartService.openCartBox();
            }
        }
    };
    /**
     * API for adding a new payment method
     */
    AccountService.prototype.agreeHippaNovember2019 = function (data) {
        return this.http.post(this.rootUrl + "/customers/agree_hippa_november_2019", data, { headers: reqHeader });
    };
    /**
     * API for customer addresses
     * @param id - Customer ID
     */
    AccountService.prototype.getAddressList = function (id) {
        return this.http.get(this.rootUrl + "/customers/addresses", { headers: reqHeader });
    };
    AccountService.prototype.getPaymentList = function (id) {
        return this.http.get(this.rootUrl + "/customers/payment-methods", { headers: reqHeader });
    };
    /**
     * API for adding a new payment method
     */
    AccountService.prototype.addNewPaymentMethod = function (data) {
        return this.http.post(this.rootUrl + "/customers/payment-methods/add", data, { headers: reqHeader });
    };
    /**
     * API for setting the default payment method
     */
    AccountService.prototype.setDefaultPaymentMethod = function (data) {
        return this.http.post(this.rootUrl + "/customers/payment-methods/default", data, { headers: reqHeader });
    };
    /**
     * API for adding a new address
     */
    AccountService.prototype.addNewAddress = function (data) {
        return this.http.post(this.rootUrl + "/customers/add-address", data, { headers: reqHeader });
    };
    /**
     * API for updating address
     */
    AccountService.prototype.updateAddress = function (updateData) {
        return this.http.put(this.rootUrl + "/customers/update-address", updateData, { headers: reqHeader });
    };
    /**
     * API for deleting an address
     * @param id - Address ID
     */
    AccountService.prototype.deleteAddress = function (data) {
        return this.http.post(this.rootUrl + "/customers/delete-address", data, { headers: reqHeader });
    };
    /**
     * API for setting a primary address
     * @param id - Address ID
     */
    AccountService.prototype.setPrimaryAddress = function (data) {
        return this.http.post(this.rootUrl + "/customers/addresses/default", data, { headers: reqHeader });
    };
    /**
     * API for retrieving address suggestions
     */
    AccountService.prototype.getAutocomplete = function (data) {
        return this.http.post(this.rootUrl + "/customers/addresses/autocomplete", data, { headers: reqHeader });
    };
    /**
     * API for retrieving address details from a suggestion
     */
    AccountService.prototype.getAutocompleteDetails = function (data) {
        return this.http.post(this.rootUrl + "/customers/addresses/autocomplete-details", data, { headers: reqHeader });
    };
    /**
     * API for deleting an address
     * @param id - Address ID
     */
    AccountService.prototype.deletePaymentMethod = function (data) {
        return this.http.post(this.rootUrl + "/customers/payment-methods/delete", data, { headers: reqHeader });
    };
    /**
     * API for customer addresses
     * @param id - Customer ID
     */
    AccountService.prototype.getInsuranceList = function (id) {
        return this.http.get(this.rootUrl + "/customers/insurances", { headers: reqHeader });
    };
    /**
     * API for adding a new insurance
     */
    AccountService.prototype.addNewInsurance = function (data) {
        return this.http.post(this.rootUrl + "/customers/add-insurance", data, { headers: reqHeader });
    };
    /**
     * API for updating insurance
     */
    AccountService.prototype.updateInsurance = function (updateData) {
        return this.http.put(this.rootUrl + "/customers/update-insurance", updateData, { headers: reqHeader });
    };
    /**
     * API for deleting an insurance
     * @param id - Insurance ID
     */
    AccountService.prototype.deleteInsurance = function (data) {
        return this.http.post(this.rootUrl + "/customers/delete-insurance", data, { headers: reqHeader });
    };
    /**
     * API for upading profile info
     */
    AccountService.prototype.updateProfileInfo = function (updateProfileData) {
        return this.http.put(this.rootUrl + "/customers/update-base-info", updateProfileData, { headers: reqHeader });
    };
    /**
     * API for list of states
     */
    AccountService.prototype.getStateLists = function () {
        return this.http.get(this.rootUrl + "/states", { headers: reqHeader });
    };
    /**
     * API for medications list
     * @param medicationVal - Pagination metadata
     */
    AccountService.prototype.getMedicationList = function (medicationVal) {
        return this.http.get(this.rootUrl + ("/requested-medications/get?limit=" + medicationVal.limit + "&page=" + medicationVal.page), { headers: reqHeader });
    };
    /**
     * API for deleting medication
     * @param medicationId - Medication ID
     */
    AccountService.prototype.deleteMedication = function (medicationId) {
        var data = {
            id: medicationId,
            customer_id: sessionStorage.getItem('userId')
        };
        return this.http.post(this.rootUrl + "/requested-medications/delete", data, { headers: reqHeader });
    };
    /**
     * API for order details
     * @param id - Order ID
     */
    AccountService.prototype.getOrderDetail = function (id) {
        var data = {
            id: id
        };
        return this.http.post(this.rootUrl + "/orders/details", data, { headers: reqHeader });
    };
    /**
     * API for canceling an order
     * @param id - Order ID
     */
    AccountService.prototype.cancelOrder = function (id) {
        var data = {
            id: id
        };
        return this.http.post(this.rootUrl + "/orders/cancel", data, { headers: reqHeader });
    };
    /**
     * API for order receipt
     * @param id - Order ID
     */
    AccountService.prototype.getOrderReceipt = function (id) {
        var data = {
            id: id
        };
        return this.http.post(this.rootUrl + "/orders/receipt", data, { headers: reqHeader });
    };
    /**
     * API for orders list
     */
    AccountService.prototype.getOrderList = function (savedfilter, saveOrderVal) {
        var data = {
            filters: {
                saved: savedfilter ? true : false,
                customer: sessionStorage.getItem('userId')
            },
            options: {
                order_by: 'created',
                order_type: 'desc',
                per_page: saveOrderVal.limit
            },
            page: saveOrderVal.page
        };
        return this.http.post(this.rootUrl + "/orders/list", data, { headers: reqHeader });
    };
    /**
     * API for recent purchases  list
     */
    AccountService.prototype.getRecentPurchasesList = function (savedfilter, saveOrderVal) {
        var data = {
            filters: {
                saved: savedfilter ? true : false,
                customer: sessionStorage.getItem('userId')
            },
            options: {
                order_by: 'created',
                order_type: 'desc',
                per_page: saveOrderVal.limit
            },
            page: saveOrderVal.page
        };
        return this.http.post(this.rootUrl + "/orders/recentpurchases", data, { headers: reqHeader });
    };
    /**
     * API for recent purchases  list
     */
    AccountService.prototype.getRecentPurchasesHomePageList = function (savedfilter, saveOrderVal) {
        var data = {
            filters: {
                saved: savedfilter ? true : false,
                customer: sessionStorage.getItem('userId')
            },
            options: {
                order_by: 'created',
                order_type: 'desc',
                per_page: saveOrderVal.limit
            },
            page: saveOrderVal.page
        };
        return this.http.post(this.rootUrl + "/orders/homerecentpurchases", data, { headers: reqHeader });
    };
    /**
     * API for saving order
     * @param orderId - Order ID
     */
    AccountService.prototype.saveOrder = function (orderId, save) {
        var data = {
            id: orderId,
            saved: save
        };
        return this.http.post(this.rootUrl + "/orders/save", data, { headers: reqHeader });
    };
    AccountService.ngInjectableDef = i0.defineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.inject(i1.CartService), i0.inject(i2.Router), i0.inject(i3.HttpClient)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}());
export { AccountService };
