import {Component, OnInit, Renderer2, ElementRef, EventEmitter} from "@angular/core";
import {AccountService} from "../account.service";
import {UserService} from "./../../shared/user.service";
import drugList from "../../../app/drug-list";
import {DatePipe} from "@angular/common";
import {SelectItem} from "primeng/api";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ServerResponse} from "../../model/base/server-response.model";
import {UserInsurance, UserInsuranceCardholder} from "../../user/model/user-insurance.model";
import {User, UserData} from "../../user/model/user.model";
import {UtilsService} from "@services/utils.service";
import {FormatService} from "@services/format.service";
import {ValidationService} from "@services/validation.service";

@Component({
    selector: "app-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.css"]
})
export class ProfileComponent implements OnInit {
    public profileSaved = false;
    public userId: any = sessionStorage.getItem("userId");
    public userDetailObj: any = {};

    public products: String[] = [];
    public filteredProducts: String[] = [];

    public currentUserID: any;
    readonly rootUrl = sessionStorage.getItem("location");

    public cardholderRelationship: SelectItem[];
    public insurances: any[] = [];

    public errorMessage: string = '';
    public insuranceErrorMessage: string = '';
    public insuranceIndexToDelete: number|null = null;
    public personalinfoForm: FormGroup;

    public loadingResponse: boolean = false;

    constructor(private formBuilder: FormBuilder,
                private accountService: AccountService,
                private userService: UserService,
                private renderer: Renderer2,
                private el: ElementRef,
                private datePipe: DatePipe,
                private http: HttpClient) {

        this.createForm();

    }

    ngOnInit() {
        this.currentUserID = sessionStorage.getItem("userId");
        this.getUserDetailsData();
        this.products = drugList.map(product => product.scientific_name);
        this.filteredProducts = this.products;

        this.getCardRelationshipOptions();
    }

    /**
     * Insurances Form Array
     */
    get insurancesFormArray(): FormArray {
        return this.personalinfoForm.get('insurances') as FormArray;
    }

    /**
     * Create profile form group
     */
    createForm() {
        this.personalinfoForm = this.formBuilder.group({
            dob: ["", [Validators.required, ValidationService.dateValidator]],
            first_name: ["", [Validators.required]],
            last_name: ["", [Validators.required]],
            phone: ["", [Validators.required, ValidationService.phoneValidator]],
            desired_meds: ["", {
                validators: [],
                updateOn: "change"
            }],
            allergies: ["", {
                validators: [Validators.required],
                updateOn: "change"
            }],
            noallergies: ["", {updateOn: "change"}],

            noinsurance: ["", {
                updateOn: "change"
            }],
            insurances: this.formBuilder.array([], Validators.required),
        }, {updateOn: "blur"});

        this.setDynamicValidators();
    }

    /**
     * Create insurance form group
     * @param {UserInsurance} data
     * @returns {FormGroup}
     */
    getInsuranceFormGroup(data: UserInsurance = null) {
        return this.formBuilder.group({
            id: [data ? data.id : ""],
            company: [data ? data.company : "", data ? Validators.required : null],
            id_member: [data ? data.id_member : "", data ? Validators.required : null],
            rx_grp: [data ? data.rx_grp : ""],
            rx_bin: [data ? data.rx_bin : ""],
            rx_pcn: [data ? data.rx_pcn : ""],
            cardholder_id: [data ? data.cardholder_id : "", {
                updateOn: "change",
                validators: data ? Validators.required : null
            }]
        }, {updateOn: "blur"});
    }

    /**
     * Controls dynamic validators
     */
    setDynamicValidators() {
        const allergies = this.personalinfoForm.get("allergies");
        this.personalinfoForm.get("noallergies").valueChanges
            .subscribe(
                (value: boolean) => {
                    if (!value) {
                        allergies.enable();
                        allergies.setValidators([Validators.required]);
                    } else {
                        allergies.disable();
                        allergies.clearValidators();
                    }
                    allergies.updateValueAndValidity();
                }
            );

        this.personalinfoForm.get("noinsurance").valueChanges
            .subscribe(
                (value: boolean) => {
                    this.insuranceErrorMessage = "";
                    if (!value) {
                        this.insurancesFormArray.setValidators([Validators.required]);
                        this.insurancesFormArray.controls.forEach((insuranceFormGroup: FormGroup) => {
                            insuranceFormGroup.get("company").setValidators([Validators.required]);
                            insuranceFormGroup.get("id_member").setValidators([Validators.required]);
                            insuranceFormGroup.get("cardholder_id").setValidators([Validators.required]);
                        });
                    } else {
                        this.insurancesFormArray.clearValidators();
                        this.insurancesFormArray.controls.forEach((insuranceFormGroup: FormGroup) => {
                            insuranceFormGroup.get("company").setValidators(null);
                            insuranceFormGroup.get("id_member").setValidators(null);
                            insuranceFormGroup.get("cardholder_id").setValidators(null);

                            insuranceFormGroup.get("company").updateValueAndValidity();
                            insuranceFormGroup.get("id_member").updateValueAndValidity();
                            insuranceFormGroup.get("cardholder_id").updateValueAndValidity();
                        });
                        UtilsService.markFormGroupPristine(this.insurancesFormArray);
                    }
                    this.insurancesFormArray.updateValueAndValidity();
                }
            );

        this.insurancesFormArray.valueChanges.subscribe((value) => {
            this.insuranceErrorMessage = "";
        });
    }

    public onAddNewInsurance() {
        if (this.insurancesFormArray.length < 2) {
            this.insurancesFormArray.push(this.getInsuranceFormGroup());
            this.personalinfoForm.get('noinsurance').setValue(false);
        }
    }

    public onDeleteInsurance(index) {
        this.insuranceIndexToDelete = index;
    }

    public deleteInsurance() {
        if (this.insuranceIndexToDelete !== null) {
            this.insurancesFormArray.removeAt(this.insuranceIndexToDelete);
            this.insuranceIndexToDelete = null;
            if (this.insurancesFormArray.controls.length === 0) {
                // If no mor insurances, set true the "noinsurance" flag
                this.personalinfoForm.get('noinsurance').setValue(true);
            }
        }
    }


    /**
     * Map the user data to the profile form
     * @param {User} data
     */
    public processUserDetails(data: User) {

        this.userDetailObj = data;

        while (this.insurancesFormArray.controls.length) {
            this.insurancesFormArray.removeAt(0);
        }

        this.personalinfoForm.reset();
        this.personalinfoForm.patchValue({
            "first_name": this.userDetailObj.first_name,
            "last_name": this.userDetailObj.last_name,
            "phone": FormatService.formatPhoneForDisplay(this.userDetailObj.phone),

            "desired_meds": this.userDetailObj.medical_info.desired_meds ? this.userDetailObj.medical_info.desired_meds.split(",").filter(el => !!el) : [],

            "dob": this.datePipe.transform(this.userDetailObj.birth_date, "MM/dd/yyyy"),

            "noallergies": this.userDetailObj.medical_info.allergies === 'No drug allergies',
            "allergies": this.userDetailObj.medical_info.allergies && this.userDetailObj.medical_info.allergies !== 'No drug allergies' ? this.userDetailObj.medical_info.allergies.split(",").filter(el => !!el) : [],

            "noinsurance": this.userDetailObj.no_insurance || !this.userDetailObj.insurances.length,

        });

        if (this.userDetailObj.insurances && this.userDetailObj.insurances.length) {
            this.userDetailObj.insurances.map(insurance => {
                this.insurancesFormArray.push(this.getInsuranceFormGroup(insurance));
            });
        }

    }

    /**
     * Get profile data
     */
    public getUserDetailsData() {
        this.userService.getUserDetails()
            .subscribe((response: ServerResponse<UserData>) => {
                if (response.status.success) {
                    if (response.data) {
                        this.processUserDetails(response.data.user);
                    }
                }
            }, error => {
            });
    }

    /**
     * Update profile info
     */
    public onSubmit() {

        this.errorMessage = '';

        if (!this.personalinfoForm.valid) {
            UtilsService.markFormGroupTouched(this.personalinfoForm);
            if (this.insurancesFormArray.invalid && !this.personalinfoForm.get('noinsurance').value) {
                this.insuranceErrorMessage = 'Please complete insurance info or select "No Insurance"';
            }
            return;
        }

        this.personalinfoForm.value["user_id"] = sessionStorage.getItem("userId");


        const personalInfo: any = Object.assign({}, this.personalinfoForm.value);

        if (this.personalinfoForm.get("noallergies").value) {
            personalInfo.allergies = "No drug allergies";
        } else {
            personalInfo.allergies = this.personalinfoForm.get("allergies").value.join(',');
        }

        personalInfo.desired_meds = this.personalinfoForm.get("desired_meds").value.join(',');
        personalInfo.dob = FormatService.formatDateToISO(this.personalinfoForm.get('dob').value, "/", "-");
        personalInfo.phone = FormatService.formatPhoneForSave(this.personalinfoForm.get('phone').value);

        this.loadingResponse = true;

        this.accountService.updateProfileInfo(personalInfo)
            .subscribe((response: ServerResponse<User>) => {
                this.loadingResponse = false;

                if (response.status.success) {
                    this.profileSaved = true;
                    if (response.data) {
                        this.processUserDetails(response.data);
                    }
                    setTimeout(() => {
                        this.profileSaved = false;
                    }, 3000);
                }
                else {
                    if (response.status.errors && response.status.errors.phone) {
                        if (response.status.errors.phone.length) {
                            this.personalinfoForm.get('phone').setErrors({'used': true});
                            this.personalinfoForm.get('phone').markAsTouched();
                            this.personalinfoForm.get('phone').markAsDirty();
                        }
                    }
                    this.errorMessage = response.status.message;
                }
            }, (err: HttpErrorResponse) => {
                this.loadingResponse = false;
            });
    }

    /**
     * Send reset link
     */
    public forgotPassword() {
        const emailObject = {
            "email": this.userDetailObj.email
        };
        this.userService.forgotPassword(emailObject)
            .subscribe((response: any) => {
            });
    }

    /**
     * Filter products based on event query
     * @param event - Event query
     */
    public filterProducts(event) {
        const query = event.query;
        this.filteredProducts = this.filterProduct(query, this.products);
    }

    /**
     * Filter given products based on filter
     * @param query - Filter query
     * @param products - Products
     */
    filterProduct(query, products: any[]): any[] {
        const filtered: any[] = [];
        products.forEach(
            product =>
                product.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
                filtered.push(product)
        );
        if (!filtered.length) {
            filtered.push(query);
        }
        return filtered;
    }


    /**
     * Fetch "insurance card relationships" options
     */
    getCardRelationshipOptions() {
        this.cardholderRelationship = [];
        this.cardholderRelationship.push({label: "Please select", value: ""});
        this.http
            .get(this.rootUrl + "/cardholder-relationship")
            .subscribe((response: ServerResponse<UserInsuranceCardholder[]>) => {
                if (response.status.success) {
                    response.data.forEach(value => {
                        this.cardholderRelationship.push({
                            label: value.name,
                            value: value.id
                        });
                    });
                }
            });
    }

}
