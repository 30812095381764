export class FormatService {

    /**
     * Transforms date MM-DD-YYYY -> YYYY-MM-DD
     * @param date - Date string in MM-DD-YYYY format
     */
    static formatDateToISO(date: string, inputSeparator: string = "-", outputSeparator: string = "-"): string {
        const parts: string[] = date.split(inputSeparator);
        return parts[2] + outputSeparator + parts[0] + outputSeparator + parts[1];
    }

    /**
     * Transforms date YYYY-MM-DD -> MM-DD-YYYY
     * @param date - Date string in YYYY-MM-DD format
     */
    static formatDateFromISO(date: string, inputSeparator: string = "-", outputSeparator: string = "-"): string {
        const parts: string[] = date.split(inputSeparator);
        return parts[1] + outputSeparator + parts[2] + outputSeparator + parts[0];
    }

    /**
     * Transforms phone 2122323190 -> 212-232-3190
     * @param {string} phone
     * @returns {string}
     */
    static formatPhoneForDisplay(phone: string): string {
        return phone;
        if (phone) {
            const phoneNumber = phone.replace(/-/g, "");
            if (phoneNumber.length === 10) {
                return phoneNumber.substr(0, 3) + '-' + phone.substr(3, 3) + '-' + phone.substr(6);
            }
            else return phone;
        }
        else return phone;
    }

    /**
     * 212-232-3190 -> 2122323190
     * @param {string} phone
     * @returns {string}
     */
    static formatPhoneForSave(phone: string): string {
        return phone.replace(/-/g, "");
    }

}