import { Router } from "@angular/router";
import { CartService } from "@modules/cart/services";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable, BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../../cart/services/cart.service";
var CheckoutService = /** @class */ (function () {
    function CheckoutService(http, router, cartService) {
        this.http = http;
        this.router = router;
        this.cartService = cartService;
        this.POST_ORDER_URL = "/orders/place";
        this.counseling = false;
        this.piwikid = '';
        this.mkwid = '';
        this.shippingStateError = new BehaviorSubject(false);
        this.errorMessage = '';
        this.rootUrl = sessionStorage.getItem("location");
        this.checkoutStep = 1;
        var savedCartData = sessionStorage.getItem("cart_details");
        if (savedCartData) {
            var data = JSON.parse(savedCartData);
            if (data) {
                if (data.checkoutStep) {
                    this.checkoutStep = data.checkoutStep;
                }
                if (data.billingAddress) {
                    this.billingAddress = data.billingAddress;
                }
                if (data.insuranceOption) {
                    this.insuranceOption = data.insuranceOption;
                }
                if (data.shippingAddress) {
                    this.shippingAddress = data.shippingAddress;
                }
                if (data.shippingOption) {
                    this.shippingOption = data.shippingOption;
                }
                if (data.payment) {
                    this.payment = data.payment;
                }
                /*
                if (data.heartlandToken) {
                    this.heartlandToken = data.heartlandToken;
                }
                if (data.heartlandData) {
                    this.heartlandData = data.heartlandData;
                }
                */
                if (data.shippingStateErrorProducts) {
                    this.shippingStateErrorProducts = data.shippingStateErrorProducts;
                }
            }
        }
        this.headers = new HttpHeaders();
        this.headers.append("Access-Control-Allow-Origin", "*");
        this.headers.append("Access-Control-Allow-Headers", "*");
        this.headers.append("Access-Control-Allow-Methods", " GET, POST, DELETE, PUT, HEAD, OPTIONS");
        this.headers.append("Request Method", "POST, GET, PUT, DELETE, OPTIONS, HEAD");
        this.headers.append("Content-Type", "application/json, application/x-www-form-urlencoded, multipart/form-data, text/plain, charset=UTF-8");
    }
    /**
     * Set the cart error
     * @param {string} error
     */
    CheckoutService.prototype.setErrorMessage = function (error) {
        this.errorMessage = error;
    };
    /**
     * get the cart error
     * @returns {string}
     */
    CheckoutService.prototype.getErrorMessage = function () {
        return this.errorMessage;
    };
    /**
     * Set counseling
     * @param counseling - Boolean
     */
    CheckoutService.prototype.setCounseling = function (counselingValue) {
        this.counseling = counselingValue;
        this.saveCartDetails();
    };
    /**
     * Get counseling
     */
    CheckoutService.prototype.getCounseling = function () {
        return this.counseling;
    };
    /**
     * Set billing address
     * @param address - Address
     */
    CheckoutService.prototype.setBillingAddress = function (address) {
        this.billingAddress = address;
        this.saveCartDetails();
    };
    /**
     * Get billing address
     */
    CheckoutService.prototype.getBillingAddress = function () {
        return this.billingAddress;
    };
    /**
     * Set Insurance
     */
    CheckoutService.prototype.setInsuranceOption = function (insurance) {
        this.insuranceOption = insurance;
        this.saveCartDetails();
    };
    /**
     * Get Insurance
     */
    CheckoutService.prototype.getInsuranceOption = function () {
        return this.insuranceOption;
    };
    /**
     * Set shipping address
     * @param address - Address
     */
    CheckoutService.prototype.setShippingAddress = function (address) {
        this.shippingAddress = address;
        this.saveCartDetails();
    };
    /**
     * Get shipping address
     */
    CheckoutService.prototype.getShippingAddress = function () {
        return this.shippingAddress;
    };
    /**
     * Set shipping option
     * @param option Shipping option
     */
    CheckoutService.prototype.setShippingOption = function (option) {
        this.shippingOption = option;
        this.saveCartDetails();
    };
    /**
     * Get shipping option
     */
    CheckoutService.prototype.getShippingOption = function () {
        return this.shippingOption;
    };
    CheckoutService.prototype.setShippingStateErrorProducts = function (products) {
        this.shippingStateErrorProducts = products;
        this.saveCartDetails();
    };
    CheckoutService.prototype.setShippingStateError = function (value) {
        if (value) {
            this.shippingStateError.next(value);
        }
        else {
            this.shippingStateError.next(false);
        }
        this.saveCartDetails();
    };
    CheckoutService.prototype.getShippingStateErrorProducts = function () {
        return this.shippingStateErrorProducts;
    };
    CheckoutService.prototype.getShippingStateErrorProduct = function (productId) {
        if (this.shippingStateErrorProducts) {
            return this.shippingStateErrorProducts.filter(function (product) { return product === productId; });
        }
        return false;
    };
    /**
     * Set Heartland token
     * @param data
     */
    CheckoutService.prototype.setHeartlandToken = function (data) {
        /*
        this.card.number = data.last_four;
        this.card.type = data.card_type && data.card_type.charAt(0).toUpperCase() + data.card_type.slice(1);
        this.saveCreditCard = data.save_credit_card;
        this.addNewCard = data.add_new_card;
        */
        this.heartlandData = data;
        if (data) {
            this.heartlandToken = data.token_value;
            this.heartlandData.card_type = data.card_type && data.card_type.charAt(0).toUpperCase() + data.card_type.slice(1);
        }
        else {
            this.heartlandToken = null;
        }
        this.saveCartDetails();
    };
    CheckoutService.prototype.setPayment = function (payment) {
        this.payment = payment;
        this.saveCartDetails();
    };
    CheckoutService.prototype.getPayment = function () {
        return this.payment;
    };
    CheckoutService.prototype.setCard = function (card) {
        console.log(card);
        this.card = {
            id: card.id,
            card_type: card.card_type,
            exp_month: card.exp_month,
            exp_year: card.exp_year,
            last_four_digits: card.last_four_digits
        };
        this.saveCartDetails();
    };
    CheckoutService.prototype.getCard = function () {
        return this.card;
    };
    /**
     * Get/Set Piwikid and Mkwid
     */
    CheckoutService.prototype.getPiwikid = function () {
        return this.piwikid;
    };
    CheckoutService.prototype.getMkwid = function () {
        return this.mkwid;
    };
    CheckoutService.prototype.setPiwikidAndMkwid = function (piwikid, mkwid) {
        if (piwikid) {
            this.piwikid = piwikid;
        }
        if (mkwid) {
            this.mkwid = mkwid;
        }
        this.saveCartDetails();
    };
    CheckoutService.prototype.getRecaptchaToken = function () {
        return this.recaptchaToken;
    };
    CheckoutService.prototype.setRecaptchaToken = function (token) {
        this.recaptchaToken = token;
    };
    /**
     * Get Heartland token
     */
    CheckoutService.prototype.getHeartlandToken = function () {
        return this.heartlandData;
    };
    CheckoutService.prototype.getHeartlandData = function () {
        return this.heartlandData;
    };
    /**
     * Move to next step
     * @param step - Current step index
     */
    CheckoutService.prototype.nextStep = function (step) {
        this.checkoutStep = step;
        this.saveCartDetails();
        switch (this.checkoutStep) {
            case 1: {
                return this.router.navigate(["/checkout/billing"]);
            }
            case 2: {
                return this.router.navigate(["/checkout/shipping"]);
            }
            case 3: {
                return this.router.navigate(["/checkout/payment"]);
            }
            case 4: {
                return this.router.navigate(["/checkout/review"]);
            }
            case 5: {
                return this.createOrder();
            }
        }
    };
    CheckoutService.prototype.setStep = function (step) {
        this.checkoutStep = step;
        this.saveCartDetails();
    };
    /**
     * Create order
     */
    CheckoutService.prototype.createOrder = function () {
        this.errorMessage = '';
        var promocode = this.cartService.getPromoCode();
        var products = this.cartService.getProducts();
        var subtotalPrice = this.cartService
            .getProducts()
            .map(function (product) { return product.price; })
            .reduce(function (f, s) { return f + s; }, 0);
        var shippingAmount = this.shippingOption.price;
        var discountPrice = this.cartService.getDiscountValue();
        var totalPrice = this.cartService.getTotal();
        //console.log('am ca payment', this.payment);
        var data = {
            /*
            token: this.heartlandToken,
            save_credit_card: this.heartlandData ? this.heartlandData.save_credit_card : false,
            new_card: this.heartlandData ? this.heartlandData.add_new_card : false,
            card_info: {
                exp_month: this.heartlandData ? this.heartlandData.exp_month : '',
                exp_year: this.heartlandData ? this.heartlandData.exp_year : '',
                number: this.heartlandData ? this.heartlandData.last_four : '',
                type: this.heartlandData ? this.heartlandData.card_type : ''
            },
            */
            payment: this.payment,
            customer_id: sessionStorage.getItem("userId"),
            products: products.map(function (product) {
                return {
                    product: product.product.id,
                    variant: product.productVariant != null ? product.productVariant.id : null,
                    price: product.pricePerItem,
                    total: product.price,
                    quantity: product.quantity,
                    medication_testing: product.product.hasOwnProperty('medication_testing') ? product.product.medication_testing.id : null
                };
            }),
            promocode: promocode != null ? promocode.id : '',
            billing_address: this.billingAddress.id,
            shipping_address: this.shippingAddress.id,
            insurance: this.insuranceOption,
            shipping_carrier: this.shippingOption.id,
            notes: "",
            subtotal_price: subtotalPrice,
            discount: discountPrice,
            total_price: totalPrice,
            counseling: this.counseling,
            piwikid: this.piwikid,
            mkwid: this.mkwid,
            recaptchaToken: this.recaptchaToken
        };
        //console.log(data);
        return this.http
            .post(this.rootUrl + this.POST_ORDER_URL, data, { headers: this.headers })
            .pipe(map(function (response) {
            return response;
        }));
    };
    CheckoutService.prototype.savedCreditCards = function () {
        return this.http
            .get(this.rootUrl + "/customers/saved-credit-cards", { headers: this.headers })
            .pipe(map(function (response) {
            return response;
        }));
    };
    CheckoutService.prototype.saveCreditCard = function (data, recaptchaToken) {
        if (recaptchaToken === void 0) { recaptchaToken = ''; }
        var dataToSend = {
            address_id: this.billingAddress.id,
            card_type: data.card_type,
            exp_month: data.exp_month,
            exp_year: data.exp_year,
            last_four_digits: data.last_four,
            token: data.token_value,
            recaptchaToken: recaptchaToken
        };
        return this.http
            .post(this.rootUrl + "/customers/payment-methods/add", dataToSend, { headers: this.headers })
            .pipe(map(function (response) {
            return response;
        }));
    };
    CheckoutService.prototype.saveCartDetails = function () {
        var dataToSave = {
            checkoutStep: this.checkoutStep,
            billingAddress: this.billingAddress,
            insuranceOption: this.insuranceOption,
            shippingAddress: this.shippingAddress,
            shippingOption: this.shippingOption,
            //heartlandToken: this.heartlandToken,
            //heartlandData: this.heartlandData,
            payment: this.payment,
            card: this.card,
            shippingStateErrorProducts: this.shippingStateErrorProducts,
            piwikid: this.piwikid,
            mkwid: this.mkwid
        };
        sessionStorage.setItem("cart_details", JSON.stringify(dataToSave));
    };
    CheckoutService.prototype.clearCart = function () {
        this.checkoutStep = 1;
        this.billingAddress = null;
        this.insuranceOption = null;
        this.shippingAddress = null;
        this.shippingOption = null;
        //this.heartlandToken = null;
        //this.heartlandData = null;
        this.payment = null;
        this.card = null;
        this.shippingStateErrorProducts = null;
        this.errorMessage = '';
        this.saveCartDetails();
    };
    CheckoutService.ngInjectableDef = i0.defineInjectable({ factory: function CheckoutService_Factory() { return new CheckoutService(i0.inject(i1.HttpClient), i0.inject(i2.Router), i0.inject(i3.CartService)); }, token: CheckoutService, providedIn: "root" });
    return CheckoutService;
}());
export { CheckoutService };
