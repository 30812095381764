<div class="clswrapper">
    <div class=""></div>
    <!-- Main container -->
    <main class="clsmain_container">
        <div class="clsmyaccount_section">
            <div class="container">
                <ul class="clsbreadcrumb">
                    <li><a href="javascript:void(0)" [routerLink]="['/']">Home</a></li>
                    <li><a href="javascript:void(0)" [routerLink]="['/account/order-history']">Account</a></li>
                    <li>Payment Methods</li>
                </ul>
                <!--<app-top-panel></app-top-panel>-->
                <div class="clsmy_account_main_content_blk">
                    <div>
                        <div class="accountContainer">
                            <h1 class="accountTitle"> Payment Methods</h1>
                            <form #myForm='ngForm'>
                                <div class="accountBox">
                                    <div class="accountBoxTitle">
                                        Payment Methods
                                        <span><a 
                                            class="action"
                                            [ngClass]="{'action_disabled': showNewPaymentForm == true,'addressCursor': showNewPaymentForm == true}"
                                            style="cursor: pointer"
                                            (click)="toogleNewPayment()">Add New</a> </span>
                                    </div>
                                    <div class="paymentMethodDisplay accountAddressMapped" *ngIf="showNewPaymentForm">
                                        <h4>New Payment Method</h4>
                                        
                                        <form #payment_form_profile id="payment_form_profile" [formGroup]="formGroup" method="GET" class="">
                                            <div>
                                                <div class="row">
                                                    <div *ngIf="addressesSelect.length > 1" class="col-xs-12 which-address form-field">
                                                        <label for="address">Select a billing address:</label>

                                                        <p-dropdown scrollHeight="300px" [options]="addressesSelect"
                                                                    id="address"
                                                                    name="address"
                                                                    class="valisure_select"
                                                                    formControlName="address"></p-dropdown>

                                                    </div>
                                                    <div class="col-xs-12 selected-address" style="margin-top: 25px">

                                                        <app-address-display [address]="selectedAddress" *ngIf="selectedAddress && !loadingAddresses"></app-address-display>

                                                        <div *ngIf="!selectedAddress && !loadingAddresses">

                                                            <app-address-form
                                                                    [formGroup]="formGroup.get('new_address')"
                                                                    [states]="states"
                                                            ></app-address-form>

                                                            <div *ngIf="newAddressError && newAddressError" class="alert alert-danger" style="margin-top: 20px">
                                                                {{ newAddressError }}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div *ngIf="selectedAddress">
                                                <div class="row">
                                                    <!-- Your payment fields go here -->
                                                    <div class="col-xs-12 card-number">
                                                        <dt><label for="iframesCardNumber">Card Number</label></dt>
                                                        <dd>
                                                            <div id="iframesCardNumber"></div>
                                                        </dd>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-6 card-expiration">
                                                        <dt><label for="iframesCardExpiration">Card Expiration</label></dt>
                                                        <dd>
                                                            <div id="iframesCardExpiration"></div>
                                                        </dd>
                                                    </div>
                                                    <div class="col-xs-6 card-cvv">
                                                        <dt><label for="iframesCardCvv">CVV</label></dt>
                                                        <dd>
                                                            <div id="iframesCardCvv"></div>
                                                        </dd>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-12 form-group">
                                                        <ul class="clsfeature_list clscheckout_review_check">
                                                            <li>
                                                            <div class="checkbox">
                                                                <input type="checkbox" formControlName="saveCreditCard" id="saveCreditCard" name="save_credit_card">
                                                                    <label for="saveCreditCard"><span></span>Make Default</label>
                                                            </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            
                                                <div *ngIf="paymentError != null && paymentError!= ''" class="col-xs-12 form-group" style="margin-top: 20px;">
                                                  <div class="alert alert-danger" *ngIf="paymentError">
                                                    {{ paymentError }}
                                                  </div>
                                                </div>
                                            
                                                <!-- Regular input fields are ok, too -->
                                                <input #payment_token_profile type="hidden" name="payment_token" />
                                            </div>
                                        </form>

                                        <div class="btn_line">
                                            <a *ngIf='!savingResponse' style="cursor: pointer" 
                                                class="action" 
                                                (click)="toogleNewPayment()"
                                                [ngClass]="{'action_disabled': loadingResponse}">
                                                Cancel
                                            </a>
                                            <a *ngIf='!savingResponse' style="cursor: pointer" 
                                                class="action" 
                                                (click)="save()"
                                                [ngClass]="{'action_disabled': loadingResponse}">
                                                <span *ngIf="!loadingResponse">Save {{!selectedAddress ? 'Address' : ''}}</span>
                                                <span *ngIf="loadingResponse">Please wait</span>
                                            </a>
                                            <span *ngIf='savingResponse'>Loading. Please wait ...</span>
                                        </div>
                                    </div>
                                    <div class="paymentMethodDisplay" 
                                        *ngFor="let paymentmethod of paymentMethodsArray; let i = index"
                                        [ngClass]="{'accountAddressMapped': selectedIndex == i}">
                                        <div class="checkbox">
                                            <input
                                              type="radio"
                                              id="radio{{ i }}"
                                              name="defaultPaymentMethod"
                                              value="1"
                                              [disabled]="!(paymentmethod.is_valid && checkExpiration(paymentmethod))"
                                              [checked]="paymentmethod.is_default"
                                              (click)="setDefaultPaymentOption(paymentmethod.id)"
                                            />
                                            <label for="radio{{ i }}"><span></span></label>
                                        </div>
                                        <div class=paymentMethod>
                                            <div style="display: inline-block;">
                                                {{paymentmethod.card_type | uppercase}} ending in {{paymentmethod.last_four_digits}}
                                                <div *ngIf='paymentmethod.exp_year != null  && paymentmethod.exp_month != null '>Exp: {{ paymentmethod.exp_month }} / {{ paymentmethod.exp_year }}</div>
                                                
                                                <div class="text-danger" *ngIf='!paymentmethod.is_valid'>This payment method is no longer valid. Please remove it and add it again, if necessary.</div>
                                                <div class="text-danger" *ngIf='!checkExpiration(paymentmethod)'>This payment method has expired. Please remove it and add it again, if necessary.</div>
                                                
                                            </div>
                                            <span>
                                                <a  *ngIf="!paymentmethod.is_default && paymentmethod.is_valid && checkExpiration(paymentmethod)"
                                                    (click)="setDefaultPaymentOption(paymentmethod.id)"
                                                    style="cursor: pointer"
                                                    class="action setdefault">
                                                    Set as Default
                                                </a>
                                                <a
                                                    data-toggle="modal"
                                                    (click)="deletePaymentWithId(paymentmethod.id)"
                                                    style="cursor: pointer"
                                                    class="action"
                                                    data-target="#myModal">
                                                    Delete
                                                </a>
                                            </span>
                                        </div>
                                    </div>


                                    <div *ngIf="!loadingResponse">
                                        <div class="accountMessage" *ngIf="paymentMethodsArray.length === 0 && !showNewPaymentForm">You don't have any payment methods saved.</div>
                                    </div>    
                                    <div *ngIf="!loadedResponse &&  loadingResponse" class="accountMessage">
                                        Loading. Please wait ...
                                    </div>                                
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </main>
</div>

<div class="modal fade clsaddress_delete_confirm_modal" id="myModal" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel">
    <div class="vertical-alignment-helper">
        <div class="modal-dialog vertical-align-center" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">Are you sure you want to delete this card ?</h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xs-6">
                            <a href="javascript:void(0)" data-dismiss="modal" class="action poppin_semibold">No</a>
                        </div>
                        <div class="col-xs-6">
                            <a href="javascript:void(0)" data-dismiss="modal" (click)="deletePaymentMethod()" class="action poppin_semibold">Yes</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
