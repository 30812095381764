/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "./shared/user.service";
import * as i5 from "./modules/cart/services/cart.service";
import * as i6 from "./modules/shop/services/categories.service";
import * as i7 from "./modules/shop/services/product.service";
import * as i8 from "@angular/router";
import * as i9 from "./shared/search.service";
import * as i10 from "./modules/doomsday/components/doomsday-header/header.component.ngfactory";
import * as i11 from "./modules/doomsday/components/doomsday-header/header.component";
import * as i12 from "./footer/footer.component.ngfactory";
import * as i13 from "./footer/footer.component";
import * as i14 from "./modules/doomsday/components/doomsday-footer/footer.component.ngfactory";
import * as i15 from "./modules/doomsday/components/doomsday-footer/footer.component";
import * as i16 from "@angular/common";
import * as i17 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "clsheader_top_bg"]], null, null, null, null, null))], null, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i3.HeaderComponent, [i4.UserService, i5.CartService, i6.CategoriesService, i7.ProductService, i8.Router, i9.SearchService, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-doomsday-header", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_DoomsdayHeaderComponent_0, i10.RenderType_DoomsdayHeaderComponent)), i1.ɵdid(1, 245760, null, 0, i11.DoomsdayHeaderComponent, [i8.Router, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, i12.View_FooterComponent_0, i12.RenderType_FooterComponent)), i1.ɵdid(1, 49152, null, 0, i13.FooterComponent, [], null, null)], null, null); }
function View_AppComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-doomsday-footer", [], null, null, null, i14.View_DoomsdayFooterComponent_0, i14.RenderType_DoomsdayFooterComponent)), i1.ɵdid(1, 49152, null, 0, i15.DoomsdayFooterComponent, [], null, null)], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "clswrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(2, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(4, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(6, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(10, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_5)), i1.ɵdid(12, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.route == "home") || (_co.route === "doomsday")); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.route === "home"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.route === "doomsday"); _ck(_v, 6, 0, currVal_2); _ck(_v, 8, 0); var currVal_3 = (_co.route === "home"); _ck(_v, 10, 0, currVal_3); var currVal_4 = (_co.route === "doomsday"); _ck(_v, 12, 0, currVal_4); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i17.AppComponent, [i16.Location, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i17.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
