import {Injectable} from "@angular/core";
import {ServerResponse} from "../../../model/base/server-response.model";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import DoomsdayStatus from "@modules/doomsday/model/doomsdaystatus.model";

@Injectable()
export class DoomsdayService {

    readonly rootUrl = sessionStorage.getItem('location');

    constructor(private http: HttpClient) {
    }

    check(): Observable<ServerResponse<DoomsdayStatus>> {
        return this.http.get<ServerResponse<DoomsdayStatus>>(this.rootUrl + "/check-doomsday", {
            params: {}
        }).pipe(catchError((error) => {
            return of(new ServerResponse<DoomsdayStatus>());
        }));
    }


}
