export const drugList = [
    {
        "scientific_name": "methylphenidate hydrochloride"
    },
    {
        "scientific_name": "abacavir sulfate"
    },
    {
        "scientific_name": "abacavir sulfate and lamivudine"
    },
    {
        "scientific_name": "abacavir sulfate and dolutegravir sodium and lamivudine"
    },
    {
        "scientific_name": "abacavir sulfate and lamivudine and zidovudine"
    },
    {
        "scientific_name": "abiraterone acetate"
    },
    {
        "scientific_name": "acamprosate calcium"
    },
    {
        "scientific_name": "acarbose"
    },
    {
        "scientific_name": "acebutolol hydrochloride"
    },
    {
        "scientific_name": "acetaminophen"
    },
    {
        "scientific_name": "acetaminophen and codeine phosphate"
    },
    {
        "scientific_name": "acetaminophen and aspirin and caffeine"
    },
    {
        "scientific_name": "acetaminophen and butalbital and caffeine"
    },
    {
        "scientific_name": "acetaminophen and caffeine and dihydrocodeine bitartrate"
    },
    {
        "scientific_name": "acetazolamide"
    },
    {
        "scientific_name": "acetic acid"
    },
    {
        "scientific_name": "acetic acid in aqueous aluminum acetate"
    },
    {
        "scientific_name": "acetohydroxamic acid"
    },
    {
        "scientific_name": "acetylsalicylic acid"
    },
    {
        "scientific_name": "acitretin"
    },
    {
        "scientific_name": "acrivastine and pseudoephedrine hydrochloride"
    },
    {
        "scientific_name": "acyclovir"
    },
    {
        "scientific_name": "adefovir dipivoxil"
    },
    {
        "scientific_name": "afatinib"
    },
    {
        "scientific_name": "albendazole"
    },
    {
        "scientific_name": "albuterol sulfate"
    },
    {
        "scientific_name": "alcaftadine"
    },
    {
        "scientific_name": "alendronate sodium"
    },
    {
        "scientific_name": "alendronate sodium and cholecalciferol"
    },
    {
        "scientific_name": "alfuzosin hydrochloride"
    },
    {
        "scientific_name": "aliskiren hemifumarate"
    },
    {
        "scientific_name": "aliskiren hemifumarate and amlodipine besylate"
    },
    {
        "scientific_name": "aliskiren hemifumarate and amlodipine besylate and hydrochlorothiazide"
    },
    {
        "scientific_name": "aliskiren hemifumarate and hydrochlorothiazide"
    },
    {
        "scientific_name": "allopurinol"
    },
    {
        "scientific_name": "almotriptan malate"
    },
    {
        "scientific_name": "alogliptin"
    },
    {
        "scientific_name": "alogliptin and metformin hydrochloride"
    },
    {
        "scientific_name": "alogliptin and pioglitazone"
    },
    {
        "scientific_name": "alosetron hydrochloride"
    },
    {
        "scientific_name": "alprazolam"
    },
    {
        "scientific_name": "alprostadil"
    },
    {
        "scientific_name": "altretamine"
    },
    {
        "scientific_name": "alvimopan"
    },
    {
        "scientific_name": "amantadine hydrochloride"
    },
    {
        "scientific_name": "ambrisentan"
    },
    {
        "scientific_name": "amiloride hydrochloride"
    },
    {
        "scientific_name": "amiloride hydrochloride and hydrochlorothiazide"
    },
    {
        "scientific_name": "aminocaproic acid"
    },
    {
        "scientific_name": "aminophylline dihydrate"
    },
    {
        "scientific_name": "amiodarone hydrochloride"
    },
    {
        "scientific_name": "amitriptyline hydrochloride"
    },
    {
        "scientific_name": "amlobenz"
    },
    {
        "scientific_name": "amlodipine besylate"
    },
    {
        "scientific_name": "amlodipine besylate and atorvastatin calcium"
    },
    {
        "scientific_name": "amlodipine besylate and benazepril hydrochloride"
    },
    {
        "scientific_name": "amlodipine besylate and olmesartan medoxomil"
    },
    {
        "scientific_name": "amlodipine besylate and valsartan"
    },
    {
        "scientific_name": "amlodipine and valsartan and hydrochlorothiazide"
    },
    {
        "scientific_name": "amoxapine"
    },
    {
        "scientific_name": "amoxicillin"
    },
    {
        "scientific_name": "amoxicillin and clavulanate potassium"
    },
    {
        "scientific_name": "amphetamine sulfate"
    },
    {
        "scientific_name": "ampicillin"
    },
    {
        "scientific_name": "ampicillin trihydrate"
    },
    {
        "scientific_name": "anagrelide hydrochloride"
    },
    {
        "scientific_name": "anastrozole"
    },
    {
        "scientific_name": "apixaban"
    },
    {
        "scientific_name": "apraclonidine hydrochloride"
    },
    {
        "scientific_name": "apremilast"
    },
    {
        "scientific_name": "aprepitant"
    },
    {
        "scientific_name": "aripiprazole"
    },
    {
        "scientific_name": "armodafinil"
    },
    {
        "scientific_name": "artemether and lumefantrine"
    },
    {
        "scientific_name": "asacol"
    },
    {
        "scientific_name": "asenapine maleate"
    },
    {
        "scientific_name": "aspirin and dipyridamole"
    },
    {
        "scientific_name": "aspirin and caffeine and and dihydrocodeine bitartrate"
    },
    {
        "scientific_name": "atazanavir and cobicistat"
    },
    {
        "scientific_name": "atazanavir sulfate"
    },
    {
        "scientific_name": "atenolol"
    },
    {
        "scientific_name": "atenolol and chlorthalidone"
    },
    {
        "scientific_name": "atomoxetine hydrochloride"
    },
    {
        "scientific_name": "atorvastatin calcium"
    },
    {
        "scientific_name": "atorvastatin calcium propylene glycol solvate"
    },
    {
        "scientific_name": "atorvastatin calcium trihydrate"
    },
    {
        "scientific_name": "atovaquone and proguanil hydrochloride"
    },
    {
        "scientific_name": "atropine sulfate"
    },
    {
        "scientific_name": "auranofin"
    },
    {
        "scientific_name": "avanafil"
    },
    {
        "scientific_name": "axitinib"
    },
    {
        "scientific_name": "azathioprine"
    },
    {
        "scientific_name": "azelastine hydrochloride"
    },
    {
        "scientific_name": "azilsartan kamedoxomil"
    },
    {
        "scientific_name": "azilsartan kamedoxomil and chlorthalidone"
    },
    {
        "scientific_name": "azilsartan medoxomil and chlorthalidone"
    },
    {
        "scientific_name": "azithromycin"
    },
    {
        "scientific_name": "azithromycin anhydrous"
    },
    {
        "scientific_name": "azithromycin dihydrate"
    },
    {
        "scientific_name": "azithromycin monohydrate"
    },
    {
        "scientific_name": "baclofen"
    },
    {
        "scientific_name": "balsalazide disodium"
    },
    {
        "scientific_name": "bedaquiline fumarate"
    },
    {
        "scientific_name": "benaphetamine"
    },
    {
        "scientific_name": "benazepril hydrochloride"
    },
    {
        "scientific_name": "benazepril hydrochloride and hydrochlorothiazide"
    },
    {
        "scientific_name": "benzonatate"
    },
    {
        "scientific_name": "benzphetamine hydrochloride"
    },
    {
        "scientific_name": "benztropine mesylate"
    },
    {
        "scientific_name": "bepotastine besilate"
    },
    {
        "scientific_name": "betaxolol hydrochloride"
    },
    {
        "scientific_name": "bethanechol chloride"
    },
    {
        "scientific_name": "bexarotene"
    },
    {
        "scientific_name": "bicalutamide"
    },
    {
        "scientific_name": "bimatoprost"
    },
    {
        "scientific_name": "bismuth subcitrate potassium and metronidazole and tetracycline hydrochloride"
    },
    {
        "scientific_name": "bisoprolol fumarate"
    },
    {
        "scientific_name": "bisoprolol fumarate and hydrochlorothiazide"
    },
    {
        "scientific_name": "boceprevir"
    },
    {
        "scientific_name": "bosentan"
    },
    {
        "scientific_name": "bosutinib monohydrate"
    },
    {
        "scientific_name": "brexpiprazole"
    },
    {
        "scientific_name": "brimonidine tartrate"
    },
    {
        "scientific_name": "brimonidine tartrate and timolol maleate"
    },
    {
        "scientific_name": "bromfenac sodium"
    },
    {
        "scientific_name": "bromocriptine mesylate"
    },
    {
        "scientific_name": "budesonide"
    },
    {
        "scientific_name": "bultabital and aspirin and caffeine"
    },
    {
        "scientific_name": "bumetanide"
    },
    {
        "scientific_name": "buprenorphine hydrochloride"
    },
    {
        "scientific_name": "buprenorphine hydrochloride and naloxone hydrochloride"
    },
    {
        "scientific_name": "buprenorphine hydrochloride and naloxone hydrochloride dihydrate"
    },
    {
        "scientific_name": "buprenorphine hydrochloride sublingual"
    },
    {
        "scientific_name": "bupropion hydrobromide"
    },
    {
        "scientific_name": "bupropion hydrochloride"
    },
    {
        "scientific_name": "buspirone hydrochloride"
    },
    {
        "scientific_name": "busulfan"
    },
    {
        "scientific_name": "butabarbital sodium"
    },
    {
        "scientific_name": "butalbital and acetaminophen"
    },
    {
        "scientific_name": "butalbital and acetaminophen and caffeine"
    },
    {
        "scientific_name": "butalbital and acetaminophen and caffeine and codeine phosphate"
    },
    {
        "scientific_name": "butalbital and aspirin and caffeine"
    },
    {
        "scientific_name": "butalbital and aspirin and caffeine and codeine phosphate"
    },
    {
        "scientific_name": "cabergoline"
    },
    {
        "scientific_name": "cabozantinib"
    },
    {
        "scientific_name": "calcitriol"
    },
    {
        "scientific_name": "calcitriol capsules 0.25 mcg"
    },
    {
        "scientific_name": "calcitriol capsules 0.5 mcg"
    },
    {
        "scientific_name": "calcium acetate"
    },
    {
        "scientific_name": "canagliflozin"
    },
    {
        "scientific_name": "canagliflozin and metformin hydrochloride"
    },
    {
        "scientific_name": "candesartan cilexetil"
    },
    {
        "scientific_name": "candesartan cilexetil and hydrochlorothiazide"
    },
    {
        "scientific_name": "capecitabine"
    },
    {
        "scientific_name": "captopril"
    },
    {
        "scientific_name": "captopril and hydrochlorothiazide"
    },
    {
        "scientific_name": "carbamazepine"
    },
    {
        "scientific_name": "carbidopa"
    },
    {
        "scientific_name": "carbidopa and levodopa"
    },
    {
        "scientific_name": "carbidopa and levodopa and entacapone"
    },
    {
        "scientific_name": "carbinoxamine maleate"
    },
    {
        "scientific_name": "carglumic acid"
    },
    {
        "scientific_name": "cariprazine"
    },
    {
        "scientific_name": "carisoprodol"
    },
    {
        "scientific_name": "carisoprodol and aspirin"
    },
    {
        "scientific_name": "carisoprodol and aspirin and codeine phosphate"
    },
    {
        "scientific_name": "carteolol hydrochloride"
    },
    {
        "scientific_name": "carvedilol phosphate"
    },
    {
        "scientific_name": "cefaclor"
    },
    {
        "scientific_name": "cefadroxil"
    },
    {
        "scientific_name": "cefadroxil hemihydrate"
    },
    {
        "scientific_name": "cefdinir"
    },
    {
        "scientific_name": "cefditoren pivoxil"
    },
    {
        "scientific_name": "cefixime"
    },
    {
        "scientific_name": "cefpodoxime proxetil"
    },
    {
        "scientific_name": "cefprozil"
    },
    {
        "scientific_name": "ceftibuten dihydrate"
    },
    {
        "scientific_name": "cefuroxime axetil"
    },
    {
        "scientific_name": "celebrex"
    },
    {
        "scientific_name": "celecoxib"
    },
    {
        "scientific_name": "cephalexin"
    },
    {
        "scientific_name": "ceritinib"
    },
    {
        "scientific_name": "cetirizine hcl and pseudoephedrine hcl"
    },
    {
        "scientific_name": "cetirizine hydrochloride"
    },
    {
        "scientific_name": "cevimeline hydrochloride"
    },
    {
        "scientific_name": "chenodiol"
    },
    {
        "scientific_name": "chlorambucil"
    },
    {
        "scientific_name": "chlordiazepoxide and amitriptyline hydrochloride"
    },
    {
        "scientific_name": "chlordiazepoxide hydrochloride"
    },
    {
        "scientific_name": "chlordiazepoxide hydrochloride and clidinium bromide"
    },
    {
        "scientific_name": "chlorhexidine gluconate"
    },
    {
        "scientific_name": "chloroquine phosphate"
    },
    {
        "scientific_name": "chlorothiazide"
    },
    {
        "scientific_name": "chlorpromazine hydrochloride"
    },
    {
        "scientific_name": "chlorpropamide"
    },
    {
        "scientific_name": "chlorthalidone"
    },
    {
        "scientific_name": "chlorzoxazone"
    },
    {
        "scientific_name": "cholic acid"
    },
    {
        "scientific_name": "cilostazol"
    },
    {
        "scientific_name": "cimetidine"
    },
    {
        "scientific_name": "cinacalcet hydrochloride"
    },
    {
        "scientific_name": "ciprofloxacin"
    },
    {
        "scientific_name": "citalopram hydrobromide"
    },
    {
        "scientific_name": "clarithromycin"
    },
    {
        "scientific_name": "clemastine fumarate"
    },
    {
        "scientific_name": "clindamycin hydrochloride"
    },
    {
        "scientific_name": "clindamycin phosphate"
    },
    {
        "scientific_name": "clobazam"
    },
    {
        "scientific_name": "clomiphene citrate"
    },
    {
        "scientific_name": "clomipramine hydrochloride"
    },
    {
        "scientific_name": "clonazepam"
    },
    {
        "scientific_name": "clonidine hydrochloride"
    },
    {
        "scientific_name": "clonidine hydrochloride and chlorthalidone"
    },
    {
        "scientific_name": "clopidogrel"
    },
    {
        "scientific_name": "clopidogrel bisulfate"
    },
    {
        "scientific_name": "clopidogrel bisulphate"
    },
    {
        "scientific_name": "clorazepate dipotassium"
    },
    {
        "scientific_name": "clotrimazole"
    },
    {
        "scientific_name": "clozapine"
    },
    {
        "scientific_name": "cobicistat"
    },
    {
        "scientific_name": "codeine and chlorpheniramine maleate er"
    },
    {
        "scientific_name": "codeine sulfate"
    },
    {
        "scientific_name": "colchicine"
    },
    {
        "scientific_name": "colesevelam hydrochloride"
    },
    {
        "scientific_name": "colestipol hydrochloride"
    },
    {
        "scientific_name": "conjugated estrogens"
    },
    {
        "scientific_name": "conjugated estrogens and medroxyprogesterone acetate"
    },
    {
        "scientific_name": "conjugated estrogens/bazedoxifene"
    },
    {
        "scientific_name": "cortisone acetate"
    },
    {
        "scientific_name": "cozaar"
    },
    {
        "scientific_name": "crizotinib"
    },
    {
        "scientific_name": "crofelemer"
    },
    {
        "scientific_name": "cromolyn sodium"
    },
    {
        "scientific_name": "cyclobenzaprine hydrochloride"
    },
    {
        "scientific_name": "cyclopentolate hydrochloride"
    },
    {
        "scientific_name": "cyclopentolate hydrochloride and phenylephrine hydrochloride"
    },
    {
        "scientific_name": "cyclophosphamide"
    },
    {
        "scientific_name": "cycloserine"
    },
    {
        "scientific_name": "cyclosporine"
    },
    {
        "scientific_name": "cymbalta"
    },
    {
        "scientific_name": "cyproheptadine hydrochloride"
    },
    {
        "scientific_name": "cysteamine bitartrate"
    },
    {
        "scientific_name": "dabigatran etexilate mesylate"
    },
    {
        "scientific_name": "dabrafenib"
    },
    {
        "scientific_name": "daclatasvir"
    },
    {
        "scientific_name": "dalfampridine"
    },
    {
        "scientific_name": "danazol"
    },
    {
        "scientific_name": "dantrolene sodium"
    },
    {
        "scientific_name": "dapagliflozin"
    },
    {
        "scientific_name": "dapagliflozin and metformin hydrochloride"
    },
    {
        "scientific_name": "dapsone"
    },
    {
        "scientific_name": "darifenacin"
    },
    {
        "scientific_name": "darunavir"
    },
    {
        "scientific_name": "darunavir ethanolate"
    },
    {
        "scientific_name": "darunavir ethanolate and cobicistat"
    },
    {
        "scientific_name": "dasatinib"
    },
    {
        "scientific_name": "deferasirox"
    },
    {
        "scientific_name": "deferiprone"
    },
    {
        "scientific_name": "delavirdine mesylate"
    },
    {
        "scientific_name": "demeclocycline hydrochloride"
    },
    {
        "scientific_name": "desflurane"
    },
    {
        "scientific_name": "desipramine hydrochloride"
    },
    {
        "scientific_name": "desloratadine"
    },
    {
        "scientific_name": "desloratadine and pseudoephedrine sulfate"
    },
    {
        "scientific_name": "desmopressin acetate"
    },
    {
        "scientific_name": "desvenlafaxine"
    },
    {
        "scientific_name": "dexamethasone"
    },
    {
        "scientific_name": "dexlansoprazole"
    },
    {
        "scientific_name": "dexmethylphenidate hydrochloride"
    },
    {
        "scientific_name": "dextroamphetamine saccharate and amphetamine aspartate and dextroamphetamine sulfate and amphetamine sulfate"
    },
    {
        "scientific_name": "dextroamphetamine saccharate and amphetamine aspartate monohydrate and dextroamphetamine sulfate and amphetamine sulfate"
    },
    {
        "scientific_name": "dextroamphetamine saccharate and amphetamine aspartate and dextroamphetamine sulfate and amphetamine suflate"
    },
    {
        "scientific_name": "dextroamphetamine sulfate"
    },
    {
        "scientific_name": "dextromethorphan hydrobromide and quinidine sulfate"
    },
    {
        "scientific_name": "dextromethorphan and guaifenesin"
    },
    {
        "scientific_name": "diatrizoate meglumine and diatrizoate sodium"
    },
    {
        "scientific_name": "diazepam"
    },
    {
        "scientific_name": "dichlorphenamide"
    },
    {
        "scientific_name": "diclofenac potassium"
    },
    {
        "scientific_name": "diclofenac sodium"
    },
    {
        "scientific_name": "diclofenac sodium and misoprostol"
    },
    {
        "scientific_name": "dicloxacillin sodium"
    },
    {
        "scientific_name": "dicyclomine"
    },
    {
        "scientific_name": "didanosine"
    },
    {
        "scientific_name": "diethylpropion hydrochloride"
    },
    {
        "scientific_name": "diflunisal"
    },
    {
        "scientific_name": "digoxin"
    },
    {
        "scientific_name": "dihydrocodeine bitartrate and acetaminophen and caffeine"
    },
    {
        "scientific_name": "dihydroergocornine mesylate and dihydroergocristine mesylate and dihydro-.alpha.-ergocryptine mesylate and and dihydro-.beta.-ergocryptine mesylate"
    },
    {
        "scientific_name": "dilantin"
    },
    {
        "scientific_name": "diltiazem hydrochloride"
    },
    {
        "scientific_name": "dimethyl fumarate"
    },
    {
        "scientific_name": "dinoprostone"
    },
    {
        "scientific_name": "diphenhydramine hydrochloride"
    },
    {
        "scientific_name": "diphenoxylate hydrochloride"
    },
    {
        "scientific_name": "diphenoxylate hydrochloride and atropine sulfate"
    },
    {
        "scientific_name": "dipyridamole"
    },
    {
        "scientific_name": "disopyramide phosphate"
    },
    {
        "scientific_name": "disulfiram"
    },
    {
        "scientific_name": "divalproex sodium"
    },
    {
        "scientific_name": "dofetilide"
    },
    {
        "scientific_name": "dolasetron mesylate"
    },
    {
        "scientific_name": "dolutegravir sodium"
    },
    {
        "scientific_name": "donepezil"
    },
    {
        "scientific_name": "dorzolamide hydrochloride"
    },
    {
        "scientific_name": "dorzolamide hydrochloride and timolol maleate"
    },
    {
        "scientific_name": "doxazosin"
    },
    {
        "scientific_name": "doxazosin mesylate"
    },
    {
        "scientific_name": "doxepin hydrochloride"
    },
    {
        "scientific_name": "doxercalciferol"
    },
    {
        "scientific_name": "doxycycline"
    },
    {
        "scientific_name": "doxycycline hyclate"
    },
    {
        "scientific_name": "doxylamine succinate and pyridoxine hydrochloride"
    },
    {
        "scientific_name": "dronabinol"
    },
    {
        "scientific_name": "dronedarone"
    },
    {
        "scientific_name": "drospirenone and estradiol"
    },
    {
        "scientific_name": "droxidopa"
    },
    {
        "scientific_name": "duloxetine hydrochloride"
    },
    {
        "scientific_name": "dutasteride"
    },
    {
        "scientific_name": "dutasteride and tamsulosin hydrochloride"
    },
    {
        "scientific_name": "dyphylline"
    },
    {
        "scientific_name": "edoxaban tosylate"
    },
    {
        "scientific_name": "efavirenz"
    },
    {
        "scientific_name": "efavirenz and emtricitabine and and tenofovir disoproxil fumarate"
    },
    {
        "scientific_name": "eletriptan hydrobromide"
    },
    {
        "scientific_name": "eliglustat"
    },
    {
        "scientific_name": "eltrombopag olamine"
    },
    {
        "scientific_name": "eluxadoline"
    },
    {
        "scientific_name": "elvitegravir"
    },
    {
        "scientific_name": "elvitegravir and cobicistat and emtricitabine and and tenofovir disoproxil fumarate"
    },
    {
        "scientific_name": "empagliflozin"
    },
    {
        "scientific_name": "empagliflozin and metformin hydrochloride"
    },
    {
        "scientific_name": "empagliflozin linagliptin"
    },
    {
        "scientific_name": "emtricitabine"
    },
    {
        "scientific_name": "emtricitabine and tenofovir disoproxil fumarate"
    },
    {
        "scientific_name": "emtricitabine and rilpivirine hydrochloride and and tenofovir disoproxil fumarate"
    },
    {
        "scientific_name": "enalapril maleate"
    },
    {
        "scientific_name": "enalapril maleate and hydrochlorothiazide"
    },
    {
        "scientific_name": "entacapone"
    },
    {
        "scientific_name": "entecavir"
    },
    {
        "scientific_name": "enzalutamide"
    },
    {
        "scientific_name": "epinastine hydrochloride"
    },
    {
        "scientific_name": "eplerenone"
    },
    {
        "scientific_name": "eprosartan mesylate"
    },
    {
        "scientific_name": "eprosartan mesylate and hydrochlorothiazide"
    },
    {
        "scientific_name": "ergocalciferol"
    },
    {
        "scientific_name": "ergotamine tartrate"
    },
    {
        "scientific_name": "ergotamine tartrate and caffeine"
    },
    {
        "scientific_name": "erlotinib hydrochloride"
    },
    {
        "scientific_name": "erythromycin"
    },
    {
        "scientific_name": "erythromycin ethylsuccinate"
    },
    {
        "scientific_name": "erythromycin stearate"
    },
    {
        "scientific_name": "escitalopram oxalate"
    },
    {
        "scientific_name": "eslicarbazepine acetate"
    },
    {
        "scientific_name": "esomeprazole magnesium"
    },
    {
        "scientific_name": "esomeprazole strontium"
    },
    {
        "scientific_name": "estazolam"
    },
    {
        "scientific_name": "esterified estrogens"
    },
    {
        "scientific_name": "estradiol"
    },
    {
        "scientific_name": "estradiol and norethindrone acetate"
    },
    {
        "scientific_name": "estradiol/norethindrone acetate"
    },
    {
        "scientific_name": "estramustine phosphate sodium"
    },
    {
        "scientific_name": "estrogens and conjugated"
    },
    {
        "scientific_name": "estrogens and esterified"
    },
    {
        "scientific_name": "estropipate"
    },
    {
        "scientific_name": "eszopiclone"
    },
    {
        "scientific_name": "ethacrynic acid"
    },
    {
        "scientific_name": "ethambutol hydrochloride"
    },
    {
        "scientific_name": "ethionamide"
    },
    {
        "scientific_name": "ethosuximide"
    },
    {
        "scientific_name": "ethotoin"
    },
    {
        "scientific_name": "etidronate disodium"
    },
    {
        "scientific_name": "etodolac"
    },
    {
        "scientific_name": "etoposide"
    },
    {
        "scientific_name": "etravirine"
    },
    {
        "scientific_name": "everolimus"
    },
    {
        "scientific_name": "exemestane"
    },
    {
        "scientific_name": "extended phenytoin sodium"
    },
    {
        "scientific_name": "ezetimibe"
    },
    {
        "scientific_name": "ezetimibe and atorvastatin"
    },
    {
        "scientific_name": "ezetimibe and simvastatin"
    },
    {
        "scientific_name": "ezogabine"
    },
    {
        "scientific_name": "famciclovir"
    },
    {
        "scientific_name": "famotidine"
    },
    {
        "scientific_name": "febuxostat"
    },
    {
        "scientific_name": "felbamate"
    },
    {
        "scientific_name": "felodipine"
    },
    {
        "scientific_name": "fenofibrate"
    },
    {
        "scientific_name": "fenofibric acid"
    },
    {
        "scientific_name": "fenoprofen"
    },
    {
        "scientific_name": "fentanyl"
    },
    {
        "scientific_name": "fentanyl buccal"
    },
    {
        "scientific_name": "fentanyl citrate"
    },
    {
        "scientific_name": "ferric citrate"
    },
    {
        "scientific_name": "fesoterodine fumarate"
    },
    {
        "scientific_name": "fexofenadine hydrochloride"
    },
    {
        "scientific_name": "fexofenadine hydrochloride and pseudoephedrine hydrochloride"
    },
    {
        "scientific_name": "fidaxomicin"
    },
    {
        "scientific_name": "finasteride"
    },
    {
        "scientific_name": "fingolimod hcl"
    },
    {
        "scientific_name": "flavoxate hydrochloride"
    },
    {
        "scientific_name": "flecainide"
    },
    {
        "scientific_name": "flibanserin"
    },
    {
        "scientific_name": "flomax"
    },
    {
        "scientific_name": "fluconazole"
    },
    {
        "scientific_name": "flucytosine"
    },
    {
        "scientific_name": "fludrocortisone acetate"
    },
    {
        "scientific_name": "flumadine"
    },
    {
        "scientific_name": "fluorometholone"
    },
    {
        "scientific_name": "fluoxetine hydrochloride"
    },
    {
        "scientific_name": "fluoxymesterone"
    },
    {
        "scientific_name": "fluphenazine hydrochloride"
    },
    {
        "scientific_name": "flurazepam hydrochloride"
    },
    {
        "scientific_name": "flurbiprofen"
    },
    {
        "scientific_name": "flutamide"
    },
    {
        "scientific_name": "fluvastatin"
    },
    {
        "scientific_name": "fluvoxamine maleate"
    },
    {
        "scientific_name": "folic acid"
    },
    {
        "scientific_name": "formoterol fumarate"
    },
    {
        "scientific_name": "fosamprenavir calcium"
    },
    {
        "scientific_name": "fosinopril sodium"
    },
    {
        "scientific_name": "fosinopril sodium and hydrochlorothiazide"
    },
    {
        "scientific_name": "frovatriptan succinate"
    },
    {
        "scientific_name": "furosemide"
    },
    {
        "scientific_name": "gabapentin"
    },
    {
        "scientific_name": "gabapentin enacarbil"
    },
    {
        "scientific_name": "galantamine"
    },
    {
        "scientific_name": "ganciclovir"
    },
    {
        "scientific_name": "gatifloxacin"
    },
    {
        "scientific_name": "gefitinib"
    },
    {
        "scientific_name": "gemfibrozil"
    },
    {
        "scientific_name": "gemifloxacin mesylate"
    },
    {
        "scientific_name": "gentamicin sulfate"
    },
    {
        "scientific_name": "glimepiride"
    },
    {
        "scientific_name": "glipizide"
    },
    {
        "scientific_name": "glipizide and metformin hydrochloride"
    },
    {
        "scientific_name": "glyburide"
    },
    {
        "scientific_name": "glyburide (micronized) and metformin hydrochloride"
    },
    {
        "scientific_name": "glyburide and metformin hydrochloride"
    },
    {
        "scientific_name": "glycerol phenylbutyrate"
    },
    {
        "scientific_name": "glycopyrrolate"
    },
    {
        "scientific_name": "granisetron hydrochloride"
    },
    {
        "scientific_name": "griseofulvin"
    },
    {
        "scientific_name": "guaifenesin and pseudoephedrine hydrochloride"
    },
    {
        "scientific_name": "guanfacine"
    },
    {
        "scientific_name": "guanfacine hydrochloride"
    },
    {
        "scientific_name": "guanidine hydrochloride"
    },
    {
        "scientific_name": "haloperidol"
    },
    {
        "scientific_name": "hydoxyzine pamoate"
    },
    {
        "scientific_name": "hydralazine hydrochloride"
    },
    {
        "scientific_name": "hydralazine hydrochloride and isosorbide dinitrate"
    },
    {
        "scientific_name": "hydrochlorothiazide"
    },
    {
        "scientific_name": "hydrochlorothiazide and triamterene"
    },
    {
        "scientific_name": "hydrochlorothiazide and valsartan"
    },
    {
        "scientific_name": "hydrocodone bitartrate"
    },
    {
        "scientific_name": "hydrocodone bitartrate and acetaminophen"
    },
    {
        "scientific_name": "hydrocodone bitartrate and homatropine methylbromide"
    },
    {
        "scientific_name": "hydrocodone bitartrate and ibuprofen"
    },
    {
        "scientific_name": "hydrocodone bitartrate and guaifenesin"
    },
    {
        "scientific_name": "hydrocodone bitartrate and pseudoephedrine hydrochloride and guaifenesin"
    },
    {
        "scientific_name": "hydrocodone polistirex and chlorpheniramine polistirex"
    },
    {
        "scientific_name": "hydrocodone and acetaminophen"
    },
    {
        "scientific_name": "hydrocortisone"
    },
    {
        "scientific_name": "hydromorphone hydrochloride"
    },
    {
        "scientific_name": "hydroxychloroquine sulfate"
    },
    {
        "scientific_name": "hydroxypropyl cellulose"
    },
    {
        "scientific_name": "hydroxyurea"
    },
    {
        "scientific_name": "hydroxyzine"
    },
    {
        "scientific_name": "hydroxyzine hydrochloride"
    },
    {
        "scientific_name": "hydroxyzine pamoate"
    },
    {
        "scientific_name": "ibandronate sodium"
    },
    {
        "scientific_name": "ibrutinib"
    },
    {
        "scientific_name": "ibuprofen"
    },
    {
        "scientific_name": "ibuprofen and famotidine"
    },
    {
        "scientific_name": "ibuprofen and pseudoephedrine hydrochloride"
    },
    {
        "scientific_name": "icosapent ethyl"
    },
    {
        "scientific_name": "idelalisib"
    },
    {
        "scientific_name": "iloperidone"
    },
    {
        "scientific_name": "imatinib mesylate"
    },
    {
        "scientific_name": "imipramine hydrochloride"
    },
    {
        "scientific_name": "imipramine pamoate"
    },
    {
        "scientific_name": "indacaterol and glycopyrrolate"
    },
    {
        "scientific_name": "indacaterol maleate"
    },
    {
        "scientific_name": "indapamide"
    },
    {
        "scientific_name": "indinavir sulfate"
    },
    {
        "scientific_name": "indomethacin"
    },
    {
        "scientific_name": "irbesartan"
    },
    {
        "scientific_name": "irbesartan and hydrochlorothiazide"
    },
    {
        "scientific_name": "isavuconazonium sulfate"
    },
    {
        "scientific_name": "isocarboxazid"
    },
    {
        "scientific_name": "isoflurane"
    },
    {
        "scientific_name": "isoniazid"
    },
    {
        "scientific_name": "isosorbide dinitrate"
    },
    {
        "scientific_name": "isosorbide mononitrate"
    },
    {
        "scientific_name": "isotretinoin"
    },
    {
        "scientific_name": "isradipine"
    },
    {
        "scientific_name": "itraconazole"
    },
    {
        "scientific_name": "ivabradine hydrochloride"
    },
    {
        "scientific_name": "ivacaftor"
    },
    {
        "scientific_name": "ivermectin"
    },
    {
        "scientific_name": "ketoconazole"
    },
    {
        "scientific_name": "ketoprofen"
    },
    {
        "scientific_name": "ketorolac tromethamine"
    },
    {
        "scientific_name": "ketotolac tromethamine"
    },
    {
        "scientific_name": "labetalol hydrochloride"
    },
    {
        "scientific_name": "lacosamide"
    },
    {
        "scientific_name": "lamivudine"
    },
    {
        "scientific_name": "lamivudine and zidovudine"
    },
    {
        "scientific_name": "lamotrigine"
    },
    {
        "scientific_name": "lansoprazole"
    },
    {
        "scientific_name": "lanthanum carbonate"
    },
    {
        "scientific_name": "lapatinib"
    },
    {
        "scientific_name": "latanoprost"
    },
    {
        "scientific_name": "ledipasvir and sofosbuvir"
    },
    {
        "scientific_name": "leflunomide"
    },
    {
        "scientific_name": "lenalidomide"
    },
    {
        "scientific_name": "lenvatinib"
    },
    {
        "scientific_name": "letrozole"
    },
    {
        "scientific_name": "leucovorin calcium"
    },
    {
        "scientific_name": "levetiracetam"
    },
    {
        "scientific_name": "levobunolol hydrochloride"
    },
    {
        "scientific_name": "levocarnitine"
    },
    {
        "scientific_name": "levocetirizine dihydrochloride"
    },
    {
        "scientific_name": "levodopa and carbidopa"
    },
    {
        "scientific_name": "levofloxacin"
    },
    {
        "scientific_name": "levomilnacipran hydrochloride"
    },
    {
        "scientific_name": "levonorgestrel"
    },
    {
        "scientific_name": "levonorgestrel and ethinyl estradiol"
    },
    {
        "scientific_name": "levorphanol tartrate"
    },
    {
        "scientific_name": "levothyroxine"
    },
    {
        "scientific_name": "levothyroxine sodium"
    },
    {
        "scientific_name": "lidocaine"
    },
    {
        "scientific_name": "linaclotide"
    },
    {
        "scientific_name": "linagliptin"
    },
    {
        "scientific_name": "linezolid"
    },
    {
        "scientific_name": "liothyronine sodium"
    },
    {
        "scientific_name": "liothyronine sodium and levothyroxine sodium"
    },
    {
        "scientific_name": "lisdexamfetamine dimesylate"
    },
    {
        "scientific_name": "lisinopril"
    },
    {
        "scientific_name": "lisinopril and hydrochlorothiazide"
    },
    {
        "scientific_name": "lisinopril and hydrochlorthiazide"
    },
    {
        "scientific_name": "lithium carbonate"
    },
    {
        "scientific_name": "lodoxamide tromethamine"
    },
    {
        "scientific_name": "lomitapide mesylate"
    },
    {
        "scientific_name": "lomustine"
    },
    {
        "scientific_name": "loperamide hydrochloride"
    },
    {
        "scientific_name": "lopinavir and ritonavir"
    },
    {
        "scientific_name": "loratadine"
    },
    {
        "scientific_name": "loratadine and pseudoephedrine"
    },
    {
        "scientific_name": "lorazepam"
    },
    {
        "scientific_name": "lorcaserin hydrochloride hemihydrate"
    },
    {
        "scientific_name": "losartan potassium"
    },
    {
        "scientific_name": "losartan potassium and hydrochlorothiazide"
    },
    {
        "scientific_name": "losartan potassium and hydrochlorothiazide tablets"
    },
    {
        "scientific_name": "lovastatin"
    },
    {
        "scientific_name": "loxapine"
    },
    {
        "scientific_name": "loxapine succinate"
    },
    {
        "scientific_name": "lubiprostone"
    },
    {
        "scientific_name": "lumacaftor and ivacaftor"
    },
    {
        "scientific_name": "lurasidone hydrochloride"
    },
    {
        "scientific_name": "lyrica"
    },
    {
        "scientific_name": "macitentan"
    },
    {
        "scientific_name": "maprotiline hydrochloride"
    },
    {
        "scientific_name": "maraviroc"
    },
    {
        "scientific_name": "maxzide"
    },
    {
        "scientific_name": "mebendazole"
    },
    {
        "scientific_name": "mecamylamine hydrochloride"
    },
    {
        "scientific_name": "meclizine hydrochloride"
    },
    {
        "scientific_name": "meclofenamate sodium"
    },
    {
        "scientific_name": "medroxyprogesterone acetate"
    },
    {
        "scientific_name": "mefenamic acid"
    },
    {
        "scientific_name": "mefloquine hydrochloride"
    },
    {
        "scientific_name": "megestrol acetate"
    },
    {
        "scientific_name": "meloxicam"
    },
    {
        "scientific_name": "melphalan"
    },
    {
        "scientific_name": "memantine hydrochloride"
    },
    {
        "scientific_name": "memantine hydrochloride and donepezil hydrochloride"
    },
    {
        "scientific_name": "mepenzolate bromide"
    },
    {
        "scientific_name": "meperidine hydrochloride"
    },
    {
        "scientific_name": "meprobamate"
    },
    {
        "scientific_name": "mercaptopurine"
    },
    {
        "scientific_name": "mesalamine"
    },
    {
        "scientific_name": "mesna"
    },
    {
        "scientific_name": "metaproterenol sulfate"
    },
    {
        "scientific_name": "metaxalone"
    },
    {
        "scientific_name": "metaxalone tablet"
    },
    {
        "scientific_name": "metformin hydrochloride"
    },
    {
        "scientific_name": "methadone hydrochloride"
    },
    {
        "scientific_name": "methamphetamine hydrochloride"
    },
    {
        "scientific_name": "methazolamide"
    },
    {
        "scientific_name": "methenamine hippurate"
    },
    {
        "scientific_name": "methimazole"
    },
    {
        "scientific_name": "methocarbamol"
    },
    {
        "scientific_name": "methotrexate"
    },
    {
        "scientific_name": "methoxsalen"
    },
    {
        "scientific_name": "methscopolamine bromide"
    },
    {
        "scientific_name": "methsuximide"
    },
    {
        "scientific_name": "methyclothiazide"
    },
    {
        "scientific_name": "methyldopa"
    },
    {
        "scientific_name": "methyldopa and hydrochlorothiazide"
    },
    {
        "scientific_name": "methylergonovine maleate"
    },
    {
        "scientific_name": "methylphenidate hydrochloride"
    },
    {
        "scientific_name": "methylphenidate hydrochloride extended release"
    },
    {
        "scientific_name": "methylprednisole"
    },
    {
        "scientific_name": "methylprednisolone"
    },
    {
        "scientific_name": "methyltestosterone"
    },
    {
        "scientific_name": "metipranolol"
    },
    {
        "scientific_name": "metoclopramide"
    },
    {
        "scientific_name": "metolazone"
    },
    {
        "scientific_name": "metoprolol"
    },
    {
        "scientific_name": "metoprolol succinate"
    },
    {
        "scientific_name": "metoprolol succinate and hydrochlorothiazide"
    },
    {
        "scientific_name": "metoprolol tartrate"
    },
    {
        "scientific_name": "metoprolol tartrate and hydrochlorothiazide"
    },
    {
        "scientific_name": "metronidazole"
    },
    {
        "scientific_name": "metyrapone"
    },
    {
        "scientific_name": "metyrosine"
    },
    {
        "scientific_name": "mexiletine hydrochloride"
    },
    {
        "scientific_name": "miconazole"
    },
    {
        "scientific_name": "midodrine hydrochloride"
    },
    {
        "scientific_name": "mifepristone"
    },
    {
        "scientific_name": "miglitol"
    },
    {
        "scientific_name": "miglustat"
    },
    {
        "scientific_name": "milnacipran hydrochloride"
    },
    {
        "scientific_name": "miltefosine"
    },
    {
        "scientific_name": "minocycline hydrochloride"
    },
    {
        "scientific_name": "minoxidil"
    },
    {
        "scientific_name": "mirabegron"
    },
    {
        "scientific_name": "mirtazapine"
    },
    {
        "scientific_name": "misoprostol"
    },
    {
        "scientific_name": "mitotane"
    },
    {
        "scientific_name": "modafinil"
    },
    {
        "scientific_name": "moexipril hydrochloride"
    },
    {
        "scientific_name": "moexipril hydrochloride and hydrochlorothiazide"
    },
    {
        "scientific_name": "molindone hydrochloride"
    },
    {
        "scientific_name": "montelukast sodium"
    },
    {
        "scientific_name": "morphine sulfate"
    },
    {
        "scientific_name": "morphine sulfate and naltrexone hydrochloride"
    },
    {
        "scientific_name": "moxifloxacin hydrochloride"
    },
    {
        "scientific_name": "mycophenilic acid"
    },
    {
        "scientific_name": "mycophenolate mofetil"
    },
    {
        "scientific_name": "mycophenolic acid"
    },
    {
        "scientific_name": "nabilone"
    },
    {
        "scientific_name": "nabumentone"
    },
    {
        "scientific_name": "nabumetone"
    },
    {
        "scientific_name": "nadolol"
    },
    {
        "scientific_name": "nadolol and bendroflumethiazide"
    },
    {
        "scientific_name": "naloxegol oxalate"
    },
    {
        "scientific_name": "naltrexone hydrochloride"
    },
    {
        "scientific_name": "naltrexone hydrochloride and bupropion hydrochloride"
    },
    {
        "scientific_name": "naphazoline hydrochloride"
    },
    {
        "scientific_name": "naproxen"
    },
    {
        "scientific_name": "naproxen and esomeprazole magnesium"
    },
    {
        "scientific_name": "naproxen sodium"
    },
    {
        "scientific_name": "naratriptan"
    },
    {
        "scientific_name": "naratriptan hydrochloride"
    },
    {
        "scientific_name": "nateglinide"
    },
    {
        "scientific_name": "nebivolol"
    },
    {
        "scientific_name": "nedocromil sodium"
    },
    {
        "scientific_name": "nefazodone hydrochloride"
    },
    {
        "scientific_name": "nelfinavir mesylate"
    },
    {
        "scientific_name": "neomycin and polymyxin b sulfates and dexamethasone"
    },
    {
        "scientific_name": "neomycin and polymyxin b sulfates and gramicidin"
    },
    {
        "scientific_name": "neomycin and polymyxin b sulfates and hydrocortisone"
    },
    {
        "scientific_name": "neomycin sulfate"
    },
    {
        "scientific_name": "neomycin sulfate and polymyxin b sulfate and gramicidin"
    },
    {
        "scientific_name": "neomycin sulfate and polymyxin b sulfate and hydrocortisone"
    },
    {
        "scientific_name": "netupitant and palonosetron"
    },
    {
        "scientific_name": "nevirapine"
    },
    {
        "scientific_name": "niacin"
    },
    {
        "scientific_name": "niacin and lovastatin"
    },
    {
        "scientific_name": "niacin and simvastatin"
    },
    {
        "scientific_name": "nicardipine hydrochloride"
    },
    {
        "scientific_name": "nifedipine"
    },
    {
        "scientific_name": "nilotinib"
    },
    {
        "scientific_name": "nilutamide"
    },
    {
        "scientific_name": "nimodipine"
    },
    {
        "scientific_name": "nintedanib"
    },
    {
        "scientific_name": "nisoldipine"
    },
    {
        "scientific_name": "nitazoxanide"
    },
    {
        "scientific_name": "nitisinone"
    },
    {
        "scientific_name": "nitrofurantoin"
    },
    {
        "scientific_name": "nitroglycerin"
    },
    {
        "scientific_name": "nizatidine"
    },
    {
        "scientific_name": "norethindrone"
    },
    {
        "scientific_name": "norethindrone acetate"
    },
    {
        "scientific_name": "norethindrone acetate and ethinyl estradiol"
    },
    {
        "scientific_name": "norethindrone acetate/ethinyl estradiol"
    },
    {
        "scientific_name": "norethindrone and ethinyl estradiol"
    },
    {
        "scientific_name": "nortiptyline hydrochloride"
    },
    {
        "scientific_name": "nortriptyline hydrochloride"
    },
    {
        "scientific_name": "nystatin"
    },
    {
        "scientific_name": "ofloxacin"
    },
    {
        "scientific_name": "olanzapine"
    },
    {
        "scientific_name": "olanzapine and fluoxetine hydrochloride"
    },
    {
        "scientific_name": "olaparib"
    },
    {
        "scientific_name": "olmesartan medoxomil"
    },
    {
        "scientific_name": "olmesartan medoxomil / amlodipine besylate / hydrochlorothiazide"
    },
    {
        "scientific_name": "olmesartan medoxomil and hydrochlorothiazide"
    },
    {
        "scientific_name": "olopatadine hydrochloride"
    },
    {
        "scientific_name": "olsalazine sodium"
    },
    {
        "scientific_name": "omega-3-acid ethyl esters"
    },
    {
        "scientific_name": "omega-3-carboxylic acids"
    },
    {
        "scientific_name": "omeprazole"
    },
    {
        "scientific_name": "omeprazole and sodium bicarbonate"
    },
    {
        "scientific_name": "omeprazole magnesium"
    },
    {
        "scientific_name": "ondansetron"
    },
    {
        "scientific_name": "orlistat"
    },
    {
        "scientific_name": "orphenadrine citrate"
    },
    {
        "scientific_name": "orphenadrine citrate and aspirin and caffeine"
    },
    {
        "scientific_name": "oseltamivir phosphate"
    },
    {
        "scientific_name": "ospemifene"
    },
    {
        "scientific_name": "oxandrolone"
    },
    {
        "scientific_name": "oxaprozin"
    },
    {
        "scientific_name": "oxazepam"
    },
    {
        "scientific_name": "oxcarbazepine"
    },
    {
        "scientific_name": "oxybutynin chloride"
    },
    {
        "scientific_name": "oxycodone hydrochloride"
    },
    {
        "scientific_name": "oxycodone hydrochloride and acetaminophen"
    },
    {
        "scientific_name": "oxycodone hydrochloride and aspirin"
    },
    {
        "scientific_name": "oxycodone hydrochloride and ibuprofen"
    },
    {
        "scientific_name": "oxymetholone"
    },
    {
        "scientific_name": "oxymorphone hydrochloride"
    },
    {
        "scientific_name": "palbociclib"
    },
    {
        "scientific_name": "paliperidone"
    },
    {
        "scientific_name": "palonosetron hydrochloride"
    },
    {
        "scientific_name": "pancrelipase"
    },
    {
        "scientific_name": "panobinostat"
    },
    {
        "scientific_name": "pantoprazole sodium"
    },
    {
        "scientific_name": "paricalcitol"
    },
    {
        "scientific_name": "paromomycin sulfate"
    },
    {
        "scientific_name": "paroxetine"
    },
    {
        "scientific_name": "paroxetine hydrochloride"
    },
    {
        "scientific_name": "paroxetine hydrochloride anhydrous"
    },
    {
        "scientific_name": "paroxetine hydrochloride hemihydrate"
    },
    {
        "scientific_name": "paroxetine mesylate"
    },
    {
        "scientific_name": "pazopanib hydrochloride"
    },
    {
        "scientific_name": "penicillamine"
    },
    {
        "scientific_name": "penicillin v potassium"
    },
    {
        "scientific_name": "pentazocine hydrochloride and acetaminophen"
    },
    {
        "scientific_name": "pentazocine hydrochloride and naloxone hydrochloride"
    },
    {
        "scientific_name": "pentosan polysulfate sodium"
    },
    {
        "scientific_name": "pentoxifylline"
    },
    {
        "scientific_name": "perampanel"
    },
    {
        "scientific_name": "perindopril arginine and amlodipine besylate"
    },
    {
        "scientific_name": "perindopril erbumine"
    },
    {
        "scientific_name": "perphenazine"
    },
    {
        "scientific_name": "perphenazine and amitriptyline hydrochloride"
    },
    {
        "scientific_name": "phendimetrazine tartrate"
    },
    {
        "scientific_name": "phenelzine sulfate"
    },
    {
        "scientific_name": "phenoxybenzamine hydrochloride"
    },
    {
        "scientific_name": "phentermine and topiramate"
    },
    {
        "scientific_name": "phentermine hydrochloride"
    },
    {
        "scientific_name": "phentermine resin"
    },
    {
        "scientific_name": "phenylephrine hydrochloride"
    },
    {
        "scientific_name": "phenytoin"
    },
    {
        "scientific_name": "phytonadione"
    },
    {
        "scientific_name": "pilocarpine hydrochloride"
    },
    {
        "scientific_name": "pimozide"
    },
    {
        "scientific_name": "pindolol"
    },
    {
        "scientific_name": "pioglitazone hydrochloride"
    },
    {
        "scientific_name": "pioglitazone hydrochloride and glimepiride"
    },
    {
        "scientific_name": "pioglitazone hydrochloride and metformin hydrochloride"
    },
    {
        "scientific_name": "pirfenidone"
    },
    {
        "scientific_name": "piroxicam"
    },
    {
        "scientific_name": "pitavastatin calcium"
    },
    {
        "scientific_name": "polymyxin b sulfate and trimethoprim sulfate"
    },
    {
        "scientific_name": "pomalidomide"
    },
    {
        "scientific_name": "ponatinib hydrochloride"
    },
    {
        "scientific_name": "posaconazole"
    },
    {
        "scientific_name": "potassium"
    },
    {
        "scientific_name": "potassium chloride"
    },
    {
        "scientific_name": "potassium citrate"
    },
    {
        "scientific_name": "pramipexole"
    },
    {
        "scientific_name": "pramipexole dihydrochloride"
    },
    {
        "scientific_name": "prasugrel hydrochloride"
    },
    {
        "scientific_name": "pravastatin sodium"
    },
    {
        "scientific_name": "praziquantel"
    },
    {
        "scientific_name": "prazosin hydrochloride"
    },
    {
        "scientific_name": "prednisolone"
    },
    {
        "scientific_name": "prednisolone sodium phosphate"
    },
    {
        "scientific_name": "prednisone"
    },
    {
        "scientific_name": "pregabalin"
    },
    {
        "scientific_name": "primaquine"
    },
    {
        "scientific_name": "primidone"
    },
    {
        "scientific_name": "probenecid"
    },
    {
        "scientific_name": "probenecid and colchicine"
    },
    {
        "scientific_name": "procarbazine hydrochloride"
    },
    {
        "scientific_name": "prochlorperazine"
    },
    {
        "scientific_name": "prochlorperazine maleate"
    },
    {
        "scientific_name": "progesterone"
    },
    {
        "scientific_name": "promethazine hydrochloride"
    },
    {
        "scientific_name": "promethazine with codeine"
    },
    {
        "scientific_name": "propafenone hydrochloride"
    },
    {
        "scientific_name": "propantheline bromide"
    },
    {
        "scientific_name": "proparacaine hydrochloride"
    },
    {
        "scientific_name": "propoxyphene hydrochloride"
    },
    {
        "scientific_name": "propoxyphene hydrochloride and acetaminophen"
    },
    {
        "scientific_name": "propoxyphene napsylate"
    },
    {
        "scientific_name": "propoxyphene napsylate and acetaminophen"
    },
    {
        "scientific_name": "propranolol hydrochloride"
    },
    {
        "scientific_name": "propranolol hydrochloride and hydrochlorothiazide"
    },
    {
        "scientific_name": "propylthiouracil"
    },
    {
        "scientific_name": "protriptyline hydrochloride"
    },
    {
        "scientific_name": "pseudoephedrine hydrochloride"
    },
    {
        "scientific_name": "pyrazinamide"
    },
    {
        "scientific_name": "pyridostigmine bromide"
    },
    {
        "scientific_name": "pyrimethamine"
    },
    {
        "scientific_name": "quazepam"
    },
    {
        "scientific_name": "quetiapine fumarate"
    },
    {
        "scientific_name": "quinapril"
    },
    {
        "scientific_name": "quinapril hydrochloride"
    },
    {
        "scientific_name": "quinapril hydrochloride and hydrochlorothiazide"
    },
    {
        "scientific_name": "quinidine gluconate"
    },
    {
        "scientific_name": "quinidine sulfate"
    },
    {
        "scientific_name": "quinine sulfate"
    },
    {
        "scientific_name": "rabeprazole"
    },
    {
        "scientific_name": "raloxifene hydrochloride"
    },
    {
        "scientific_name": "raltegravir"
    },
    {
        "scientific_name": "ramelteon"
    },
    {
        "scientific_name": "ramipril"
    },
    {
        "scientific_name": "ranitidine"
    },
    {
        "scientific_name": "ranolazine"
    },
    {
        "scientific_name": "rasagiline mesylate"
    },
    {
        "scientific_name": "regorafenib"
    },
    {
        "scientific_name": "renagel"
    },
    {
        "scientific_name": "repaglinide"
    },
    {
        "scientific_name": "repaglinide and metformin hydrochloride"
    },
    {
        "scientific_name": "reserpine"
    },
    {
        "scientific_name": "ribavirin"
    },
    {
        "scientific_name": "rifabutin"
    },
    {
        "scientific_name": "rifampin"
    },
    {
        "scientific_name": "rifampin and isoniazid"
    },
    {
        "scientific_name": "rifampin and isoniazid and pyrazinamide"
    },
    {
        "scientific_name": "rifapentine"
    },
    {
        "scientific_name": "rifaximin"
    },
    {
        "scientific_name": "rilpivirine hydrochloride"
    },
    {
        "scientific_name": "riluzole"
    },
    {
        "scientific_name": "rimantadine hydrochloride"
    },
    {
        "scientific_name": "riociguat"
    },
    {
        "scientific_name": "risedronate sodium"
    },
    {
        "scientific_name": "risperidone"
    },
    {
        "scientific_name": "ritonavir"
    },
    {
        "scientific_name": "rivaroxaban"
    },
    {
        "scientific_name": "rivastigmine tartrate"
    },
    {
        "scientific_name": "rizatriptan"
    },
    {
        "scientific_name": "rizatriptan benzoate"
    },
    {
        "scientific_name": "roflumilast"
    },
    {
        "scientific_name": "rolapitant"
    },
    {
        "scientific_name": "ropinirole"
    },
    {
        "scientific_name": "rosiglitazone maleate"
    },
    {
        "scientific_name": "rosiglitazone maleate and glimepiride"
    },
    {
        "scientific_name": "rosiglitazone maleate and metformin hydrochloride"
    },
    {
        "scientific_name": "rosuvastatin calcium"
    },
    {
        "scientific_name": "rozerem"
    },
    {
        "scientific_name": "rufinamide"
    },
    {
        "scientific_name": "ruxolitinib"
    },
    {
        "scientific_name": "sacubitril and valsartan"
    },
    {
        "scientific_name": "sapropterin dihydrochloride"
    },
    {
        "scientific_name": "saquinavir mesylate"
    },
    {
        "scientific_name": "sargramostim"
    },
    {
        "scientific_name": "saxagliptin"
    },
    {
        "scientific_name": "saxagliptin and metformin hydrochloride"
    },
    {
        "scientific_name": "secobarbital sodium"
    },
    {
        "scientific_name": "selegiline hydrochloride"
    },
    {
        "scientific_name": "sennosides a and b"
    },
    {
        "scientific_name": "sertraline hydrochloride"
    },
    {
        "scientific_name": "sevelamer carbonate"
    },
    {
        "scientific_name": "sevelamer hydrochloride"
    },
    {
        "scientific_name": "sevoflurane"
    },
    {
        "scientific_name": "sildenafil citrate"
    },
    {
        "scientific_name": "silodosin"
    },
    {
        "scientific_name": "simeprevir"
    },
    {
        "scientific_name": "simvastatin"
    },
    {
        "scientific_name": "simvastatin and niacin"
    },
    {
        "scientific_name": "sirolimus"
    },
    {
        "scientific_name": "sitagliptin"
    },
    {
        "scientific_name": "sitagliptin and metformin hydrochloride"
    },
    {
        "scientific_name": "sodium phenylbutyrate"
    },
    {
        "scientific_name": "sofosbuvir"
    },
    {
        "scientific_name": "solifenacin succinate"
    },
    {
        "scientific_name": "sonidegib"
    },
    {
        "scientific_name": "sorafenib"
    },
    {
        "scientific_name": "sotalol hydrochloride"
    },
    {
        "scientific_name": "spironolactone"
    },
    {
        "scientific_name": "spironolactone and hydrochlorothiazide"
    },
    {
        "scientific_name": "stannous fluoride"
    },
    {
        "scientific_name": "stavudine"
    },
    {
        "scientific_name": "succimer"
    },
    {
        "scientific_name": "sucralfate"
    },
    {
        "scientific_name": "sucroferric oxyhydroxide"
    },
    {
        "scientific_name": "sulfaccetamide sodium"
    },
    {
        "scientific_name": "sulfacetamide sodium"
    },
    {
        "scientific_name": "sulfacetamide sodium and prednisolone sodium phosphate"
    },
    {
        "scientific_name": "sulfadiazine"
    },
    {
        "scientific_name": "sulfamethoxazle and trimethoprim"
    },
    {
        "scientific_name": "sulfamethoxazole"
    },
    {
        "scientific_name": "sulfamethoxazole and trimethoprim"
    },
    {
        "scientific_name": "sulfamethoxazole and trimethoprim ds"
    },
    {
        "scientific_name": "sulfasalazine"
    },
    {
        "scientific_name": "sulindac"
    },
    {
        "scientific_name": "sumatriptan"
    },
    {
        "scientific_name": "sumatriptan and naproxen sodium"
    },
    {
        "scientific_name": "sumatriptan succinate"
    },
    {
        "scientific_name": "sumatriptan succinate and naproxen sodium"
    },
    {
        "scientific_name": "sunitinib malate"
    },
    {
        "scientific_name": "suvorexant"
    },
    {
        "scientific_name": "synthetic conjugated estrogens, a"
    },
    {
        "scientific_name": "synthetic conjugated estrogens, b"
    },
    {
        "scientific_name": "tacrolimus"
    },
    {
        "scientific_name": "tadalafil"
    },
    {
        "scientific_name": "tafluprost"
    },
    {
        "scientific_name": "tamoxifen citrate"
    },
    {
        "scientific_name": "tamsulosin hydrochloride"
    },
    {
        "scientific_name": "tapentadol"
    },
    {
        "scientific_name": "tasimelteon"
    },
    {
        "scientific_name": "tedizolid phosphate"
    },
    {
        "scientific_name": "telbivudine"
    },
    {
        "scientific_name": "telithromycin"
    },
    {
        "scientific_name": "telmisartan"
    },
    {
        "scientific_name": "telmisartan and amlodipine"
    },
    {
        "scientific_name": "telmisartan and hydrochlorothiazide"
    },
    {
        "scientific_name": "temazepam"
    },
    {
        "scientific_name": "temozolomide"
    },
    {
        "scientific_name": "tenofovir disoproxil fumarate"
    },
    {
        "scientific_name": "terazosin"
    },
    {
        "scientific_name": "terazosin hydrochloride"
    },
    {
        "scientific_name": "terazosin hydrochloride anhydrous"
    },
    {
        "scientific_name": "terbinafine"
    },
    {
        "scientific_name": "terbutaline sulfate"
    },
    {
        "scientific_name": "terconazole"
    },
    {
        "scientific_name": "teriflunomide"
    },
    {
        "scientific_name": "testosterone"
    },
    {
        "scientific_name": "tetrabenazine"
    },
    {
        "scientific_name": "tetracycline hydrochloride"
    },
    {
        "scientific_name": "thalidomide"
    },
    {
        "scientific_name": "theophylline"
    },
    {
        "scientific_name": "theophylline anhydrous"
    },
    {
        "scientific_name": "thioguanine"
    },
    {
        "scientific_name": "thioridazine hydrochloride"
    },
    {
        "scientific_name": "thiothixene"
    },
    {
        "scientific_name": "tiagabine hydrochloride"
    },
    {
        "scientific_name": "ticagrelor"
    },
    {
        "scientific_name": "ticlopidine hydrochloride"
    },
    {
        "scientific_name": "timolol"
    },
    {
        "scientific_name": "tinidazole"
    },
    {
        "scientific_name": "tiopronin"
    },
    {
        "scientific_name": "tipranavir"
    },
    {
        "scientific_name": "titanium dioxide and octinoxate"
    },
    {
        "scientific_name": "tizanidine"
    },
    {
        "scientific_name": "tobramycin"
    },
    {
        "scientific_name": "tofacitinib"
    },
    {
        "scientific_name": "tolazamide"
    },
    {
        "scientific_name": "tolbutamide"
    },
    {
        "scientific_name": "tolcapone"
    },
    {
        "scientific_name": "tolmetin sodium"
    },
    {
        "scientific_name": "tolterodine"
    },
    {
        "scientific_name": "tolvaptan"
    },
    {
        "scientific_name": "topiramate"
    },
    {
        "scientific_name": "topotecan hydrochloride"
    },
    {
        "scientific_name": "toremifene citrate"
    },
    {
        "scientific_name": "torsemide"
    },
    {
        "scientific_name": "tramadol hydrochloride"
    },
    {
        "scientific_name": "tramadol hydrochloride and acetaminophen"
    },
    {
        "scientific_name": "trametinib"
    },
    {
        "scientific_name": "trandolapril"
    },
    {
        "scientific_name": "trandolapril and verapamil hydrochloride"
    },
    {
        "scientific_name": "trandolapril tablets"
    },
    {
        "scientific_name": "tranexamic acid"
    },
    {
        "scientific_name": "tranylcypromine sulfate"
    },
    {
        "scientific_name": "travoprost"
    },
    {
        "scientific_name": "trazodone"
    },
    {
        "scientific_name": "trazodone hydrochloride"
    },
    {
        "scientific_name": "treprostinil"
    },
    {
        "scientific_name": "tretinoin"
    },
    {
        "scientific_name": "triamterene"
    },
    {
        "scientific_name": "triamterene and hydrochlorothiazide"
    },
    {
        "scientific_name": "triazolam"
    },
    {
        "scientific_name": "triclosan"
    },
    {
        "scientific_name": "trientine hydrochloride"
    },
    {
        "scientific_name": "trifluoperazine"
    },
    {
        "scientific_name": "trifluridine and tipiracil"
    },
    {
        "scientific_name": "trihexyphenidyl hydrochloride"
    },
    {
        "scientific_name": "trimethadione"
    },
    {
        "scientific_name": "trimethobenzamide"
    },
    {
        "scientific_name": "trimethoprim"
    },
    {
        "scientific_name": "trimipramine maleate"
    },
    {
        "scientific_name": "tropicamide"
    },
    {
        "scientific_name": "trospium chloride"
    },
    {
        "scientific_name": "ulipristal acetate"
    },
    {
        "scientific_name": "unoprostone isopropyl"
    },
    {
        "scientific_name": "urea and c-14"
    },
    {
        "scientific_name": "ursodiol"
    },
    {
        "scientific_name": "valacyclovir"
    },
    {
        "scientific_name": "valganciclovir"
    },
    {
        "scientific_name": "valproic acid"
    },
    {
        "scientific_name": "valsartan"
    },
    {
        "scientific_name": "valsartan and hydrochlorothiazide"
    },
    {
        "scientific_name": "vancomycin hydrochloride"
    },
    {
        "scientific_name": "vandetanib"
    },
    {
        "scientific_name": "vardenafil hydrochloride"
    },
    {
        "scientific_name": "varenicline tartrate"
    },
    {
        "scientific_name": "vemurafenib"
    },
    {
        "scientific_name": "venlafaxine"
    },
    {
        "scientific_name": "verapamil"
    },
    {
        "scientific_name": "vigabatrin"
    },
    {
        "scientific_name": "vilazodone hydrochloride"
    },
    {
        "scientific_name": "vismodegib"
    },
    {
        "scientific_name": "vivotif-b"
    },
    {
        "scientific_name": "vorapaxar"
    },
    {
        "scientific_name": "voriconazole"
    },
    {
        "scientific_name": "vorinostat"
    },
    {
        "scientific_name": "vortioxetine"
    },
    {
        "scientific_name": "warfarin"
    },
    {
        "scientific_name": "zafirlukast"
    },
    {
        "scientific_name": "zaleplon"
    },
    {
        "scientific_name": "zidovudine"
    },
    {
        "scientific_name": "zileuton"
    },
    {
        "scientific_name": "zinc acetate"
    },
    {
        "scientific_name": "ziprasidone hydrochloride"
    },
    {
        "scientific_name": "zolmitriptan"
    },
    {
        "scientific_name": "zolpidem tartrate"
    },
    {
        "scientific_name": "zonisamide"
    },
    {
        "scientific_name": "A-Hydrocort"
    },
    {
        "scientific_name": "Abilify"
    },
    {
        "scientific_name": "Acticlate"
    },
    {
        "scientific_name": "Actos"
    },
    {
        "scientific_name": "Adalat CC"
    },
    {
        "scientific_name": "Adderall"
    },
    {
        "scientific_name": "Adipex-P"
    },
    {
        "scientific_name": "Advair"
    },
    {
        "scientific_name": "Advil"
    },
    {
        "scientific_name": "Afeditab CR"
    },
    {
        "scientific_name": "Airduo Respiclick"
    },
    {
        "scientific_name": "Akten"
    },
    {
        "scientific_name": "Aldactone"
    },
    {
        "scientific_name": "Aleve"
    },
    {
        "scientific_name": "Allersoothe"
    },
    {
        "scientific_name": "Aloprim"
    },
    {
        "scientific_name": "Alora"
    },
    {
        "scientific_name": "Alphagan P"
    },
    {
        "scientific_name": "Alsuma"
    },
    {
        "scientific_name": "Altace"
    },
    {
        "scientific_name": "Altavera"
    },
    {
        "scientific_name": "Altoprev"
    },
    {
        "scientific_name": "Amaryl"
    },
    {
        "scientific_name": "Ambien"
    },
    {
        "scientific_name": "Amethia"
    },
    {
        "scientific_name": "Amrix"
    },
    {
        "scientific_name": "Androgel"
    },
    {
        "scientific_name": "Anecream"
    },
    {
        "scientific_name": "Antara"
    },
    {
        "scientific_name": "Aplenzin"
    },
    {
        "scientific_name": "Apresoline"
    },
    {
        "scientific_name": "Apri"
    },
    {
        "scientific_name": "Apriso"
    },
    {
        "scientific_name": "Aricept"
    },
    {
        "scientific_name": "Arimidex"
    },
    {
        "scientific_name": "Aristada"
    },
    {
        "scientific_name": "Arymo ER"
    },
    {
        "scientific_name": "Asacol HD"
    },
    {
        "scientific_name": "Astramorph-PF"
    },
    {
        "scientific_name": "Ativan"
    },
    {
        "scientific_name": "Augmentin"
    },
    {
        "scientific_name": "Avapro"
    },
    {
        "scientific_name": "Avidoxy"
    },
    {
        "scientific_name": "Axiron"
    },
    {
        "scientific_name": "Aygestin"
    },
    {
        "scientific_name": "Azasite"
    },
    {
        "scientific_name": "Bactrim"
    },
    {
        "scientific_name": "Benicar"
    },
    {
        "scientific_name": "Bentyl"
    },
    {
        "scientific_name": "Betimol"
    },
    {
        "scientific_name": "Bidil"
    },
    {
        "scientific_name": "Binosto"
    },
    {
        "scientific_name": "Bonine"
    },
    {
        "scientific_name": "Brisdelle"
    },
    {
        "scientific_name": "Buspar"
    },
    {
        "scientific_name": "Bystolic"
    },
    {
        "scientific_name": "Calan"
    },
    {
        "scientific_name": "Calciferol "
    },
    {
        "scientific_name": "Calphron"
    },
    {
        "scientific_name": "Cambia"
    },
    {
        "scientific_name": "Camila"
    },
    {
        "scientific_name": "Camrese"
    },
    {
        "scientific_name": "Camreselo"
    },
    {
        "scientific_name": "Canasa"
    },
    {
        "scientific_name": "Cardura"
    },
    {
        "scientific_name": "Carospir"
    },
    {
        "scientific_name": "Catapres"
    },
    {
        "scientific_name": "Caziant"
    },
    {
        "scientific_name": "Celebrex"
    },
    {
        "scientific_name": "Celexa"
    },
    {
        "scientific_name": "Cetraxal"
    },
    {
        "scientific_name": "Ciloxan"
    },
    {
        "scientific_name": "Cipro"
    },
    {
        "scientific_name": "Claritin"
    },
    {
        "scientific_name": "Clearlax"
    },
    {
        "scientific_name": "Cleocin"
    },
    {
        "scientific_name": "Climara"
    },
    {
        "scientific_name": "Clindacin P"
    },
    {
        "scientific_name": "Clindagel"
    },
    {
        "scientific_name": "Clindamycin Pediatric"
    },
    {
        "scientific_name": "Colace"
    },
    {
        "scientific_name": "Combivent"
    },
    {
        "scientific_name": "Concerta"
    },
    {
        "scientific_name": "Conzip"
    },
    {
        "scientific_name": "Coreg"
    },
    {
        "scientific_name": "Cortef"
    },
    {
        "scientific_name": "Coumadin"
    },
    {
        "scientific_name": "Cozaar"
    },
    {
        "scientific_name": "Crestor"
    },
    {
        "scientific_name": "Cutivate"
    },
    {
        "scientific_name": "Cyclessa"
    },
    {
        "scientific_name": "Cymbalta"
    },
    {
        "scientific_name": "Cyred"
    },
    {
        "scientific_name": "Daxbia"
    },
    {
        "scientific_name": "Daytrana"
    },
    {
        "scientific_name": "Deblitane"
    },
    {
        "scientific_name": "Delestrogen"
    },
    {
        "scientific_name": "Deltasone"
    },
    {
        "scientific_name": "Delzicol"
    },
    {
        "scientific_name": "Depakote"
    },
    {
        "scientific_name": "Depo-Medrol"
    },
    {
        "scientific_name": "Dermacinrx"
    },
    {
        "scientific_name": "Desogen"
    },
    {
        "scientific_name": "Desoxyn"
    },
    {
        "scientific_name": "Desyrel"
    },
    {
        "scientific_name": "Dexedrine"
    },
    {
        "scientific_name": "Diflucan"
    },
    {
        "scientific_name": "Digitek"
    },
    {
        "scientific_name": "Digox"
    },
    {
        "scientific_name": "Dilantin"
    },
    {
        "scientific_name": "Diovan"
    },
    {
        "scientific_name": "Diovan HCT"
    },
    {
        "scientific_name": "Ditropan XL"
    },
    {
        "scientific_name": "Divigel"
    },
    {
        "scientific_name": "Doryx"
    },
    {
        "scientific_name": "Dramamine Less Drowsy"
    },
    {
        "scientific_name": "Duoneb"
    },
    {
        "scientific_name": "Duramorph"
    },
    {
        "scientific_name": "Dyazide"
    },
    {
        "scientific_name": "Dyloject"
    },
    {
        "scientific_name": "Effexor XR"
    },
    {
        "scientific_name": "Eha"
    },
    {
        "scientific_name": "Elavil"
    },
    {
        "scientific_name": "Elestrin"
    },
    {
        "scientific_name": "Eliphos"
    },
    {
        "scientific_name": "Eliquis"
    },
    {
        "scientific_name": "Elocon"
    },
    {
        "scientific_name": "Emoquette"
    },
    {
        "scientific_name": "Endep"
    },
    {
        "scientific_name": "Endoxcin"
    },
    {
        "scientific_name": "Entocort"
    },
    {
        "scientific_name": "Estrace"
    },
    {
        "scientific_name": "Estrogel"
    },
    {
        "scientific_name": "Estrostep"
    },
    {
        "scientific_name": "Evamist"
    },
    {
        "scientific_name": "Evoclin"
    },
    {
        "scientific_name": "Ex-Lax"
    },
    {
        "scientific_name": "Fa-8"
    },
    {
        "scientific_name": "Fenoglide"
    },
    {
        "scientific_name": "Fer-In-Sol"
    },
    {
        "scientific_name": "Feratab"
    },
    {
        "scientific_name": "Fexmid"
    },
    {
        "scientific_name": "Fibricor"
    },
    {
        "scientific_name": "Flagyl"
    },
    {
        "scientific_name": "Flector"
    },
    {
        "scientific_name": "Flolipid"
    },
    {
        "scientific_name": "Flomax"
    },
    {
        "scientific_name": "Flonase"
    },
    {
        "scientific_name": "Focalin"
    },
    {
        "scientific_name": "Forfivo XL"
    },
    {
        "scientific_name": "Fortamet"
    },
    {
        "scientific_name": "Fortesta"
    },
    {
        "scientific_name": "Fosamax"
    },
    {
        "scientific_name": "Furadantin"
    },
    {
        "scientific_name": "Gablofen"
    },
    {
        "scientific_name": "Gavilax"
    },
    {
        "scientific_name": "Gavilyte-C"
    },
    {
        "scientific_name": "Gialax"
    },
    {
        "scientific_name": "Gianvi"
    },
    {
        "scientific_name": "Gildess"
    },
    {
        "scientific_name": "Glucophage"
    },
    {
        "scientific_name": "Glucotrol"
    },
    {
        "scientific_name": "Glumetza"
    },
    {
        "scientific_name": "Glycolax"
    },
    {
        "scientific_name": "Glynase"
    },
    {
        "scientific_name": "Gralise"
    },
    {
        "scientific_name": "Healthylax"
    },
    {
        "scientific_name": "Horizant"
    },
    {
        "scientific_name": "Humalog"
    },
    {
        "scientific_name": "Humira"
    },
    {
        "scientific_name": "Humulin N"
    },
    {
        "scientific_name": "Hycet"
    },
    {
        "scientific_name": "Hytrin"
    },
    {
        "scientific_name": "Hyzaar"
    },
    {
        "scientific_name": "Imitrex"
    },
    {
        "scientific_name": "Infumorph"
    },
    {
        "scientific_name": "Innopran XL"
    },
    {
        "scientific_name": "Intermezzo"
    },
    {
        "scientific_name": "Intuniv ER"
    },
    {
        "scientific_name": "Invokana"
    },
    {
        "scientific_name": "Irenka"
    },
    {
        "scientific_name": "Iron"
    },
    {
        "scientific_name": "Isibloom"
    },
    {
        "scientific_name": "Istalol"
    },
    {
        "scientific_name": "Jantoven"
    },
    {
        "scientific_name": "Janumet XR"
    },
    {
        "scientific_name": "Januvia"
    },
    {
        "scientific_name": "Jencycla"
    },
    {
        "scientific_name": "Jolivette"
    },
    {
        "scientific_name": "Juleber"
    },
    {
        "scientific_name": "Junel"
    },
    {
        "scientific_name": "K-Lyte Cl"
    },
    {
        "scientific_name": "K-Tab"
    },
    {
        "scientific_name": "Kaon Cl"
    },
    {
        "scientific_name": "Kapvay"
    },
    {
        "scientific_name": "Keflex"
    },
    {
        "scientific_name": "Kenalog"
    },
    {
        "scientific_name": "Keppra"
    },
    {
        "scientific_name": "Klonopin"
    },
    {
        "scientific_name": "Klor Con"
    },
    {
        "scientific_name": "Klorvess"
    },
    {
        "scientific_name": "Klotrix"
    },
    {
        "scientific_name": "Lamictal"
    },
    {
        "scientific_name": "Lanoxin"
    },
    {
        "scientific_name": "Lantus"
    },
    {
        "scientific_name": "Lasix"
    },
    {
        "scientific_name": "Laxaclear"
    },
    {
        "scientific_name": "Levaquin"
    },
    {
        "scientific_name": "Levemir"
    },
    {
        "scientific_name": "Levo-T"
    },
    {
        "scientific_name": "Levora"
    },
    {
        "scientific_name": "Levoxyl"
    },
    {
        "scientific_name": "Lexapro"
    },
    {
        "scientific_name": "Lexitral"
    },
    {
        "scientific_name": "Lialda"
    },
    {
        "scientific_name": "Lidoderm"
    },
    {
        "scientific_name": "Lioresal"
    },
    {
        "scientific_name": "Lipitor"
    },
    {
        "scientific_name": "Lipofen"
    },
    {
        "scientific_name": "Lithate"
    },
    {
        "scientific_name": "Lithobid"
    },
    {
        "scientific_name": "Loestrin"
    },
    {
        "scientific_name": "Lomaira"
    },
    {
        "scientific_name": "Lopid"
    },
    {
        "scientific_name": "Lopressor"
    },
    {
        "scientific_name": "Lorcet"
    },
    {
        "scientific_name": "Loryna (28)"
    },
    {
        "scientific_name": "Loseasonique"
    },
    {
        "scientific_name": "Lotensin"
    },
    {
        "scientific_name": "Lotrel"
    },
    {
        "scientific_name": "Lovaza"
    },
    {
        "scientific_name": "Lutera"
    },
    {
        "scientific_name": "Lyrica"
    },
    {
        "scientific_name": "Lyza"
    },
    {
        "scientific_name": "Macrobid"
    },
    {
        "scientific_name": "Macrodantin"
    },
    {
        "scientific_name": "Marlissa"
    },
    {
        "scientific_name": "Maxzide"
    },
    {
        "scientific_name": "Medrol"
    },
    {
        "scientific_name": "Metadate"
    },
    {
        "scientific_name": "Methylin"
    },
    {
        "scientific_name": "Metrocream"
    },
    {
        "scientific_name": "Metrogel"
    },
    {
        "scientific_name": "Metrolotion"
    },
    {
        "scientific_name": "Microgestin"
    },
    {
        "scientific_name": "Microzide"
    },
    {
        "scientific_name": "Migranow"
    },
    {
        "scientific_name": "Millipred DP"
    },
    {
        "scientific_name": "Minastrin"
    },
    {
        "scientific_name": "Minivelle"
    },
    {
        "scientific_name": "Miralax"
    },
    {
        "scientific_name": "Mirapex"
    },
    {
        "scientific_name": "Mirvaso"
    },
    {
        "scientific_name": "Mobic"
    },
    {
        "scientific_name": "Mondoxyne NL"
    },
    {
        "scientific_name": "Monodox"
    },
    {
        "scientific_name": "Monoket"
    },
    {
        "scientific_name": "Morgidox"
    },
    {
        "scientific_name": "Morphabond ER"
    },
    {
        "scientific_name": "Motrin IB"
    },
    {
        "scientific_name": "Moxatag"
    },
    {
        "scientific_name": "MS Contin"
    },
    {
        "scientific_name": "Mydayis"
    },
    {
        "scientific_name": "Namenda"
    },
    {
        "scientific_name": "Nasacort"
    },
    {
        "scientific_name": "Neosporin"
    },
    {
        "scientific_name": "Neurontin"
    },
    {
        "scientific_name": "Nexium"
    },
    {
        "scientific_name": "Nexterone"
    },
    {
        "scientific_name": "Nikki (28)"
    },
    {
        "scientific_name": "Nitrolingual"
    },
    {
        "scientific_name": "Nitrostat"
    },
    {
        "scientific_name": "Nora-Be"
    },
    {
        "scientific_name": "Norco"
    },
    {
        "scientific_name": "Noritate"
    },
    {
        "scientific_name": "Norlyda"
    },
    {
        "scientific_name": "Norlyroc"
    },
    {
        "scientific_name": "Norvasc"
    },
    {
        "scientific_name": "Novolin N"
    },
    {
        "scientific_name": "Novolog"
    },
    {
        "scientific_name": "Nuvaring"
    },
    {
        "scientific_name": "Nuvessa"
    },
    {
        "scientific_name": "Ocella"
    },
    {
        "scientific_name": "Omeppi"
    },
    {
        "scientific_name": "Omnipred"
    },
    {
        "scientific_name": "Omtryg"
    },
    {
        "scientific_name": "Onzetra"
    },
    {
        "scientific_name": "Oracea"
    },
    {
        "scientific_name": "Orapred"
    },
    {
        "scientific_name": "Orsythia"
    },
    {
        "scientific_name": "Ortho-Cyclen"
    },
    {
        "scientific_name": "Otiprio"
    },
    {
        "scientific_name": "Oxaydo"
    },
    {
        "scientific_name": "Oxtellar XR"
    },
    {
        "scientific_name": "Oxycontin"
    },
    {
        "scientific_name": "Oxytrol"
    },
    {
        "scientific_name": "Pacerone"
    },
    {
        "scientific_name": "Paxil"
    },
    {
        "scientific_name": "Pediapred"
    },
    {
        "scientific_name": "Pennsaid"
    },
    {
        "scientific_name": "Pentasa"
    },
    {
        "scientific_name": "Pepcid"
    },
    {
        "scientific_name": "Pepcid AC"
    },
    {
        "scientific_name": "Pexeva"
    },
    {
        "scientific_name": "Phenergan"
    },
    {
        "scientific_name": "Phenytek"
    },
    {
        "scientific_name": "Phoslyra"
    },
    {
        "scientific_name": "Plaquenil"
    },
    {
        "scientific_name": "Plavix"
    },
    {
        "scientific_name": "Powderlax"
    },
    {
        "scientific_name": "Pravachol"
    },
    {
        "scientific_name": "Pred Forte"
    },
    {
        "scientific_name": "Premarin"
    },
    {
        "scientific_name": "Prevacid"
    },
    {
        "scientific_name": "Prilosec"
    },
    {
        "scientific_name": "Prinivil"
    },
    {
        "scientific_name": "Proair "
    },
    {
        "scientific_name": "Procardia"
    },
    {
        "scientific_name": "Procentra"
    },
    {
        "scientific_name": "Propecia"
    },
    {
        "scientific_name": "Proscar"
    },
    {
        "scientific_name": "Protonix"
    },
    {
        "scientific_name": "Proventil"
    },
    {
        "scientific_name": "Prozac"
    },
    {
        "scientific_name": "Pulmicort"
    },
    {
        "scientific_name": "Purelax"
    },
    {
        "scientific_name": "Qbrelis"
    },
    {
        "scientific_name": "Qudexy XR"
    },
    {
        "scientific_name": "Quillivant"
    },
    {
        "scientific_name": "Rayos"
    },
    {
        "scientific_name": "Reclipsen"
    },
    {
        "scientific_name": "Recticare"
    },
    {
        "scientific_name": "Rectiv"
    },
    {
        "scientific_name": "Remeron"
    },
    {
        "scientific_name": "Requip"
    },
    {
        "scientific_name": "Restoril"
    },
    {
        "scientific_name": "Riomet"
    },
    {
        "scientific_name": "Risperdal"
    },
    {
        "scientific_name": "Ritalin"
    },
    {
        "scientific_name": "Robaxin"
    },
    {
        "scientific_name": "Roweepra"
    },
    {
        "scientific_name": "Roxicodone"
    },
    {
        "scientific_name": "Sarafem"
    },
    {
        "scientific_name": "Saxenda"
    },
    {
        "scientific_name": "Senokot S"
    },
    {
        "scientific_name": "Seroquel"
    },
    {
        "scientific_name": "Sharobel"
    },
    {
        "scientific_name": "Singulair"
    },
    {
        "scientific_name": "Sitavig"
    },
    {
        "scientific_name": "Slow Fe"
    },
    {
        "scientific_name": "Slow-K"
    },
    {
        "scientific_name": "Solaraze"
    },
    {
        "scientific_name": "Solu-Medrol"
    },
    {
        "scientific_name": "Soma"
    },
    {
        "scientific_name": "Spiriva"
    },
    {
        "scientific_name": "Spritam"
    },
    {
        "scientific_name": "Sronyx"
    },
    {
        "scientific_name": "Strattera"
    },
    {
        "scientific_name": "Sulfatrim"
    },
    {
        "scientific_name": "Sulisent"
    },
    {
        "scientific_name": "Sumavel Dosepro"
    },
    {
        "scientific_name": "Syeda"
    },
    {
        "scientific_name": "Symbicort"
    },
    {
        "scientific_name": "Synthroid"
    },
    {
        "scientific_name": "Targadox"
    },
    {
        "scientific_name": "Temovate"
    },
    {
        "scientific_name": "Ten-K"
    },
    {
        "scientific_name": "Tenormin"
    },
    {
        "scientific_name": "Tessalon Perles"
    },
    {
        "scientific_name": "Testim"
    },
    {
        "scientific_name": "Thalitone"
    },
    {
        "scientific_name": "Timoptic"
    },
    {
        "scientific_name": "Tirosint"
    },
    {
        "scientific_name": "Topamax"
    },
    {
        "scientific_name": "Topicaine"
    },
    {
        "scientific_name": "Toprol"
    },
    {
        "scientific_name": "Triglide"
    },
    {
        "scientific_name": "Trileptal"
    },
    {
        "scientific_name": "Trilipix"
    },
    {
        "scientific_name": "Trokendi XR"
    },
    {
        "scientific_name": "Tylenol"
    },
    {
        "scientific_name": "Uceris"
    },
    {
        "scientific_name": "Ultram"
    },
    {
        "scientific_name": "Unithroid"
    },
    {
        "scientific_name": "Valium"
    },
    {
        "scientific_name": "Valtrex"
    },
    {
        "scientific_name": "Vasotec"
    },
    {
        "scientific_name": "Velivet Triphasic Regimen (28)"
    },
    {
        "scientific_name": "Ventolin"
    },
    {
        "scientific_name": "Verelan"
    },
    {
        "scientific_name": "Veripred"
    },
    {
        "scientific_name": "Verticalm"
    },
    {
        "scientific_name": "Vesicare"
    },
    {
        "scientific_name": "Vestura"
    },
    {
        "scientific_name": "Vicodin"
    },
    {
        "scientific_name": "Victoza"
    },
    {
        "scientific_name": "Vistaril"
    },
    {
        "scientific_name": "Vitamin B-12"
    },
    {
        "scientific_name": "Vitamin E"
    },
    {
        "scientific_name": "Vivelle"
    },
    {
        "scientific_name": "Vivlodex"
    },
    {
        "scientific_name": "Vogelxo"
    },
    {
        "scientific_name": "Voltaren"
    },
    {
        "scientific_name": "Vyvanse"
    },
    {
        "scientific_name": "Wellbutrin"
    },
    {
        "scientific_name": "Xalatan"
    },
    {
        "scientific_name": "Xanax"
    },
    {
        "scientific_name": "Xarelto"
    },
    {
        "scientific_name": "Xenaflamm"
    },
    {
        "scientific_name": "Xtampza ER"
    },
    {
        "scientific_name": "Xylocaine"
    },
    {
        "scientific_name": "Yasmin (28)"
    },
    {
        "scientific_name": "Yaz (28)"
    },
    {
        "scientific_name": "Zamicet"
    },
    {
        "scientific_name": "Zanaflex"
    },
    {
        "scientific_name": "Zantac"
    },
    {
        "scientific_name": "Zarah"
    },
    {
        "scientific_name": "Zayasel"
    },
    {
        "scientific_name": "Zegerid"
    },
    {
        "scientific_name": "Zembrace Symtouch"
    },
    {
        "scientific_name": "Zenzedi"
    },
    {
        "scientific_name": "Zestoretic"
    },
    {
        "scientific_name": "Zestril"
    },
    {
        "scientific_name": "Zetia"
    },
    {
        "scientific_name": "Zipsor"
    },
    {
        "scientific_name": "Zithromax"
    },
    {
        "scientific_name": "Zmax"
    },
    {
        "scientific_name": "Zocor"
    },
    {
        "scientific_name": "Zofran"
    },
    {
        "scientific_name": "Zoloft"
    },
    {
        "scientific_name": "Zonatuss"
    },
    {
        "scientific_name": "Zovirax"
    },
    {
        "scientific_name": "Zuplenz"
    },
    {
        "scientific_name": "Zyban"
    },
    {
        "scientific_name": "Zyloprim"
    },
    {
        "scientific_name": "Zyrtec"
    }
];

export default drugList