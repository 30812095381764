import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../account.service';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { Router } from '@angular/router';
import {ProductService} from "@shop/services";

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit {
  public orderData: any = null;
  public imageBaseUrl: any = sessionStorage.getItem('location');
  public orderStatus: any = null;
  public billingAddress: any = null;
  public shipping_address: any = null;
  public orderId: any;
  public isDisable: boolean = false;
  public loadingResponse: boolean = false;
  public loadingCancelResponse: boolean = false;
  public cancelOrderError: string = '';
  public loadedResponse: boolean = false;

  constructor(private accountService: AccountService,
              public productService: ProductService,
              private route: ActivatedRoute,
			  public sanitizer: DomSanitizer, 
			  private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.orderId = params['id'];
    });
    this.getOrderDetailData();
  }

  /**
   * Get order details
   */
  public getOrderDetailData() {
    this.loadingResponse = true;
    this.accountService.getOrderDetail(this.orderId)
      .subscribe((response: any) => {
        if (response.status && response.status.success) {
          this.orderData = response.data;
          this.orderStatus = response.data.status.name;
          this.billingAddress = response.data.billing_address;
          this.shipping_address = response.data.shipping_address;
          this.loadedResponse = true;
          this.loadingResponse = false;
        }
        else {
          this.router.navigateByUrl('/not-found');
        }
      });
  }

  /**
   * check if an order can be canceled
   */
  public canCustomerCancelOrder() {
    if (this.orderData == null ) return false;
    if (this.orderNotShipped() && !this.orderCancelled()) return true;
    return false;
  }

  public orderNotShipped() {
    //Order is not shipped yet = Status = 1,2,3,9
    return [1, 2 , 3, 9].includes(this.orderData.status.id);
  }
  public orderCancelled() {
    return this.orderData.status.id === 4;
  }

  /**
   * Navigate to product details 
    */
   public viewProductDetail(product) {
	this.router.navigate(['/shop/products', product.real.id]);
}


  /**
   * check if an order can be canceled
   */
  public cancelOrder() {
    if (!this.canCustomerCancelOrder) return ;
    this.loadingCancelResponse = true;
    this.accountService.cancelOrder(this.orderId)
		.subscribe((response: any) => {
			if (response.status.success){
				this.orderData = response.data;
				this.orderStatus = response.data.status.name;
				this.billingAddress = response.data.billing_address;
				this.shipping_address = response.data.shipping_address;
			}else{
				this.cancelOrderError = response.status.message;
			}
			this.loadingCancelResponse = false;
    });
  }

  

  /**
   * Check if has FREE SHIPPING amount
   */
  public hasDiscountShipping(): boolean {
    if (this.orderData == null) return false;
    if (this.orderData.promocode == null) return false;
    if (this.orderData.promocode.type !== 'freeshipping' && this.orderData.promocode.type !== 'both') return false;
    return true;
  }

  /**
   * Check if has Refunds amount
   */
  public hasRefundsAmount(): boolean {
    return this.orderData.refunds_subtotal > 0;
  }

  /**
   * Check if has Refunds amount
   */
  public hasCustomRefundsAmount(): boolean {
    return this.orderData.custom_refunds_subtotal > 0;
  }

  /**
   * Check if has Returns amount
   */
  public hasReturnsAmount(): boolean {
    return this.orderData.returns_subtotal > 0;
  }

  /**
   * Check if has Discount amount
   */
  public hasDiscountAmount(): boolean {
    if (this.orderData.promocode == null) return false;
    if (this.orderData.promocode.type !== 'amount' && this.orderData.promocode.type !== 'both') return false;
    return true;
  }
  /**
   * Capture screen canvas and transform it to pdf
   */
  public captureScreen() {
    this.isDisable = true;
    setTimeout(() => {
      let data = document.getElementById('printOrderDetail');
      html2canvas(data).then(canvas => {
        let imgWidth = 208;
        let imgHeight = canvas.height * imgWidth / canvas.width;

        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jspdf('p', 'mm', 'a4');
        var position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        let string = pdf.output('datauristring');
        let iframe = `<iframe width="100%" height="1000px" marginWidth="50" src='${string}'></iframe>`
        let x = window.open();
        x.document.open();
        x.document.write(iframe);
        x.document.close();
        this.isDisable = false;
      });
    }, 1000);
  }
  public b64toBlob(b64Data, contentType='', sliceSize=512){
      const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
  }
  public reorderProduct(product){
    this.accountService.reorderProduct(product);
  }

  public reorderAllProducts(orderProducts){
    this.accountService.reorderOrder(orderProducts);
  }

  public allowedReorderProducts(){
    return this.orderData.products.filter(orderProduct => orderProduct.real.active && orderProduct.medication_testing === null);
  }


  public printReceipt() {
    this.accountService.getOrderReceipt(this.orderId)
      .subscribe((response: any) => {
         let pdfBlob = this.b64toBlob(response.data, 'application/pdf')
          if (!window.navigator.msSaveOrOpenBlob){
              // BLOB NAVIGATOR
              const url = window.URL.createObjectURL(pdfBlob);
              window.open(url);
          }else{
              // BLOB FOR EXPLORER 11
              //const url = window.navigator.msSaveOrOpenBlob(pdfBlob, 'shipping-label-'  + this.order.id +'.pdf');
          }
      });
  }
}
