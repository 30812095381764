import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ValidationService } from './shared/validation.service';

@Component({
  selector: 'control-messages',
  template: `<div *ngIf="errorMessage !== null">{{errorMessage}}</div>`
})
export class ControlMessagesComponent {
  @Input() control: FormControl;

  /**
   * Validates errors FormControl and returns pre-defined error message based on error type
   */
  get errorMessage() {
    // console.log(this.control.errors);

    for (const propertyName in this.control.errors) {
      if (this.control.invalid && this.control.touched && this.control.dirty) {
        return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }

    return null;
  }
}
