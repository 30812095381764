/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./policy.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../header/header.component.ngfactory";
import * as i5 from "../header/header.component";
import * as i6 from "../shared/user.service";
import * as i7 from "../modules/cart/services/cart.service";
import * as i8 from "../modules/shop/services/categories.service";
import * as i9 from "../modules/shop/services/product.service";
import * as i10 from "../shared/search.service";
import * as i11 from "../footer/footer.component.ngfactory";
import * as i12 from "../footer/footer.component";
import * as i13 from "./policy.component";
var styles_PolicyComponent = [i0.styles];
var RenderType_PolicyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PolicyComponent, data: {} });
export { RenderType_PolicyComponent as RenderType_PolicyComponent };
function View_PolicyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "header", [["class", "clsbefore_log_header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 25, "nav", [["class", "navbar navbar-default"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 24, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "navbar-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "button", [["aria-expanded", "false"], ["class", "navbar-toggle collapsed"], ["data-target", "#bs-example-navbar-collapse-1"], ["data-toggle", "collapse"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Toggle navigation"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "a", [["class", "navbar-brand"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(12, 1), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["alt", ""], ["src", "../../../assets/images/logo@2x.png"], ["width", "150"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 12, "div", [["class", "collapse navbar-collapse"], ["id", "bs-example-navbar-collapse-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 11, "ul", [["class", "nav navbar-nav navbar-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "a", [["href", "https://www.valisure.com/how-it-works/"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["How It Works "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["(current)"])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "a", [["href", "https://www.valisure.com/about-us/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["About Us"])), (_l()(), i1.ɵeld(24, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "a", [["href", "https://www.valisure.com/faq/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["FAQ"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 12, 0, "/signin"); _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 11).target; var currVal_1 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_0, currVal_1); }); }
function View_PolicyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_HeaderComponent_0, i4.RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i5.HeaderComponent, [i6.UserService, i7.CartService, i8.CategoriesService, i9.ProductService, i2.Router, i10.SearchService, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_PolicyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "clswrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PolicyComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PolicyComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-footer", [], null, null, null, i11.View_FooterComponent_0, i11.RenderType_FooterComponent)), i1.ɵdid(8, 49152, null, 0, i12.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.userId; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.userId; _ck(_v, 4, 0, currVal_1); _ck(_v, 6, 0); }, null); }
export function View_PolicyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-policy", [], null, null, null, View_PolicyComponent_0, RenderType_PolicyComponent)), i1.ɵdid(1, 114688, null, 0, i13.PolicyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PolicyComponentNgFactory = i1.ɵccf("app-policy", i13.PolicyComponent, View_PolicyComponent_Host_0, {}, {}, []);
export { PolicyComponentNgFactory as PolicyComponentNgFactory };
