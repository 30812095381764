import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {CartService} from "@modules/cart/services/cart.service";
import {UtilsService} from "@services/utils.service";

const reqHeader = new HttpHeaders();
reqHeader.append('Access-Control-Allow-Origin', '*');
reqHeader.append('Access-Control-Allow-Headers', '*');
reqHeader.append('Access-Control-Allow-Methods', ' GET, POST, DELETE, PUT, HEAD, OPTIONS');
reqHeader.append('Request Method', 'POST, GET, PUT, DELETE, OPTIONS, HEAD');
reqHeader.append('Content-Type', 'application/json, application/x-www-form-urlencoded, multipart/form-data, text/plain, charset=UTF-8');

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  readonly rootUrl = sessionStorage.getItem('location');

  constructor(private cartService: CartService, private router: Router, private http: HttpClient) { }


  getProductVariant(product, variantId) {

    if (variantId == null ) return null;
    if (product.real.variants.length === 0 ) return null;

    return product.real.variants.reduce(function (variantSearched, variant, index) {
        if (variantSearched.variantId == variant.id) {
            variantSearched.variant = variant;
        }
        return variantSearched;
    }, {variant: null, variantId: variantId}).variant;
}
getProductPriceRange(product, quantity) {

    return product.real.variants.sort(function (a, b) {
        return a.quantity - b.quantity;
    }).reduce(function (variantSearched, variant, index) {
        if (variantSearched.quantity >= variant.quantity) {
            variantSearched.variant = variant;
        }
        return variantSearched;
    }, {variant: null, quantity: quantity}).variant;
}
calculatePrice(orderProduct) {

    const recalculatedPrice = {
        productPrice : 0, 
        productPricePerUnit : 0, 
    }

    const selectedProductVariant = this.getProductVariant(orderProduct, orderProduct.product_variant_id);
    if (!orderProduct.real.rx) {
        if (orderProduct.real.variants.length > 0) {
            recalculatedPrice.productPrice = UtilsService.roundPrice(selectedProductVariant.price * orderProduct.quantity);
            recalculatedPrice.productPricePerUnit = selectedProductVariant.price / selectedProductVariant.quantity;
        } else {
            recalculatedPrice.productPrice = UtilsService.roundPrice(orderProduct.real.price * orderProduct.quantity);
        }
    } else {
        if (orderProduct.real.variants.length > 0) {
            const productRange = this.getProductPriceRange(orderProduct, orderProduct.quantity);
            // console.log(productRange);
            if (productRange == null) {
                recalculatedPrice.productPrice = UtilsService.roundPrice(orderProduct.real.price * orderProduct.quantity);
                recalculatedPrice.productPricePerUnit = orderProduct.real.price;
            } else {
                recalculatedPrice.productPrice = UtilsService.roundPrice(productRange.price * orderProduct.quantity);
                recalculatedPrice.productPricePerUnit = productRange.price;
            }
        } else {
            recalculatedPrice.productPrice = orderProduct.real.price * orderProduct.quantity;
            recalculatedPrice.productPricePerUnit = orderProduct.real.price;
        }
    }

    return recalculatedPrice;
}

  processReorderProduct(orderProduct){
    const recalculatedPrice = this.calculatePrice(orderProduct);
    const selectedProductVariant = this.getProductVariant(orderProduct, orderProduct.product_variant_id);

    const product = orderProduct.real;
    const productVariant = selectedProductVariant;
    const quantity = orderProduct.quantity;
    const pricePerItem = orderProduct.real.rx ? recalculatedPrice.productPricePerUnit : ((selectedProductVariant != null) ? selectedProductVariant.price : orderProduct.real.price);
    const price = recalculatedPrice.productPrice;

    this.cartService.addProduct(product, productVariant, quantity, pricePerItem, price);

    return {
      product : product,
      productVariant : productVariant,
      quantity : quantity
    };

  }
  reorderProduct(orderProduct) {
    this.cartService.openCartBox(this.processReorderProduct(orderProduct));
  }

  reorderOrder(orderProducts){
    if (orderProducts.length === 1){
      this.cartService.openCartBox(this.processReorderProduct(orderProducts[0]));
    }else{
      for (let i = 0; i < orderProducts.length; i++) {
        this.processReorderProduct(orderProducts[i]);
        this.cartService.openCartBox();
      }
    }    
  }


  /**
   * API for adding a new payment method
   */
  agreeHippaNovember2019(data) {
    return this.http.post(this.rootUrl + `/customers/agree_hippa_november_2019`, data, { headers: reqHeader });
  }


  /**
   * API for customer addresses
   * @param id - Customer ID
   */
  getAddressList(id) {
    return this.http.get(this.rootUrl + `/customers/addresses`, { headers: reqHeader });
  }

  getPaymentList(id) {
    return this.http.get(this.rootUrl + `/customers/payment-methods`, { headers: reqHeader });
  }

  /**
   * API for adding a new payment method
   */
  addNewPaymentMethod(data) {
    return this.http.post(this.rootUrl + `/customers/payment-methods/add`, data, { headers: reqHeader });
  }

  /**
   * API for setting the default payment method 
   */
  setDefaultPaymentMethod(data) {
    return this.http.post(this.rootUrl + `/customers/payment-methods/default`, data, { headers: reqHeader });
  }

  /**
   * API for adding a new address
   */
  addNewAddress(data) {
    return this.http.post(this.rootUrl + `/customers/add-address`, data, { headers: reqHeader });
  }

  /**
   * API for updating address
   */
  updateAddress(updateData) {
    return this.http.put(this.rootUrl + `/customers/update-address`, updateData, { headers: reqHeader });
  }

  /**
   * API for deleting an address
   * @param id - Address ID
   */
  deleteAddress(data) {
    return this.http.post(this.rootUrl + `/customers/delete-address`, data, { headers: reqHeader });
  }

  /**
   * API for setting a primary address
   * @param id - Address ID
   */
  setPrimaryAddress(data) {
    return this.http.post(this.rootUrl + `/customers/addresses/default`, data, { headers: reqHeader });
  }

  /**
   * API for retrieving address suggestions
   */
  getAutocomplete(data) {
    return this.http.post(this.rootUrl + `/customers/addresses/autocomplete`, data, { headers: reqHeader });
  }

  /**
   * API for retrieving address details from a suggestion
   */
  getAutocompleteDetails(data) {
    return this.http.post(this.rootUrl + `/customers/addresses/autocomplete-details`, data, { headers: reqHeader });
  }

  /**
   * API for deleting an address
   * @param id - Address ID
   */
  deletePaymentMethod(data) {
    return this.http.post(this.rootUrl + `/customers/payment-methods/delete`, data, { headers: reqHeader });
  }
  /**
   * API for customer addresses
   * @param id - Customer ID
   */
  getInsuranceList(id) {
    return this.http.get(this.rootUrl + `/customers/insurances`, { headers: reqHeader });
  }

  /**
   * API for adding a new insurance
   */
  addNewInsurance(data) {
    return this.http.post(this.rootUrl + `/customers/add-insurance`, data, { headers: reqHeader });
  }

  /**
   * API for updating insurance
   */
  updateInsurance(updateData) {
    return this.http.put(this.rootUrl + `/customers/update-insurance`, updateData, { headers: reqHeader });
  }

  /**
   * API for deleting an insurance
   * @param id - Insurance ID
   */
  deleteInsurance(data) {
    return this.http.post(this.rootUrl + `/customers/delete-insurance`, data, { headers: reqHeader });
  }

  /**
   * API for upading profile info
   */
  updateProfileInfo(updateProfileData) {
    return this.http.put(this.rootUrl + `/customers/update-base-info`, updateProfileData, { headers: reqHeader });
  }

  /**
   * API for list of states
   */
  getStateLists() {
    return this.http.get(this.rootUrl + `/states`, { headers: reqHeader });
  }

  /**
   * API for medications list
   * @param medicationVal - Pagination metadata
   */
  getMedicationList(medicationVal) {
    return this.http.get(this.rootUrl + `/requested-medications/get?limit=${medicationVal.limit}&page=${medicationVal.page}`, { headers: reqHeader });
  }

  /**
   * API for deleting medication
   * @param medicationId - Medication ID
   */
  deleteMedication(medicationId) {
    const data = {
      id: medicationId,
      customer_id: sessionStorage.getItem('userId')
    };
    return this.http.post(this.rootUrl + `/requested-medications/delete`, data, { headers: reqHeader });
  }

  /**
   * API for order details
   * @param id - Order ID
   */
  getOrderDetail(id) {
    const data = {
      id: id
    };
    return this.http.post(this.rootUrl + `/orders/details`, data, { headers: reqHeader });
  }

  /**
   * API for canceling an order
   * @param id - Order ID
   */
  cancelOrder(id) {
    const data = {
      id: id
    };
    return this.http.post(this.rootUrl + `/orders/cancel`, data, { headers: reqHeader });
  }


  
  /**
   * API for order receipt
   * @param id - Order ID
   */
  getOrderReceipt(id) {
    const data = {
      id: id
    };
    return this.http.post(this.rootUrl + `/orders/receipt`, data, { headers: reqHeader });
  }


  /**
   * API for orders list
   */
  getOrderList(savedfilter?: string, saveOrderVal?) {
    const data = {
      filters: {
        saved: savedfilter ? true : false,
        customer: sessionStorage.getItem('userId')
      },
      options: {
        order_by: 'created',
        order_type: 'desc',
        per_page: saveOrderVal.limit
      },
      page: saveOrderVal.page
    };
    return this.http.post(this.rootUrl + `/orders/list`, data, { headers: reqHeader });
  }

  /**
   * API for recent purchases  list
   */
  getRecentPurchasesList(savedfilter?: string, saveOrderVal?) {
    const data = {
      filters: {
        saved: savedfilter ? true : false,
        customer: sessionStorage.getItem('userId')
      },
      options: {
        order_by: 'created',
        order_type: 'desc',
        per_page: saveOrderVal.limit
      },
      page: saveOrderVal.page
    };
    return this.http.post(this.rootUrl + `/orders/recentpurchases`, data, { headers: reqHeader });
  }

  /**
   * API for recent purchases  list
   */
  getRecentPurchasesHomePageList(savedfilter?: string, saveOrderVal?) {
    const data = {
      filters: {
        saved: savedfilter ? true : false,
        customer: sessionStorage.getItem('userId')
      },
      options: {
        order_by: 'created',
        order_type: 'desc',
        per_page: saveOrderVal.limit
      },
      page: saveOrderVal.page
    };
    return this.http.post(this.rootUrl + `/orders/homerecentpurchases`, data, { headers: reqHeader });
  }

  
  /**
   * API for saving order
   * @param orderId - Order ID
   */
  saveOrder(orderId, save) {
    const data = {
      id: orderId,
      saved: save
    };
    return this.http.post(this.rootUrl + `/orders/save`, data, { headers: reqHeader });
  }
}
