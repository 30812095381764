<main class="clsmain_container">
  <div class="clsmedication_request_section clspolicy_section">
      <div class="container">
          <ul class="clsbreadcrumb">
              <li><a [routerLink]="routePath">Home</a></li>
              <li>Return and Refund Policy</li>
          </ul>
          <div class="clslmedication_request_content_blk">
              <div class="row">
                  <div class="col-md-4 col-sm-5">
                      <div class="clslmedication_request_content_left">
                          <h1>Return and Refund Policy</h1>
                      </div>
                  </div>
                  <div class="col-md-8 col-sm-7" style ="word-break: break-word;">
                      <div class="clspolicy_content">
                          <p><strong><em>Prescription Return Policy</em></strong></p>
                          <p>We are prevented by law from accepting returns for any prescription medications. If you need guidance on the safe disposal of medications, including sharps and transdermal patches, please contact your state health agency or follow the U.S. Food and Drug Administration (FDA) guidelines <br>
                              <a style="color: #F4A619" target="_blank" href="http://www.fda.gov/drugs/resourcesforyou/consumers/buyingusingmedicinesafely/ensuringsafeuseofmedicine/safedisposalofmedicines/ucm186187.htm">www.fda.gov/drugs/resourcesforyou/consumers/buyingusingmedicinesafely/ensuringsafeuseofmedicine/safedisposalofmedicines/ucm186187.htm </a><br>
                            If you need additional guidance, please call us at 1-833-497-7370.
                          </p>
                          <hr>
                          <p><strong><em>Over the Counter Return Policy</em></strong></p>
                          <p>If you are not 100% satisfied with your purchase, we will be happy to accept a return for a full refund. You can return your OTC purchase for up to 90 days from the purchase date, with no restocking fees.
                            <br>Products must be in the condition you received them and in the original box.
                            <br>If there is a problem with your product, please contact us immediately for assistance.  For safety concerns, we cannot accept returns for refrigerated items such as insulin.</p>
                          <hr>
                          <p><strong><em>How To Return An Item</em></strong></p>
                          <p>If you need to return an item, please contact us by phone for assistance. Returns can be addressed to:<br>
                            <em>ValisureRX<br>
                                Attention:  Customer Service<br>
                                5 Science Park<br>
                                1st Floor<br>
                                New Haven, CT 06511
                            </em>
                            </p>
                            <p>In the package, please include a note detailing the need for the return and contact information so we may reach you if there are any questions. Refunds are processed within 7 business days upon receipt pending approval.</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</main>
