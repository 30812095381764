/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./address-display.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./address-display.component";
var styles_AddressDisplayComponent = [i0.styles];
var RenderType_AddressDisplayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddressDisplayComponent, data: {} });
export { RenderType_AddressDisplayComponent as RenderType_AddressDisplayComponent };
function View_AddressDisplayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.address.phone; _ck(_v, 2, 0, currVal_0); }); }
function View_AddressDisplayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.address.company; _ck(_v, 2, 0, currVal_0); }); }
export function View_AddressDisplayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "address", [["class", "clscheckout_address"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressDisplayComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressDisplayComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(9, null, [" ", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", ", ", " ", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" United States of America\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.address.phone; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.address.company; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.address.first_name; var currVal_1 = _co.address.last_name; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = _co.address.address_line_1; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.address.city; var currVal_6 = ((_co.address.state == null) ? null : _co.address.state.abbreviation); var currVal_7 = _co.address.zip_code; _ck(_v, 11, 0, currVal_5, currVal_6, currVal_7); }); }
export function View_AddressDisplayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-address-display", [], null, null, null, View_AddressDisplayComponent_0, RenderType_AddressDisplayComponent)), i1.ɵdid(1, 114688, null, 0, i3.AddressDisplayComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddressDisplayComponentNgFactory = i1.ɵccf("app-address-display", i3.AddressDisplayComponent, View_AddressDisplayComponent_Host_0, { address: "address", states: "states" }, {}, []);
export { AddressDisplayComponentNgFactory as AddressDisplayComponentNgFactory };
