import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import DoomsdayStatus from "@modules/doomsday/model/doomsdaystatus.model";
import {Subscription} from "rxjs";

@Component({
    selector: "app-doomsday-standard",
    templateUrl: "./doomsday-standard.component.html",
    styleUrls: ["./doomsday-standard.component.scss"]
})
export class DoomsdayStandardComponent implements OnInit, OnDestroy {

    public userFname: string;

    public doomsdayStatus: DoomsdayStatus;

    dataSubscription: Subscription;

    constructor(private router: Router, private route: ActivatedRoute) {
        this.dataSubscription = this.route.data
            .subscribe((data: { doomsdayStatus: DoomsdayStatus}) => {
                this.doomsdayStatus = data.doomsdayStatus;
            });
    }

    ngOnInit() {
        this.userFname = sessionStorage.getItem("userFname");
        if (!this.doomsdayStatus.doomsday_enabled) this.router.navigate(['/']);
        //console.log(this.doomsdayStatus);
    }

    ngOnDestroy(): void {
        this.dataSubscription.unsubscribe();
    }

}
