import { Component } from '@angular/core';
import {menuitems, MenuItems} from "@services/menuitems";

@Component({
  selector: 'app-doomsday-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class DoomsdayFooterComponent {
  public menuItems: MenuItems;

  public year;

  constructor() {
    this.menuItems = menuitems;
    const today = new Date();
    this.year = today.getFullYear();
  }
}
