import { FormGroup } from '@angular/forms';

export class ValidationService {

  /**
   * Returns pre-defined error message based on validatorName
   */
  static getValidatorErrorMessage(validatorName: string, validatorValue: any = null) {
    const config = {
      'required': 'This field is required.',
      'invalidCreditCard': 'Is invalid credit card number.',
      'invalidEmailAddress': 'Invalid email address.',
      'invalidPassword': 'Please create your password with the following:  8-20 characters, at least 1 lower letter, 1 upper case letter, 1 number. ',
      'pattern': 'Invalid value.',
      'invalidPhoneNumber': 'Invalid phone number.',
      'invalidDate': 'Invalid date.',
      'invalidTerms' : 'You must agree Terms & Conditions and HIPPA Policy.',
      'interestedIn' : 'Please let us know the services you\'re interested in.',
      'minlength': `Minimum length ${validatorValue.requiredLength}.`
    };

    if (config.hasOwnProperty(validatorName)) {
        return config[validatorName];
    }
    else {
      return validatorValue;
    }
  }

  /**
   * Validator for credit card form control
   * @param control - Form control to validate
   */
  static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (!control.value || control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null;
    } else {
      return { 'invalidCreditCard': true };
    }
  }

  /**
   * Validator for email
   * @param control - Form control to validate
   */
  static emailValidator(control) {
    // RFC 2822 compliant regex
    if (!control.value || control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null;
    } else {
      return { 'invalidEmailAddress': true };
    }
  }

    /**
     * Date validator
     * @param control - Form control to validate
     */
    static dateValidator(control) {
        if (!control.value || control.value.match(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/)) {
            return null;
        } else {
            return { 'invalidDate': true };
        }
    }

    /**
     * Phone number validator
     * @param control - Form control to validate
     */
    static phoneValidator(control) {
        if (!control.value || control.value.match(/^\d{3}-\d{3}-\d{4}$/)) {
            return null;
        } else {
            return { 'invalidPhoneNumber': true };
        }
    }

    static termsValidator(control) {
      if (control.value === true) {
          return null;
      } else {
          return { 'invalidTerms': true };
      }
    }

  static interestedInValidator(control) {
    if (control.value === true) {
      return null;
    } else {
      return { 'interestedIn': true };
    }
  }

  /**
   * Validator for password
   * @param control - Form control to validate
   */
  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (!control.value || control.value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
      return null;
    } else {
      return { invalidPassword: true };
    }
  }

  /**
   * Validator for email, checks if both fields are equal
   * @param firstField - First form field
   * @param secondField - Second form field
   */
  static validate(firstField: any, secondField: any) {
    return (c: FormGroup) => {
      return (c.controls && c.controls[firstField].value == c.controls[secondField].value) ? null : {
        emailEqual: {
          valid: false
        }
      };
    };
  }
}
