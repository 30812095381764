export const environment = {
    production: false,
    heartland_key: "pkapi_cert_fprVkR8tnUGBCE0MKU",
    root_url: "http://shop.valisure.visualmax.com/api",
    recaptcha_key: "6LeMZboZAAAAAHFcU--9uAQRY_HkSLG-A2xGWw8H",
    recaptcha_limit: 0.5,
    rx_category_id: 42,
    otc_category_id: 40,
    medication_tests_category_id: 96,
    medication_testing_type_id: 4
};

import "zone.js/dist/zone-error";  // Included with Angular CLI.
