
import {UserAddress} from "../../../../user/model/user-address.model";
import {Component, Input, OnInit} from "@angular/core";

@Component({
    selector: "app-address-display",
    templateUrl: "./address-display.component.html",
    styleUrls: ["./address-display.component.css"]
})
export class AddressDisplayComponent implements OnInit {

    @Input() address: UserAddress;
    @Input() states: any[];

    constructor() {
    }

    ngOnInit() {
    }

}
