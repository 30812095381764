import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  public userId: any = sessionStorage.getItem('userId');
  public routePath: any;

  ngOnInit() {
    this.checkRoutepath();
  }

  /**
   * Check route path based on user ID
   */
  public checkRoutepath() {
    if (this.userId) {
      this.routePath = '/user-home';
    } else {
      this.routePath = '/signin';
    }
  }
}
