<div class="clswrapper">
    <div class=""></div>
    <!-- Main container -->
    <main class="clsmain_container">
        <div class="clsmyaccount_section">
            <div class="container">
                <ul class="clsbreadcrumb">
                    <li><a href="javascript:void(0)" [routerLink]="['/']">Home</a></li>
                    <li><a href="javascript:void(0)" [routerLink]="['/account/order-history']">Account</a></li>
                    <li>Address</li>
                </ul>
                <!--<app-top-panel></app-top-panel>-->
                <div class="clsmy_account_main_content_blk">
                    <div>
                        <div class="accountContainer">
                            <h1 class="accountTitle"> Addresses</h1>
                            <form #myForm='ngForm'>
                                <div class="accountBox">
                                    <div class="accountBoxTitle">
                                        ADDRESS
                                        <span><a 
                                            class="action"
                                            [ngClass]="{'action_disabled': showAddress == true,'addressCursor': showAddress == true}"
                                            style="cursor: pointer"
                                            (click)="showNewAddress()">Add New</a> </span>
                                    </div>
                                    <div class="accountAddress accountAddressMapped" *ngIf="showAddress">
                                        <h4>New Address</h4>

                                        <app-address-form
                                            [formGroup]="newAddressFormGroup"
                                            [states]="stateArray"
                                        ></app-address-form>

                                        <div *ngIf="newAddressFormGroup.get('error').value" class="alert alert-danger" style="margin-top: 20px">
                                            {{ newAddressFormGroup.get('error').value }}
                                        </div>

                                        <div class="btn_line">
                                            <a style="cursor: pointer" 
                                                class="action" 
                                                (click)="showNewAddress()"
                                                [ngClass]="{'action_disabled': loadingResponse}">
                                                Cancel
                                            </a>
                                            <a style="cursor: pointer" 
                                                class="action" 
                                                (click)="addNewAddress(newAddressFormGroup)"
                                                [ngClass]="{'action_disabled': loadingResponse}">
                                                <span *ngIf="!loadingResponse">Add</span> 
                                                <span *ngIf="loadingResponse">Please wait</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="accountAddress" 
                                        *ngFor="let address of addressArray; let i = index"
                                        [ngClass]="{'accountAddressMapped': selectedIndex == i}">
                                        <div>
                                            <h4 *ngIf="selectedIndex != i">{{address.name}} 
                                                <div class="checkbox">
                                                    <input
                                                        type="radio"
                                                        id="radio{{ i }}"
                                                        name="defaultAddress"
                                                        value="1"
                                                        [checked]="address.default_billing"
                                                        (click)="setDefaultPrimaryOption(address.id)"
                                                    />
                                                    <label for="radio{{ i }}"><span></span></label>
                                                </div>
                                                <span>
                                                    <a  *ngIf="!address.default_billing"
                                                        (click)="setDefaultPrimaryOption(address.id)" 
                                                        style="cursor: pointer"
                                                        class="action setdefault">
                                                        Set as Primary
                                                    </a>
                                                    <a
                                                    style="cursor: pointer"
                                                    (click)="showEdit(address,i)">Edit</a> <a
                                                    data-toggle="modal"
                                                    (click)="deleteAddressId(address.id)"
                                                    style="cursor: pointer"
                                                    data-target="#myModal">Delete</a>
                                                </span>
                                            </h4>

                                            <app-address-display [address]="address" *ngIf="selectedIndex != i"></app-address-display>


                                            <div *ngIf="selectedIndex == i">
                                                <form>
                                                    <h4>{{selectedValue.name}} 
                                                        <span>
                                                            <a (click)="updateAddress(addressesFormGroups[i])"
                                                                href="javascript:void(0)">
                                                                Save Changes
                                                            </a>
                                                        </span>
                                                    </h4>

                                                    <app-address-form
                                                        [formGroup]="addressesFormGroups[i]"
                                                        [states]="stateArray"
                                                    ></app-address-form>

                                                    <div *ngIf="addressesFormGroups[i].get('error').value" class="alert alert-danger" style="margin-top: 20px">
                                                        {{ addressesFormGroups[i].get('error').value }}
                                                    </div>
                                                    <div class="btn_line">
                                                        <a style="cursor: pointer" 
                                                            class="action" 
                                                            (click)="selectedIndex = null"
                                                            [ngClass]="{'action_disabled': loadingResponse}">
                                                            Cancel
                                                        </a>
                                                        <a style="cursor: pointer" 
                                                            class="action" 
                                                            (click)="updateAddress(addressesFormGroups[i])"
                                                            [ngClass]="{'action_disabled': loadingResponse}">
                                                            <span *ngIf="!loadingResponse">Save Changes</span> 
                                                            <span *ngIf="loadingResponse">Please wait</span>
                                                        </a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>


                                    <div *ngIf="!loadingResponse">
                                        <div class="accountMessage" *ngIf="addressArray.length === 0 && !showAddress">You don't have any addresses created.</div>
                                    </div>    
                                    <div *ngIf="loadingResponse && !loadedResponse" class="accountMessage">
                                        Loading. Please wait ...
                                    </div>                                
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </main>
</div>
<!-- Modal -->
<!-- <div class="modal fade clsaddress_delete_confirm_modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="vertical-alignment-helper">
        <div class="modal-dialog vertical-align-center" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">Are you sure you want to delete this address?</h4>
                </div>
                <div class="modal-body">
                    <a href="javascript:void(0)" data-dismiss="modal">No, Keep Address</a>
                    <button type="button" class="btn btn-primary clsyellow_btn" (click)="deleteAddress()" data-dismiss="modal">Yes,
                        Delete Address</button>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="modal fade clsaddress_delete_confirm_modal" id="myModal" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel">
    <div class="vertical-alignment-helper">
        <div class="modal-dialog vertical-align-center" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">Are you sure you want to delete this address?</h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xs-6">
                            <a href="javascript:void(0)" data-dismiss="modal" class="action poppin_semibold">No</a>
                        </div>
                        <div class="col-xs-6">
                            <a href="javascript:void(0)" data-dismiss="modal" (click)="deleteAddress()" class="action poppin_semibold">Yes</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
