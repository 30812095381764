import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";

import {AppComponent} from "./app.component";
import {UserService} from "./shared/user.service";

import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {UserComponent} from "./user/user.component";
import {HttpModule} from "@angular/http";
import {RouterModule} from "@angular/router";
import {appRoutes} from "./routes";
import {HeaderComponent} from "./header/header.component";
import {FooterComponent} from "./footer/footer.component";
import {HomeComponent} from "./home/home.component";
import {SigninComponent} from "./user/signin/signin.component";
import {SignupComponent} from "./user/signup/signup.component";
import {AuthGuard} from "./auth/auth.guard";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {AuthInterceptor} from "./auth/auth.interceptor";

// prime ng
import {DataViewModule} from "primeng/dataview";
import {DropdownModule} from "primeng/dropdown";
import {CheckboxModule} from "primeng/checkbox";
import {InputTextModule} from "primeng/inputtext";
import {SliderModule} from "primeng/slider";
import {PaginatorModule} from "primeng/paginator";
import {CalendarModule} from "primeng/calendar";
import {AutoCompleteModule} from "primeng/autocomplete";
import {ControlMessagesComponent} from "./control-messages.component";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {UserHomeComponent} from "./user-home/user-home.component";
import {PerscriptionComponent} from "./user-home/perscription/perscription.component";
import {InputMaskModule} from "primeng/inputmask";
import {ChipsModule} from "primeng/chips";
import {SpinnerModule} from "primeng/spinner";
import {AccountModule} from "./account/account.module";
import {PersonalinfoComponent} from "./user/personalinfo/personalinfo.component";
import {CartModule} from "@modules/cart/cart.module";
import {CartService} from "@modules/cart/services/cart.service";
import {ForgotPasswordComponent} from "./password/forgot-password/forgot-password.component";
import {ResetPasswordComponent} from "./password/reset-password/reset-password.component";
import {PasswordComponent} from "./password/password.component";
import {CaptchaModule} from "primeng/captcha";
import {NotFoundComponent} from "./not-found/not-found.component";
import {PolicyComponent} from "./policy/policy.component";
import {ShoppingPolicyComponent} from "./policy/shopping-policy/shopping-policy.component";
import {ReturnPolicyComponent} from "./policy/return-policy/return-policy.component";
import {ProductService} from "@shop/services";
import {BaseModule} from "@modules/base/base.module";
import {LoginGuard} from "./auth/login.guard";
import {DoomsdayModule} from "@modules/doomsday/doomsday.module";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import {environment} from "../environments/environment";

@NgModule({
    declarations: [
        AppComponent,
        UserComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        SigninComponent,
        SignupComponent,
        PersonalinfoComponent,
        ControlMessagesComponent,
        UserHomeComponent,
        PerscriptionComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        PasswordComponent,
        NotFoundComponent,
        PolicyComponent,
        ShoppingPolicyComponent,
        ReturnPolicyComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RecaptchaV3Module,
        RouterModule.forRoot(appRoutes, {onSameUrlNavigation: "reload", scrollPositionRestoration: "enabled"}),
        FormsModule,
        BaseModule,
        AccountModule,
        CartModule,
        DoomsdayModule,
    ],
    providers: [
        UserService,
        AuthGuard, {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        LoginGuard,
        ProductService,
        CartService,
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha_key },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
