import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import {UserService} from "../../../../shared/user.service";
import {AccountService} from "../../../../account/account.service";
import {ServerResponse} from "../../../../model/base/server-response.model";
import PerfectScrollbar from 'perfect-scrollbar';
import {FormControl, FormGroup, Validators} from "@angular/forms";

declare var $: any;

@Component({
    selector: "app-hippa-update-november-2019",
    templateUrl: "./hippa-update-november-2019.component.html",
    styleUrls: [
        "./hippa-update-november-2019.component.scss",
        "./hippa-update-november-2019.component.css"
    ]
})
export class HippaUpdateNovember2019Component implements OnInit, AfterViewInit  {
    public userId: any = sessionStorage.getItem("userId");
    public ps = null;
    public scrollDisabled = false;
    public formGroup: FormGroup;
    public savingResponse = false;
    public hippaAgreed = false;
    public hippaError = false;
    public hippaAgreedErrorMessage = 'You must agree with HIPPA Policy to continue.';
    public hippaAgreedDefaultMessage = 'You must agree with HIPPA Policy to continue.';
    //public hasHippaNovember2019: boolean = true;
    @ViewChild("hippanovember2019") hippanovember2019: ElementRef<HTMLElement>;
    @ViewChild('hippa_content_block') hippa_content_block: ElementRef;

    constructor(private accountService: AccountService, private userService: UserService) {
    }

    ngOnInit() {
        this.getUserData();
        this.formGroup = new FormGroup({
            agree_hippa_november_2019: new FormControl("")
        });
    }
    ngAfterViewInit() {
        if (this.hippa_content_block) {
            this.ps = new PerfectScrollbar(this.hippa_content_block.nativeElement);
        }
    }
    /**
     * API for getting User Details
     */
    public getUserData() {
        this.userService.getUserDetails()
            .subscribe((response: any) => {
                if (response.data.user.hippa_november_2019 === null) {
                    $("#hippanovember2019Modal").modal('show');
                }
            });
    }

    public saveHippaAgreement(){
        this.hippaError = false;
        if (!this.hippaAgreed) {
            this.hippaError = true;
            this.hippaAgreedErrorMessage = this.hippaAgreedDefaultMessage;
            return;
        }
        this.savingResponse = true;
        this.accountService.agreeHippaNovember2019({
            user_id: this.userId
        }).subscribe((response: ServerResponse<any>) => {
            if (response && response.status && response.status.success) {
                $("#hippanovember2019Modal").modal('hide');
            }
            else {
                this.hippaError = true;
                this.hippaAgreedErrorMessage = response.status.message;
            }
            this.savingResponse = false;
        });
    }
}
