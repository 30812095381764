import {Component, OnInit, AfterViewInit, HostListener} from "@angular/core";
import {UserService} from "../../shared/user.service";
import {Router, ActivatedRoute} from "@angular/router";
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import * as jwt_decode from "jwt-decode";
import {ValidationService} from "@services/validation.service";
import {UtilsService} from "@services/utils.service";

declare var $: any;

@Component({
    selector: "app-signin",
    templateUrl: "./signin.component.html",
    styleUrls: ["./signin.component.css"]
})
export class SigninComponent implements OnInit, AfterViewInit {
    public email: any;
    public password: any;
    public loginerror = false;
    public userClaims: any;
    public users: any;
    public token: any;
    public IsLoggined = true;
    public isTimeout: boolean = sessionStorage.getItem("userToken") && !!(sessionStorage.getItem("timeout"));
    public innerWidth: any;
    public loadingResponse: boolean = false;
    public showPassword = false;

    public loginForm: FormGroup;

    constructor(private userService: UserService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder) {

        this.loginForm = fb.group({
                "email": ["", Validators.compose([Validators.required, 
                    //Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                    ValidationService.emailValidator
                ])],
                "password": ["", Validators.compose([Validators.required])],
            },
            {
                // updateOn: "blur",
            });

    }

    ngOnInit() {
        this.token = this.route.snapshot.queryParams.token;
        if (this.token) {
            this.impersonateUser();
        }

        this.innerWidth = window.innerWidth;
        if (window.innerWidth < 600) {
            document.getElementById("signin").scrollIntoView(true);
        }
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    ngAfterViewInit() {
        this.displayModal();
    }

    impersonateUser() {
        this.loadingResponse = true;
        this.userService.impersonate(this.token).subscribe(
            (res: any) => {
                const tokenInfo = jwt_decode(this.token);
                this.loadingResponse = false;
                if (res.status.success && tokenInfo.user.id === res.data.user.id) {
                    this.IsLoggined = true;
                    this.userService.setToken(res, tokenInfo);

                    const returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : '/';
                    UtilsService.redirectToPath(this.router, returnUrl);
                    // this.router.navigate([decodeURI(returnUrl)]);
                } else {
                    this.IsLoggined = false;
                    return false;
                }
            },
            (err: HttpErrorResponse) => {
                this.loadingResponse = false;
            }
        );
    }

    /**
     * Signin form submit, logs user in
     */
    onSubmit() {
        if (!this.loginForm.valid) {
            UtilsService.markFormGroupTouched(this.loginForm as FormGroup);
            return;
        }

        if (!this.loadingResponse) {
            this.loadingResponse = true;
            this.email = this.loginForm.value.email;
            this.password = this.loginForm.value.password;

            this.userService.userAuthentication(this.email, this.password).subscribe(
                (res: any) => {
                    this.loadingResponse = false;
                    if (res.status.success) {
                        this.IsLoggined = true;
                        this.userService.setToken(res, jwt_decode(res.data.token));

                        const returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : '/';
                        UtilsService.redirectToPath(this.router, returnUrl);
                        // this.router.navigate([decodeURI(returnUrl)]);
                    } else {
                        this.IsLoggined = false;
                        this.loginerror = true;
                        return false;
                    }
                },
                (err: HttpErrorResponse) => {
                    this.loginerror = true;
                    this.loadingResponse = false;
                }
            );
        }
    }

    /**
     * Opens modal on click
     */
    displayModal() {
        if (this.isTimeout) {
            document.getElementById("openModalButton").click();
            this.isTimeout = false;
            sessionStorage.removeItem("timeout");
        }
    }

    /**
     * User sign out and redirect to sign in
     */
    logout() {
        sessionStorage.clear();
        this.router.navigate(["/signin"]);
    }

    public toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }
}
