
  <!-- Main header -->
  <header class="clsmain_header">
    <div class="clsheader_top">
      <div class="container">
        <div class="clslogo_blk">
          <div class="clslogo"><img src="assets/images/logo@2x.png" alt="" width="150" /></div>
        </div>
      </div>
    </div>
    <div class="clsheader_bottom">
      <div class="container">
        <div class="navbar navbar-default clsmega_menu">
          <div class="navbar-header">
            <button type="button" data-toggle="collapse" data-target="#navbar-collapse-2" class="navbar-toggle">
              <div id="navbar-hamburger">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </div>
              <div id="navbar-close" class="hidden">
                <span><img src="assets/images/close-icon@2x.png" alt="" width="14" /></span>
              </div>
            </button>
          </div>
          <div id="navbar-collapse-1" class="navbar-collapse collapse clsdesktop_navbar">
            <ul class="nav navbar-nav">
              <li class="dropdown">
                <a href="{{ menuItems.analysis }}" target="_blank" class="dropdown-toggle">Analysis</a>
              </li>
              <li class="dropdown yamm-fw">
                <a href="{{ menuItems.forDoctors }}" target="_blank" class="dropdown-toggle">For Doctors</a>
              </li>
              <li class="dropdown yamm-fw">
                <a href="{{ menuItems.faq }}" target="_blank" class="dropdown-toggle">FAQ</a>
              </li>
            </ul>

          </div>
          <div id="navbar-collapse-2" class="navbar-collapse collapse clsmobile_navbar">
            <ul class="nav nav-list">
              <li class="dropdown">
                <a href="{{ menuItems.analysis }}" target="_blank" class="dropdown-toggle">Analysis</a>
              </li>
              <li class="dropdown yamm-fw">
                <a href="{{ menuItems.forDoctors }}" target="_blank" class="dropdown-toggle">For Doctors</a>
              </li>
              <li class="dropdown yamm-fw">
                <a href="{{ menuItems.faq }}" target="_blank" class="dropdown-toggle">FAQ</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>