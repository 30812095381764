<!-- Main container -->
<main class="clsmain_container">
    <div class="container">
        <ul class="clsbreadcrumb">
            <li>
                <a [routerLink]="['/user-home']">Home</a>
            </li>
            <li>About</li>
        </ul>
        <div class="clssignup_secondary_form_blk">
            <div class="row">
                <div class="col-sm-4">
                    <div class="clssignup_secondary_form_blk_left">
                        <h1>Tell us a little about yourself.</h1>
                        <p>As your new pharmacy, we'll need a few details to help you get started. You’ll be able to
                            edit these
                            details
                            at any time from your account page.</p>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="clssignup_secondary_form_blk_right">
                        <div class="clssignup_step2_form">
                            <form [formGroup]="personalinfoForm" (ngSubmit)="onSubmit()" method="POST">
                                <h3>Personal Info</h3>
                                <div class="form-group">
                                    <label for="dob">Date of Birth</label>
                                    <div>
                                        <p-inputMask class="clsmedication" id="dob" formControlName="dob" mask="99/99/9999" placeholder="MM/DD/YYYY" >
                                        </p-inputMask>
                                        <!-- <input type="date" formControlName="dob" name="dob" class="form-control" placeholder="MM / DD / YYYY"> -->
                                        <!-- <p-calendar formControlName="dob" name dateFormat="mm-dd-yy" placeholder="mm-dd-yy" class="clsdate_picker" required=""></p-calendar> -->
                                        <!-- <control-messages [control]="personalinfoForm.controls.dob" class="valisure_error"></control-messages> -->
                                        <div *ngIf="personalinfoForm.controls.dob.hasError('invalidDate') &&  (personalinfoForm.controls.dob.touched && personalinfoForm.controls.dob.dirty )"
                                             class="alert alert-danger">
                                            Please enter a valid date of birth.
                                        </div>
                                        <div
                                                *ngIf="personalinfoForm?.controls.dob.hasError('required') &&  (personalinfoForm.controls.dob.touched && personalinfoForm.controls.dob.dirty )"
                                                class="alert alert-danger">
                                            This field is required for Rx.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Drug Allergies</label>
                                    <ul class="clsfeature_list">
                                        <li>
                                            <div class="checkbox">
                                                <input type="checkbox" formControlName="noallergies" name="noallergies"
                                                       id="noallergies">
                                                <label for="noallergies">
                                                    <span></span>
                                                    No drug allergies</label>
                                            </div>
                                        </li>
                                    </ul>
                                    <p-chips class="clschips" formControlName="allergies" addOnBlur=true></p-chips>
                                    <!-- <input class="form-control input-tags" [attr.disabled]="personalinfoForm.controls.allergiesw.value === true ? 'disabled' : null"
                                      formControlName="allergies" name="allergies" type="text" data-role="tagsinput" required> -->
                                    <!-- <control-messages [control]="personalinfoForm.controls.allergies" class="valisure_error"></control-messages> -->
                                    <div
                                            *ngIf="personalinfoForm?.controls.allergies.hasError('required') &&  (personalinfoForm.controls.allergies.touched && personalinfoForm.controls.allergies.dirty )"
                                            class="alert alert-danger">
                                        Required for Rx. Add allergies or check the box to confirm you have none.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Mailing Address</label>
                                    <!-- <input type="text" class="form-control" formControlName="address"
                                           id="exampleInputEmail1"
                                           placeholder=""> -->

                                    <app-address-autocomplete
                                            id="exampleInputEmail1"
                                            [formControl]="address"
                                            required
                                            (addressSelected)="onSuggestedAddressSelected($event)"
                                    ></app-address-autocomplete>

                                    <div *ngIf="address.invalid && (address.dirty && address.touched)"
                                         class="alert alert-danger">
                                        <div *ngIf="address.errors.required">
                                            Add your mailing address.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputEmail2">Mailing Address 2 <span>(optional)</span></label>
                                    <input type="text" class="form-control" formControlName="address_2"
                                           id="exampleInputEmail2"
                                           placeholder="">
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-lg-4">
                                        <div class="form-group">
                                            <label for="city">City</label>
                                            <input type="text" class="form-control" formControlName="city" id="city"
                                                   placeholder="">
                                            <div *ngIf="city.invalid && (city.dirty && city.touched)"
                                                 class="alert alert-danger">
                                                <div *ngIf="city.errors.required">
                                                    Add your city.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-8 col-lg-5">
                                        <div class="form-group">
                                            <label for="state">State</label>

                                            <p-dropdown scrollHeight="300px" [options]="states"
                                                        id="state"
                                                        name="profile_state"
                                                        class="valisure_select"
                                                        formControlName="state_id"></p-dropdown>
                                            <div *ngIf="state.invalid && (state.dirty && state.touched)"
                                                 class="alert alert-danger">
                                                <div *ngIf="state.errors.required">
                                                    Select State
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-lg-3">
                                        <div class="form-group">
                                            <label for="zip_code">Zip</label>
                                            <input type="text" maxlength="10" class="form-control" formControlName="zip" id="zip_code" (keypress)="onlyNumberKey($event)"
                                                   placeholder="10001">
                                            <div *ngIf="zip_code.invalid && (zip_code.dirty && zip_code.touched)"
                                                 class="alert alert-danger">
                                                <div *ngIf="zip_code.errors.required">
                                                    Add your zip code.
                                                </div>
                                                <div *ngIf="zip_code.errors.pattern">
                                                    Invalid zip code. 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!---------------------------------------------------------------
                                -
                                - INSURANCES
                                -
                                ---------------------------------------------------------------->

                                <h3>Insurance Info <span>(optional)</span></h3>
                                <div class="insurance-info">
                                    <p>
                    <span style="padding-right: 15px">
                      <img alt="" src="assets/images/info_icon.png" width="18">
                    </span>
                                        <span>
                      Please note: <strong>Currently we only accept a limited number of insurance providers and plans.</strong> Please speak with one of our pharmacists for more information.
                    </span>
                                    </p>
                                </div>


                                <ul class="clsfeature_list">
                                    <li>
                                        <div class="checkbox ">
                                            <input type="checkbox" formControlName="noinsurance"
                                                   id="no-insurance"><label for="no-insurance"><span></span>
                                            No Insurance</label>
                                        </div>
                                    </li>
                                </ul>

                                <div *ngIf="!personalinfoForm.get('noinsurance').value" formArrayName="insurances">

                                    <app-insurance-form
                                            *ngFor="let insurance of insurancesFormArray.controls; let i=index"
                                            [formGroupName]="i"
                                            [index]="i"
                                            [formGroup]="insurancesFormArray.controls[i]"
                                            [cardholderRelationship]="cardholderRelationship"
                                            [allowDelete]="insurancesFormArray.controls.length > 1"
                                            (onDelete)="onDeleteInsurance(i)"
                                    >
                                    </app-insurance-form>

                                    <div class="clsaddinsurance_button"
                                         *ngIf="insurancesFormArray.controls.length < 2">
                                        <a (click)="onAddNewInsurance()"><span>Add Insurance</span></a>
                                    </div>

                                    <div *ngIf="insuranceErrorMessage" >
                                        <div class="alert alert-danger" style="margin-top: 20px">
                                            {{ insuranceErrorMessage }}
                                        </div>
                                    </div>

                                </div>

                                <div *ngIf="errorMessage">
                                    <div  class="alert alert-danger" style="margin-top: 20px">
                                        {{ errorMessage }}
                                    </div>
                                </div>

                                <div class="clssignup_step2_form_content">
                                    <div class="clssignup_step2_form_content_btn">
                                        <button type="submit"
                                                class="btn btn-default clsyellow_btn"><span *ngIf="!loadingResponse">Save</span>
                                            <span
                                                    *ngIf="loadingResponse"><i class="fa fa-spinner fa-spin"></i> Please wait</span>
                                        </button>
                                        <span class="clslater_text">
                      <a [routerLink]="['/']">Finish later</a>
                    </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>


<div class="modal fade clsaddress_delete_confirm_modal" id="deleteInsuranceModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="vertical-alignment-helper">
        <div class="modal-dialog vertical-align-center" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">Are you sure you want to delete this insurance?</h4>
                </div>
                <div class="modal-body">
                    <a href="javascript:void(0)" data-dismiss="modal">No, Keep Insurance</a>
                    <button type="button" class="btn btn-primary clsyellow_btn" data-dismiss="modal" (click)="deleteInsurance()">Yes, Delete Insurance</button>
                </div>
            </div>
        </div>
    </div>
</div>