import {Component, OnInit, AfterViewInit, ViewChild, OnDestroy} from "@angular/core";
import {Router} from "@angular/router";
import {AccountService} from "../account.service";
import {SelectItem} from "primeng/api";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "@services/utils.service";
import {ServerResponse} from "../../model/base/server-response.model";
import {UserAddress, USState} from "../../user/model/user-address.model";
import { UserPaymentMethod } from "../../model/user/user-payment-method.model";
import {Address} from "@modules/checkout/models/address.model";
import {CardDetails} from "@modules/checkout/models/card-details.model";
import {environment} from "../../../environments/environment";

import {ValidationService} from "@services/validation.service";
import {FormatService} from "@services/format.service";
import {Subscription} from "rxjs";
import {AddressService} from "@modules/checkout/services/address.service";
import {ReCaptchaV3Service} from "ng-recaptcha";

declare const Heartland: any;

@Component({
    selector: "app-payment-methods",
    templateUrl: "./payment-methods.component.html",
    styleUrls: ["./payment-methods.component.css"]
})
export class PaymentMethodsComponent implements OnInit, OnDestroy, AfterViewInit {
    public userId: any = sessionStorage.getItem("userId");
    public showNewPaymentForm: any = false;
    public model: any = {};

    public paymentMethodsArray: any = [];
    public selectedIndex: any;
    public selectedValue: any = {};
    public stateObject: any = {};
    public deletePaymentMethodId: any;
    public stateArray: SelectItem[];
    public loadingResponse: boolean = false;
    public savingResponse: boolean = false;
    public loadedResponse = true;

    public states: any[];
    public addresses: any[] = [];
    public defaultBilling: any = '';
    public addressesSelect: any[] = [];
    private newAddress: any = {label: "Add new address", value: ""};
    public address: FormControl;
    public selectedAddress: any;
    public loadingAddresses = true;
    public newAddressError: any = false;

    private hps: any = null;
    public formGroup: FormGroup;
    public cardDetails: CardDetails = new CardDetails();
    public paymentError: string;


    @ViewChild("payment_form_profile") payment_form: HTMLFormElement;
    @ViewChild("payment_token_profile") payment_token: HTMLInputElement;

    constructor(private accountService: AccountService, private router: Router, private recaptchaV3Service: ReCaptchaV3Service) {
        this.stateArray = [];
    }

    ngOnInit() {

        this.formGroup = new FormGroup({
            address: new FormControl(this.defaultBilling, {
                updateOn: "change"
            }),
            cardToken: new FormControl(""),
            saveCreditCard: new FormControl(""),

            new_address: new FormGroup({
                "name": new FormControl("", Validators.required),
                "first_name": new FormControl("", Validators.required),
                "last_name": new FormControl("", Validators.required),
                "company": new FormControl(""),
                "email": new FormControl("", ValidationService.emailValidator),
                "phone": new FormControl("", [Validators.required, ValidationService.phoneValidator]),
                "address_line_1": new FormControl("", Validators.required),
                "address_line_2": new FormControl(""),
                "city": new FormControl("", Validators.required),
                "state_id": new FormControl("", {
                    validators: Validators.required,
                    updateOn: "change"
                }),
                "zip_code": new FormControl("", [Validators.required, Validators.pattern('[0-9]{5}(-[0-9]{4})?')]),
            }),

        });
        this.getPaymentsData();

        this.formGroup.get("address").valueChanges.subscribe(change => this.loadAddress(change));

        this.getAddressData();
        this.getStateLists();


    }
    ngOnDestroy() {
        if (this.hps !== null) this.hps.dispose();
    }

    ngAfterViewInit() {
        //this.getSavedCreditCards();
    }

    /**
     * Initiate heartland config
     */
    initiateHeartland() {
        this.hps = new Heartland.HPS({
            publicKey: environment.heartland_key,
            type: "iframe",
            fields: {
                cardNumber: {
                    target: "iframesCardNumber",
                    placeholder: "•••• •••• •••• ••••"
                },
                cardExpiration: {
                    target: "iframesCardExpiration",
                    placeholder: "MM / YYYY"
                },
                cardCvv: {
                    target: "iframesCardCvv",
                    placeholder: "CVV"
                }
            },
            style: {
                "#heartland-field": {
                    width: "calc(100% - 22px)",
                    "border-radius": "2px",
                    border: "1px solid lightgray",
                    color: "gray",
                    padding: "10px",
                    "font-size": "16px"
                }
            },
            onTokenSuccess: function (resp) {
                this.paymentError = null;
                //console.log(resp);

                this.recaptchaV3Service.execute('savePaymentMethod')
                    .subscribe((recaptchaToken) => {
                        this.saveToken(resp, recaptchaToken);
                    });
            }.bind(this),
            onTokenError: function (resp) {
                this.paymentError = resp.error.message;
                this.savingResponse = false;
            }.bind(this)
        });
        /*
        Heartland.Events.addHandler(
            "payment_form",
            "submit",
            function (e) {
                // Prevent the form from continuing to the `action` address
                e.preventDefault();
                // Tell the iframes to tokenize the data
                this.hps.Messages.post(
                    {
                        accumulateData: true,
                        action: "tokenize",
                        message: environment.heartland_key
                    },
                    "cardNumber"
                );
            }.bind(this)
        );
        */
        for (let i = 0; i < document.getElementsByTagName('iframe').length; i++) {
            document.getElementsByTagName("iframe")[i].style.width = "100%";
            document.getElementsByTagName("iframe")[i].style.height = "40px";
        }
    }
    public retriveHeartlandToken(){
        this.savingResponse = true;
        this.hps.Messages.post(
            {
                accumulateData: true,
                action: "tokenize",
                message: environment.heartland_key
            },
            "cardNumber"
        );

    }

    public saveToken(resp, recaptchaToken){
        const paymentObject = {
            address_id: this.formGroup.controls.address.value,
            user_id: this.userId, 
            card_type:  resp.card_type,
            exp_month:  resp.exp_month,
            exp_year:  resp.exp_year,
            last_four_digits:  resp.last_four,
            token: resp.token_value,
            recaptchaToken: recaptchaToken,
            default: this.formGroup.controls.saveCreditCard.value
        };
        this.accountService.addNewPaymentMethod(paymentObject).subscribe((response: ServerResponse<any>) => {
            if (response && response.status && response.status.success) {
                this.getPaymentsData();
                this.toogleNewPayment();
            }
            else {
                this.paymentError = response.status.message;
                if (response.status.errors && response.status.errors.recaptchaToken && response.status.errors.recaptchaToken.length) {
                    this.paymentError += " " + response.status.errors.recaptchaToken[0];
                }

            }
            this.savingResponse = false;
        }, (error) => {
            this.paymentError = "The payment method could not be added. Please review the card details.";
        });
    }

    public setDefaultPaymentOption(paymentMethodId){
        const paymentObject = {
            user_id: this.userId,
            saved_card_id: paymentMethodId
        };
        this.accountService.setDefaultPaymentMethod(paymentObject).subscribe((response: ServerResponse<any>) => {
            if (response && response.status && response.status.success) {
                this.getPaymentsData();
            }
        });
    }

     /**
     * Toggle new address
     */
    public toogleNewPayment() {
        this.showNewPaymentForm = !this.showNewPaymentForm;
         this.checkHeartlandForm();
    }

    /**
     * Get list of addresses
     */
    public getPaymentsData() {
        this.loadingResponse = true;
        this.accountService
            .getPaymentList(this.userId)
            .subscribe((response: ServerResponse<any>) => {
                this.paymentMethodsArray = response.data.user.paymentmethods;
                this.loadedResponse = true;
                this.loadingResponse = false;
            });
    }
    
    /**
     * Set delete address id
     * @param addressId - Address ID to delete
     */
    public deletePaymentWithId(paymentMethodId) {
        this.deletePaymentMethodId = paymentMethodId;
    }

    /**
     * Delete address
     */
    public deletePaymentMethod() {
        this.accountService
            .deletePaymentMethod({id: this.deletePaymentMethodId, customer_id: this.userId})
            .subscribe((response: any) => {
                this.getPaymentsData();
            });
    }

    /**
     * Get New address Form Group
     */
    get newAddressFormGroup() {
        return this.formGroup.get('new_address') as FormGroup;
    }

    /**
     * Load selected address
     */
    private loadAddress(value) {
        if (!value) {
            this.selectedAddress = null;
            Object.keys(this.newAddressFormGroup.controls).forEach((control) => {
                this.newAddressFormGroup.get(control).reset();
            });
        } else {
            this.selectedAddress = this.addresses.filter((addr, index) => {
                return addr && addr.id && (addr.id.toString() === value.toString());
            }).pop();
            /**
             * If the value does not exist in the list of addresses (eg: because it was deleted after being selected and saved into the session),
             * default to the first one if it exists
             */
            if (!this.selectedAddress && this.addresses.length) {
                this.formGroup.get("address").setValue(this.addresses[0].id);
            }
        }

        this.checkHeartlandForm();

    }


    public checkHeartlandForm() {
        /**
         * When the selected address value is toggled, verify if the Heartland form should be reinitialized
         */
        if (this.showNewPaymentForm && this.selectedAddress) {
            if (!this.hps) {
                setTimeout(() => {
                    this.initiateHeartland();
                }, 10);
            }
        }else{
            if (this.hps !== null) {
                this.hps.dispose();
                this.hps = null;
            }
        }
    }

    /**
     * Get list of addresses
     */
    public getAddressData() {
        this.accountService
            .getAddressList(this.userId)
            .subscribe((response: ServerResponse<any>) => {

                this.addresses = response.data.user.addresses.concat(<any[]>[this.newAddress]);

                this.addressesSelect = [];
                response.data.user.addresses.forEach((value, index) => {
                    this.addressesSelect.push({
                        label: value.name,
                        value: value.id
                    });
                });
                this.addressesSelect.push(this.newAddress);

                this.addresses.map((address, idx) => {
                    if (address.default_billing === 1) {
                        this.defaultBilling = address.id;
                    }
                });

                // Fallback to first address
                if (!this.defaultBilling && this.addresses.length) {
                    this.defaultBilling = this.addresses[0].id;
                }

                if (this.defaultBilling) {
                    this.formGroup.get("address").setValue(this.defaultBilling.toString());
                }

                this.loadingAddresses = false;
            });
    }

    /**
     * Get list of states
     */
    public getStateLists() {
        this.accountService.getStateLists().subscribe((response: ServerResponse<USState[]>) => {
            this.states = response.data.map((st) => {
                return {
                    value: st.id,
                    label: st.name
                };
            });
            this.states.splice(0, 0, {value: '', label: 'Select State'});
        });
    }


    public save() {
        if (!this.selectedAddress) {
            this.addNewAddress(this.newAddressFormGroup);
        }
        else {
            this.retriveHeartlandToken();
        }
    }


    /**
     * Add new address
     * @param formGroup - New address form
     */
    public addNewAddress(formGroup: FormGroup) {

        if (!formGroup.valid) {
            UtilsService.markFormGroupTouched(formGroup);
            return;
        }

        const addressObject = {
            user_id: this.userId,
            name: formGroup.get('name').value,
            address_line_1: formGroup.get('address_line_1').value,
            //address_line_2: formGroup.get('address_line_2').value,
            city: formGroup.get('city').value,
            zip_code: formGroup.get('zip_code').value,
            state_id: formGroup.get('state_id').value,
            country: "",
            first_name: formGroup.get('first_name').value,
            last_name: formGroup.get('last_name').value,
            //email: formGroup.get('email').value,
            company: formGroup.get('company').value,
            phone: FormatService.formatPhoneForSave(formGroup.get('phone').value),
            phonetype_id: 1,
            is_active: 1
        };

        this.loadingResponse = true;

        this.accountService.addNewAddress(addressObject).subscribe((response: ServerResponse<UserAddress>) => {
            if (response && response.status && response.status.success) {
                this.addresses = [response.data].concat(this.addresses);
                this.addressesSelect.splice(0, 0, {
                    label: response.data.name,
                    value: response.data.id
                });
                this.formGroup.get("address").setValue(response.data.id.toString());
                formGroup.reset();

                this.loadingResponse = false;
            }
            else {
                if (response.status) {
                    this.newAddressError = response.status.message;
                }
                else {
                    this.newAddressError = 'The address could not be saved.';
                }
            }
        });
    }

    public checkExpiration(paymentMethod){
        if (paymentMethod.exp_month && paymentMethod.exp_year ){
            let exp_month = parseInt(paymentMethod.exp_month);
            let exp_year = parseInt(paymentMethod.exp_year);

            var d = new Date();
            if ( d.getFullYear() > exp_year){
                return false;
            }
            if ( d.getFullYear() === exp_year &&  (d.getMonth() + 1) > exp_month){
                return false;
            }
        }

        return true;
    }

}
