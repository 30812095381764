import { Component, OnInit } from '@angular/core';
import { AccountService } from '../account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-saved-order',
  templateUrl: './saved-order.component.html',
  styleUrls: ['./saved-order.component.css']
})
export class SavedOrderComponent implements OnInit {
  public ordersArray: any = [];
  public selectedLimit = 5;
  public selectedPage = 1;
  public savedOrderPage = 0;
  public disableNext: boolean = false;

  constructor(private accountService: AccountService, private router: Router) { }

  ngOnInit() {
    this.getOrderLists('saved');
  }

  /**
   * Fetches orders
   */
  public getOrderLists(setsave) {
    let pageData = {
      'limit': this.selectedLimit,
      'page': this.selectedPage
    }
    this.accountService.getOrderList(setsave, pageData)
      .subscribe((response: any) => {
        this.ordersArray = response.data.orders;
        this.savedOrderPage = response.data.meta.total;
      });
  }

  /**
   * Navigate to order details page
   * @param order - Order
   */
  public viewOrderDetail(order) {
    this.router.navigate(['/account/orderDetail', order.id]);
  }

  /**
   * Save order 
   * @param orderId - Order id
   */
  public removeOrder(orderId) {
    this.accountService.saveOrder(orderId, false)
      .subscribe((response: any) => {
        this.getOrderLists('saved');
      });
  }

  /**
   * Get number of pages
   */
  public getPages(): number[] {
    if (!this.savedOrderPage) {
      return [];
    }

    let numberOfPages = this.savedOrderPage / this.selectedLimit;

    if (!Number.isInteger(numberOfPages)) {
      numberOfPages = Math.ceil(numberOfPages);
    }

    const pages = [];
    for (let i = 1; i < numberOfPages + 1; i++) {
      pages.push(i);
    }

    return pages;
  }

  /**
   * Select page index
   * @param page - Page index
   */
  public selectPage(page) {
    if (
      page &&
      this.savedOrderPage &&
      this.savedOrderPage >= page - 1 * this.selectedLimit
    ) {
      this.selectedPage = page;
      this.getOrderLists('saved');
    }
  }
  
  /**
   * Select previous page
   */
  public prevPage() {
    if (this.selectedPage > 1) {
        this.selectPage(this.selectedPage - 1);
    }
  }
 
  /**
   * Select nextp age
   */
  public nextPage() {
    if ((Math.ceil(this.savedOrderPage / this.selectedLimit) === this.selectedPage)) {
      this.disableNext = true;
    } else {
      this.selectPage(this.selectedPage + 1);
    }
  }
 
  /**
   * Check if current limit equals selected limit
   * @param limit - Current limit
   */
  public isLimitActive(limit): boolean {
    return this.selectedLimit === limit;
  }
}
