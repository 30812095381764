import {Injectable} from "@angular/core";
import {CartProduct} from "../models/cart-product.model";
import {PromoCode} from "../models/cart-promocode.model";
import {ProductDetails} from "@modules/shop/models";
import {ProductVariant} from "@modules/shop/models/product-variant.model";
import {Subject} from "rxjs";
import {UtilsService} from "@services/utils.service";

declare var $: any;

@Injectable()
export class CartService {
    private cartProductsSubject: Subject<CartProduct[]>;
    private cartProducts: CartProduct[] = [];
    private cartPromoCodeSubject: Subject<PromoCode>;
    private productAddedSubject: Subject<boolean>;
    private promocode: PromoCode = null;

    private cartReady = false;
    private orderIsRx = false;
    private productAddedPopup = false;

    constructor(
        ) {
        this.cartProductsSubject = new Subject<CartProduct[]>();
        this.cartPromoCodeSubject = new Subject<PromoCode>();
        this.productAddedSubject = new Subject<any>();

        const savedCartData = sessionStorage.getItem("cart");
        const savedPromocodeData = sessionStorage.getItem("promocode");
        if (savedCartData || savedPromocodeData) {
            if (savedCartData) {
                this.cartProducts = JSON.parse(savedCartData);
                this.checkIfOrderIsRx();
            }

            if (savedPromocodeData) {
                this.promocode = JSON.parse(savedPromocodeData);
            }

            this.updateCart();
        }

        this.cartReady = true;
    }

    public getProductAddedPopup(){
      return this.productAddedPopup;
    }
    /**
     * Get cart products
     */
    public getProducts() {
        return this.cartProducts;
    }

     /**
     * Get cart promocode
     */
    public getPromoCode() {
        return this.promocode;
    }


    /**
     * Returns rx status of the order
     * @returns {boolean}
     */
    public getOrderIsRx() {
        return this.orderIsRx;
    }

    /**
     * Add promo code  to cart
     * @param code - Code to be added
     */
    public addPromoCode(code: PromoCode) {
        this.promocode = code;
        this.updateCart();
    }

    /**
     * Remove promo code from cart
     * @param code - Code to be added
     */
    public removePromoCode() {
        this.promocode = null;
        this.updateCart();
    }

    /**
     * Add product to cart
     * @param product - Product to be added
     * @param quantity - Quantity of the product
     * @param price - Total price
     */
    public addProduct(product: ProductDetails, productVariant: ProductVariant, quantity: number, pricePerItem: number, price: number) {
        const existingProductIndex = this.cartProducts.findIndex(function (cartProduct: CartProduct) {
            if (product.rx) return cartProduct.product.id === product.id;
            else {
                if (cartProduct.product.id === product.id && !cartProduct.product.hasOwnProperty('medication_testing')) {
                    if (cartProduct.productVariant) {
                        return cartProduct.productVariant.id === productVariant.id;
                    }
                    else return true;
                }
            }
            return false;
        });
        if (existingProductIndex >= 0 && !product.hasOwnProperty('medication_testing')) {
            const existingProduct = this.cartProducts[existingProductIndex];
            if (product.rx) {
                existingProduct.quantity += quantity;
                const productRange = this.getProductPriceRange(existingProduct.product, existingProduct.quantity);
                // console.log(productRange);
                if (productRange == null) {
                    existingProduct.price = UtilsService.roundPrice(existingProduct.price * existingProduct.quantity);
                } else {
                    existingProduct.price = UtilsService.roundPrice(productRange.price * existingProduct.quantity);
                    existingProduct.pricePerItem = productRange.price;
                }
                // console.log(existingProduct);

                this.cartProducts[existingProductIndex] = existingProduct;
            } else {
                existingProduct.quantity += quantity;
                existingProduct.price = UtilsService.roundPrice(((existingProduct.productVariant == null) ? existingProduct.product.price : existingProduct.productVariant.price) * existingProduct.quantity);

                this.cartProducts[existingProductIndex] = existingProduct;
            }

        } else {
            product = this.getProductCopy( Object.assign({}, product));
            this.cartProducts.push({
                id: product.id,
                product,
                productVariant,
                quantity,
                pricePerItem,
                price
            });
        }

        /**
        // Check if product already exists and fetch it's index
        const existingProductIndex = this.cartProducts.findIndex(
          (cartProduct: CartProduct) => cartProduct.product.id == product.id
        );
        const productVariantsCopy = [...product.variants];
        const productVariants = productVariantsCopy.sort(
          (variant1, variant2) => variant2.quantity - variant1.quantity
        );
        // If product exists
        if (existingProductIndex >= 0 && quantity && price) {
          const existingProduct = this.cartProducts[existingProductIndex];
          const newQuantity = existingProduct.quantity + quantity;
          const selectedProductVariant = productVariants.find(
            productVariant => productVariant.quantity <= newQuantity
          );
          const newPrice = selectedProductVariant
            ? selectedProductVariant.price / selectedProductVariant.quantity
            : product.price;

          // Update quantity and price
          this.cartProducts[existingProductIndex].price = newQuantity * newPrice;
          this.cartProducts[existingProductIndex].quantity = newQuantity;
        } else {
          const selectedProductVariant = productVariants.find(
            productVariant => productVariant.quantity <= quantity
          );
          const newPrice = selectedProductVariant
            ? selectedProductVariant.price / selectedProductVariant.quantity
            : product.price;

          this.cartProducts.push({
            id: product.id,
            product,
            quantity,
            price: quantity * newPrice
          });
        }
        */
        this.checkIfOrderIsRx();
        this.updateCart();
    }

    public updateProduct(product: ProductDetails, productVariant: ProductVariant, quantity: number, pricePerItem: number, price: number) {
      const existingProductIndex = this.cartProducts.findIndex(function (cartProduct: CartProduct) {
          if (product.rx) return cartProduct.product.id === product.id;
          else {
              if (cartProduct.product.id === product.id && !cartProduct.product.hasOwnProperty('medication_testing')) {
                  if (cartProduct.productVariant) {
                      return cartProduct.productVariant.id === productVariant.id;
                  }
                  else return true;
              }
          }
          return false;
      });
      if (existingProductIndex >= 0 && !product.hasOwnProperty('medication_testing')) {
          this.cartProducts[existingProductIndex] = {
            id: product.id,
            product,
            productVariant,
            quantity,
            pricePerItem,
            price
        };
      }
      this.checkIfOrderIsRx();
      this.updateCart();
    }

    public openCartBox(args = null){

      this.productAddedSubject.next(args);
      /*
      const element: HTMLElement = document.getElementById('cartdropdown') as HTMLElement;
      const event = new Event('click');
      $(element).click(function(){
        alert('gata');
      });
      //$('#cartdropdown').trigger('click.bs.dropdown');


      //$(".clscart_dropdown").addClass("open");
      //$('#cartdropdown')[0].click();
      console.log($('.clscart_dropdown').find('.dropdown-cart').is(":hidden"));
      console.log($('#cartdropdown')[0]);
      if ($('.clscart_dropdown').find('.dropdown-cart').is(":hidden")){
        //$('.clscart_dropdown').trigger('click.bs.dropdown');
        //$('#cartdropdown').trigger('click.bs.dropdown');
        console.log($('.clscart_dropdown').find('.clscart_dropdown'));
        $('.clscart_dropdown').find('.clscart_dropdown').dropdown('open');
        //.dropdown('toggle');
      }
      setTimeout(function(){
        this.closeCartBox();
      }.bind(this), 3000);
      */
    }


    public closeCartBox(){
      
      //if ($(".clscart_dropdown")[0].hasClass('open')){
      //  $(".clscart_dropdown")[0].removeClass('open');
      //}
    }
    private getProductCopy(product: ProductDetails) {
        return product;
    }

    private getProductPriceRange(product: ProductDetails, quantity) {

        return product.variants.sort(function (a, b) {
            return a.quantity - b.quantity;
        }).reduce(function (variantSearched, variant, index) {
            if (variantSearched.quantity >= variant.quantity) {
                variantSearched.variant = variant;
            }
            return variantSearched;
        }, {variant: null, quantity: quantity}).variant;
    }

    /**
     * Internal function to add all products and variants in the same array
     */
    public getFlattenedProducts(): CartProduct[] {
        const cartProducts = [].concat(...this.cartProducts);
        return cartProducts.filter(cartProduct => cartProduct.quantity);
    }

    /**
     * Remove product from cart
     * @param id - Product ID
     */
    public removeProduct(id: number) {
        this.cartProducts.splice(id, 1);
        /*
        this.cartProducts.forEach((cartProduct: CartProduct, index: number) => {
          if (cartProduct.product.id == id) {
            this.cartProducts[index].price = 0;
            this.cartProducts[index].quantity = 0;
          }
        });
        this.cartProducts = this.cartProducts.filter(
          cartProduct => cartProduct.quantity != 0
        );
        */
        this.checkIfOrderIsRx();
        this.updateCart();
    }

    /**
     * Empty cart - removing all products
     */
    public clearCart() {
        this.cartProducts = [];
        this.promocode = null;
        this.orderIsRx = false;
        this.updateCart();
    }

    public checkIfOrderIsRx() {
        this.orderIsRx = false;
        this.cartProducts.forEach((cartProduct: CartProduct) => {
            if (cartProduct.product.rx) {
                this.orderIsRx = true;
            }
        });
    }

    /**
     * Update cart
     */
    public updateCart() {
        if (this.cartReady) {
            sessionStorage.setItem("cart", JSON.stringify(this.cartProducts));
            sessionStorage.setItem("promocode", JSON.stringify(this.promocode));
        }
        this.cartProductsSubject.next(this.cartProducts);
        this.cartPromoCodeSubject.next(this.promocode);
        
    }

    /**
     * Internal function to get product rxjs subject
     */
    public getProductAddedSubject(): Subject<boolean> {
      return this.productAddedSubject;
    }

    /**
     * Internal function to get product rxjs subject
     */
    public getCartProductsSubject(): Subject<CartProduct[]> {
        return this.cartProductsSubject;
    }

    /**
     * Internal function to get promocode rxjs subject
     */
    public getPromoCodeSubject(): Subject<PromoCode> {
        return this.cartPromoCodeSubject;
    }

    /**
   * Check if shipping is selected
   */
  public getSavedShipping(): any {
    const savedCartDetailsData = sessionStorage.getItem("cart_details");
    if (savedCartDetailsData) {
        const data = JSON.parse(savedCartDetailsData);
        if (data) {
            if (data.shippingOption) {
                return data.shippingOption;
            }
        }
    }
    
    return null;    
  }
    
    /**
   * Check if shipping is selected
   */
  public hasShipping(): boolean {
    return this.getSavedShipping() != null ? true : false;
  }

  /**
   * Get shipping details
   */
  public getShippingDetails(): any {
    return this.getSavedShipping();
  }

  /**
   * Get shipping options
   */
  public getShipping(): number {
    return this.hasShipping() ? this.getShippingDetails().price : 0.00;
  }

  /**
   * Get subtotal price value
   */
  public getSubtotalValue(): number {
    return this.cartProducts.map(product => product.price).reduce((f, s) => f + s, 0);
  }

  /**
   * Get subtotal otc price value
   */
  public getSubtotalOTCValue(): number {
    return this.cartProducts.reduce(function(f, s) { 
      if (!s.product.rx) { return f + s.price; }
      return f;
    }, 0);
  }

  /**
   * Get subtotal rx price value
   */
  public getSubtotalRXValue(): number {
    return this.cartProducts.reduce(function(f, s) { 
      if (s.product.rx) { return f + s.price; }
      return f;
    }, 0);
  }

  /**
   * Get subtotal price
   */
  public getSubtotal(): number {
    return this.getSubtotalValue();
  }

  /**
   * Get total price
   */
  public getTotal(): number {
    return this.getSubtotalOTCValue() + this.getSubtotalRXValue() - this.getDiscountAmountValue() - this.getDiscountShippingValue() + this.getShipping();
  }
  
  /**
   * Check if has FREE SHIPPING amount
   */
  public hasDiscountShipping(): boolean {
    if (this.promocode == null) return false;
    if (this.promocode.type !== 'freeshipping' && this.promocode.type !== 'both') return false;
    return true;
  }


  /**
   * check if Discount freeshipping is Applicable
   */
  public isDiscountShippingAppliable(): boolean {
    if (!this.hasDiscountShipping()) return false;
    if (!this.hasShipping()) return false;
    if (!this.promocode.carriers.map(function(carrier){ return carrier.id; }).includes(this.getShippingDetails().id)) return false;
    return true;
  }


  /**
   * Get Discount freeshipping value
   */
  public getDiscountShippingValue(): number {
    if (!this.hasDiscountShipping()) return 0.00;
    if (!this.hasShipping()) return 0.00;
    if (!this.isDiscountShippingAppliable()) return 0.00;
    return this.getShipping();
  }

  /**
   * Check if has Discount amount
   */
  public hasDiscountAmount(): boolean {
    if (this.promocode == null) return false;
    if (this.promocode.type !== 'amount' && this.promocode.type !== 'both') return false;
    return true;
  }

  /**
   * check if Discount amount is Applicable
   */
  public isDiscountAmountAppliable(): boolean {
    if (!this.hasDiscountAmount()) return false;
    if (this.promocode.when_total_over >= this.getSubtotalOTCValue()) return false;
    return true;
  }


  /**
   * Get Discount amount value
   */
  public getDiscountAmountValue(): number {
    if (!this.hasDiscountAmount()) return 0.00;
    if (!this.isDiscountAmountAppliable()) return 0.00;
    if (this.promocode.discount_type === '$') return (this.promocode.discount_amount > this.getSubtotalOTCValue()) ?  this.getSubtotalOTCValue() : this.promocode.discount_amount;
    if (this.promocode.discount_type === '%') return ((this.getSubtotalOTCValue() / 100) *  this.promocode.discount_amount);
    return 0.00;
  }

  public getDiscountValue(): number{
    if (this.hasDiscountAmount()) return this.getDiscountAmountValue();
    if (this.hasDiscountShipping()) return this.getDiscountShippingValue();
    return 0.00;
  }

}
