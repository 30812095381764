import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, zip} from "rxjs";
import {ServerResponse} from "../../../model/base/server-response.model";
import {DoomsdayService} from "@modules/doomsday/services/doomsday.service";
import {map, take} from "rxjs/operators";
import DoomsdayStatus from "@modules/doomsday/model/doomsdaystatus.model";

@Injectable()
export class DoomsdayResolver implements Resolve<DoomsdayStatus> {
    constructor(private doomsdaySvc: DoomsdayService,
                private router: Router,
                private activeRoute: ActivatedRoute) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DoomsdayStatus> {

        let bs: Observable<DoomsdayStatus>;

        bs = this.doomsdaySvc.check().pipe(map((response: ServerResponse<DoomsdayStatus>) => {
            return response.data;
        }));

        return bs;
    }
}
