import { OnInit, Renderer2, ElementRef } from "@angular/core";
import { AccountService } from "../account.service";
import { UserService } from "./../../shared/user.service";
import drugList from "../../../app/drug-list";
import { DatePipe } from "@angular/common";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { UtilsService } from "@services/utils.service";
import { FormatService } from "@services/format.service";
import { ValidationService } from "@services/validation.service";
var ProfileComponent = /** @class */ (function () {
    function ProfileComponent(formBuilder, accountService, userService, renderer, el, datePipe, http) {
        this.formBuilder = formBuilder;
        this.accountService = accountService;
        this.userService = userService;
        this.renderer = renderer;
        this.el = el;
        this.datePipe = datePipe;
        this.http = http;
        this.profileSaved = false;
        this.userId = sessionStorage.getItem("userId");
        this.userDetailObj = {};
        this.products = [];
        this.filteredProducts = [];
        this.rootUrl = sessionStorage.getItem("location");
        this.insurances = [];
        this.errorMessage = '';
        this.insuranceErrorMessage = '';
        this.insuranceIndexToDelete = null;
        this.loadingResponse = false;
        this.createForm();
    }
    ProfileComponent.prototype.ngOnInit = function () {
        this.currentUserID = sessionStorage.getItem("userId");
        this.getUserDetailsData();
        this.products = drugList.map(function (product) { return product.scientific_name; });
        this.filteredProducts = this.products;
        this.getCardRelationshipOptions();
    };
    Object.defineProperty(ProfileComponent.prototype, "insurancesFormArray", {
        /**
         * Insurances Form Array
         */
        get: function () {
            return this.personalinfoForm.get('insurances');
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Create profile form group
     */
    ProfileComponent.prototype.createForm = function () {
        this.personalinfoForm = this.formBuilder.group({
            dob: ["", [Validators.required, ValidationService.dateValidator]],
            first_name: ["", [Validators.required]],
            last_name: ["", [Validators.required]],
            phone: ["", [Validators.required, ValidationService.phoneValidator]],
            desired_meds: ["", {
                    validators: [],
                    updateOn: "change"
                }],
            allergies: ["", {
                    validators: [Validators.required],
                    updateOn: "change"
                }],
            noallergies: ["", { updateOn: "change" }],
            noinsurance: ["", {
                    updateOn: "change"
                }],
            insurances: this.formBuilder.array([], Validators.required),
        }, { updateOn: "blur" });
        this.setDynamicValidators();
    };
    /**
     * Create insurance form group
     * @param {UserInsurance} data
     * @returns {FormGroup}
     */
    ProfileComponent.prototype.getInsuranceFormGroup = function (data) {
        if (data === void 0) { data = null; }
        return this.formBuilder.group({
            id: [data ? data.id : ""],
            company: [data ? data.company : "", data ? Validators.required : null],
            id_member: [data ? data.id_member : "", data ? Validators.required : null],
            rx_grp: [data ? data.rx_grp : ""],
            rx_bin: [data ? data.rx_bin : ""],
            rx_pcn: [data ? data.rx_pcn : ""],
            cardholder_id: [data ? data.cardholder_id : "", {
                    updateOn: "change",
                    validators: data ? Validators.required : null
                }]
        }, { updateOn: "blur" });
    };
    /**
     * Controls dynamic validators
     */
    ProfileComponent.prototype.setDynamicValidators = function () {
        var _this = this;
        var allergies = this.personalinfoForm.get("allergies");
        this.personalinfoForm.get("noallergies").valueChanges
            .subscribe(function (value) {
            if (!value) {
                allergies.enable();
                allergies.setValidators([Validators.required]);
            }
            else {
                allergies.disable();
                allergies.clearValidators();
            }
            allergies.updateValueAndValidity();
        });
        this.personalinfoForm.get("noinsurance").valueChanges
            .subscribe(function (value) {
            _this.insuranceErrorMessage = "";
            if (!value) {
                _this.insurancesFormArray.setValidators([Validators.required]);
                _this.insurancesFormArray.controls.forEach(function (insuranceFormGroup) {
                    insuranceFormGroup.get("company").setValidators([Validators.required]);
                    insuranceFormGroup.get("id_member").setValidators([Validators.required]);
                    insuranceFormGroup.get("cardholder_id").setValidators([Validators.required]);
                });
            }
            else {
                _this.insurancesFormArray.clearValidators();
                _this.insurancesFormArray.controls.forEach(function (insuranceFormGroup) {
                    insuranceFormGroup.get("company").setValidators(null);
                    insuranceFormGroup.get("id_member").setValidators(null);
                    insuranceFormGroup.get("cardholder_id").setValidators(null);
                    insuranceFormGroup.get("company").updateValueAndValidity();
                    insuranceFormGroup.get("id_member").updateValueAndValidity();
                    insuranceFormGroup.get("cardholder_id").updateValueAndValidity();
                });
                UtilsService.markFormGroupPristine(_this.insurancesFormArray);
            }
            _this.insurancesFormArray.updateValueAndValidity();
        });
        this.insurancesFormArray.valueChanges.subscribe(function (value) {
            _this.insuranceErrorMessage = "";
        });
    };
    ProfileComponent.prototype.onAddNewInsurance = function () {
        if (this.insurancesFormArray.length < 2) {
            this.insurancesFormArray.push(this.getInsuranceFormGroup());
            this.personalinfoForm.get('noinsurance').setValue(false);
        }
    };
    ProfileComponent.prototype.onDeleteInsurance = function (index) {
        this.insuranceIndexToDelete = index;
    };
    ProfileComponent.prototype.deleteInsurance = function () {
        if (this.insuranceIndexToDelete !== null) {
            this.insurancesFormArray.removeAt(this.insuranceIndexToDelete);
            this.insuranceIndexToDelete = null;
            if (this.insurancesFormArray.controls.length === 0) {
                // If no mor insurances, set true the "noinsurance" flag
                this.personalinfoForm.get('noinsurance').setValue(true);
            }
        }
    };
    /**
     * Map the user data to the profile form
     * @param {User} data
     */
    ProfileComponent.prototype.processUserDetails = function (data) {
        var _this = this;
        this.userDetailObj = data;
        while (this.insurancesFormArray.controls.length) {
            this.insurancesFormArray.removeAt(0);
        }
        this.personalinfoForm.reset();
        this.personalinfoForm.patchValue({
            "first_name": this.userDetailObj.first_name,
            "last_name": this.userDetailObj.last_name,
            "phone": FormatService.formatPhoneForDisplay(this.userDetailObj.phone),
            "desired_meds": this.userDetailObj.medical_info.desired_meds ? this.userDetailObj.medical_info.desired_meds.split(",").filter(function (el) { return !!el; }) : [],
            "dob": this.datePipe.transform(this.userDetailObj.birth_date, "MM/dd/yyyy"),
            "noallergies": this.userDetailObj.medical_info.allergies === 'No drug allergies',
            "allergies": this.userDetailObj.medical_info.allergies && this.userDetailObj.medical_info.allergies !== 'No drug allergies' ? this.userDetailObj.medical_info.allergies.split(",").filter(function (el) { return !!el; }) : [],
            "noinsurance": this.userDetailObj.no_insurance || !this.userDetailObj.insurances.length,
        });
        if (this.userDetailObj.insurances && this.userDetailObj.insurances.length) {
            this.userDetailObj.insurances.map(function (insurance) {
                _this.insurancesFormArray.push(_this.getInsuranceFormGroup(insurance));
            });
        }
    };
    /**
     * Get profile data
     */
    ProfileComponent.prototype.getUserDetailsData = function () {
        var _this = this;
        this.userService.getUserDetails()
            .subscribe(function (response) {
            if (response.status.success) {
                if (response.data) {
                    _this.processUserDetails(response.data.user);
                }
            }
        }, function (error) {
        });
    };
    /**
     * Update profile info
     */
    ProfileComponent.prototype.onSubmit = function () {
        var _this = this;
        this.errorMessage = '';
        if (!this.personalinfoForm.valid) {
            UtilsService.markFormGroupTouched(this.personalinfoForm);
            if (this.insurancesFormArray.invalid && !this.personalinfoForm.get('noinsurance').value) {
                this.insuranceErrorMessage = 'Please complete insurance info or select "No Insurance"';
            }
            return;
        }
        this.personalinfoForm.value["user_id"] = sessionStorage.getItem("userId");
        var personalInfo = Object.assign({}, this.personalinfoForm.value);
        if (this.personalinfoForm.get("noallergies").value) {
            personalInfo.allergies = "No drug allergies";
        }
        else {
            personalInfo.allergies = this.personalinfoForm.get("allergies").value.join(',');
        }
        personalInfo.desired_meds = this.personalinfoForm.get("desired_meds").value.join(',');
        personalInfo.dob = FormatService.formatDateToISO(this.personalinfoForm.get('dob').value, "/", "-");
        personalInfo.phone = FormatService.formatPhoneForSave(this.personalinfoForm.get('phone').value);
        this.loadingResponse = true;
        this.accountService.updateProfileInfo(personalInfo)
            .subscribe(function (response) {
            _this.loadingResponse = false;
            if (response.status.success) {
                _this.profileSaved = true;
                if (response.data) {
                    _this.processUserDetails(response.data);
                }
                setTimeout(function () {
                    _this.profileSaved = false;
                }, 3000);
            }
            else {
                if (response.status.errors && response.status.errors.phone) {
                    if (response.status.errors.phone.length) {
                        _this.personalinfoForm.get('phone').setErrors({ 'used': true });
                        _this.personalinfoForm.get('phone').markAsTouched();
                        _this.personalinfoForm.get('phone').markAsDirty();
                    }
                }
                _this.errorMessage = response.status.message;
            }
        }, function (err) {
            _this.loadingResponse = false;
        });
    };
    /**
     * Send reset link
     */
    ProfileComponent.prototype.forgotPassword = function () {
        var emailObject = {
            "email": this.userDetailObj.email
        };
        this.userService.forgotPassword(emailObject)
            .subscribe(function (response) {
        });
    };
    /**
     * Filter products based on event query
     * @param event - Event query
     */
    ProfileComponent.prototype.filterProducts = function (event) {
        var query = event.query;
        this.filteredProducts = this.filterProduct(query, this.products);
    };
    /**
     * Filter given products based on filter
     * @param query - Filter query
     * @param products - Products
     */
    ProfileComponent.prototype.filterProduct = function (query, products) {
        var filtered = [];
        products.forEach(function (product) {
            return product.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
                filtered.push(product);
        });
        if (!filtered.length) {
            filtered.push(query);
        }
        return filtered;
    };
    /**
     * Fetch "insurance card relationships" options
     */
    ProfileComponent.prototype.getCardRelationshipOptions = function () {
        var _this = this;
        this.cardholderRelationship = [];
        this.cardholderRelationship.push({ label: "Please select", value: "" });
        this.http
            .get(this.rootUrl + "/cardholder-relationship")
            .subscribe(function (response) {
            if (response.status.success) {
                response.data.forEach(function (value) {
                    _this.cardholderRelationship.push({
                        label: value.name,
                        value: value.id
                    });
                });
            }
        });
    };
    return ProfileComponent;
}());
export { ProfileComponent };
