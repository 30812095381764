/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./order-history-display.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./order-history-display.component";
import * as i4 from "@angular/router";
var styles_OrderHistoryDisplayComponent = [i0.styles];
var RenderType_OrderHistoryDisplayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderHistoryDisplayComponent, data: {} });
export { RenderType_OrderHistoryDisplayComponent as RenderType_OrderHistoryDisplayComponent };
export function View_OrderHistoryDisplayComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 19, "div", [["class", "orderHistoryDisplay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "cursor-hand viewOrderLink"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewOrderDetail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["View Order"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "orderNumber"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewOrderDetail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["#", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 14, "div", [["class", "orderHistoryDetails"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PLACED"])), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵppd(12, 2), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["TOTAL"])), (_l()(), i1.ɵted(16, null, [" ", " "])), i1.ɵppd(17, 1), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["STATUS"])), (_l()(), i1.ɵted(21, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.order.order_number; _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), _co.order.created, "MM/dd/yyyy")); _ck(_v, 11, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v, 1), _co.order.total_amount)); _ck(_v, 16, 0, currVal_2); var currVal_3 = (_co.order.status && _co.order.status.name); _ck(_v, 21, 0, currVal_3); }); }
export function View_OrderHistoryDisplayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-order-history-display", [], null, null, null, View_OrderHistoryDisplayComponent_0, RenderType_OrderHistoryDisplayComponent)), i1.ɵdid(1, 114688, null, 0, i3.OrderHistoryDisplayComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrderHistoryDisplayComponentNgFactory = i1.ɵccf("app-order-history-display", i3.OrderHistoryDisplayComponent, View_OrderHistoryDisplayComponent_Host_0, { order: "order" }, {}, []);
export { OrderHistoryDisplayComponentNgFactory as OrderHistoryDisplayComponentNgFactory };
