<div class="clswrapper">
  <div class=""></div>
  <!-- Main container -->
  <main class="clsmain_container clsuser_home_container">
    <div class="clsuser_help_blk">
      <div class="container">
        <h1>Hi {{userName}}. How can we help you today?</h1>
        <ul *ngIf="!isCapitalRx || true">
          
          <li>
            <div class="clsuser_help_box">
              <a routerLink="/shop/products/rx">
                <figure><img src="assets/images/home-v7-shop-rx@2x.png" alt="" /></figure>
                <h5>Shop Rx</h5>
              </a>
            </div>
          </li>
          <li>
            <div class="clsuser_help_box">
              <a [routerLink]="['/shop/products/otc']">
                <figure><img src="assets/images/home-v7-shop-otc@2x.png" alt="" /></figure>
                <h5>Shop OTC</h5>
              </a>
            </div>
          </li>
          <li>
            <div class="clsuser_help_box">
              <a routerLink="/prescription">
                <figure><img src="assets/images/home-v7-transfer-rx@2x.png" alt="" /></figure>
                <h5>Transfer Rx</h5>
              </a>
            </div>
          </li>
          
        </ul>
        <!--
        <div class="clsuser_attention_blk" *ngIf="isCapitalRx" style="margin-bottom: 35px">
          <h4>Attention Capital Rx Member</h4>
          <div class="clsattention_content_blk">
            <div class="clsattention_content">
              <p>If you have not placed an order please call our pharmacy at <span>833-497-7370</span>. If you would like to refill a
                prescription you can do so from your Recent Orders section. Once we have confirmed your refill
                amount we will place your order and your co-pay will be charged to your credit card on file.</p>
            </div>
            <div class="clsattention_btn">
                <a href="https://calendly.com/valisurerx_caprx/order" target="_blank" class="btn btn-default clsyellow_btn">Schedule a Call</a>
            </div>
          </div>
        </div>
        -->

      </div>
    </div>
    <div class="clsuser_home_recent_purchases" *ngIf="recentPurchases.length">
      <div class="container">
        <div class="clsuser_home_recent_purchases_tab">
          <div class="clsuser_home_recent_purchases_title">
            <p class="clsview_allorders"><a style="cursor:pointer;" [routerLink]="['/account/recent-purchases']">View All</a></p>
            Recent Purchases</div>
          
          <div class="table-fo">
            <table class="table" *ngIf="recentPurchases.length">
              <tbody>
                
                  <tr *ngFor="let product of recentPurchases">
                      <td>
                        <div class="media">
                          <div class="media-left">
                            <a [routerLink]="['/shop/products',product.product_id]">
                              <img class="media-object" width="50"
                                [src]="sanitizer.bypassSecurityTrustResourceUrl(getOrderProductImage(product))" alt="{{product.scientific_name}}" />
                            </a>
                          </div>
                          <div class="media-body  media-middle">
                            <h4 class="media-heading">
                              <a [routerLink]="['/shop/products',product.product_id]">
                                {{product.quantity}} x {{product.scientific_name}} <span *ngIf="!product.is_rx && product.variant_quantity">({{product.variant_quantity}} {{productService.getOptionDescriptor(product.type, product.variant_quantity)}})</span>
                              </a>
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <a *ngIf="product.real.active && product.medication_testing === null" href="javascript:void(0)" class="clsreorder" (click)="reorderProduct(product)">
                          Reorder
                        </a>
                      </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--
    <div class="clsuser_home_tab_blk">
      <div class="container">
        <div class="clsuser_home_tab">
          <ul class="nav nav-tabs" role="tablist">
            <li role="presentation" class="active"><a href="#Recent" role="tab" data-toggle="tab">Recent
                Orders</a></li>
            <li role="presentation" style="cursor: pointer"><a data-target="#Request" #requests role="tab"
                data-toggle="tab">Request
                a Medication</a></li>
          </ul>

          
          <div class="tab-content">

            
            <div role="tabpanel" class="tab-pane active" id="Recent">
              <p *ngIf="recentOrders.length" class="clsview_allorders"><a style="cursor:pointer;"
                  [routerLink]="['/account/order-history']">View
                  All Orders</a></p>
              <div class="table-responsive">
                <table class="table" *ngIf="recentOrders.length">
                  <thead>
                    <tr>
                      <th width="50%">medication</th>
                      <th width="10%">Order</th>
                      <th width="10%">Placed</th>
                      <th width="20%">Status</th>
                      <th width="10%">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                  <ng-template ngFor let-order [ngForOf]="recentOrders">
                    <tr *ngFor="let line of order.products">
                        <td>
                          <div class="media">
                            <div class="media-left">
                                <img class="media-object" width="50"
                                  [src]="sanitizer.bypassSecurityTrustResourceUrl(getOrderProductImage(line))" alt="{{line.scientific_name}}" />
                            </div>
                            <div class="media-body  media-middle">
                              <h4 class="media-heading">
                                <a [routerLink]="['/shop/products',line.product_id]">
                                  {{line.scientific_name}} <span *ngIf="!line.is_rx && line.variant_quantity">({{line.variant_quantity}} {{productService.getOptionDescriptor(line.type, line.variant_quantity)}})</span>
                                </a>
                              </h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a [routerLink]="['/account/orderDetail', order.id]">
                            #{{order.order_number}}
                          </a>
                        </td>
                        <td>{{order.created | date:'shortDate'}}</td>
                        <td><label class="clsorder_Status">{{order.status.name}}</label></td>
                        <td>
                          <p class="clsorder_total">{{line.subtotal | currency}}</p>
                        </td>
                    </tr>
                  </ng-template>
                  </tbody>
                </table>
              </div>
              <div class="clsno_order" *ngIf="!recentOrders.length">
                <p class="empty-message">You haven’t placed any orders recently.</p>
                <div class="clsno_order_btn_blk">
                  <a type="button" [routerLink]="['/shop/products/rx']"
                    class="btn btn-default clsyellow_btn">Shop Rx</a>
                  <a type="button" [routerLink]="['/shop/products/otc']"
                    class="btn btn-default clsyellow_btn">Shop OTC</a>
                </div>
              </div>
            </div>

            

            <div role="tabpanel" class="tab-pane" id="Request">
              <p class="clsview_allorders"><a [routerLink]="['/account/request-medication']">View Requested
                  Medications</a></p>
              <div class="clsrequest_blk">
                <p *ngIf="showMedication == true">Help our pharmacy grow! We want to hear from you and concentrate
                  first on the
                  drugs that you need. If you do not see the medications you are looking for
                  please send us a request. We will keep you posted on the status and let you
                  know once it has been added to our inventory.</p>
                <form #requestMedForm='ngForm' *ngIf="showMedication == true">
                  <div class="form-group">
                    <label >Medications</label>
                    <input type="text" class="form-control" placeholder="Enter Medication" name="medication"
                      #medication="ngModel" [(ngModel)]="medicationObject.medication" autocomplete="off" required>
                  </div>
                  <div class="text-center"><button type="submit"
                      [attr.disabled]="!requestMedForm.form.valid?'disabled':null" (click)="addMedicationData()"
                      class="btn btn-default clsyellow_btn"><span *ngIf="!loadingResponse">Request
                        Medications </span><span *ngIf="loadingResponse"><i class="fa fa-spinner fa-spin"></i> Please
                        wait</span></button></div>
                </form>
                <div *ngIf="showMedication == false" class="clsrequest_thankyou">
                  <figure><img src="assets/images/check-icon@2x.png" alt="" width="69" /></figure>
                  <h4>Thank you for submitting your request.</h4>
                  <div class="text-center"><button type="submit" (click)="showNewMedication()"
                      class="btn btn-default clsyellow_btn">Request
                      Another Medication</button></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->
    <div class="clsorder_item_list_blk" *ngIf="featuredRx.length > 0">
      <div class="container">
        <h3 class="text-center">Featured Rx</h3>
        <div class="clsproducts">
          <div class="row">
            <p-dataView [value]="featuredRx" [paginator]="false"  emptyMessage="">
    
              <ng-template let-prod pTemplate="listItem">
                <div class="product shop_list_grid">
                  <div class="clsproduct_box">
                    <figure class="clsproduct_img">
                      <a [routerLink]="['/shop/products',prod.id]">
                        <img src="{{prod.image.full_url}}" alt="{{prod.scientific_name}}" class="img-responsive">
                      </a>
                    </figure>
                    <h4><a [routerLink]="['/shop/products',prod.id]" title="{{prod.scientific_name}}">{{prod.scientific_name}}</a></h4>
                  </div>
                </div>
              </ng-template>
            </p-dataView>
          </div>
        </div>        
      </div>
    </div>
    
    <div class="clsorder_item_list_blk" *ngIf="featuredOtc.length > 0">
      <div class="container">
        <h3 class="text-center">Featured OTC</h3>
        <div class="clsproducts">
          <div class="row">
            <p-dataView [value]="featuredOtc" [paginator]="false"  emptyMessage="">
    
              <ng-template let-prod pTemplate="listItem">
                <div class="product shop_list_grid">
                  <div class="clsproduct_box">
                    <figure class="clsproduct_img">
                      <a [routerLink]="['/shop/products',prod.id]" title="{{prod.scientific_name}}">
                        <img src="{{prod.image.full_url}}" alt="{{prod.scientific_name}}" class="img-responsive">
                      </a>
                    </figure>
                    <h4><a [routerLink]="['/shop/products',prod.id]" title="{{prod.scientific_name}}">{{prod.scientific_name}}</a></h4>
                  </div>
                </div>
              </ng-template>
            </p-dataView>
          </div>
        </div>        
      </div>
    </div>

  </main>
  <app-hippa-update-november-2019></app-hippa-update-november-2019>
</div>
