
  <div class="clswrapper">
    <div class=""></div>
    <!-- Main container -->
    <main class="clsmain_container">
      <div class="container">
        <div class="prescription_faq">
          <div class="row">
            <div class="col-sm-4">
              <div class="clssignup_secondary_form_blk_left">
                <h5>Have Questions?</h5>
                <h1>We are happy to help.</h1>
                <ul class="clsprescription_details">
                  <li><span>Prescription Services:</span> 1-833-497-7370</li>
                  <li><span>Pharmacy Hours:</span> Monday to Friday, 9:00 AM - 5:00 PM ET</li>
                  <li><span>Customer Service:</span> 1-833-497-7370 <a href="mailto:CustomerCare@valisure.com?Subject=Hello!"
                      class="clsedit_address_link">CustomerCare@valisure.com</a></li>
                </ul>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="clssignup_secondary_form_blk_right">
                <div class="prescription_loc">
                  <div class="pres_row">
                    <div class="drug_co">
                      <ul>
                        <li>
                          <figure><img src="assets/images/start-icon@2x.png" alt="" width="65" /></figure>
                        </li>
                        <li>
                          <h5>Start New Prescription</h5>
                        </li>
                      </ul>
                      <div class="clear_fix"></div>
                    </div>
                    <p>Your provider must call, fax or e-prescribe your prescription directly to Valisure or you may mail your prescription
                      directly to the
                      Valisure Pharmacy (emailed prescriptions will not be accepted).</p>
                    <p><span>Pharmacy Address:</span> ValisureRX, 5 Science Park, New Haven, CT 06511</p>
                  </div>
                  <div class="pres_row">
                    <div class="drug_co">
                      <ul>
                        <li>
                          <figure><img src="assets/images/refill-icon@2x.png" alt="" width="54" /></figure>
                        </li>
                        <li>
                          <h5>Refill Prescription</h5>
                        </li>
                      </ul>
                      <div class="clear_fix"></div>
                    </div>
                    <p>To refill your prescription, please place your order or call the pharmacy at (833) 497-7370 and we will help you.</p>
                    <p>We will verify your prescription and ship your medication to your door in a discreet, secure package.</p>                      
                  </div>
                  <div class="pres_row">
                    <div class="drug_co">
                      <ul>
                        <li>
                          <figure><img src="assets/images/transfer-icon@2x.png" alt="" width="57" /></figure>
                        </li>
                        <li>
                          <h5>Transfer Prescription</h5>
                        </li>
                      </ul>
                      <div class="clear_fix"></div>
                    </div>
                    <p>To transfer your prescription(s), please call the Valisure Pharmacy 1-833-497-7370 with your
                      prescription(s) info and current
                      pharmacy name and phone number.</p>
                    <p><span>Pharmacy Hours:</span> Monday to Friday, 9:00 AM-5:00 PM ET</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
 