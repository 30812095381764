<div class="clswrapper">
    <div class=""></div>
    <!-- Main container -->
    <main class="clsmain_container">
        <div class="clsmyaccount_section">
            <div class="container">
                <ul class="clsbreadcrumb">
                    <li><a href="javascript:void(0);" [routerLink]="['/']">Home</a></li>
                    <li><a [routerLink]="['/account/order-history']">Account</a></li>
                    <li>Saved Order</li>
                </ul>
                <app-top-panel></app-top-panel>
                <div class="clsmy_account_main_content_blk">
                    <div class="row">
                        <app-side-panel></app-side-panel>
                        <div class="col-sm-8">
                            <div class="clsmyaccount_menu_content_blk">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Order</th>
                                                <th>Placed</th>
                                                <th>Status</th>
                                                <th>Total</th>
                                                <th>&nbsp;</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngIf="!ordersArray.length">
                                            <td class="empty-message" colspan="6">You haven’t saved any orders.</td>
                                        </tr>
                                            <tr *ngFor="let order of ordersArray">
                                                <td>#{{order.order_number}}</td>
                                                <td>{{order.created | date:'MM/dd/yyyy'}}</td>
                                                <td><label class="clsorder_Status">{{order.status &&
                                                        order.status.name}}</label></td>
                                                <td>
                                                    <p class="clsorder_total">{{order.total_amount | currency}}</p>
                                                </td>
                                                <td><a href="javascript:void(0);" style="cursor:pointer;" (click)="viewOrderDetail(order)">View
                                                        Order</a></td>
                                                <td>
                                                    <a href="javascript:void(0);" style="cursor:pointer;" (click)="removeOrder(order.id)">
                                                            Remove
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div *ngIf="savedOrderPage > 0" class="clspagination_blk clspagination_blk_bottom">
                                    <div class="clspagination_div">
                                        <nav aria-label="Page navigation">
                                            <!-- <p>View <span *ngFor="let limit of limits" [ngClass]="{'active': isLimitActive(limit) }"><a (click)="selectLimit(limit)">{{limit}}</a></span></p> -->
                                            <ul class="pagination">
                                              <li class='pagination-item' [ngClass]="{'disabled': selectedPage == 1}">
                                                <a href="javascript:void(0);" class="cls_shop_page cursor-hand " (click)="prevPage()" aria-label="Prev">
                                                  <span class="glyphicon glyphicon-menu-left" aria-hidden="true"></span>
                                                </a>
                                              </li>
                                  
                                              <li class='pagination-item' *ngFor="let page of getPages()" [ngClass]="{'active': page == selectedPage}">
                                                <a href="javascript:void(0);" class="cls_shop_page" style="cursor: pointer;" (click)="selectPage(page)">{{ page }}</a>
                                              </li>
                                  
                                              <li class='pagination-item' [ngClass]="{'disabled': selectedPage == getPages().length}">
                                                <a href="javascript:void(0);" class="cls_shop_page cursor-hand " disabled="disableNext" (click)="nextPage()" aria-label="Next">
                                                  <span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
                                                </a>
                                              </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
