<main class="clsmain_container doomsday-page">
    <div class="container">
        <p><b>Dear {{userFname}},</b></p>
        <p>We’re very sorry that our store is temporarily unavailable due to a very high volume of requests. We will contact you as soon as we are able to serve your needs.</p>
        <p>More information on the breaking news of Zantac and ranitidine’s toxicity and Valisure’s investigation on this is <a href="https://www.valisure.com/blog/uncategorized/detection-of-ndma-in-raniditine/" target="_blank">available here</a>.</p>
        <p>Please know that our lab analysis of all ranitidine products produces extremely high levels of NDMA. Therefore it is generally not necessary to test your own ranitidine product because it is practically certain we will detect NDMA in our tests.</p>
        <ng-template [ngIf]="doomsdayStatus.doomsday_shipping_code">
            <p>Below is a free shipping code you can use as soon as we are able to reopen our store:</p>
            <p>
                <b>{{doomsdayStatus.doomsday_shipping_code}}</b><br/>
                <span *ngIf="doomsdayStatus.doomsday_shipping_code_data">{{doomsdayStatus.doomsday_shipping_code_data.description}}</span>
            </p>            
        </ng-template>
        <p>Again, our apologies and we’ll follow up with you as soon as we can,</p>
        <p><b>Valisure Team</b></p>
    </div>
</main>