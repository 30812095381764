import {NgModule} from "@angular/core";
import {CommonModule, DatePipe} from "@angular/common";
import {OrderHistoryComponent} from "./order-history/order-history.component";
import {RecentPurchasesComponent} from "./recent-purchases/recent-purchases.component";
import {PaymentMethodsComponent} from "./payment-methods/payment-methods.component";
import {AddressComponent} from "./address/address.component";
import {ProfileComponent} from "./profile/profile.component";
import {AccountRouteModule} from "./account.route";
import {RouterModule} from "@angular/router";
import {AccountComponent} from "./account.component";
import {SidePanelComponent} from "./side-panel/side-panel.component";
import {TopPanelComponent} from "./top-panel/top-panel.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {OrderDetailComponent} from "./order-detail/order-detail.component";
import {InputMaskModule} from "primeng/inputmask";
import {DropdownModule} from "primeng/dropdown";
import {RequestMedicationComponent} from "./request-medication/request-medication.component";
import {CalendarModule} from "primeng/calendar";
import {ChipsModule} from "primeng/chips";
import {AutoCompleteModule} from "primeng/autocomplete";
import {SavedOrderComponent} from "./saved-order/saved-order.component";
import {BaseModule} from "@modules/base/base.module";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {AuthInterceptor} from "../auth/auth.interceptor";

@NgModule({
    imports: [
        BaseModule,
        AccountRouteModule,
        RouterModule,

    ],
    declarations: [OrderHistoryComponent, RecentPurchasesComponent, PaymentMethodsComponent, AddressComponent, ProfileComponent, AccountComponent, SidePanelComponent, TopPanelComponent, OrderDetailComponent, SavedOrderComponent, RequestMedicationComponent],
    providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ]
})
export class AccountModule {
}
