import { Router } from '@angular/router';
import { UserService } from './user.service';
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./user.service";
var ShopGuard = /** @class */ (function () {
    function ShopGuard(router, userService) {
        this.router = router;
        this.userService = userService;
    }
    /**
     * Validates the access to products between RX and OTC
     */
    ShopGuard.prototype.canActivate = function (next, state) {
        var url = state.url;
        if (url === '/shop/products/rx') {
            this.router.navigate(['shop/products'], {
                queryParams: { category: environment.rx_category_id }
            });
            return false;
        }
        else if (url === '/shop/products/otc') {
            this.router.navigate(['shop/products'], {
                queryParams: { category: environment.otc_category_id }
            });
            return false;
        }
        else {
            return true;
        }
    };
    ShopGuard.ngInjectableDef = i0.defineInjectable({ factory: function ShopGuard_Factory() { return new ShopGuard(i0.inject(i1.Router), i0.inject(i2.UserService)); }, token: ShopGuard, providedIn: "root" });
    return ShopGuard;
}());
export { ShopGuard };
