<div class="clswrapper">
  <div class=""></div>
  <!-- Main container -->
  <main class="clsmain_container">
    <div class="clsmyaccount_section">
      <div class="container">
        <ul class="clsbreadcrumb">
          <li><a href="javascript:void(0);" [routerLink]="['/']">Home</a></li>
          <li><a href="javascript:void(0);" [routerLink]="['/account/order-history']">Account</a></li>
          <li>Requested Medications</li>
        </ul>
        <app-top-panel></app-top-panel>
        <div class="clsmy_account_main_content_blk">
          <div class="row">
            <app-side-panel></app-side-panel>
            <div class="col-sm-8">
              <div class="clsmyaccount_menu_content_blk clsacc_medication_form">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Medication</th>
                        <th>Requested</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="medicationArray.length === 0">
                            <td colspan="2" class="empty-message">You haven’t requested any medications.</td>
                        </tr>
                      <tr *ngFor="let medication of medicationArray">
                        <td>{{medication.name}}</td>
                        <td>{{medication.created | date:'MM/dd/yyyy'}}</td>
                        <td><a href="#" data-toggle="modal"
                           class="cursor-hand" (click)="deleteMedicationId(medication)"
                          data-target="#myModal">Delete</a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div *ngIf="medicationPages > 0" class="clspagination_blk clspagination_blk_bottom">
                  <div class="clspagination_div">
                      <nav aria-label="Page navigation">
                          <!-- <p>View <span *ngFor="let limit of limits" [ngClass]="{'active': isLimitActive(limit) }"><a (click)="selectLimit(limit)">{{limit}}</a></span></p> -->
                          <ul class="pagination">
                            <li class='pagination-item' [ngClass]="{'disabled': selectedPage == 1}">
                              <a href="javascript:void(0);" class="cls_shop_page cursor-hand" (click)="prevPage()" aria-label="Prev">
                                <span class="glyphicon glyphicon-menu-left" aria-hidden="true"></span>
                              </a>
                            </li>
                
                            <li class='pagination-item' *ngFor="let page of getPages()" [ngClass]="{'active': page == selectedPage}">
                              <a href="javascript:void(0);" class="cls_shop_page" style="cursor: pointer;" (click)="selectPage(page)">{{ page }}</a>
                            </li>
                
                            <li class='pagination-item' [ngClass]="{'disabled': selectedPage == getPages().length}">
                              <a href="javascript:void(0);" class="cls_shop_page cursor-hand" disabled="disableNext" (click)="nextPage()" aria-label="Next">
                                <span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
                              </a>
                            </li>
                          </ul>
                      </nav>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

    <!-- Modal -->
    <div class="modal fade clsaddress_delete_confirm_modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="vertical-alignment-helper">
          <div class="modal-dialog vertical-align-center" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h4 class="modal-title" id="myModalLabel">Are you sure you want to delete this Medication?</h4>
                  </div>
                  <div class="modal-body">
                      <a href="javascript:void(0)" data-dismiss="modal">No, Keep Medication</a>
                      <button type="button" class="btn btn-primary clsyellow_btn" (click)="deleteMedication()" data-dismiss="modal">Yes,
                          Delete Medication</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
