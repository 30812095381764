import {Pipe} from "@angular/core";
import {FormatService} from "app/shared/format.service";

@Pipe({
    name: 'phone'
})
export class PhonePipe{
    transform(val, args) {
        return FormatService.formatPhoneForDisplay(val);
    }
}
