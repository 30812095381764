var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var BaseModule = /** @class */ (function () {
    function BaseModule() {
    }
    return BaseModule;
}());
export { BaseModule };
export { ɵ0 };
