import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {InsuranceFormComponent} from "@modules/base/components/insurance-form/insurance-form.component";
import {DataViewModule} from "primeng/dataview";
import {AutoCompleteModule} from "primeng/autocomplete";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ChipsModule} from "primeng/chips";
import {InputMaskModule} from "primeng/inputmask";
import {SpinnerModule} from "primeng/spinner";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {CalendarModule} from "primeng/calendar";
import {DropdownModule} from "primeng/dropdown";
import {PaginatorModule} from "primeng/paginator";
import {HttpClientModule} from "@angular/common/http";
import {HttpModule} from "@angular/http";
import {CaptchaModule} from "primeng/captcha";
import {InputTextModule} from "primeng/inputtext";
import {SliderModule} from "primeng/slider";
import {CheckboxModule} from "primeng/checkbox";
import {AddressFormComponent} from "@modules/base/components/address-form/address-form.component";
import {PhonePipe} from "@modules/base/pipes/phone.pipe";
import {AddressDisplayComponent} from "@modules/base/components/address-display/address-display.component";
import {OrderHistoryDisplayComponent} from "@modules/base/components/order-history-display/order-history-display.component";
import {OrderRecentPurchaseDisplayComponent} from "@modules/base/components/order-recent-purchase-display/order-recent-purchase-display.component";
import {HippaUpdateNovember2019Component} from "@modules/base/components/hippa-update-november-2019/hippa-update-november-2019.component";

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AddressAutocompleteComponent } from './components/address-autocomplete/address-autocomplete.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
    declarations: [
        InsuranceFormComponent,
        AddressFormComponent,
        AddressDisplayComponent,
        OrderHistoryDisplayComponent,
        OrderRecentPurchaseDisplayComponent,
        HippaUpdateNovember2019Component,
        PhonePipe,
        AddressAutocompleteComponent
    ],
    imports: [
        CommonModule,

        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpModule,
        DataViewModule,
        DropdownModule,
        CheckboxModule,
        InputTextModule,
        SliderModule,
        PaginatorModule,
        CalendarModule,
        InputMaskModule,
        ChipsModule,
        AutoCompleteModule,
        SpinnerModule,
        CaptchaModule,
        ProgressSpinnerModule

    ],
    providers: [
        PhonePipe,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ],
    exports: [
        InsuranceFormComponent,
        AddressFormComponent,
        AddressDisplayComponent,
        AddressAutocompleteComponent,
        OrderHistoryDisplayComponent,
        OrderRecentPurchaseDisplayComponent,
        HippaUpdateNovember2019Component,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpModule,
        DataViewModule,
        DropdownModule,
        CheckboxModule,
        InputTextModule,
        SliderModule,
        PaginatorModule,
        CalendarModule,
        InputMaskModule,
        ChipsModule,
        AutoCompleteModule,
        SpinnerModule,
        CaptchaModule,
        ProgressSpinnerModule,
    ]
})
export class BaseModule {
}
