import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    private GET_SEARCH_API = 'https://shop.valisure.com/admin-interface/ngproduct/getproducts?search=';
    public term = '';

    constructor(private http: Http) { }

    /**
     * Update the local search term
     * @param term - New search term
     */
    public updateSearhTerm(term: string) {
        this.term = term;
    }

    /**
     * API for searching products
     */
    public search(): Observable<any> {
        const headers = new Headers({
          'Content-Type': 'application/json',
          'VsAuth': 'Authorization'
        });
        return this.http.get(this.GET_SEARCH_API + this.term, { headers });
    }
}
