/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./account.component";
var styles_AccountComponent = [];
var RenderType_AccountComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AccountComponent, data: {} });
export { RenderType_AccountComponent as RenderType_AccountComponent };
export function View_AccountComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_AccountComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-account", [], null, null, null, View_AccountComponent_0, RenderType_AccountComponent)), i0.ɵdid(1, 49152, null, 0, i1.AccountComponent, [], null, null)], null, null); }
var AccountComponentNgFactory = i0.ɵccf("app-account", i1.AccountComponent, View_AccountComponent_Host_0, {}, {}, []);
export { AccountComponentNgFactory as AccountComponentNgFactory };
