import { Http, Headers } from '@angular/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
var SearchService = /** @class */ (function () {
    function SearchService(http) {
        this.http = http;
        this.GET_SEARCH_API = 'https://shop.valisure.com/admin-interface/ngproduct/getproducts?search=';
        this.term = '';
    }
    /**
     * Update the local search term
     * @param term - New search term
     */
    SearchService.prototype.updateSearhTerm = function (term) {
        this.term = term;
    };
    /**
     * API for searching products
     */
    SearchService.prototype.search = function () {
        var headers = new Headers({
            'Content-Type': 'application/json',
            'VsAuth': 'Authorization'
        });
        return this.http.get(this.GET_SEARCH_API + this.term, { headers: headers });
    };
    SearchService.ngInjectableDef = i0.defineInjectable({ factory: function SearchService_Factory() { return new SearchService(i0.inject(i1.Http)); }, token: SearchService, providedIn: "root" });
    return SearchService;
}());
export { SearchService };
