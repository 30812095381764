
import {OrderComplete} from "../../../../model/orders/order-complete.model";
import {Component, Input, OnInit} from "@angular/core";
import { Router } from '@angular/router';

@Component({
    selector: "app-order-history-display",
    templateUrl: "./order-history-display.component.html",
    styleUrls: ["./order-history-display.component.css"]
})
export class OrderHistoryDisplayComponent implements OnInit {

    @Input() order: OrderComplete;

    constructor(private router: Router) {
    }

    ngOnInit() {
    }

    /**
   * Navigate to order details 
   * @param order - Order
   */
    public viewOrderDetail() {
        this.router.navigate(['/account/orderDetail', this.order.id]);
    }

}
