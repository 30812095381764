<address class="clscheckout_address">
    <p>
        <strong>{{address.first_name}} {{address.last_name}}</strong><br />
        <span *ngIf="address.phone"><i>{{address.phone}} </i><br/></span>
        <span *ngIf="address.company"><i>{{address.company}} </i><br/></span>
        {{address.address_line_1}}<br />
        <!--<span *ngIf="address.address_line_2">{{address.address_line_2}}<br /></span>-->
        {{address.city}}, {{address.state?.abbreviation}} {{address.zip_code}}<br />
        <!--
        <span *ngIf="address.phone">{{address.phone}}</span><br />
        {{address.email}}</p>
        -->
        United States of America
</address>