import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Category } from '../models';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private categorySubject: Subject<Category>;
  private categories: Category[];

  constructor(private http: HttpClient) {
    this.categorySubject = new Subject<Category>();
  }

  readonly rootUrl = sessionStorage.getItem('location');

  /**
   * Updates active category in navigation
   * @param category - Category
   */
  public updateActiveCategory(category: Category) {
    this.categorySubject.next(category);
  }

  /**
   * Returns category subject
   */
  public getCategorySubject(): Subject<Category> {
    return this.categorySubject;
  }

  /**
   * Fetches categories from the server
   */
  public fetchCategories(): Observable<Category[]> {
    if (this.categories) {
      return of(this.categories);
    }

    return this.http
      .get(this.rootUrl + '/categories?order_by=name')
      .pipe(
        map((response: any) => {
          const categories = response.data;

          this.categories = categories;
          return categories;
        })
      );
  }
}
