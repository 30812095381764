<div role="tabpanel" class="tab-pane" id="signin" #signin>
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" method="POST" id="signin-form">
    <div *ngIf="loginerror">
      <div class="alert alert-danger">Email or Password is incorrect!</div>
    </div>
    <div class="form-group">
      <label for="email">Email</label>
      <input
        name="email"
        type="email"
        id="email"
        class="form-control"
        placeholder="jane@janedoe.com"
        autofocus
        autocomplete="off"
        formControlName="email"
      />
      <div
        class="alert alert-danger"
        *ngIf="loginForm.controls.email.invalid && (loginForm.controls.email.dirty && loginForm.controls.email.touched)"
      >
        <div *ngIf="loginForm.controls.email.hasError('required')">Please enter your email address.</div>
        <div *ngIf="loginForm.controls.email.hasError('pattern')">Please enter a valid email address.</div>
      </div>
    </div>
    <div class="form-group">
      <label for="password">Password</label>
      <div class="show-password">
      <input
        name="password"
        [type]="showPassword ? 'text' : 'password'"
        id="password"
        class="form-control"
        placeholder="Password"
        autocomplete="off"
        formControlName="password"
      />
        <a (click)="toggleShowPassword()">{{showPassword ? 'Hide' : 'Show'}}</a>
      </div>
      <div
        class="alert alert-danger"
        *ngIf="loginForm.controls.password.touched && loginForm.controls.password.dirty && loginForm.controls.password.invalid"
      >
        Please enter your password.
      </div>
    </div>
    <button
      class="btn btn-default btn-block clsyellow_btn"
      type="submit"
    >
      <span *ngIf="!loadingResponse">Sign in</span>
      <span *ngIf="loadingResponse"
        ><i class="fa fa-spinner fa-spin"></i> Please wait</span
      >
    </button>
    <p class="clsforget_text text-center">
      <a [routerLink]="['/forgot']">Forgot your password?</a>
    </p>
  </form>
  <div
    style="font-size: 14px;   text-align: justify; text-justify: inter-word;"
  >
    <p style="padding-top: 15px;">
      To help protect your privacy and to help ensure HIPAA compliance, browsing inventory and analytical tests is enabled once signed in. We carry most prescription drugs, over-the-counter medications and supplements but generally do not stock schedule II controlled substances, specialty medications, certain drugs that have issues in testing or products that have been rejected due to not meeting our quality standards.
    </p>
    <p style="padding-top: 5px;">

      We’re currently licensed in 38 states for dispensing prescriptions (all 50 states for over-the-counter). The licensed states are: AK, AZ, CA, CO, CT, DE, FL, GA, HI, IA, ID, IL, IN, MA, MD, ME, MN, MO, MT, NC, ND, NH, NM, NJ, NV, NY, OH, PA, RI, SC, SD, TX, UT, VA, VT, WA, WI, WY and Washington DC.
    </p>
    <p style="padding-top: 5px;">
      We are working hard to expand our reach and by signing up you are helping us to focus on what matters most to you. Thanks!
    </p>
  </div>
  <button
    id="openModalButton"
    [hidden]="true"
    data-toggle="modal"
    data-target="#myModal_timeout"
  >
    Open Modal
  </button>
  <div
    class="modal fade clsaddress_delete_confirm_modal clstimeout_modal"
    id="myModal_timeout"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="vertical-alignment-helper">
      <div class="modal-dialog vertical-align-center" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h3>Your session has timed out</h3>
            <p>
              We've signed you out of your account to help protect your
              prescription information. Any action (such as refill request) that
              wasn't confirmed has been lost. Please sign back in on the
              dashboard to resume your activity.
            </p>
            <p>
              If your session is complete, quit your browser for added security.
            </p>
            <button
              type="button"
              class="btn btn-primary clsyellow_btn"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
