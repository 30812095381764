<div class="clswrapper">
    <!-- <div class=""></div> -->
    <!-- Main container -->
    <main class="clsmain_container">
        <div class="clsmyaccount_section">
            <div class="container">
                <ul class="clsbreadcrumb">
                    <li><a href="javascript:void(0);" [routerLink]="['/']">Home</a></li>
                    <li><a [routerLink]="['/account/order-history']">Account</a></li>
                    <li>Order History</li>
                </ul>
                <!--<app-top-panel></app-top-panel>-->
                <div class="clsmy_account_main_content_blk">
                    <div class="">
                        <!--<app-side-panel></app-side-panel>-->
                        <div class="accountContainer">
                            <h1 class="accountTitle">Order History</h1>    
                            <div class="accountBox">
                                <div *ngIf="loadingResponse" class="accountMessage">
                                    Loading. Please wait ...
                                </div>
                                <div *ngIf="!loadingResponse">
                                    <app-order-history-display  *ngFor="let order of ordersArray" [order]="order" class="orderHistoryBox"></app-order-history-display>
                                    <div class="accountMessage" *ngIf="ordersArray.length == 0">You haven’t placed any orders yet.</div>
                                </div>
                            </div>
                            <div *ngIf="loadedResponse && orderHistoryPage" class="paginationBox">
                                <nav aria-label="Pagination">
                                    <div class="limits">
                                        <span><b>View</b></span>
                                        <span class='cls_shop_limits' *ngFor="let limit of limits" [ngClass]="{'active': isLimitActive(limit) }">
                                            <a class='limit' (click)="selectLimit(limit)">{{ limit }}</a>
                                        </span>
                                    </div>
                                    <ul class="pagination">
                                    <li class='pagination-item' [ngClass]="{'disabled': selectedPage == 1}">
                                        <a class="cls_shop_page" (click)="prevPage()" aria-label="Prev">
                                        <span class="glyphicon glyphicon-menu-left" aria-hidden="true"></span>
                                        </a>
                                    </li>
                        
                                    <li class='pagination-item' *ngFor="let page of getPages()" [ngClass]="{'active': page == selectedPage}">
                                        <a class="cls_shop_page" *ngIf="page !== -1" (click)="selectPage(page)">{{ page }}</a>
                                        <span class="dots" *ngIf="page === -1">...</span>
                                    </li>
                        
                                    <li class='pagination-item' [ngClass]="{'disabled': selectedPage == numberOfPages}">
                                        <a class="cls_shop_page" (click)="nextPage()" aria-label="Next">
                                        <span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
                                        </a>
                                    </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
