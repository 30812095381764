import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";

export abstract class UtilsService {

    public static roundPrice(num: number) {
        // First try to fix floating number rounding issue if the number is the result of a
        num = parseFloat((num * 10000).toFixed(2)) / 10000;

        // Then round to 2 decimals
        return +(Math.round(parseFloat(num + "e2"))  + "e-2");
    }

    public static markFormGroupTouched(formGroup: FormGroup | FormArray) {
        if (formGroup.controls) {
            const keys = Object.keys(formGroup.controls);
            for (let i = 0; i < keys.length; i++) {
                const control = formGroup.controls[keys[i]];

                if (control instanceof FormControl) {
                    control.markAsTouched();
                    control.markAsDirty();
                } else if (control instanceof FormGroup || control instanceof FormArray) {
                    UtilsService.markFormGroupTouched(control);
                }
            }
        }
    }

    public static markFormGroupPristine(formGroup: FormGroup | FormArray) {
        if (formGroup.controls) {
            const keys = Object.keys(formGroup.controls);
            for (let i = 0; i < keys.length; i++) {
                const control = formGroup.controls[keys[i]];

                if (control instanceof FormControl) {
                    control.markAsPristine();
                    control.markAsUntouched();
                } else if (control instanceof FormGroup || control instanceof FormArray) {
                    UtilsService.markFormGroupPristine(control);
                }
            }
        }
    }

    public static redirectToPath(router: Router, returnUrl: string) {
        returnUrl = decodeURI(returnUrl);
        if (returnUrl.indexOf('?') !== -1) {
            const urlParams = new URLSearchParams(returnUrl.substr(returnUrl.indexOf('?') + 1));
            const params = {};
            urlParams.forEach((value, key) => {
                params[key] = value;
            });
            router.navigate([returnUrl.substr(0, returnUrl.indexOf('?'))], {
                queryParams: params
            });
        }
        else {
            router.navigate([returnUrl]);
        }
    }

}
