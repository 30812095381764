import { HttpClient } from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CategoriesService = /** @class */ (function () {
    function CategoriesService(http) {
        this.http = http;
        this.rootUrl = sessionStorage.getItem('location');
        this.categorySubject = new Subject();
    }
    /**
     * Updates active category in navigation
     * @param category - Category
     */
    CategoriesService.prototype.updateActiveCategory = function (category) {
        this.categorySubject.next(category);
    };
    /**
     * Returns category subject
     */
    CategoriesService.prototype.getCategorySubject = function () {
        return this.categorySubject;
    };
    /**
     * Fetches categories from the server
     */
    CategoriesService.prototype.fetchCategories = function () {
        var _this = this;
        if (this.categories) {
            return of(this.categories);
        }
        return this.http
            .get(this.rootUrl + '/categories?order_by=name')
            .pipe(map(function (response) {
            var categories = response.data;
            _this.categories = categories;
            return categories;
        }));
    };
    CategoriesService.ngInjectableDef = i0.defineInjectable({ factory: function CategoriesService_Factory() { return new CategoriesService(i0.inject(i1.HttpClient)); }, token: CategoriesService, providedIn: "root" });
    return CategoriesService;
}());
export { CategoriesService };
