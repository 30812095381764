<div class="address-with-autocomplete-wrapper" [class.with-suggestions]="suggestionsVisible"
     (click)="onComponentClick($event)">
    <input type="text" tabindex="0" class="form-control" [(ngModel)]="value" (change)="onChange($event)" (focus)="onInputFocus()"
           (blur)="onInputBlur($event)" (keydown)="onKeyDown($event)" required/>

    <div class="loading" *ngIf="loading"><i class="fa fa-spinner fa-spin"></i></div>

    <div class="autocomplete-box" *ngIf="suggestionsVisible" (click)="$event.stopPropagation()">
        <div class="inner">
          <div class="list-item no-results" *ngIf="value && !loading && suggestions.length === 0">No Results</div>
          <div class="list-item"
               [class.highlight]="highlightValue && highlightValue.place_id === suggestion.place_id"
               *ngFor="let suggestion of suggestions">
              <a tabindex="0"  (click)="onSelectAddress(suggestion, $event)">{{suggestion.description}}</a>
          </div>
        </div>
        <div class="list-item google-copyright">
          <img src="/assets/images/powered_by_google_on_white_hdpi.png"/>
        </div>
    </div>
</div>