import {Injectable} from "@angular/core";
import {Headers} from "@angular/http";
import {HttpHeaders, HttpClient} from "@angular/common/http";

import {Observable, Subject} from "rxjs";
import {map} from "rxjs/operators";
import {ProductsFilter} from "@shop/models/products-filter.model";
import {Product} from "@shop/models/product.model";
import {ProductDetails} from "@shop/models/product-details.model";
import {SearchService} from "@services/search.service";
import {ProductType} from "@shop/models/product-variant.model";
import {environment} from "../../../../environments/environment";

const reqHeader = new HttpHeaders();
reqHeader.append("Access-Control-Allow-Origin", "*");
reqHeader.append("Access-Control-Allow-Headers", "*");
reqHeader.append(
    "Access-Control-Allow-Methods",
    " GET, POST, DELETE, PUT, HEAD, OPTIONS"
);
reqHeader.append("Request Method", "POST, GET, PUT, DELETE, OPTIONS, HEAD");
reqHeader.append(
    "Content-Type",
    "application/json, application/x-www-form-urlencoded, multipart/form-data, text/plain, charset=UTF-8"
);

@Injectable({
    providedIn: "root"
})
export class ProductService {
    private productsSubject: Subject<Product[]>;
    public totalProducts: number;
    public displaySearchResults = false;

    constructor(private http: HttpClient, private searchService: SearchService) {
        this.productsSubject = new Subject<Product[]>();
        this.totalProducts = 0;
    }

    readonly rootUrl = sessionStorage.getItem("location");

    /**
     * API for products with filter
     * @param filter - Filters
     */
    public getProducts(filter: ProductsFilter) {
        // reqHeader.append('VsAuth', 'Authorization');

        this.http
            .post(
                this.rootUrl +
                // '/products' + (this.searchService.term ? ('?search=' + this.searchService.term) : ('?filterid=' + filter.id + '&filtertype=' + filter.type + '&page=' + filter.page + '&limit=' + filter.limit)),
                "/products",
                {
                    options: {
                        per_page: filter.limit,
                    },
                    filters: {
                        category: this.searchService.term ? "" : filter.id,
                        search: this.searchService.term ? this.searchService.term : ""
                    },
                    page: filter.page 
                },
                {headers: reqHeader}
            )
            .subscribe((response: any) => {
                const responseData = response;

                if (responseData.status.success) {
                    this.totalProducts = responseData.data.meta.total;
                    const products = responseData.data.products.map((product: Product) =>
                        Object.assign({}, product, {
                            image_url: product.image != null ? product.image.full_url : "",
                            thumbnail_url: product.image != null ? product.image.full_thumbnail_url : ""
                        })
                    );
                    this.productsSubject.next(products);
                } else {
                    this.totalProducts = 0;
                    this.productsSubject.next([]);
                }
            });
    }

    /**
     * API for product details
     * @param id - Product ID
     */
    public getProductDetails(id: string): Observable<ProductDetails> {
        return this.http
            .post(this.rootUrl + "/products/get",
                {
                    id: id
                },
                {
                    headers: reqHeader
                })
            .pipe(
                map((response: any) => {
                    const responseData = response;
                    const product = responseData.data ? responseData.data : null;

                    if (product) {
                        product.image_url = product.image != null ? product.image.full_url : "";
                        product.thumbnail_url = product.image != null ? product.image.full_thumbnail_url : "";
                    }

                    return product;
                })
            );
    }

    /**
     * Get products subject
     */
    public getProductsSubject(): Observable<Product[]> {
        return this.productsSubject;
    }

    /**
     * API for listing products
     */
    public getProductLists() {
        return this.http
            .get(this.rootUrl + "/products/all", {
                headers: reqHeader
            })
            .pipe(
                map((response: any) => {
                    const product = response;
                    return product;
                })
            );
    }

    /**
     * API for listing products
     */
    public getMedicationTests() {
        return this.http
            .post(
                this.rootUrl + "/products",
                {
                    options: {
                        per_page: 100
                    },
                    filters: {
                        category: environment.medication_tests_category_id,
                    }
                },
                {
                    headers: reqHeader
                }
            )
            .pipe(
                map((response: any) => {
                    const products = response.data.products.map((product: Product) =>
                        Object.assign({}, product, {
                            image_url: product.image != null ? product.image.full_url : "",
                            thumbnail_url: product.image != null ? product.image.full_thumbnail_url : ""
                        })
                    );
                    return products;
                })
            );
    }


    public getUnitDescriptor(type: ProductType, quantity: number = 1) {
        if (type) {
            if ((type.descriptor && type.descriptor === 'pill') || type.name === 'Pill') {
                if (quantity !== 1) {
                    return 'pills';
                }
                else return 'pill';
            } else
            if (type.name === 'Analysis') {
                if (quantity !== 1) {
                    return 'analyses';
                }
                else return 'analysis';
            }
            else {

                if (type.descriptor) {
                    return type.descriptor;
                }
                else {
                    let hasPluralS = quantity !== 1;
                    if (hasPluralS && type.name.toLowerCase().charAt(type.name.toLowerCase().length - 1) === 's') {
                        hasPluralS = false;
                    }
                    return type.name.toLowerCase() + (hasPluralS ? 's' : '');
                }
            }
        }
        else return '';
    }

    public getProductDescriptor(type: ProductType, quantity: number) {
        let hasPluralS = quantity !== 1;
        if (hasPluralS && type.name.toLowerCase().charAt(type.name.toLowerCase().length - 1) === 's') {
            hasPluralS = false;
        }
        return type.name.toLowerCase() + (hasPluralS ? 's' : '');
    }

    public getOptionDescriptor(type: ProductType, quantity: number) {
        if (type) {
            if (type.descriptor) {
                return this.getUnitDescriptor(type, quantity) + ' / ' + type.name.toLowerCase();
            }
            else {
                return this.getUnitDescriptor(type, quantity);
            }
        }
        else return '';
    }

}
