import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AccountService} from "./../account.service";
import {SelectItem} from "primeng/api";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "@services/utils.service";
import {ServerResponse} from "../../model/base/server-response.model";
import {UserAddress, USState} from "../../user/model/user-address.model";
import {Address} from "@modules/checkout/models/address.model";
import {ValidationService} from "@services/validation.service";
import {FormatService} from "@services/format.service";

@Component({
    selector: "app-address",
    templateUrl: "./address.component.html",
    styleUrls: ["./address.component.css"]
})
export class AddressComponent implements OnInit {
    public userId: any = sessionStorage.getItem("userId");
    public showAddress: any = false;
    public model: any = {};
    public addressArray: any = [];
    public selectedIndex: any;
    public selectedValue: any = {};
    public stateObject: any = {};
    public deleteAddId: any;
    public stateArray: SelectItem[];
    public loadingResponse: boolean = false;
    public loadedResponse = false;
    public newAddressFormGroup: FormGroup;

    public addressesFormGroups: FormGroup[] = [];

    constructor(private accountService: AccountService, private router: Router) {
        this.stateArray = [];
    }

    ngOnInit() {
        this.getAddressData();
        this.getStateLists();
        this.newAddressFormGroup = this.createNewAddressFormGroup(null);
    }

    /**
     * Generate a new Address form group
     * @param {Address} data
     * @returns {FormGroup}
     */
    public createNewAddressFormGroup(data: Address = null) {
        return new FormGroup({
            "id": new FormControl(data ? data.id : ""),
            "name": new FormControl(data ? data.name : "", Validators.required),
            "first_name": new FormControl(data ? data.first_name : "", Validators.required),
            "last_name": new FormControl(data ? data.last_name : "", Validators.required),
            "company": new FormControl(data ? data.company : ""),
            //"email": new FormControl(data ? data.email : "", [ValidationService.emailValidator]),
            "phone": new FormControl(data ? FormatService.formatPhoneForDisplay(data.phone) : "", {
                updateOn: "blur",
                validators: [Validators.required, ValidationService.phoneValidator]
            }),
            "address_line_1": new FormControl(data ? data.address_line_1 : "", Validators.required),
            //"address_line_2": new FormControl(data ? data.address_line_2 : ""),
            "city": new FormControl(data ? data.city : "", Validators.required),
            "state_id": new FormControl(data ? data.state_id : "", {
                updateOn: "change",
                validators: Validators.required
            }),
            "zip_code": new FormControl(data ? data.zip_code : "", [Validators.required, Validators.pattern('[0-9]{5}(-[0-9]{4})?')]),
            "error": new FormControl("")
        }, {updateOn: "blur"});
    }

     /**
     * Toggle new address
     */
    public showNewAddress() {
        this.showAddress = !this.showAddress;
        if (this.showAddress) {
            this.newAddressFormGroup.reset();
            this.newAddressFormGroup.updateValueAndValidity();
        }
    }

    /**
     * Get list of addresses
     */
    public getAddressData() {
        this.loadingResponse = true;
        this.accountService
            .getAddressList(this.userId)
            .subscribe((response: ServerResponse<any>) => {
                this.addressArray = response.data.user.addresses;
                this.addressesFormGroups = [];
                response.data.user.addresses.forEach((address: Address) => {
                    this.addressesFormGroups.push(this.createNewAddressFormGroup(address));
                });
                this.loadedResponse = true;
                this.loadingResponse = false;
            });
    }

    

    /**
     * Add new address
     * @param formGroup - New address form
     */
    public addNewAddress(formGroup: FormGroup) {

        if (!formGroup.valid) {
            UtilsService.markFormGroupTouched(formGroup);
            return;
        }

        const addressObject = {
            user_id: this.userId,
            name: formGroup.get('name').value,
            address_line_1: formGroup.get('address_line_1').value,
            //address_line_2: formGroup.get('address_line_2').value,
            city: formGroup.get('city').value,
            zip_code: formGroup.get('zip_code').value,
            state_id: formGroup.get('state_id').value,
            country: "",
            first_name: formGroup.get('first_name').value,
            last_name: formGroup.get('last_name').value,
            //email: formGroup.get('email').value,
            company: formGroup.get('company').value,
            phone: FormatService.formatPhoneForSave(formGroup.get('phone').value),
            phonetype_id: 1,
            is_active: 1
        };

        formGroup.get('error').setValue('');

        this.accountService.addNewAddress(addressObject).subscribe((response: ServerResponse<UserAddress>) => {
            if (response && response.status && response.status.success) {
                formGroup.reset();
                this.getAddressData();
                this.showNewAddress();
            }
            else {
                formGroup.get('error').setValue(response.status.message);
            }
        });
    }

    /**
     * Display edit form
     * @param editValue - Value to edit
     * @param index - Index
     */
    public showEdit(editValue, index) {
        this.selectedIndex = index;
        this.selectedValue = editValue;
    }

    /**
     * Update address
     */
    public updateAddress(formGroup: FormGroup) {

        if (!formGroup.valid) {
            UtilsService.markFormGroupTouched(formGroup);
            return;
        }

        const updateAddressObject = {
            id: this.selectedValue.id,
            user_id: this.userId,
            name: formGroup.get('name').value,
            address_line_1: formGroup.get('address_line_1').value,
            //address_line_2: formGroup.get('address_line_2').value,
            city: formGroup.get('city').value,
            zip_code: formGroup.get('zip_code').value,
            state_id: formGroup.get('state_id').value,
            country: "",
            first_name: formGroup.get('first_name').value,
            last_name: formGroup.get('last_name').value,
            //email: formGroup.get('email').value,
            company: formGroup.get('company').value,
            phone: FormatService.formatPhoneForSave(formGroup.get('phone').value),
            phonetype_id: 1,
            is_active: 1
        };

        formGroup.get('error').setValue('');

        this.accountService.updateAddress(updateAddressObject).subscribe(
            (response: ServerResponse<UserAddress>): any => {
                if (response && response.status && response.status.success) {
                    this.getAddressData();
                    this.selectedIndex = null;
                }
                else {
                    formGroup.get('error').setValue(response.status.message);
                }
            }
        );
    }

    /**
     * Get list of states
     */
    public getStateLists() {
        this.accountService.getStateLists().subscribe((response: ServerResponse<USState[]>) => {
            this.stateArray = response.data.map((st) => {
                return {
                    value: st.id,
                    label: st.name
                };
            });
            this.stateArray.splice(0, 0, {value: '', label: 'Select State'});
        });
    }

    /**
     * Set delete address id
     * @param addressId - Address ID to delete
     */
    public deleteAddressId(addressId) {
        this.deleteAddId = addressId;
    }

    /**
     * Delete address
     */
    public deleteAddress() {
        this.accountService
            .deleteAddress({id: this.deleteAddId, customer_id: this.userId})
            .subscribe((response: any) => {
                this.getAddressData();
            });
    }
    public setDefaultPrimaryOption(addressId){
        this.accountService
            .setPrimaryAddress({id: addressId, customer_id: this.userId})
            .subscribe((response: any) => {
                this.getAddressData();
            });
    }
}
