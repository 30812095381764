import { FormControl } from '@angular/forms';
import { ValidationService } from './shared/validation.service';
var ControlMessagesComponent = /** @class */ (function () {
    function ControlMessagesComponent() {
    }
    Object.defineProperty(ControlMessagesComponent.prototype, "errorMessage", {
        /**
         * Validates errors FormControl and returns pre-defined error message based on error type
         */
        get: function () {
            // console.log(this.control.errors);
            for (var propertyName in this.control.errors) {
                if (this.control.invalid && this.control.touched && this.control.dirty) {
                    return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
                }
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    return ControlMessagesComponent;
}());
export { ControlMessagesComponent };
