import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {UserInsurance} from "../../../../user/model/user-insurance.model";

@Component({
    selector: "app-insurance-form",
    templateUrl: "./insurance-form.component.html",
    styleUrls: ["./insurance-form.component.css"]
})
export class InsuranceFormComponent implements OnInit {

    @Input() formGroup: FormGroup;
    @Input() deleteText = 'Delete';
    @Input() index: number;
    @Input() cardholderRelationship: any[];
    @Input() allowDelete: boolean = true;
    @Input() allowEdit: boolean = true;
    @Input() editing: boolean = true;

    @Input() insurance: UserInsurance;

    @Output() onDelete = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit() {
    }

    /**
     * Delete insurance
     */
    public onDeleteInsurance() {
        this.onDelete.emit(true);
    }

    public onEditInsurance() {
        this.editing = true;
    }

}
