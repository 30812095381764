/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-panel.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./side-panel.component";
import * as i5 from "../../shared/user.service";
var styles_SidePanelComponent = [i0.styles];
var RenderType_SidePanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidePanelComponent, data: {} });
export { RenderType_SidePanelComponent as RenderType_SidePanelComponent };
export function View_SidePanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 42, "div", [["class", "col-sm-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 41, "div", [["class", "clsmy_account_menus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 40, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "li", [], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { "active": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵted(-1, null, ["Order History"])), (_l()(), i1.ɵeld(10, 0, null, null, 6, "li", [], null, null, null, null, null)), i1.ɵdid(11, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(12, { "active": 0 }), (_l()(), i1.ɵeld(13, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(15, 1), (_l()(), i1.ɵted(-1, null, ["Saved Orders"])), (_l()(), i1.ɵeld(17, 0, null, null, 6, "li", [], null, null, null, null, null)), i1.ɵdid(18, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(19, { "active": 0 }), (_l()(), i1.ɵeld(20, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(22, 1), (_l()(), i1.ɵted(-1, null, ["Addresses"])), (_l()(), i1.ɵeld(24, 0, null, null, 6, "li", [], null, null, null, null, null)), i1.ɵdid(25, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(26, { "active": 0 }), (_l()(), i1.ɵeld(27, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 28).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(28, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(29, 1), (_l()(), i1.ɵted(-1, null, ["Requested Medications"])), (_l()(), i1.ɵeld(31, 0, null, null, 6, "li", [], null, null, null, null, null)), i1.ɵdid(32, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(33, { "active": 0 }), (_l()(), i1.ɵeld(34, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 35).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(35, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(36, 1), (_l()(), i1.ɵted(-1, null, ["Profile"])), (_l()(), i1.ɵeld(38, 0, null, null, 4, "li", [], null, null, null, null, null)), i1.ɵdid(39, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(40, { "active": 0 }), (_l()(), i1.ɵeld(41, 0, null, null, 1, "a", [["style", "cursor: pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.userService.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Sign Out"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, (_co.selectedTab == "order-history")); _ck(_v, 4, 0, currVal_0); var currVal_3 = _ck(_v, 8, 0, "/account/order-history"); _ck(_v, 7, 0, currVal_3); var currVal_4 = _ck(_v, 12, 0, (_co.selectedTab == "saved-order")); _ck(_v, 11, 0, currVal_4); var currVal_7 = _ck(_v, 15, 0, "/account/saved-order"); _ck(_v, 14, 0, currVal_7); var currVal_8 = _ck(_v, 19, 0, (_co.selectedTab == "address")); _ck(_v, 18, 0, currVal_8); var currVal_11 = _ck(_v, 22, 0, "/account/address"); _ck(_v, 21, 0, currVal_11); var currVal_12 = _ck(_v, 26, 0, (_co.selectedTab == "request-medication")); _ck(_v, 25, 0, currVal_12); var currVal_15 = _ck(_v, 29, 0, "/account/request-medication"); _ck(_v, 28, 0, currVal_15); var currVal_16 = _ck(_v, 33, 0, (_co.selectedTab == "profile")); _ck(_v, 32, 0, currVal_16); var currVal_19 = _ck(_v, 36, 0, "/account/profile"); _ck(_v, 35, 0, currVal_19); var currVal_20 = _ck(_v, 40, 0, (_co.selectedTab == "signin")); _ck(_v, 39, 0, currVal_20); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 7).target; var currVal_2 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_5 = i1.ɵnov(_v, 14).target; var currVal_6 = i1.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_5, currVal_6); var currVal_9 = i1.ɵnov(_v, 21).target; var currVal_10 = i1.ɵnov(_v, 21).href; _ck(_v, 20, 0, currVal_9, currVal_10); var currVal_13 = i1.ɵnov(_v, 28).target; var currVal_14 = i1.ɵnov(_v, 28).href; _ck(_v, 27, 0, currVal_13, currVal_14); var currVal_17 = i1.ɵnov(_v, 35).target; var currVal_18 = i1.ɵnov(_v, 35).href; _ck(_v, 34, 0, currVal_17, currVal_18); }); }
export function View_SidePanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-side-panel", [], null, null, null, View_SidePanelComponent_0, RenderType_SidePanelComponent)), i1.ɵdid(1, 114688, null, 0, i4.SidePanelComponent, [i3.Router, i5.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidePanelComponentNgFactory = i1.ɵccf("app-side-panel", i4.SidePanelComponent, View_SidePanelComponent_Host_0, {}, {}, []);
export { SidePanelComponentNgFactory as SidePanelComponentNgFactory };
