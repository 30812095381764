import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormGroup} from "@angular/forms";

@Component({
    selector: "app-address-form",
    templateUrl: "./address-form.component.html",
    styleUrls: ["./address-form.component.scss"]
})
export class AddressFormComponent implements OnInit {

    @Input() formGroup: FormGroup;
    @Input() states: any[];

    constructor() {
    }

    ngOnInit() {
    }

    /**
     * Get nickname
     */
    get nickname() {
        return this.formGroup.get("name");
    }

    /**
     * Get first name
     */
    get first() {
        return this.formGroup.get("first_name");
    }

    /**
     * Get last name
     */
    get last() {
        return this.formGroup.get("last_name");
    }

    /**
     * Get company
     */
    get company() {
        return this.formGroup.get("company");
    }

    /**
     * Get email
     */
    get email() {
        return this.formGroup.get("email");
    }

    /**
     * Get phone
     */
    get phone() {
        return this.formGroup.get("phone");
    }

    /**
     * Get street
     */
    get street() {
        return this.formGroup.get("address_line_1");
    }

    /**
     * Get street
     */
    get street_2() {
        return this.formGroup.get("address_line_2");
    }

    /**
     * Get city
     */
    get city() {
        return this.formGroup.get("city");
    }

    /**
     * Get state
     */
    get state() {
        return this.formGroup.get("state_id");
    }

    /**
     * Get zip
     */
    get zip() {
        return this.formGroup.get("zip_code");
    }

    /**
     * Checkes if selected key is a number
     * @param event - Key event
     */
    onlyNumberKey(event) {
        return event.charCode == 8 || event.charCode == 0
            ? null
            : (event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 45);
    }


    onSuggestedAddressSelected(address) {
        if (address.address_line_1) {
            this.formGroup.get('address_line_1').setValue(address.address_line_1);
        }
        if (address.city) {
            this.formGroup.get('city').setValue(address.city);
        }
        if (address.state) {
            const selectedState = this.states.find((state) => {
                return state.label === address.state;
            });
            if (selectedState) {
                this.formGroup.get('state_id').setValue(selectedState.value);
            }
        }
        if (address.zip_code) {
            this.formGroup.get('zip_code').setValue(address.zip_code);
        }

    }

}
