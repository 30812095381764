import {Injectable} from "@angular/core";
import {HttpHeaders, HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";
import {CartService} from "@modules/cart/services";
import * as jwt_decode from "jwt-decode";
import {CheckoutService} from "@modules/checkout/services/checkout.service";
import {UtilsService} from "@services/utils.service";

const reqHeader = new HttpHeaders();

reqHeader.append("Access-Control-Allow-Origin", "*");
reqHeader.append("Access-Control-Allow-Headers", "*");
reqHeader.append("Access-Control-Allow-Methods", " GET, POST, DELETE, PUT, HEAD, OPTIONS");
reqHeader.append("Request Method", "POST, GET, PUT, DELETE, OPTIONS, HEAD");
reqHeader.append("Content-Type", "application/json, application/x-www-form-urlencoded, multipart/form-data, text/plain, charset=UTF-8");

@Injectable({
    providedIn: "root"
})
export class UserService {
    readonly rootUrl = sessionStorage.getItem("location");

    public showMed = false;
    public sendMedication = new BehaviorSubject<boolean>(this.showMed);

    constructor(private http: HttpClient,
                private router: Router,
                private cartService: CartService,
                private checkoutService: CheckoutService
    ) {
    }

    getProductVariant(product, variantId) {

        if (variantId == null ) return null;
        if (product.real.variants.lenght == 0 ) return null;

        return product.real.variants.reduce(function (variantSearched, variant, index) {
            if (variantSearched.variantId == variant.id) {
                variantSearched.variant = variant;
            }
            return variantSearched;
        }, {variant: null, variantId: variantId}).variant;
    }
    getProductPriceRange(product, quantity) {

        return product.real.variants.sort(function (a, b) {
            return a.quantity - b.quantity;
        }).reduce(function (variantSearched, variant, index) {
            if (variantSearched.quantity >= variant.quantity) {
                variantSearched.variant = variant;
            }
            return variantSearched;
        }, {variant: null, quantity: quantity}).variant;
    }
    calculatePrice(orderProduct) {

        const recalculatedPrice = {
            productPrice : 0,
            productPricePerUnit : 0,
        }

        const selectedProductVariant = this.getProductVariant(orderProduct, orderProduct.product_variant_id)

        if (!orderProduct.real.rx) {
            if (orderProduct.real.variants.length > 0) {
                recalculatedPrice.productPrice = UtilsService.roundPrice(selectedProductVariant.price * orderProduct.quantity);
                recalculatedPrice.productPricePerUnit = selectedProductVariant.price / selectedProductVariant.quantity;
            } else {
                recalculatedPrice.productPrice = UtilsService.roundPrice(orderProduct.real.price * orderProduct.quantity);
            }
        } else {
            if (orderProduct.real.variants.length > 0) {
                const productRange = this.getProductPriceRange(orderProduct, orderProduct.quantity);
                // console.log(productRange);
                if (productRange == null) {
                    recalculatedPrice.productPrice = UtilsService.roundPrice(orderProduct.real.price * orderProduct.quantity);
                    recalculatedPrice.productPricePerUnit = orderProduct.real.price;
                } else {
                    recalculatedPrice.productPrice = UtilsService.roundPrice(productRange.price * orderProduct.quantity);
                    recalculatedPrice.productPricePerUnit = productRange.price;
                }
            } else {
                recalculatedPrice.productPrice = orderProduct.real.price * orderProduct.quantity;
                recalculatedPrice.productPricePerUnit = orderProduct.real.price;
            }
        }

        return recalculatedPrice;
    }

    processReorderProduct(orderProduct) {


        const recalculatedPrice = this.calculatePrice(orderProduct);
        const selectedProductVariant = this.getProductVariant(orderProduct, orderProduct.product_variant_id)

        const product = orderProduct.real;
        const productVariant = selectedProductVariant;
        const quantity = orderProduct.quantity;
        const pricePerItem = orderProduct.real.rx ? recalculatedPrice.productPricePerUnit : ((selectedProductVariant != null) ? selectedProductVariant.price : orderProduct.real.price);
        const price = recalculatedPrice.productPrice;

        this.cartService.addProduct(product, productVariant, quantity, pricePerItem, price);

        return {
            product : product,
            productVariant : productVariant,
            quantity : quantity
        }
    }

    reorderProduct(orderProduct) {
        this.cartService.openCartBox(this.processReorderProduct(orderProduct));
      }


    /**
     * Updates access token in local storage and clears the cart for new users
     * @param response - set local storage
     * @param tokenInfo - get token info
     */
    setToken(response: any, tokenInfo: any = null) {
        const previousUser = sessionStorage.getItem("userId");

        if (previousUser !== response.data.user.id) {
            this.cartService.clearCart();
            this.checkoutService.clearCart();
        }
        if (response.data.token) {
            sessionStorage.setItem("userToken", response.data.token);
        }
        if (tokenInfo && tokenInfo.admin_id) {
            sessionStorage.setItem("adminId", tokenInfo.admin_id);
            sessionStorage.setItem("userTokenExpiration", new Date(tokenInfo.exp * 1000).toString());
        } else {
            sessionStorage.setItem("userTokenExpiration", new Date(new Date().getTime() + 360 * 60000).toString());
        }
        sessionStorage.setItem("userFname", response.data.user.first_name);
        sessionStorage.setItem("userId", response.data.user.id);
    }

    /**
     * Returns user ID
     */
    getUserId() {
        return parseInt(sessionStorage.getItem("userId"), 10);
    }

    /**
     * Returns token expiration
     */
    getTokenExpiration() {
        return sessionStorage.getItem("userTokenExpiration");
    }

    /**
     * Returns access token
     */
    getToken() {
        return sessionStorage.getItem("userToken");
    }

    /**
     * Returns access token
     */
    getAdminId() {
        return sessionStorage.getItem("adminId");
    }

    /**
     * Checks if user is logged in based on token expiration
     */
    isLoggedIn() {
        return this.getToken() && (new Date() < new Date(this.getTokenExpiration()));
    }

    /**
     * get account info
     */
    impersonate(token) {
        const tokenInfo = jwt_decode(token);

        sessionStorage.clear();
        sessionStorage.setItem("location", this.rootUrl);

        sessionStorage.setItem("userToken", token);
        sessionStorage.setItem("userTokenExpiration", new Date(tokenInfo.exp * 1000).toString());
        return this.http.get(this.rootUrl + "/customers/info");
    }

    /**
     * Logs the user out and clears local storage
     * Redirects to signin page
     */
    logout(returnUrl = null, doRedirect = true) {
        sessionStorage.clear();
        sessionStorage.setItem("location", this.rootUrl);
        if (this.router.routerState.snapshot.url.indexOf('signin') === -1 && doRedirect) {
            this.router.navigate(["signin"], {queryParams: {returnUrl: returnUrl}});
        }
    }

    /**
     * API for user login
     * @param email - User email
     * @param password - User password
     */
    userAuthentication(email, password) {
        const body = {
            email: email,
            password: password
        };

        return this.http.post(this.rootUrl + "/login", body, {
            headers: reqHeader
        });
    }

    /**
     * API for user registration - step 1
     * @param reginfo - Registration info
     */
    userSignupStepone(reginfo) {
        return this.http.post(this.rootUrl + "/register", reginfo, {
            headers: reqHeader
        });
    }

    /**
     * API for user registration - step 2
     * @param reginfo - Registration info
     */
    completeProfile(reginfo) {
        return this.http.put(this.rootUrl + "/customers/complete-profile", reginfo, {
            headers: reqHeader
        });
    }

    /**
     * API for user registration - rx info from checkout
     * @param reginfo - Registration info
     */
    completeProfileRx(reginfo) {
        return this.http.put(this.rootUrl + "/customers/complete-profile-rx", reginfo, {
            headers: reqHeader
        });
    }

    /**
     * API for forgot password
     **/
    forgotPassword(data) {
        return this.http.post(this.rootUrl + `/forgot/password `, data, {
            headers: reqHeader
        });
    }

    /**
     * API for reset password
     */
    resetPassword(resetData) {
        return this.http.post(this.rootUrl + `/password/change `, resetData, {
            headers: reqHeader
        });
    }

    /**
     * API for password reset link verification
     */
    verifyResetLink(resetlink) {
        return this.http.post(this.rootUrl + `/password/verify-token `, resetlink, {
            headers: reqHeader
        });
    }

    /**
     * Updates medication value
     */
    setmedicationValue(value) {
        this.sendMedication.next(true);
    }

    /**
     * API for user details
     * @param id - User id
     */
    getUserDetails() {
        return this.http.get(this.rootUrl + `/customers/info`);
    }

    /**
     * API for featured products
     */
    getFeaturedProductList() {
        return this.http.get(this.rootUrl + `/products/featured`, {headers: reqHeader});
    }


    /**
     * API for recent products
     */
    getRecentProductList() {
        const data = {
            filters: {
                customer: sessionStorage.getItem("userId"),
            },
            options: {
                order_by: "created",
                order_type: "desc",
                per_page: 3
            }
        };
        return this.http.post(this.rootUrl + `/orders/recent`, data, {headers: reqHeader});
    }

    /**
     * API for adding new medication
     * @param medicationData - Medication data
     */
    addNewMedication(medicationData) {
        return this.http.post(this.rootUrl + `/requested-medications/add`, medicationData, {headers: reqHeader});
    }

}
