export class ServerResponse<T> {

    public status: {
        success: boolean;
        message?: string;
        messages?: string[];
        errors?: any[]|any;
        code: number;
    };

    public data?: T;

    constructor() {}
}
