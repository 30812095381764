import {Component, OnInit, HostListener, OnDestroy} from "@angular/core";
import {UserService} from "../shared/user.service";
import {map} from "rxjs/operators";
import {Observable, of, Subject, Subscription} from "rxjs";
import {Response, RequestOptions} from "@angular/http";
import {
    HttpClientModule,
    HttpHeaders,
    HttpClient,
    HttpRequest
} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {menuitems, MenuItems} from "../shared/menuitems";
import DoomsdayStatus from "@modules/doomsday/model/doomsdaystatus.model";
import {DomSanitizer} from "@angular/platform-browser";
import {SafeUrl} from "@angular/platform-browser/src/security/dom_sanitization_service";
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/throw';

declare var $: any;

@Component({
    selector: "app-user",
    templateUrl: "./user.component.html",
    styleUrls: ["./user.component.scss"]
})
export class UserComponent implements OnInit, OnDestroy {

    public doomsdayStatus: DoomsdayStatus;

    menuItems: MenuItems;
    userClaims: any;
    users: any;
    isAuth: any;
    title: any;
    customActive: string;
    url_getting: string;
    howItWorks: string;
    faq: string;
    aboutUs: string;
    innerWidth: any;
    active = false;

    videoSrcOriginal = 'https://www.youtube.com/embed/eZsVDkRkdMo?autoplay=1';
    videoSrc: SafeUrl;


    dataSubscription: Subscription;

    constructor(private userService: UserService,
                private router: Router,
                private route: ActivatedRoute,
                private sanitizer: DomSanitizer) {
        this.menuItems = menuitems;
        this.howItWorks = this.menuItems.howItWorks;
        this.faq = this.menuItems.faq;
        this.aboutUs = this.menuItems.aboutUs;

        this.dataSubscription = this.route.data
            .subscribe((data: { doomsdayStatus: DoomsdayStatus}) => {
                this.doomsdayStatus = data.doomsdayStatus;
            });

    }

    autoPlayYouTubeModal(){
        const trigger = $("body").find('[data-toggle="modal"]');
        trigger.click(function() {
            console.log('play video');
            const theModal = $(this).data( "target" ),
                videoSRC = $(this).attr( "data-theVideo" ),
                videoSRCauto = videoSRC + "?autoplay=1" ;
            $(theModal + ' iframe').attr('src', videoSRCauto);
            $(theModal + ' button.close').click(function () {
                $(theModal + ' iframe').attr('src', videoSRC);
            });
        });
    }

    ngOnInit() {
        if (this.router.url == "/") {
            this.customActive = "active";
        }
        this.innerWidth = window.innerWidth;
        
    }

    ngOnDestroy(): void {
      this.dataSubscription.unsubscribe();
    }

    setUpVideo() {
        this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoSrcOriginal);
    }
    stopVideo() {
        this.videoSrc = '';
    }

  @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    /**
     * Toggles navigation menu
     */
    activeCollapse() {
        if (this.innerWidth < 600) {
            this.active = !this.active;
        }
    }
}
