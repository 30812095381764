import {Component} from "@angular/core";
import {UserService} from "../../shared/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, AbstractControl, FormGroup, Validators} from "@angular/forms";
import {ValidationService} from "./../../shared/validation.service";
import {UtilsService} from "@services/utils.service";

@Component({
    selector: "app-reset-password",
    templateUrl: "./reset-password.component.html",
    styleUrls: ["./reset-password.component.css"]
})
export class ResetPasswordComponent {
    public resetForm: FormGroup;
    public email: AbstractControl;
    public password: AbstractControl;
    public confirmPassword: AbstractControl;
    public message: any;
    public passwordData: any = {};
    public password_reset: any;
    public resetFormData = false;
    public loading = false;
    public showPassword = false;

    constructor(private userService: UserService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder) {
        this.route.params.subscribe(params => {
            this.password_reset = params["id"];
        });
        // this.verifyResetpassword();
        this.resetForm = fb.group({
                "email": ["", Validators.compose([Validators.required, 
                    //Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                    ValidationService.emailValidator
                ])],
                "password": ["", Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)])],
                "confirmPassword": ["", Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)])]
            },
            {
                // updateOn: "blur",
                validator: ValidationService.validate("password", "confirmPassword")
            });
    }

    /**
     * Reset password and redirects user to login
     */
    public resetPassword() {

        if (!this.loading) {

            if (!this.resetForm.valid) {
                UtilsService.markFormGroupTouched(this.resetForm as FormGroup);
                return;
            }

            const resetObj = {
                "email": this.resetForm.value["email"],
                "password": this.resetForm.value["password"],
                "password_confirmation": this.resetForm.value["confirmPassword"],
                "token": this.password_reset
            };
            this.loading = true;
            this.userService.resetPassword(resetObj)
                .subscribe((response: any) => {
                    this.loading = false;
                    if (response.status.success) {
                        this.resetForm.reset();
                        this.message = response.status.message;
                        setTimeout(() => {
                            this.router.navigateByUrl("/signin");
                        }, 1500);
                    } else {
                        this.message = response.status.message;
                    }
                });
        }
    }

    /**
     * Verifies password reset link
     */
    public verifyResetpassword() {
        const resetLinkObj = {
            "password_reset": this.password_reset
        };
        this.userService.verifyResetLink(resetLinkObj)
            .subscribe((response: any) => {
                if (response.status == 0) {
                    this.resetFormData = true;
                } else {
                    this.resetFormData = false;
                }
            });
    }

    public toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }
}
