import { Component, OnInit } from '@angular/core';
import { AccountService } from '../account.service';

@Component({
  selector: 'app-request-medication',
  templateUrl: './request-medication.component.html',
  styleUrls: ['./request-medication.component.css']
})
export class RequestMedicationComponent implements OnInit {
  public medicationArray: any = [];
  public medicationId: any;
  public selectedLimit = 5;
  public selectedPage = 1;
  public medicationPages = 0;
  public disableNext: boolean = false;

  constructor(private accountService: AccountService) { }

  ngOnInit() {
    this.getMedicationData();
  }

  /**
   * Get medication data
   */
  getMedicationData() {
    let pageData = {
      'limit': this.selectedLimit,
      'page': this.selectedPage
    }
    this.accountService.getMedicationList(pageData)
      .subscribe((response: any) => {
        this.medicationArray = response.data.medications;
        this.medicationPages = response.data.meta.total;
      })
  }

  /**
   * Set medication id to delete
   * @param data - Medication data
   */
  deleteMedicationId(data) {
    this.medicationId = data.id;
  }

  /**
   * Delete medication
   */
  deleteMedication() {
    this.accountService.deleteMedication(this.medicationId)
      .subscribe((response: any) => {
        this.getMedicationData();
      });
  }

  /**
   * Get number of pages
   */
  public getPages(): number[] {
    if (!this.medicationPages) {
      return [];
    }

    let numberOfPages = this.medicationPages / this.selectedLimit;

    if (!Number.isInteger(numberOfPages)) {
      numberOfPages = Math.ceil(numberOfPages);
    }

    const pages = [];
    for (let i = 1; i < numberOfPages + 1; i++) {
      pages.push(i);
    }

    return pages;
  }
  
  /**
   * Select page
   * @param page - Page index
   */
  public selectPage(page) {
    if (
      page &&
      this.medicationPages &&
      this.medicationPages >= page - 1 * this.selectedLimit
    ) {
      this.selectedPage = page;
      this.getMedicationData();
    }
  }
  
  /**
   * Select previous page
   */
  public prevPage() {
    this.selectPage(this.selectedPage - 1);
  }
  
  /**
   * Select next page
   */
  public nextPage() {
    if ((Math.ceil(this.medicationPages / this.selectedLimit) === this.selectedPage)) {
      this.disableNext = true;
    } else {
      this.selectPage(this.selectedPage + 1);
    }
  }
  
  /**
   * Check if current limit equals selected limit
   * @param limit - Current limit
   */
  public isLimitActive(limit): boolean {
    return this.selectedLimit === limit;
  }
}
