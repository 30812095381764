<!--for primeng-->
<div role="tabpanel" class="tab-pane active" id="signup">
    <form [formGroup]="signupForm" (ngSubmit)="onSubmit()" method="POST" id="signup-form">
        <input type="hidden" id="piwikid" name="piwikid" #piwikid formControlName="piwikid"/>
        <input type="hidden" id="mkwid" name="mkwid" #mkwid formControlName="mkwid"/>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="first_name">First</label>
                    <input type="text" formControlName="first_name" name="first_name" class="form-control"
                           id="first_name"
                           placeholder="First Name"/>
                    <control-messages [control]="signupForm.controls.first_name"
                                      class="valisure_error"></control-messages>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="last_name">Last</label>
                    <input type="text" class="form-control" name="last_name" formControlName="last_name" id="last_name"
                           placeholder="Last Name"/>
                    <control-messages [control]="signupForm.controls.last_name"
                                      class="valisure_error"></control-messages>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" name="email" formControlName="email" id="email"
                   autocomplete="off" placeholder="jane@janedoe.com"/>
            <control-messages [control]="signupForm.controls.email" class="valisure_error"></control-messages>
            <div *ngIf="message">
                <div *ngIf="message.email" class="alert alert-danger">{{ message.email }}</div>
            </div>
            <p class="casesensitive" ><span style="color:red;">*</span> Case
                sensitive
            </p>
        </div>
        <div class="form-group">
            <label for="password">Password</label>
            <div class="show-password">
            <input [type]="showPassword ? 'text' : 'password'" class="form-control" name="password" formControlName="password" id="password"
                   autocomplete="off" placeholder="Password"/>
                <a (click)="toggleShowPassword()">{{showPassword ? 'Hide' : 'Show'}}</a>
            </div>
            <control-messages [control]="signupForm.controls.password" class="valisure_error"></control-messages>
            <p class="casesensitive" ><span style="color:red;">*</span> 8-20 characters: Use at least 1 lower letter, 1 upper case letter, 1 number.
            </p>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-7">
                <div class="form-group">
                    <label for="phone">Phone</label>
                    <!-- <input type="number" class="form-control" name="phone" formControlName="phone" onKeyPress="if(this.value.length==10) return false;" id="phone" placeholder="000-000-0000">                        -->
                    <p-inputMask class="clsmedication" formControlName="phone" mask="999-999-9999" id="phone"
                                 placeholder="000-000-0000">
                    </p-inputMask>
                    <control-messages [control]="signupForm.controls.phone" class="valisure_error"></control-messages>
                    <div *ngIf="message">
                        <div *ngIf="message.phone" class="alert alert-danger">{{ message.phone }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-5">
                <div class="form-group">
                    <label for="zip">Zip</label>
                    <!-- <p-inputMask class="clsmedication" formControlName="zip" mask="999-999-9999" placeholder="10001"></p-inputMask> -->
                    <!-- <input matInput class="form-control" formControlName="zip"  placeholder="10001" numberOnly> -->
                    <!-- <input type="text" class="form-control" onKeyPress="if(this.value.length==5) return false;" name="zip" formControlName="zip" id="zip" placeholder="10001"> -->
                    <input type="text" class="form-control" (keypress)="onlyNumberKey($event)" name="zip" maxlength="10"
                           formControlName="zip" id="zip" placeholder="10001"/>
                    <control-messages [control]="signupForm.controls.zip" class="valisure_error"></control-messages>
                </div>
            </div>
        </div>
        <!-- <div class="form-group">
                    <label for="exampleInputEmail1">Desired medications</label>
                    <p-chips class="clschips" formControlName="desired_meds" addOnBlur=true placeholder="Prescription, OTC or supplement"></p-chips>
                    <control-messages [control]="signupForm.controls.desired_meds" class="valisure_error"></control-messages>
                </div> -->
        <!--
        <div class="form-group">
          <label for="desiredMedications">Desired Medications</label>
          <p-autoComplete [suggestions]="filteredProducts" (completeMethod)="filterProducts($event)" [forceSelection]="true"
            [multiple]="true" formControlName="desired_meds" styleClass="clsautocomplete clsmedication" id="desiredMedications"
            [style]="{ width: '100%' }" [inputStyle]="{
              width: '100%',
              'min-width': '400px',
              'font-size': '18px',
              'font-family': 'proxima_novaregular',
              color: '#B1BFCD',
              'white-space': 'nowrap',
              'text-overflow': 'ellipsis'
            }" addOnBlur="true" placeholder="Prescription, OTC or supplement"></p-autoComplete>
          <control-messages [control]="signupForm.controls.desired_meds" class="valisure_error"></control-messages>
        </div>
        -->
<!--
        <div class="form-group">
          <label class="clsinfo_label">Are you a Capital Rx member?
            <span><img src="assets/images/info_icon.png" alt="Info" width="18" style="margin-top: -4px"/>
              <div class="clspopover_content">
                <p>If you are not an exclusive Capital Rx customer please select no. An
                  incorrect selection could delay or compromise your order.</p>
                <p><em>Capital Rx is a next generation pharmacy benefits manager, overseeing
                    prescription benefit plans on behalf of employers, unions and its
                    members.</em></p>
              </div>
            </span>
          </label>
          <ul class="clsfeature_list">
            <li>
              <div class="checkbox">
                <input type="radio" formControlName="is_capitalrx" id="yes" name="is_capitalrx" value="yes"><label
                  for="yes"><span></span>
                  Yes</label>
              </div>
            </li>
            <li>
              <div class="checkbox">
                <input type="radio" formControlName="is_capitalrx" id="no" name="is_capitalrx" value="no"><label
                  for="no"><span></span>
                  No</label>
              </div>
            </li>
          </ul>
        </div>
-->

        <div class="form-group">
            <label for="referred_by">How did you hear about us?</label>
            <p-dropdown scrollHeight="300px" [options]="refrredOptions" id="referred_by" name="referred_by"
                        class="valisure_select" formControlName="referred_by"></p-dropdown>
            <control-messages [control]="signupForm.controls.referred_by" class="valisure_error"></control-messages>
        </div>


        <!--
        <div class="form-group">
            <label>Please let us know the services you’re interested in: <br/>
                Check one or both</label>

            <ul class="clsfeature_list clscheckout_review_check">
                <li>
                    <div class="checkbox">
                        <input type="checkbox" formControlName="purchase_new"
                               id="purchaseNewMedication"
                               (change)="onInterestChange()"
                               name="purchase_new">
                        <label for="purchaseNewMedication"><span></span>Purchase New Medication</label>
                    </div>
                </li>
            </ul>


            <ul class="clsfeature_list clscheckout_review_check">
                <li>
                    <div class="checkbox">
                        <input type="checkbox" formControlName="test_existing"
                               id="testExistingMedicartion"
                               (change)="onInterestChange()"
                               name="test_existing">
                        <label for="testExistingMedicartion"><span></span>Test My Existing Medication</label>
                    </div>
                </li>
            </ul>

            <control-messages [control]="signupForm.controls.test_existing" class="valisure_error"></control-messages>
        </div>
        -->

      <hr />

        <div class="form-group">
            <ul class="clsfeature_list clscheckout_review_check">
                <li>
                    <div class="checkbox">
                        <input type="checkbox" formControlName="agree" id="termsAgree" name="agree">
                        <label for="termsAgree"><span></span>I agree to the <a
                                href="https://www.valisure.com/terms-of-use/" target="_blank">Terms &amp; Conditions</a>
                            and <a href="https://www.valisure.com/hipaa-policy/" target="_blank">HIPAA
                                policy</a></label>
                    </div>
                </li>
            </ul>
            <control-messages [control]="signupForm.controls.agree" class="valisure_error"></control-messages>
            <div *ngIf="message">
                <div *ngIf="message.agree" class="alert alert-danger">{{ message.agree }}</div>
            </div>
        </div>

        <p class="casesensitive" >All fields are required</p>
        <div class="form-group">
            <!-- <p-captcha siteKey="6LcJ3oYUAAAAAIiZl_fjU_AePqxXywHiocfIhLNb" (onResponse)="showResponse($event)" required>
            </p-captcha> -->
            <!-- <div class="g-recaptcha" data-sitekey="6LdfRYAUAAAAAMoZ8ukKPluWpF3c6c9fnshw6M5K"></div> -->
            <!-- <div><img src="images/captcha@2x.png" class="img-responsive" alt="" /></div> -->
        </div>

        <button [disabled]="loadingResponse" type="submit"
                class="btn btn-default btn-block clsyellow_btn form-group">
            <span *ngIf="!loadingResponse">Sign Up</span>
            <span *ngIf="loadingResponse"><i class="fa fa-spinner fa-spin"></i> Please wait</span>
        </button>
        <div *ngIf="message">
            <div *ngIf="message.generic" class="alert alert-danger">{{ message.generic }}</div>
        </div>
        <!-- <button type="submit" class="btn btn-default btn-block clsyellow_btn">Sign Up</button> -->
    </form>
    <div style="font-size: 14px;   text-align: justify; text-justify: inter-word;">
        <p style="padding-top: 15px;">
            To help protect your privacy and to help ensure HIPAA compliance, browsing inventory and analytical tests is
            enabled once signed in. We carry most prescription drugs, over-the-counter medications and supplements but
            generally do not stock schedule II controlled substances, specialty medications, certain drugs that have
            issues in testing or products that have been rejected due to not meeting our quality standards.
        </p>
        <p style="padding-top: 5px;">
            We’re currently licensed in 38 states for dispensing prescriptions (all 50 states for over-the-counter). The
            licensed states are: AK, AZ, CA, CO, CT, DE, FL, GA, HI, IA, ID, IL, IN, MA, MD, ME, MN, MO, MT, NC, ND, NH,
            NM, NJ, NV, NY, OH, PA, RI, SC, SD, TX, UT, VA, VT, WA, WI, WY and Washington DC.

        </p>
        <p style="padding-top: 5px;">
            We’re working hard to expand our reach and by signing up you’re helping us
            to focus on what matters most to you. Thanks!
        </p>
    </div>
    <!-- <div *ngIf="message">
              <div class="alert alert-danger">This email is already registered</div>
            </div> -->
</div>
