import {Component, ElementRef, ViewChild} from "@angular/core";
import {UserService} from "../../shared/user.service";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "@services/utils.service";
import {ValidationService} from "@services/validation.service";

@Component({
    selector: "app-forgot-password",
    templateUrl: "./forgot-password.component.html",
    styleUrls: ["./forgot-password.component.css"]
})
export class ForgotPasswordComponent {
    public forgotForm: FormGroup;
    public message: any;
    public loading = false;

    constructor(private userService: UserService, private router: Router, private fb: FormBuilder) {

        this.forgotForm = fb.group({
                "email": ["", Validators.compose([Validators.required, 
                    //Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                    ValidationService.emailValidator
                ])],
            },
            {
                // updateOn: "blur"
            });

    }

    /**
     * Forgot password and redirect user to login
     */
    public forgotPassword() {

        if (!this.loading) {

            if (!this.forgotForm.valid) {
                UtilsService.markFormGroupTouched(this.forgotForm as FormGroup);
                return;
            }

            const forgotObj = {
                "email": this.forgotForm.value["email"]
            };

            this.loading = true;

            this.userService.forgotPassword(forgotObj)
                .subscribe((response: any) => {
                    this.loading = false;
                    if (response.status.success) {
                        this.forgotForm.reset();
                        this.message = response.status.message;
                        setTimeout(() => {
                            this.router.navigateByUrl("/signin");
                        }, 1500);
                    } else {
                        this.message = response.status.message;
                    }
                });
        }
    }
}
