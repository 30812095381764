import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {UserService} from "@services/user.service";
import {UtilsService} from "@services/utils.service";

@Injectable()
export class LoginGuard implements CanActivate {

    constructor(private router: Router, private userService: UserService) { }

    canActivate(route: ActivatedRouteSnapshot) {
        if (!this.userService.isLoggedIn()) {
            // not logged in so return true
            return true;
        }

        // logged in so redirect to home page
        const returnUrl = route.queryParams['returnUrl'] ? route.queryParams['returnUrl'] : '/';

        UtilsService.redirectToPath(this.router, returnUrl);

        // this.router.navigate([decodeURI(returnUrl)]);

        return false;
    }
}
