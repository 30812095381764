/*
  * SignUp functionality Step 2:
  * @ Created by : Rajasingh S
  * @ Modified by :
  * @ Created date : 14-12-2018
  * @ Modified date : 17-12-2018
  */
import { OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ElementRef } from "@angular/core";
import { DatePipe } from "@angular/common";
/*
  * To import custom ingredients:
  */
import { UserService } from "../../shared/user.service";
import { AccountService } from "app/account/account.service";
import { FormatService } from "@services/format.service";
import { UtilsService } from "@services/utils.service";
import { ValidationService } from "@services/validation.service";
var PersonalinfoComponent = /** @class */ (function () {
    function PersonalinfoComponent(formBuilder, userService, http, router, elem, datePipe, accountService) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.http = http;
        this.router = router;
        this.elem = elem;
        this.datePipe = datePipe;
        this.accountService = accountService;
        this.rootUrl = sessionStorage.getItem("location");
        this.title = "Tell us a little about yourself";
        this.submitted = false;
        this.allergyVal = "";
        this.allergyRel = "";
        this.loadingResponse = false;
        this.states = [];
        this.insurances = [];
        this.showAddress = false;
        this.errorMessage = "";
        this.insuranceErrorMessage = "";
        this.insuranceIndexToDelete = null;
        this.createForm();
        this.getCardRelationshipOptions();
        this.accountService.getStateLists().subscribe(function (states) {
            _this.states = Object.keys(states.data).map(function (key) {
                return {
                    label: states.data[key].name,
                    value: states.data[key].id
                };
            });
            _this.states.splice(0, 0, { value: "", label: "Select State" });
        });
    }
    PersonalinfoComponent.prototype.ngOnInit = function () {
        this.currentUserID = sessionStorage.getItem("userId");
        this.getUserDetailsData();
        // this.getInsuranceData();
    };
    Object.defineProperty(PersonalinfoComponent.prototype, "state", {
        /**
         * State ID of the selected state
         */
        get: function () {
            return this.personalinfoForm.get("state_id");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PersonalinfoComponent.prototype, "address", {
        /**
         * Address of the selected state
         */
        get: function () {
            return this.personalinfoForm.get("address");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PersonalinfoComponent.prototype, "city", {
        /**
         * Address of the selected state
         */
        get: function () {
            return this.personalinfoForm.get("city");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PersonalinfoComponent.prototype, "zip_code", {
        /**
         * Zip code
         */
        get: function () {
            return this.personalinfoForm.get("zip");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PersonalinfoComponent.prototype, "insurancesFormArray", {
        /**
         * Insurances Form Array
         */
        get: function () {
            return this.personalinfoForm.get("insurances");
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Initiates the form group
     */
    PersonalinfoComponent.prototype.createForm = function () {
        this.personalinfoForm = this.formBuilder.group({
            dob: ["", [Validators.required, ValidationService.dateValidator]],
            address_id: "",
            allergies: ["", {
                    validator: Validators.required,
                    updateOn: "change"
                }],
            noallergies: ["", { updateOn: "change" }],
            address: ["", [Validators.required]],
            address_2: "",
            city: ["", [Validators.required]],
            state_id: ["", {
                    updateOn: "change",
                    validators: Validators.required
                }],
            zip: ["", [Validators.required, Validators.pattern('[0-9]{5}(-[0-9]{4})?')]],
            noinsurance: ["", {
                    updateOn: "change"
                }],
            insurances: this.formBuilder.array([], Validators.required),
        }, { updateOn: "blur" });
        this.setDynamicValidators();
    };
    /**
     * Checkes if selected key is a number
     * @param event - Key event
     */
    PersonalinfoComponent.prototype.onlyNumberKey = function (event) {
        return event.charCode == 8 || event.charCode == 0
            ? null
            : (event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 45);
    };
    PersonalinfoComponent.prototype.getInsuranceFormGroup = function (data, addValidators) {
        if (data === void 0) { data = null; }
        if (addValidators === void 0) { addValidators = true; }
        return this.formBuilder.group({
            id: [data ? data.id : ""],
            company: [data ? data.company : "", addValidators ? Validators.required : null],
            id_member: [data ? data.id_member : "", addValidators ? Validators.required : null],
            rx_grp: [data ? data.rx_grp : ""],
            rx_bin: [data ? data.rx_bin : ""],
            rx_pcn: [data ? data.rx_pcn : ""],
            cardholder_id: [data ? data.cardholder_id : "", {
                    updateOn: "change",
                    validators: addValidators ? Validators.required : null
                }]
        }, { updateOn: "blur" });
    };
    /**
     * Controls dynamic validators
     */
    PersonalinfoComponent.prototype.setDynamicValidators = function () {
        var _this = this;
        var allergies = this.personalinfoForm.get("allergies");
        this.personalinfoForm.get("noallergies").valueChanges
            .subscribe(function (value) {
            if (!value) {
                allergies.enable();
                allergies.setValidators([Validators.required]);
            }
            else {
                allergies.disable();
                allergies.reset();
                allergies.clearValidators();
            }
            allergies.updateValueAndValidity();
        });
        this.personalinfoForm.get("noinsurance").valueChanges
            .subscribe(function (value) {
            _this.insuranceErrorMessage = "";
            if (!value) {
                _this.insurancesFormArray.setValidators([Validators.required]);
                _this.insurancesFormArray.controls.forEach(function (insuranceFormGroup) {
                    insuranceFormGroup.get("company").setValidators([Validators.required]);
                    insuranceFormGroup.get("id_member").setValidators([Validators.required]);
                    insuranceFormGroup.get("cardholder_id").setValidators([Validators.required]);
                });
            }
            else {
                _this.insurancesFormArray.clearValidators();
                _this.insurancesFormArray.controls.forEach(function (insuranceFormGroup) {
                    insuranceFormGroup.get("company").setValidators(null);
                    insuranceFormGroup.get("id_member").setValidators(null);
                    insuranceFormGroup.get("cardholder_id").setValidators(null);
                    insuranceFormGroup.get("company").updateValueAndValidity();
                    insuranceFormGroup.get("id_member").updateValueAndValidity();
                    insuranceFormGroup.get("cardholder_id").updateValueAndValidity();
                });
                UtilsService.markFormGroupPristine(_this.insurancesFormArray);
            }
            _this.insurancesFormArray.updateValueAndValidity();
        });
        this.insurancesFormArray.valueChanges.subscribe(function (value) {
            _this.insuranceErrorMessage = "";
        });
    };
    PersonalinfoComponent.prototype.onAddNewInsurance = function () {
        if (this.insurancesFormArray.length < 2) {
            this.insurancesFormArray.push(this.getInsuranceFormGroup(null, true));
        }
    };
    PersonalinfoComponent.prototype.onDeleteInsurance = function (index) {
        // this.insurancesFormArray.removeAt(index);
        this.insuranceIndexToDelete = index;
    };
    PersonalinfoComponent.prototype.deleteInsurance = function () {
        if (this.insuranceIndexToDelete !== null) {
            this.insurancesFormArray.removeAt(this.insuranceIndexToDelete);
            this.insuranceIndexToDelete = null;
        }
    };
    PersonalinfoComponent.prototype.onSuggestedAddressSelected = function (address) {
        if (address.address_line_1) {
            this.personalinfoForm.get('address').setValue(address.address_line_1);
        }
        if (address.city) {
            this.personalinfoForm.get('city').setValue(address.city);
        }
        if (address.state) {
            var selectedState = this.states.find(function (state) {
                return state.label === address.state;
            });
            if (selectedState) {
                this.personalinfoForm.get('state_id').setValue(selectedState.value);
            }
        }
        if (address.zip_code) {
            this.personalinfoForm.get('zip').setValue(address.zip_code);
        }
    };
    /**
     * PersonalInfo form submit, updates personal info
     */
    PersonalinfoComponent.prototype.onSubmit = function () {
        var _this = this;
        this.errorMessage = "";
        if (!this.personalinfoForm.valid) {
            UtilsService.markFormGroupTouched(this.personalinfoForm);
            if (this.insurancesFormArray.invalid && !this.personalinfoForm.get("noinsurance").value) {
                this.insuranceErrorMessage = "Please complete insurance info or select \"No Insurance\"";
            }
            return;
        }
        this.loadingResponse = true;
        this.personalinfoForm.value["user_id"] = sessionStorage.getItem("userId");
        this.submitted = true;
        var personalInfo = Object.assign({}, this.personalinfoForm.value);
        if (this.personalinfoForm.get("noallergies").value) {
            personalInfo.allergies = "No drug allergies";
        }
        else {
            personalInfo.allergies = this.personalinfoForm.get("allergies").value.join(",");
        }
        personalInfo.dob = FormatService.formatDateToISO(this.personalinfoForm.get("dob").value, "/", "-");
        this.userService.completeProfile(personalInfo).subscribe(function (redStep2) {
            _this.loadingResponse = false;
            if (redStep2.status && redStep2.status.success) {
                _this.router.navigate(["/"]);
            }
            else {
                if (redStep2.status) {
                    _this.errorMessage = redStep2.status.message;
                }
            }
        }, function (err) {
            _this.loadingResponse = false;
        });
    };
    /**
     * API for user details
     */
    PersonalinfoComponent.prototype.getUserDetailsData = function () {
        var _this = this;
        this.userService.getUserDetails()
            .subscribe(function (response) {
            if (response.data) {
                _this.userDetailObj = response.data.user;
                _this.personalinfoForm.patchValue({
                    "dob": _this.datePipe.transform(_this.userDetailObj.birth_date, "MM/dd/yyyy"),
                    "noallergies": _this.userDetailObj.medical_info.allergies === "No drug allergies",
                    "noinsurance": !!_this.userDetailObj.no_insurance,
                    "allergies": _this.userDetailObj.medical_info.allergies && _this.userDetailObj.medical_info.allergies !== "No drug allergies" ? _this.userDetailObj.medical_info.allergies.split(",").filter(function (el) { return !!el; }) : [],
                    "address_id": _this.userDetailObj.addresses && _this.userDetailObj.addresses.length && _this.userDetailObj.addresses[0].id ? _this.userDetailObj.addresses[0].id : "",
                    "address": _this.userDetailObj.addresses && _this.userDetailObj.addresses.length && _this.userDetailObj.addresses[0].address_line_1 ? _this.userDetailObj.addresses[0].address_line_1 : "",
                    //"address_2": this.userDetailObj.addresses && this.userDetailObj.addresses.length && this.userDetailObj.addresses[0].address_line_2 ? this.userDetailObj.addresses[0].address_line_2 : "",
                    "city": _this.userDetailObj.addresses && _this.userDetailObj.addresses.length && _this.userDetailObj.addresses[0].city ? _this.userDetailObj.addresses[0].city : "",
                    "state_id": _this.userDetailObj.addresses && _this.userDetailObj.addresses.length && _this.userDetailObj.addresses[0].state_id ? _this.userDetailObj.addresses[0].state_id : "",
                    "zip": _this.userDetailObj.zip_code ? _this.userDetailObj.zip_code : "",
                });
                if (_this.userDetailObj.insurances && _this.userDetailObj.insurances.length) {
                    _this.userDetailObj.insurances.map(function (insurance) {
                        _this.insurancesFormArray.push(_this.getInsuranceFormGroup(insurance, true));
                    });
                }
                else {
                    _this.insurancesFormArray.push(_this.getInsuranceFormGroup(null, !_this.userDetailObj.no_insurance));
                }
            }
        });
    };
    /**
     * Fetch "insurance card relationships" options
     */
    PersonalinfoComponent.prototype.getCardRelationshipOptions = function () {
        var _this = this;
        this.cardholderRelationship = [];
        this.cardholderRelationship.push({ label: "Please select", value: "" });
        this.http
            .get(this.rootUrl + "/cardholder-relationship")
            .subscribe(function (response) {
            if (response.status.success) {
                response.data.forEach(function (value) {
                    _this.cardholderRelationship.push({
                        label: value.name,
                        value: value.id
                    });
                });
            }
        });
    };
    return PersonalinfoComponent;
}());
export { PersonalinfoComponent };
