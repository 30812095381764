import {HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent, HttpResponse, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import { UserService } from '../shared/user.service';
import {Injectable, Injector} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";

import {catchError, tap} from "rxjs/internal/operators";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private userService: UserService, private inj: Injector) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.userService.getToken();

        const router = this.inj.get(Router);

        // console.log(request.url);

        if (this.userService.isLoggedIn() && token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if (event.headers.has('JWT-Refresh')) {
                    const newJWT = event.headers.get('JWT-Refresh');
                    if (newJWT !== '' && newJWT !== null) {
                        this.userService.setToken(newJWT);
                    }
                }
                if (event.body && event.body.status && event.body.status && event.body.status.code === 401) {
                    this.userService.logout( router.url, router.navigated);
                }
            }
        }), catchError((error: HttpErrorResponse) => {
            if (error.status === 503) {
                // DOOMSDAY detected
                if (error.error.is_medication_testing) {
                    this.router.navigate(['/temporarily-unavailable/mt']);
                }
                else {
                    this.router.navigate(['/temporarily-unavailable']);
                }
            }
            return throwError(error);
        }));
    }
}
