import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {AuthInterceptor} from "../../auth/auth.interceptor";

// Services
import {DoomsdayStandardComponent} from "@modules/doomsday/components/doomsday-standard/doomsday-standard.component";
import {DoomsdayHeaderComponent} from "@modules/doomsday/components/doomsday-header/header.component";
import {DoomsdayFooterComponent} from "@modules/doomsday/components/doomsday-footer/footer.component";
import {DoomsdayMtComponent} from "@modules/doomsday/components/doomsday-mt/doomsday-mt.component";
import {RouterModule} from "@angular/router";
import {DoomsdayService} from "@modules/doomsday/services/doomsday.service";
import {DoomsdayResolver} from "@modules/doomsday/resolvers/doomsday.resolver";

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [
        DoomsdayStandardComponent,
        DoomsdayMtComponent,
        DoomsdayHeaderComponent,
        DoomsdayFooterComponent
    ],
    exports: [
        DoomsdayHeaderComponent,
        DoomsdayFooterComponent
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    },
        DoomsdayService,
        DoomsdayResolver
    ]
})
export class DoomsdayModule {
}
