<div class="insurance-form form-group" [formGroup]="formGroup">

  <div class="delete-insurance" *ngIf="allowDelete">
    <a  (click)="onDeleteInsurance()"
        data-toggle="modal" data-target="#deleteInsuranceModal"
       style="cursor: pointer" class="action" >{{deleteText}}</a>
  </div>


  <h4>{{ index == 0 ? 'Primary' : 'Secondary'}} Insurance
    <a  (click)="onEditInsurance()" *ngIf="allowEdit && !editing" style="cursor: pointer" class="action">Edit</a>
  </h4>

  <div class="insurance-preview" *ngIf="!editing">
    <label class="clsno_insurance">
      <strong>
        <em>
          {{ insurance.company }} <br>
          Member Id: {{ insurance.id_member }} <br>
          Group #: {{ insurance.rx_grp }} <br>
          RX #: {{ insurance.rx_bin }} <br>
          RX PCN#: {{ insurance.rx_pcn }} <br>
          Relationship to Cardholder: {{ insurance.cardholder.name }}
        </em>
      </strong>
    </label>
  </div>

  <div class="edit-insurance-form" *ngIf="editing">

    <div class="form-group">
      <label for="insurance_company">Insurance Name</label>
      <input type="text" class="form-control" formControlName="company"
             name="company" id="insurance_company" placeholder="Type here">

      <div *ngIf="formGroup.get('company').invalid && (formGroup.get('company').dirty && formGroup.get('company').touched)" class="alert alert-danger">
        <div *ngIf="formGroup.get('company').errors.required">
          Enter the insurance company name
        </div>
      </div>

    </div>

    <div class="form-group">
      <div class="row">
        <div class="col-sm-6">
          <label for="insurance_member_id">Member ID#</label>
          <input type="text" class="form-control"
                 formControlName="id_member"
                 name="insurance_member_id" id="insurance_member_id"
                 placeholder="Type here">

          <div *ngIf="formGroup.get('id_member').invalid && (formGroup.get('id_member').dirty && formGroup.get('id_member').touched)" class="alert alert-danger">
            <div *ngIf="formGroup.get('id_member').errors.required">
              Enter the member ID#
            </div>
          </div>

        </div>
        <div class="col-sm-6">
          <label for="insurance_group">Group# <span>(optional)</span></label>
          <input type="text" class="form-control"
                 formControlName="rx_grp" name="insurance_group"
                 id="insurance_group" placeholder="Type here">
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="insurance_rx_bin">RX Bin#</label>
      <input type="text" class="form-control" formControlName="rx_bin"
             name="insurance_rx_bin" id="insurance_rx_bin"
             placeholder="Type here">
    </div>

    <div class="form-group">
      <label for="insurance_rx_pcn">RX PCN# <span>(Info Tip:  Find this on your insurance card)</span></label>
      <input type="text" class="form-control" formControlName="rx_pcn"
             name="insurance_rx_bin" id="insurance_rx_pcn"
             placeholder="Type here">
    </div>

    <div class="form-group">
      <label for="insurance_cardholder_relationship">Relationship to
        Cardholder</label>
      <p-dropdown scrollHeight="300px" [options]="cardholderRelationship"
                  id="insurance_cardholder_relationship"
                  name="insurance_cardholder_relationship"
                  class="valisure_select"
                  formControlName="cardholder_id"></p-dropdown>

      <div *ngIf="formGroup.get('cardholder_id').invalid && (formGroup.get('cardholder_id').dirty && formGroup.get('cardholder_id').touched)" class="alert alert-danger">
        <div *ngIf="formGroup.get('cardholder_id').errors.required">
          Select the relationship to cardholder
        </div>
      </div>
    </div>

  </div>


</div>