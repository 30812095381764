import {Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef, Renderer2} from "@angular/core";

import {UserService} from "../shared/user.service";
import {menuitems, MenuItems} from "../shared/menuitems";
import {CartService} from "@modules/cart/services/cart.service";
import {CartProduct} from "@modules/cart/models/cart-product.model";
import {CategoriesService, ProductService} from "@shop/services";
import {Category} from "@modules/shop/models";
import {Subscription} from "rxjs";
import {SearchService} from "@services/search.service";
import {NavigationEnd, Router} from "@angular/router";
import {environment} from "../../environments/environment";

declare var $: any;

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit, OnDestroy {
    private cartProductsSubscription: Subscription;
    private productAddedSubscription: Subscription;

    public cartProducts: CartProduct[] = [];
    public categories: Category[];
    public activeCategory: Category;
    public term = "";
    public productDataAddedToCart: any = null;

    public title = "Valisure";
    public users: any;
    public userClaims: any;
    public userFname: any;
    public menuItems: MenuItems;

    public rxMenuOpen = false;

    public innerWidth: any;
    public active: boolean = false;

    private routerSubscription: Subscription;

    public mobileSearchVisible = false;

    @ViewChild("productAddedModal") productAddedModal: ElementRef<HTMLElement>;

    constructor(public userService: UserService,
                public cartService: CartService,
                private categoriesService: CategoriesService,
                public productService: ProductService,
                private router: Router,
                private searchService: SearchService,
                private renderer: Renderer2) {
        this.cartProductsSubscription = this.cartService
            .getCartProductsSubject()
            .subscribe((products: CartProduct[]) => {
                this.cartProducts = this.cartService.getProducts();
                //console.log(this.cartProducts);
            });
        this.cartService.updateCart();

        this.productAddedSubscription = this.cartService
            .getProductAddedSubject()
            .subscribe((productDataAddedToCart: any) => {
                this.productDataAddedToCart = productDataAddedToCart;
                //if(productAdded){
                //    //this.productAddedModal.nativeElement.modal('show');
                $("#productAddedModal").modal('show')
                //}
            });
        this.menuItems = menuitems;
    }

    ngOnInit() {
        this.userFname = sessionStorage.getItem("userFname");
        this.getCategories();
        this.innerWidth = window.innerWidth;
        this.addJsToElement("./../assets/js/mobileCustom.js").onload = () => {
        };

        this.routerSubscription = this.router.events.subscribe((routerEvent) => {
            if(routerEvent instanceof NavigationEnd){
                this.hideRxMenu();
            }
        });
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    ngOnDestroy() {
        if (this.cartProductsSubscription) {
            this.cartProductsSubscription.unsubscribe();
        }
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
        if (this.productAddedSubscription) {
            this.productAddedSubscription.unsubscribe();
        }
    }

    public showRxMenu() {
        this.rxMenuOpen = true;
    }

    public hideRxMenu() {
        this.rxMenuOpen = false;
    }

    /**
     * Close navigation menu
     */
    public closeNav() {
        $("#navbar-hamburger").removeClass("hidden");
        $("#navbar-close").addClass("hidden");
        $(".clsmobile_navbar").removeClass("in");
    }

    /**
     * Async add asset to project
     * @param src - Asset source url
     */
    addJsToElement(src: string): HTMLScriptElement {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        this.renderer.appendChild(document.body, script);
        return script;
    }

    /**
     * Fetches categories from the server
     */
    private getCategories() {
        this.categoriesService
            .fetchCategories()
            .subscribe((categories: Category[]) => {
                if (categories) {
                    this.activeCategory = categories.find(category => category.id === environment.rx_category_id);
                }
            });
    }

    /**
     * Calculate cart subtotal price
     */
    public getSubtotal(): string {
        return this.cartProducts && this.cartProducts.length
            ? this.cartProducts.map(p => p.price).reduce((f, s) => f + s).toFixed(2)
            : "0.00";
    }

    /**
     * Search shop products and update URL
     */
    public search() {
        this.searchService.updateSearhTerm(this.term);

        this.mobileSearchVisible = false;

        let url = this.router.url;
        if (url.includes("/shop/products") && !url.includes("/shop/products/")) {
            if (url.includes("?")) {
                url = url.substring(0, url.indexOf("?") + 1);
                url += this.term ? "search=" + this.term : "category=2";
            } else {
                url += this.term ? "?search=" + this.term : "/rx";
            }
            this.router.navigateByUrl(url.substring(1, url.length));
        } else {
            this.router.navigateByUrl("shop/products" + (this.term ? "?search=" + this.term : "/rx"));
        }
    }

    /**
     * Remove product from the cart and fetch new products
     * @param id - Product ID
     */
    public removeProduct(id: number) {
        this.cartService.removeProduct(id);
        this.cartProducts = this.cartService.getProducts();
    }

    /**
     * Toggle navigation menu
     */
    activeCollapse() {
        if (this.innerWidth < 600) {
            this.active = !this.active;
        }
    }

    gotoCart(){

    }
}
