import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ShopGuard implements CanActivate {
    constructor(private router: Router, private userService: UserService) { }

    /**
     * Validates the access to products between RX and OTC
     */
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const url = state.url;

        if (url === '/shop/products/rx') {

            this.router.navigate(['shop/products'], {
                queryParams: { category: environment.rx_category_id }
            });
            return false;
        } else if (url === '/shop/products/otc') {
            this.router.navigate(['shop/products'], {
                queryParams: { category: environment.otc_category_id }
            });
            return false;
        } else {
            return true;
        }
    }
}
