import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { SearchService } from "@services/search.service";
import { environment } from "../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/search.service";
var reqHeader = new HttpHeaders();
reqHeader.append("Access-Control-Allow-Origin", "*");
reqHeader.append("Access-Control-Allow-Headers", "*");
reqHeader.append("Access-Control-Allow-Methods", " GET, POST, DELETE, PUT, HEAD, OPTIONS");
reqHeader.append("Request Method", "POST, GET, PUT, DELETE, OPTIONS, HEAD");
reqHeader.append("Content-Type", "application/json, application/x-www-form-urlencoded, multipart/form-data, text/plain, charset=UTF-8");
var ProductService = /** @class */ (function () {
    function ProductService(http, searchService) {
        this.http = http;
        this.searchService = searchService;
        this.displaySearchResults = false;
        this.rootUrl = sessionStorage.getItem("location");
        this.productsSubject = new Subject();
        this.totalProducts = 0;
    }
    /**
     * API for products with filter
     * @param filter - Filters
     */
    ProductService.prototype.getProducts = function (filter) {
        // reqHeader.append('VsAuth', 'Authorization');
        var _this = this;
        this.http
            .post(this.rootUrl +
            // '/products' + (this.searchService.term ? ('?search=' + this.searchService.term) : ('?filterid=' + filter.id + '&filtertype=' + filter.type + '&page=' + filter.page + '&limit=' + filter.limit)),
            "/products", {
            options: {
                per_page: filter.limit,
            },
            filters: {
                category: this.searchService.term ? "" : filter.id,
                search: this.searchService.term ? this.searchService.term : ""
            },
            page: filter.page
        }, { headers: reqHeader })
            .subscribe(function (response) {
            var responseData = response;
            if (responseData.status.success) {
                _this.totalProducts = responseData.data.meta.total;
                var products = responseData.data.products.map(function (product) {
                    return Object.assign({}, product, {
                        image_url: product.image != null ? product.image.full_url : "",
                        thumbnail_url: product.image != null ? product.image.full_thumbnail_url : ""
                    });
                });
                _this.productsSubject.next(products);
            }
            else {
                _this.totalProducts = 0;
                _this.productsSubject.next([]);
            }
        });
    };
    /**
     * API for product details
     * @param id - Product ID
     */
    ProductService.prototype.getProductDetails = function (id) {
        return this.http
            .post(this.rootUrl + "/products/get", {
            id: id
        }, {
            headers: reqHeader
        })
            .pipe(map(function (response) {
            var responseData = response;
            var product = responseData.data ? responseData.data : null;
            if (product) {
                product.image_url = product.image != null ? product.image.full_url : "";
                product.thumbnail_url = product.image != null ? product.image.full_thumbnail_url : "";
            }
            return product;
        }));
    };
    /**
     * Get products subject
     */
    ProductService.prototype.getProductsSubject = function () {
        return this.productsSubject;
    };
    /**
     * API for listing products
     */
    ProductService.prototype.getProductLists = function () {
        return this.http
            .get(this.rootUrl + "/products/all", {
            headers: reqHeader
        })
            .pipe(map(function (response) {
            var product = response;
            return product;
        }));
    };
    /**
     * API for listing products
     */
    ProductService.prototype.getMedicationTests = function () {
        return this.http
            .post(this.rootUrl + "/products", {
            options: {
                per_page: 100
            },
            filters: {
                category: environment.medication_tests_category_id,
            }
        }, {
            headers: reqHeader
        })
            .pipe(map(function (response) {
            var products = response.data.products.map(function (product) {
                return Object.assign({}, product, {
                    image_url: product.image != null ? product.image.full_url : "",
                    thumbnail_url: product.image != null ? product.image.full_thumbnail_url : ""
                });
            });
            return products;
        }));
    };
    ProductService.prototype.getUnitDescriptor = function (type, quantity) {
        if (quantity === void 0) { quantity = 1; }
        if (type) {
            if ((type.descriptor && type.descriptor === 'pill') || type.name === 'Pill') {
                if (quantity !== 1) {
                    return 'pills';
                }
                else
                    return 'pill';
            }
            else if (type.name === 'Analysis') {
                if (quantity !== 1) {
                    return 'analyses';
                }
                else
                    return 'analysis';
            }
            else {
                if (type.descriptor) {
                    return type.descriptor;
                }
                else {
                    var hasPluralS = quantity !== 1;
                    if (hasPluralS && type.name.toLowerCase().charAt(type.name.toLowerCase().length - 1) === 's') {
                        hasPluralS = false;
                    }
                    return type.name.toLowerCase() + (hasPluralS ? 's' : '');
                }
            }
        }
        else
            return '';
    };
    ProductService.prototype.getProductDescriptor = function (type, quantity) {
        var hasPluralS = quantity !== 1;
        if (hasPluralS && type.name.toLowerCase().charAt(type.name.toLowerCase().length - 1) === 's') {
            hasPluralS = false;
        }
        return type.name.toLowerCase() + (hasPluralS ? 's' : '');
    };
    ProductService.prototype.getOptionDescriptor = function (type, quantity) {
        if (type) {
            if (type.descriptor) {
                return this.getUnitDescriptor(type, quantity) + ' / ' + type.name.toLowerCase();
            }
            else {
                return this.getUnitDescriptor(type, quantity);
            }
        }
        else
            return '';
    };
    ProductService.ngInjectableDef = i0.defineInjectable({ factory: function ProductService_Factory() { return new ProductService(i0.inject(i1.HttpClient), i0.inject(i2.SearchService)); }, token: ProductService, providedIn: "root" });
    return ProductService;
}());
export { ProductService };
