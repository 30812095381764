<div class="clswrapper">
  <!-- Main container -->
      <header *ngIf="!userId" class="clsbefore_log_header">
          <nav class="navbar navbar-default">
              <div class="container">
                  <!-- Brand and toggle get grouped for better mobile display -->
                  <div class="navbar-header">
                      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1"
                          aria-expanded="false">
                          <span class="sr-only">Toggle navigation</span>
                          <span class="icon-bar"></span>
                          <span class="icon-bar"></span>
                          <span class="icon-bar"></span>
                      </button>
                      <a class="navbar-brand" [routerLink]="['/signin']"><img  src="../../../assets/images/logo@2x.png" alt="" width="150" /></a>
                  </div>

                  <!-- Collect the nav links, forms, and other content for toggling -->
                  <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                      <ul class="nav navbar-nav navbar-right">
                          <li><a href="https://www.valisure.com/how-it-works/">How It Works <span class="sr-only">(current)</span></a></li>
                          <li><a href="https://www.valisure.com/about-us/" target="_blank">About Us</a></li>
                          <li ><a href="https://www.valisure.com/faq/" target="_blank">FAQ</a></li>
                      </ul>
                  </div><!-- /.navbar-collapse -->
              </div><!-- /.container-fluid -->
          </nav>
      </header>
</div>
 <app-header *ngIf="userId"></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
