import { OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService } from "../account.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UtilsService } from "@services/utils.service";
import { CardDetails } from "@modules/checkout/models/card-details.model";
import { environment } from "../../../environments/environment";
import { ValidationService } from "@services/validation.service";
import { FormatService } from "@services/format.service";
import { ReCaptchaV3Service } from "ng-recaptcha";
var PaymentMethodsComponent = /** @class */ (function () {
    function PaymentMethodsComponent(accountService, router, recaptchaV3Service) {
        this.accountService = accountService;
        this.router = router;
        this.recaptchaV3Service = recaptchaV3Service;
        this.userId = sessionStorage.getItem("userId");
        this.showNewPaymentForm = false;
        this.model = {};
        this.paymentMethodsArray = [];
        this.selectedValue = {};
        this.stateObject = {};
        this.loadingResponse = false;
        this.savingResponse = false;
        this.loadedResponse = true;
        this.addresses = [];
        this.defaultBilling = '';
        this.addressesSelect = [];
        this.newAddress = { label: "Add new address", value: "" };
        this.loadingAddresses = true;
        this.newAddressError = false;
        this.hps = null;
        this.cardDetails = new CardDetails();
        this.stateArray = [];
    }
    PaymentMethodsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formGroup = new FormGroup({
            address: new FormControl(this.defaultBilling, {
                updateOn: "change"
            }),
            cardToken: new FormControl(""),
            saveCreditCard: new FormControl(""),
            new_address: new FormGroup({
                "name": new FormControl("", Validators.required),
                "first_name": new FormControl("", Validators.required),
                "last_name": new FormControl("", Validators.required),
                "company": new FormControl(""),
                "email": new FormControl("", ValidationService.emailValidator),
                "phone": new FormControl("", [Validators.required, ValidationService.phoneValidator]),
                "address_line_1": new FormControl("", Validators.required),
                "address_line_2": new FormControl(""),
                "city": new FormControl("", Validators.required),
                "state_id": new FormControl("", {
                    validators: Validators.required,
                    updateOn: "change"
                }),
                "zip_code": new FormControl("", [Validators.required, Validators.pattern('[0-9]{5}(-[0-9]{4})?')]),
            }),
        });
        this.getPaymentsData();
        this.formGroup.get("address").valueChanges.subscribe(function (change) { return _this.loadAddress(change); });
        this.getAddressData();
        this.getStateLists();
    };
    PaymentMethodsComponent.prototype.ngOnDestroy = function () {
        if (this.hps !== null)
            this.hps.dispose();
    };
    PaymentMethodsComponent.prototype.ngAfterViewInit = function () {
        //this.getSavedCreditCards();
    };
    /**
     * Initiate heartland config
     */
    PaymentMethodsComponent.prototype.initiateHeartland = function () {
        this.hps = new Heartland.HPS({
            publicKey: environment.heartland_key,
            type: "iframe",
            fields: {
                cardNumber: {
                    target: "iframesCardNumber",
                    placeholder: "•••• •••• •••• ••••"
                },
                cardExpiration: {
                    target: "iframesCardExpiration",
                    placeholder: "MM / YYYY"
                },
                cardCvv: {
                    target: "iframesCardCvv",
                    placeholder: "CVV"
                }
            },
            style: {
                "#heartland-field": {
                    width: "calc(100% - 22px)",
                    "border-radius": "2px",
                    border: "1px solid lightgray",
                    color: "gray",
                    padding: "10px",
                    "font-size": "16px"
                }
            },
            onTokenSuccess: function (resp) {
                var _this = this;
                this.paymentError = null;
                //console.log(resp);
                this.recaptchaV3Service.execute('savePaymentMethod')
                    .subscribe(function (recaptchaToken) {
                    _this.saveToken(resp, recaptchaToken);
                });
            }.bind(this),
            onTokenError: function (resp) {
                this.paymentError = resp.error.message;
                this.savingResponse = false;
            }.bind(this)
        });
        /*
        Heartland.Events.addHandler(
            "payment_form",
            "submit",
            function (e) {
                // Prevent the form from continuing to the `action` address
                e.preventDefault();
                // Tell the iframes to tokenize the data
                this.hps.Messages.post(
                    {
                        accumulateData: true,
                        action: "tokenize",
                        message: environment.heartland_key
                    },
                    "cardNumber"
                );
            }.bind(this)
        );
        */
        for (var i = 0; i < document.getElementsByTagName('iframe').length; i++) {
            document.getElementsByTagName("iframe")[i].style.width = "100%";
            document.getElementsByTagName("iframe")[i].style.height = "40px";
        }
    };
    PaymentMethodsComponent.prototype.retriveHeartlandToken = function () {
        this.savingResponse = true;
        this.hps.Messages.post({
            accumulateData: true,
            action: "tokenize",
            message: environment.heartland_key
        }, "cardNumber");
    };
    PaymentMethodsComponent.prototype.saveToken = function (resp, recaptchaToken) {
        var _this = this;
        var paymentObject = {
            address_id: this.formGroup.controls.address.value,
            user_id: this.userId,
            card_type: resp.card_type,
            exp_month: resp.exp_month,
            exp_year: resp.exp_year,
            last_four_digits: resp.last_four,
            token: resp.token_value,
            recaptchaToken: recaptchaToken,
            default: this.formGroup.controls.saveCreditCard.value
        };
        this.accountService.addNewPaymentMethod(paymentObject).subscribe(function (response) {
            if (response && response.status && response.status.success) {
                _this.getPaymentsData();
                _this.toogleNewPayment();
            }
            else {
                _this.paymentError = response.status.message;
                if (response.status.errors && response.status.errors.recaptchaToken && response.status.errors.recaptchaToken.length) {
                    _this.paymentError += " " + response.status.errors.recaptchaToken[0];
                }
            }
            _this.savingResponse = false;
        }, function (error) {
            _this.paymentError = "The payment method could not be added. Please review the card details.";
        });
    };
    PaymentMethodsComponent.prototype.setDefaultPaymentOption = function (paymentMethodId) {
        var _this = this;
        var paymentObject = {
            user_id: this.userId,
            saved_card_id: paymentMethodId
        };
        this.accountService.setDefaultPaymentMethod(paymentObject).subscribe(function (response) {
            if (response && response.status && response.status.success) {
                _this.getPaymentsData();
            }
        });
    };
    /**
    * Toggle new address
    */
    PaymentMethodsComponent.prototype.toogleNewPayment = function () {
        this.showNewPaymentForm = !this.showNewPaymentForm;
        this.checkHeartlandForm();
    };
    /**
     * Get list of addresses
     */
    PaymentMethodsComponent.prototype.getPaymentsData = function () {
        var _this = this;
        this.loadingResponse = true;
        this.accountService
            .getPaymentList(this.userId)
            .subscribe(function (response) {
            _this.paymentMethodsArray = response.data.user.paymentmethods;
            _this.loadedResponse = true;
            _this.loadingResponse = false;
        });
    };
    /**
     * Set delete address id
     * @param addressId - Address ID to delete
     */
    PaymentMethodsComponent.prototype.deletePaymentWithId = function (paymentMethodId) {
        this.deletePaymentMethodId = paymentMethodId;
    };
    /**
     * Delete address
     */
    PaymentMethodsComponent.prototype.deletePaymentMethod = function () {
        var _this = this;
        this.accountService
            .deletePaymentMethod({ id: this.deletePaymentMethodId, customer_id: this.userId })
            .subscribe(function (response) {
            _this.getPaymentsData();
        });
    };
    Object.defineProperty(PaymentMethodsComponent.prototype, "newAddressFormGroup", {
        /**
         * Get New address Form Group
         */
        get: function () {
            return this.formGroup.get('new_address');
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Load selected address
     */
    PaymentMethodsComponent.prototype.loadAddress = function (value) {
        var _this = this;
        if (!value) {
            this.selectedAddress = null;
            Object.keys(this.newAddressFormGroup.controls).forEach(function (control) {
                _this.newAddressFormGroup.get(control).reset();
            });
        }
        else {
            this.selectedAddress = this.addresses.filter(function (addr, index) {
                return addr && addr.id && (addr.id.toString() === value.toString());
            }).pop();
            /**
             * If the value does not exist in the list of addresses (eg: because it was deleted after being selected and saved into the session),
             * default to the first one if it exists
             */
            if (!this.selectedAddress && this.addresses.length) {
                this.formGroup.get("address").setValue(this.addresses[0].id);
            }
        }
        this.checkHeartlandForm();
    };
    PaymentMethodsComponent.prototype.checkHeartlandForm = function () {
        var _this = this;
        /**
         * When the selected address value is toggled, verify if the Heartland form should be reinitialized
         */
        if (this.showNewPaymentForm && this.selectedAddress) {
            if (!this.hps) {
                setTimeout(function () {
                    _this.initiateHeartland();
                }, 10);
            }
        }
        else {
            if (this.hps !== null) {
                this.hps.dispose();
                this.hps = null;
            }
        }
    };
    /**
     * Get list of addresses
     */
    PaymentMethodsComponent.prototype.getAddressData = function () {
        var _this = this;
        this.accountService
            .getAddressList(this.userId)
            .subscribe(function (response) {
            _this.addresses = response.data.user.addresses.concat([_this.newAddress]);
            _this.addressesSelect = [];
            response.data.user.addresses.forEach(function (value, index) {
                _this.addressesSelect.push({
                    label: value.name,
                    value: value.id
                });
            });
            _this.addressesSelect.push(_this.newAddress);
            _this.addresses.map(function (address, idx) {
                if (address.default_billing === 1) {
                    _this.defaultBilling = address.id;
                }
            });
            // Fallback to first address
            if (!_this.defaultBilling && _this.addresses.length) {
                _this.defaultBilling = _this.addresses[0].id;
            }
            if (_this.defaultBilling) {
                _this.formGroup.get("address").setValue(_this.defaultBilling.toString());
            }
            _this.loadingAddresses = false;
        });
    };
    /**
     * Get list of states
     */
    PaymentMethodsComponent.prototype.getStateLists = function () {
        var _this = this;
        this.accountService.getStateLists().subscribe(function (response) {
            _this.states = response.data.map(function (st) {
                return {
                    value: st.id,
                    label: st.name
                };
            });
            _this.states.splice(0, 0, { value: '', label: 'Select State' });
        });
    };
    PaymentMethodsComponent.prototype.save = function () {
        if (!this.selectedAddress) {
            this.addNewAddress(this.newAddressFormGroup);
        }
        else {
            this.retriveHeartlandToken();
        }
    };
    /**
     * Add new address
     * @param formGroup - New address form
     */
    PaymentMethodsComponent.prototype.addNewAddress = function (formGroup) {
        var _this = this;
        if (!formGroup.valid) {
            UtilsService.markFormGroupTouched(formGroup);
            return;
        }
        var addressObject = {
            user_id: this.userId,
            name: formGroup.get('name').value,
            address_line_1: formGroup.get('address_line_1').value,
            //address_line_2: formGroup.get('address_line_2').value,
            city: formGroup.get('city').value,
            zip_code: formGroup.get('zip_code').value,
            state_id: formGroup.get('state_id').value,
            country: "",
            first_name: formGroup.get('first_name').value,
            last_name: formGroup.get('last_name').value,
            //email: formGroup.get('email').value,
            company: formGroup.get('company').value,
            phone: FormatService.formatPhoneForSave(formGroup.get('phone').value),
            phonetype_id: 1,
            is_active: 1
        };
        this.loadingResponse = true;
        this.accountService.addNewAddress(addressObject).subscribe(function (response) {
            if (response && response.status && response.status.success) {
                _this.addresses = [response.data].concat(_this.addresses);
                _this.addressesSelect.splice(0, 0, {
                    label: response.data.name,
                    value: response.data.id
                });
                _this.formGroup.get("address").setValue(response.data.id.toString());
                formGroup.reset();
                _this.loadingResponse = false;
            }
            else {
                if (response.status) {
                    _this.newAddressError = response.status.message;
                }
                else {
                    _this.newAddressError = 'The address could not be saved.';
                }
            }
        });
    };
    PaymentMethodsComponent.prototype.checkExpiration = function (paymentMethod) {
        if (paymentMethod.exp_month && paymentMethod.exp_year) {
            var exp_month = parseInt(paymentMethod.exp_month);
            var exp_year = parseInt(paymentMethod.exp_year);
            var d = new Date();
            if (d.getFullYear() > exp_year) {
                return false;
            }
            if (d.getFullYear() === exp_year && (d.getMonth() + 1) > exp_month) {
                return false;
            }
        }
        return true;
    };
    return PaymentMethodsComponent;
}());
export { PaymentMethodsComponent };
