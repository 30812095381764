import { Component, OnInit } from '@angular/core';
import { AccountService } from '../account.service';
import { Router } from '@angular/router';
import {OrderComplete} from "./../../model/orders/order-complete.model";

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {

  public limits: number[] = [20, 50, 100];
  public ordersArray: OrderComplete[] = [];
  public selectedLimit = 20;
  public selectedPage = 1;
  public orderHistoryPage = 0;
  public disableNext: boolean = false;
  public loadingResponse: boolean = true;
  public loadedResponse: boolean = false;

  constructor(private accountService: AccountService, private router: Router) { }

  ngOnInit() {
    this.getOrderLists();
  }

  
  /**
   * Get orders list
   */
  public getOrderLists() {
    this.loadingResponse = true;
    let pageData = {
      'limit': this.selectedLimit,
      'page': this.selectedPage
    }
    this.accountService.getOrderList('', pageData)
      .subscribe((response: any) => {
        this.ordersArray = response.data.orders;
        this.orderHistoryPage = response.data.meta.total;
        this.loadedResponse = true;
        this.loadingResponse = false;
      });
  }

  /**
   * Navigate to order details 
   * @param order - Order
   */
  public viewOrderDetail(order) {
    this.router.navigate(['/account/orderDetail', order.id]);
  }

  /**
   * Save order
   * @param orderId - Order ID
   */
  public saveOrder(orderId) {
    this.accountService.saveOrder(orderId, true)
      .subscribe((response: any) => {
        this.getOrderLists();
      });
  }

  /**
   * Get number of pages
   */
  public getPages(): number[] {

    let numberOfPages = this.orderHistoryPage / this.selectedLimit;

    if (Number.isInteger(numberOfPages)) {
      numberOfPages = Math.ceil(numberOfPages);
    }

    const pages = [];
    for (let i = 1; i < numberOfPages + 1; i++) {
      pages.push(i);
    }

    return pages;
  }
  
  /**
   * Select page
   * @param page - Page index
   */
  public selectPage(page) {
    if (
      page &&
      this.orderHistoryPage &&
      this.orderHistoryPage >= page - 1 * this.selectedLimit
    ) {
      this.selectedPage = page;
      this.getOrderLists();
    }
  }
  
  /**
   * Select previous page
   */
  public prevPage() {
      if (this.selectedPage > 1) {
          this.selectPage(this.selectedPage - 1);
      }
  }
  
  /**
   * Select next page
   */
  public nextPage() {
    if ((Math.ceil(this.orderHistoryPage / this.selectedLimit) === this.selectedPage)) {
      this.disableNext = true;
    } else {
      this.selectPage(this.selectedPage + 1);
    }
  }
  
  /**
   * Check if current limit equals selected limit
   * @param limit - Current limit
   */
  public isLimitActive(limit): boolean {
    return this.selectedLimit === limit;
  }
  /**
     * Sets the new active limit and updates products
     * @param limit - Limit to set as active
     */
    public selectLimit(limit: number) {
      if (this.limits.indexOf(limit) !== -1) {
        this.selectedPage = 1;
        this.selectedLimit = limit;
          this.getOrderLists();
      }
  }
}
