<div class="clswrapper">
    <div class=""></div>
    <!-- Main container -->
    <main class="clsmain_container" *ngIf="orderData">
        <div class="clsmyaccount_section clsorder_details_section">
            <div class="container">
                <ul class="clsbreadcrumb">
                    <li><a [routerLink]="['/']">Home</a></li>
                    <li><a [routerLink]="['/account/order-history']">Account</a></li>
                    <li><a href="javascript:void(0)" [routerLink]="['/account/order-history']">Order History</a></li>
                    <li>Order Details</li>
                </ul>
                <!--
                <p class="clsaccount_top_backto_btn">
                    <a [routerLink]="['/account/order-history']"><span class="glyphicon glyphicon-menu-left"
                                                                       aria-hidden="true"></span>
                        Back to Order History</a>
                </p>
                -->
                <div class="clsmy_account_main_content_blk">
                    <div class="">
                        <div class="accountContainer">
                            <h1 class="accountTitle " *ngIf="loadedResponse">#{{orderData.order_number}}</h1>    
                            <div class="accountBox" *ngIf="!loadedResponse">
                                <div class="accountMessage">
                                    Loading. Please wait ...
                                </div>
                            </div>
                            <div class="row clscheckout_form_blk_section" *ngIf="loadedResponse">
                                <div class="col-md-6">
                                    <div class="accountBox clscheckout_form clsshipping_form">
                                        <form>
                                            <div class="clslreview_order">
                                                <ul>
                                                    <li>
                                                        <div class="accountBoxTitleSimple">Order Status</div>
                                                        <address class="clscheckout_address">
                                                            <p><strong>Order Placed:</strong> {{orderData.placed |
                                                                date:'MM/dd/yyyy'}}<br>
                                                                <strong>Order Status:</strong> {{orderStatus}} 
                                                                <span *ngIf="canCustomerCancelOrder()"> 
                                                                    (<span *ngIf="!loadingCancelResponse"><a href="javascript:void(0)" data-toggle="modal" data-target="#myModal" title="cancel" class="action" >cancel</a></span> 
                                                                        <span *ngIf="loadingCancelResponse">canceling order...</span>) 
                                                                </span>
                                                            </p>
                                                            <div *ngIf="cancelOrderError != ''" class="form-group">
                                                                <div class="alert alert-danger">{{cancelOrderError}}</div>
                                                            </div>
                                                        </address>
                                                    </li>
                                                    <li>
                                                        <h4>Billing Address</h4>
                                                        <app-address-display [address]="billingAddress"></app-address-display>
    
                                                    </li>
                                                    <li>
                                                        <h4>Shipping Address</h4>
                                                        <app-address-display [address]="shipping_address"></app-address-display>
                                                    </li>
    
                                                    <li>
                                                        <h4>Shipping Method</h4>
                                                        <p>
                                                            {{orderData.shipping_service_name}}<br />
                                                            <span *ngIf="orderData.tracking_number && !orderNotShipped() && !orderCancelled()">Tracking Number: {{orderData.tracking_number}}<br /></span>
                                                            <br />
                                                        </p>
    
                                                    </li>
    
                                                    <li >
                                                        <h4>Payment Method</h4>
                                                        <p *ngIf="!orderData.paymentcc">
                                                            {{orderData.payment_card_type}} {{orderData.payment_last4 ? 'ending with ' + orderData.payment_last4 : ''}}<br /><br />
                                                        </p>
                                                        <p *ngIf="orderData.paymentcc">
                                                            {{orderData.paymentcc.card_type}} {{orderData.paymentcc.last_four_digits ? 'ending with ' + orderData.paymentcc.last_four_digits : ''}}<br /><br />
                                                        </p>
                                                    </li>
    
                                                    <li *ngIf="orderData.insurance">
                                                        <h4>Insurance</h4>
                                                        <p>
                                                            {{orderData.insurance.name}}
                                                            <span *ngIf="orderData.insurance.id_member">ID Member: {{orderData.insurance.id_member}}<br/></span>
                                                            <span *ngIf="orderData.insurance.rx_bin">RX Bin: {{orderData.insurance.rx_bin}}<br/></span>
                                                            <span *ngIf="orderData.insurance.rx_grp">RX Grp: {{orderData.insurance.rx_grp}}<br/></span>
                                                            <span *ngIf="orderData.insurance.rx_pcn">RX PCN: {{orderData.insurance.rx_pcn}}<br/></span>
                                                            <span *ngIf="orderData.insurance.cardholder">Cardholder Relationship: {{orderData.insurance.cardholder.name}}<br/></span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <h4>Counseling Request</h4>
                                                        <p>Would you like to receive counseling from a Pharmacist? Your selection is:</p><br/>
                                                        <p *ngIf="orderData.counseling">YES</p>
                                                        <p *ngIf="!orderData.counseling">NO</p>
                                                        <p *ngIf="orderData.counseling && orderData.counseling_completed !=null"> <br/>Counseling completed on {{orderData.counseling_completed |
                                                            date:'MM/dd/yyyy'}}</p>
                                                        <br/>
                                                        <p>Written information about this prescription will be provided with the medication. Please read this information before you take the medication. If you have questions concerning this prescription, a pharmacist is available during normal business hours ( 9-5pm EST) to answer these questions at 203-497-7370.</p>
                                                    </li>
    
                                                </ul>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="accountBox clsorder_summary_blk">
                                            <div class="accountBoxTitleSimple">Order Summary</div>
                                            <ul>
                                                <li *ngFor="let orderProduct of orderData.products">
                                                    <div class="media">
                                                        <div class="media-body media-middle">
                                                            <span class="item-info">
                                                                <h4 style="font-weight: bold; margin-bottom: 10px;">
                                                                    <span *ngIf="orderProduct.real.active && orderProduct.medication_testing === null" class="userActions" style="padding-left: 20px;" >
                                                                        <a href="javascript:void(0)" (click)="reorderProduct(orderProduct)" class="action poppin_semibold">Reorder</a>
                                                                    </span>
                                                                    <a *ngIf="orderProduct.real.active" href="javascript:void(0);" class="orderPrdDetails" (click)="viewProductDetail(orderProduct)">
                                                                        {{orderProduct.scientific_name}} 
                                                                        <span *ngIf="!orderProduct.is_rx && orderProduct.variant_quantity">({{orderProduct.variant_quantity}} {{productService.getOptionDescriptor(orderProduct.type, orderProduct.variant_quantity)}})</span>
                                                                    </a>
                                                                    
                                                                    
                                                                </h4>
                                                                <p *ngIf="orderProduct.medication_testing" class="medtesting_minicartdisplay" >
                                                                    {{orderProduct.medication_testing.medication_name}} {{orderProduct.medication_testing.dose}}
                                                                </p>
                                                                <p>
                                                                    <span class="clsitem_price">{{(orderProduct.subtotal | currency)}}</span>
        
                                                                    <span class="clsitem_price strike" *ngIf="orderProduct.original_subtotal !== orderProduct.subtotal">{{(orderProduct.original_subtotal | currency)}}</span>
        
                                                                    <span class="clsitem_quantity">Q:
                                                                        {{orderProduct.quantity}}</span>
        
                                                                </p>
                                                                <div class="clearfix"></div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <hr>
                                            <div class="clscalculate_blk">
                                                <ul>
                                                    <li>Subtotal <span
                                                            class="clstotal_amt">
                                                        <i class="strike" *ngIf="orderData?.original_subtotal !== orderData?.subtotal_price">{{ orderData?.original_subtotal | currency}}</i>
                                                        {{ orderData?.subtotal_price | currency}}
                                                    </span>
                                                        <div class="clearfix"></div>
                                                    </li>
        
                                                    <li *ngIf="hasReturnsAmount()">
                                                        <span class="clstotal_amt">-{{orderData.returns_subtotal | currency}}</span>
                                                        Returns
                                                    </li>
        
                                                    <li *ngIf="hasRefundsAmount()">
                                                        <span class="clstotal_amt">-{{orderData.refunds_subtotal | currency}}</span>
                                                        Refunds
                                                    </li>


                                                    
                                                    <li *ngIf="hasDiscountAmount()">
                                                        <span class="clstotal_amt">-{{orderData.discount_amount | currency}}</span>
                                                        Discount ({{ orderData.promocode.code }}) <br/>
                                                        <small style="display:block">{{ orderData.promocode.description }} </small>
                                                        
                                                    </li>
        
                                                    <!--<li>Tax <span class="clstotal_amt">{{orderData.tax_amount | currency}}</span></li> -->
                                                    <li>Shipping <span class="clstotal_amt">{{orderData.shipping_amount && orderData.shipping_amount | currency}}</span></li>
                                                    <li *ngIf="hasDiscountShipping()">
                                                        <span class="clstotal_amt">-{{orderData.shipping_discount_amount | currency}}</span>
                                                        Free Shipping ({{ orderData.promocode.code }}) <br/>
                                                        <small *ngIf="!hasDiscountShipping()" style="display:block">{{ orderData.promocode.description }} </small>
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                            <p class="clscart_total clsfinal_total">Total <span class="clstotal_amt">{{orderData.total_amount | currency}}</span>
                                            </p>
                                            <p *ngIf="allowedReorderProducts().length > 0" class="clscart_total clsfinal_total">&nbsp;
                                                <span class="clstotal_amt">
                                                    <a href="javascript:void(0)" (click)="reorderAllProducts(allowedReorderProducts())" class="action poppin_semibold">Reorder all</a>
                                                </span>
                                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>


<div class="modal fade clsaddress_delete_confirm_modal" id="myModal" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel">
    <div class="vertical-alignment-helper">
        <div class="modal-dialog vertical-align-center" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">Are you sure you want to cancel this order ?</h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xs-6">
                            <a href="javascript:void(0)" data-dismiss="modal" class="action poppin_semibold">No</a>
                        </div>
                        <div class="col-xs-6">
                            <a href="javascript:void(0)" data-dismiss="modal" (click)="cancelOrder()" class="action poppin_semibold">Yes</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>