
  <!-- Main header -->
  <header class="clsmain_header">
      <div class="clsheader_top">
        <div class="container">
          <div class="clslogo_blk">
            <div class="clslogo"><a [routerLink]="['/']"><img src="assets/images/logo@2x.png" alt="" width="150" /></a></div>
          </div>
          <div id="custom-search-input" class="hidden-xs">
            <form>
              <div class="input-group col-md-12">
                <input type="text" class="search-query form-control" [(ngModel)]="term" name="term" (keyup.enter)="search()" placeholder="Losartan, Acetaminophen, etc." autocapitalize="none" />
                <span class="input-group-btn">
                  <button class="btn btn-danger" type="button" (click)="search()">
                    <span class=""><img src="assets/images/search@2x.png" alt="" width="15" /></span>
                  </button>
                </span>
              </div>
            </form>
          </div>
          <form class="navbar-form visible-xs clssearch_xs_form" role="search" [class.active]="mobileSearchVisible">
            <div class="input-group">
              <input type="text" class="form-control" *ngIf="mobileSearchVisible" placeholder="Search" [(ngModel)]="term" name="term" (keyup.enter)="search()" autocapitalize="none" />
              <span class="input-group-btn">
                <button type="reset" class="btn btn-default" (click)="mobileSearchVisible = false">
                  <span class="glyphicon glyphicon-remove">
                    <span class="sr-only">Close</span>
                  </span>
                </button>
                <button type="button" class="btn btn-default clssearch_icon" (click)="mobileSearchVisible = true">
                  <span class=""><img src="assets/images/search@2x.png" alt="" width="15" />
                    <span class="sr-only">Search</span>
                  </span>
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
      <div class="clsheader_bottom">
        <div class="container">
          <div class="navbar navbar-default clsmega_menu">
            <div class="navbar-header">
              <button type="button" data-toggle="collapse" data-target="#navbar-collapse-2" class="navbar-toggle">
                <div id="navbar-hamburger">
                  <span class="sr-only">Toggle navigation</span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                </div>
                <div id="navbar-close" class="hidden">
                  <span><img src="assets/images/close-icon@2x.png" alt="" width="14" /></span>
                </div>
              </button>
            </div>
            <div id="navbar-collapse-1" class="navbar-collapse collapse clsdesktop_navbar">
              <ul class="nav navbar-nav">
                <li class="dropdown"><a [routerLink]="['/shop/products/rx']" class="dropdown-toggle">Shop Rx</a></li>
                <!--
                <li class="dropdown" [class.open]="rxMenuOpen" (mouseenter)="showRxMenu()" (mouseleave)="hideRxMenu()"><a [routerLink]="['/shop/products/rx']" class="dropdown-toggle">Shop Rx</a>
                  <ul class="dropdown-menu">
                    <li>
                      <div class="clsmega_menu_content">
                        <div class="clsmegamenu_submenu_blk">
                          <h4>My Rx</h4>
                          <ul>
                            <li><a [routerLink]="['/shop/products/rx']">Shop Rx</a></li>
                            <li><a [routerLink]="['/prescription']">Rx: How To</a></li>
                            <li><a [routerLink]="['/shop/products/rx']">Start New Prescription</a></li>
                            <li><a [routerLink]="['/account/order-history']">Refill Rx</a></li>
                            <li><a [routerLink]="['/account/order-history']">Rx History</a></li>
                            <li><a [routerLink]="['/shop/inventory']">Valisure Inventory</a></li>
                          </ul>
                        </div>
                        
                      </div>
                    </li>
                  </ul>
                </li>
                -->
                <li class="dropdown"><a [routerLink]="['/shop/products/otc']" class="dropdown-toggle">Shop OTC</a>
                </li>
                

                <!--
                <li class="dropdown">
                  <a [routerLink]="['/shop/medication-testing']" class="dropdown-toggle">Medication Testing</a>
                </li>
                -->
                <!--
                <li class="dropdown">
                  <a href="{{ menuItems.analysis }}" target="_blank" class="dropdown-toggle">Analysis</a>
                </li>
                -->
                <li class="dropdown yamm-fw">
                  <!--<a href="{{ menuItems.forDoctors }}" target="_blank" class="dropdown-toggle">For Doctors</a>-->
                  <a [routerLink]="['/shop/inventory']">Valisure Inventory</a>
                </li>
                <li class="dropdown yamm-fw">
                  <!--<a href="{{ menuItems.faq }}" target="_blank" class="dropdown-toggle">FAQ</a>-->
                  <a [routerLink]="['/prescription']">Rx: How To</a>
                </li>
                <li class="dropdown yamm-fw">
                  <a href="{{ menuItems.faq }}" target="_blank" class="dropdown-toggle">FAQ</a>
                </li>
              </ul>
  
            </div>
            <div id="navbar-collapse-2" class="navbar-collapse collapse clsmobile_navbar">
              <ul class="nav nav-list" style="padding-top: 10px; padding-bottom:10px">
                <li class="dropdown">
                  <a [routerLink]="['/shop/products/rx']" class="dropdown-toggle" (click)="closeNav()">Shop Rx</a>
                </li>
                <!--
                <li><a href="javascript:void(0)" class="tree-toggle">Shop Rx <span class="clsclick_arrow down_arrow"></span></a>
                  <ul class="nav nav-list tree">
                    <li (click)="closeNav()"><a [routerLink]="['/shop/products/rx']">Shop All</a></li>
                    <li><a href="javascript:void(0)" class="tree-toggle">My Rx <span class="clsclick_arrow down_arrow"></span></a>
                      <ul class="nav nav-list tree">
                        <li (click)="closeNav()"><a [routerLink]="['/prescription']">Start New Rx</a></li>
                        <li (click)="closeNav()"><a [routerLink]="['/prescription']">Refill Rx</a></li>
                        <li (click)="closeNav()"><a [routerLink]="['/prescription']">Transfer Rx</a></li>
                        <li (click)="closeNav()"><a [routerLink]="['/account/order-history']">Rx History</a></li>
                        <li (click)="closeNav()"><a [routerLink]="['/shop/inventory']">Valisure Inventory</a></li>
                      </ul>
                    </li>
                    <li ><a href="javascript:void(0)" class="tree-toggle">Categories <span class="clsclick_arrow down_arrow"></span></a>
  
                      <ul class="nav nav-list tree">
                        <li *ngFor="let category of activeCategory?.children_recursive" (click)="closeNav()">
                          <a [routerLink]="['/shop/products']" [queryParams]="{ category: category.id }">{{
                            category.name }}</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                -->
                <li class="dropdown">
                  <a [routerLink]="['/shop/products/otc']" class="dropdown-toggle" (click)="closeNav()">Shop OTC</a>
                </li>
                <!--
                <li class="dropdown">
                  <a [routerLink]="['/shop/medication-testing']" class="dropdown-toggle" (click)="closeNav()">Medication Testing</a>
                </li>
                -->
                <!--
                <li class="dropdown">
                  <a href="{{ menuItems.analysis }}" target="_blank" class="dropdown-toggle">Analysis</a>
                </li>
                -->
                <li class="dropdown yamm-fw">
                  <!--<a href="{{ menuItems.forDoctors }}" target="_blank" class="dropdown-toggle">For Doctors</a>-->
                  <a [routerLink]="['/shop/inventory']" (click)="closeNav()">Valisure Inventory</a>
                </li>
                <li class="dropdown yamm-fw">
                  <!--<a href="{{ menuItems.faq }}" target="_blank" class="dropdown-toggle">FAQ</a>-->
                  <a [routerLink]="['/prescription']" (click)="closeNav()">Rx: How To</a>
                </li>
                <li class="foruser navsep"><a [routerLink]="['/account/recent-purchases']" (click)="closeNav()">Recent Purchases</a></li>
                <li class="foruser"><a [routerLink]="['/account/order-history']" (click)="closeNav()" >Order History</a></li>
                <!--<li><a [routerLink]="['account/saved-order']">Saved Orders</a></li>-->
                <li class="foruser"><a [routerLink]="['/account/address']" (click)="closeNav()">Addresses</a></li>
                <li class="foruser"><a [routerLink]="['account/payment-methods']" (click)="closeNav()">Payment Methods</a></li>
                <!--<li><a [routerLink]="['account/request-medication']">Requested Medications</a></li>-->
                <li class="foruser"><a [routerLink]="['/account/profile']" (click)="closeNav()">Profile</a></li>
                <li class="foruser"><a *ngIf="userService.isLoggedIn()" (click)="userService.logout()" style="cursor: pointer;">Sign Out</a></li>
              </ul>
  
            </div>
            <ul class="nav navbar-nav navbar-right clscart_menu_blk">
              <li class="dropdown clsuser_dropdown"><a href="#" data-toggle="dropdown" class="dropdown-toggle">{{ userFname }}
                  <span class="glyphicon glyphicon-menu-down" aria-hidden="true"></span></a>
                <ul class="dropdown-menu">
                  <li><a [routerLink]="['/account/recent-purchases']" >Recent Purchases</a></li>
                  <li><a [routerLink]="['/account/order-history']">Order History</a></li>
                  <!--<li><a [routerLink]="['account/saved-order']">Saved Orders</a></li>-->
                  <li><a [routerLink]="['/account/address']">Addresses</a></li>
                  <li><a [routerLink]="['/account/payment-methods']">Payment Methods</a></li>
                  <!--<li><a [routerLink]="['account/request-medication']">Requested Medications</a></li>-->
                  <li><a [routerLink]="['/account/profile']">Profile</a></li>
                  <li><a *ngIf="userService.isLoggedIn()" (click)="userService.logout()" style="cursor: pointer;">Sign Out</a></li>
                </ul>
              </li>
              <li class="dropdown clscart_dropdown">
                <a href="#" id="cartdropdown" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"> <span
                    class=""><img src="assets/images/cart@2x.png" alt="" width="30" /></span><span *ngIf="cartProducts && cartProducts.length > 0" class="badge">{{ cartProducts.length }}</span></a>
                <div class="dropdown-menu dropdown-cart" role="menu">
                  <h3>Cart</h3>
                  <ul>
                    <li *ngIf="cartProducts.length == 0">Your cart is empty</li>
                    <li *ngFor="let cartProduct of cartProducts; let i = index">
                      <div class="media item">
                        <div class="media-left">
                          <!-- <a href="#"> -->
                          <img class="product-image" alt="{{cartProduct.product.scientific_name}}" src="{{ cartProduct.product.image.full_thumbnail_url }}" alt="" />
                          <!-- </a> -->
                        </div>
                        <div class="media-body media-middle">
                          <span class="item-info">
                            <h4>{{ cartProduct.product.scientific_name }}</h4>
                            <div *ngIf="!cartProduct.product.rx  && cartProduct.productVariant != null"> ( {{ cartProduct.productVariant.quantity}} {{productService.getOptionDescriptor(cartProduct.product.type, cartProduct.productVariant.quantity)}} )</div>
                            <p *ngIf="cartProduct.product.hasOwnProperty('medication_testing')" class="medtesting_minicartdisplay" >
                              {{cartProduct.product.medication_testing.medication_name}} {{cartProduct.product.medication_testing.dose}}
                            </p>
                            <p>
                              <span class="clsitem_price">{{ cartProduct.price | currency }}</span>
                              <span class="clsitem_quantity">Q: {{ cartProduct.quantity }}</span>
                              <span class="clsdelete">
                                <button class="btn btn-xs pull-right product-delete" (click)="removeProduct(i)">
                                  <i class="glyphicon glyphicon-trash" aria-hidden="true"></i>
                                </button>
                              </span>
                            </p>
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <p class="clscart_total">Subtotal <span class="clstotal_amt">{{ getSubtotal() | currency }}</span></p>
                  <div class="clscart_checkout_blk">
                    <ul>
                      <li>
                        <a [routerLink]="['/cart']" class="btn clsview_cart" style="margin-right: 10px; padding-left: 0px;">View Cart</a>
                      </li>
                      <li *ngIf="cartProducts.length > 0">
                        <button type="button" [routerLink]="['/checkout']" class="btn btn-default clsyellow_btn">
                          Checkout
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>

  <div  #productAddedModal class="modal fade clsproduct_added_cart_confirmation" id="productAddedModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="vertical-alignment-helper">
        <div class="modal-dialog vertical-align-center" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title" *ngIf="productDataAddedToCart !== null" id="myModalLabel">{{productDataAddedToCart.quantity}} x {{productDataAddedToCart.product.scientific_name}} <span *ngIf="!productDataAddedToCart.product.rx  && productDataAddedToCart.productVariant != null"> ( {{ productDataAddedToCart.productVariant.quantity}} {{productService.getOptionDescriptor(productDataAddedToCart.product.type, productDataAddedToCart.productVariant.quantity)}} ) </span> added to cart</div>
                    <div class="modal-title" *ngIf="productDataAddedToCart === null" id="myModalLabel">Products added to cart</div>
                </div>
                <div class="modal-body text-center">
                    <div class="row">
                        <div class="col-xs-6">
                            <a [routerLink]="['/cart']" data-dismiss="modal" class="action poppin_semibold">View Cart</a>
                        </div>
                        <div class="col-xs-6">
                            <a href="javascript:void(0)" data-dismiss="modal" class="action poppin_semibold">Continue Shopping</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
