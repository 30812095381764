import {Component, OnInit, ElementRef, ViewChild, OnDestroy} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {AccountService} from "../account/account.service";
import {ProductService} from "././../modules/shop/services/product.service";
import {UserService} from "../shared/user.service";

@Component({
    selector: "app-user-home",
    templateUrl: "./user-home.component.html",
    styleUrls: ["./user-home.component.css"]
})
export class UserHomeComponent implements OnInit, OnDestroy {
    public userName: any = sessionStorage.getItem("userFname");
    public userId: any = sessionStorage.getItem("userId");
    public orderListArray: any = [];
    public featuredOtc: any = [];
    public featuredRx: any = [];
    public imageBaseUrl: any = sessionStorage.getItem("location");
    public recentOrderArray: any = [];
    public recentOrdersList: any = [];
    public medicationObject: any = {};
    public showMedication = true;
    public recentOrders: any = [];
    public recentPurchases: any = [];
    public loadingResponse: boolean = false;
    public isCapitalRx: boolean = false;

    @ViewChild("requests") requests: ElementRef;

    constructor(private accountService: AccountService,
                public productService: ProductService,
                private userService: UserService,
                public sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.getUserData();
        this.getOrderLists();
        this.getFeaturedProductList();
        this.userService.sendMedication.subscribe(response => {
            if (response === true) {
                const requestDiv: HTMLElement = this.requests.nativeElement as HTMLElement;
                requestDiv.click();
            }
        }, error => {
        });
    }

    /**
     * API for getting User Details
     */
    public getUserData() {
        this.userService.getUserDetails()
            .subscribe((response: any) => {
                if (response.data.user.rx_member == "1") {
                    this.isCapitalRx = true;
                }
            });
    }

    /**
     * API for getting products list
     */
    public getOrderLists() {
        const pageData = {
            'limit': 3,
            'page': 1
        }
        this.accountService.getRecentPurchasesHomePageList('', pageData)
            .subscribe((response: any) => {
                this.recentPurchases = response.data.products;
                //this.loadedResponse = true;
                this.loadingResponse = false;
            }
        );
        /*
        this.userService.getRecentProductList()
            .subscribe((response: any) => {
                if (response.status.success) {
                    this.recentOrders = response.data.orders;
                }
            }, error => {
            });
        */
    }

    public getOrderProductImage(orderProduct) {
        if (orderProduct.real && orderProduct.real.image) {
            return orderProduct.real.image.full_thumbnail_url;
        }
        else return '/assets/images/valisure-default-product-thumbnail.jpg';
    }

    /**
     * API for featured products list
     */
    public getFeaturedProductList() {
        this.userService.getFeaturedProductList()
            .subscribe((response: any) => {
                if (response.status.success === true) {
                    this.featuredOtc = response.data.otc;
                    this.featuredRx = response.data.rx;

                    //console.log(this.featuredOtc);
                }
            }, error => {
            });
    }

    /**
     * API for recent orders
     */
    public getRecentOrderData() {
        this.orderListArray.forEach(elementOrder => {
            this.recentOrderArray.forEach(elementRecent => {
                if (elementOrder.id == elementRecent.order_id && !this.recentOrdersList.find(order => order.orderNumber == elementRecent.orderNumber)) {
                    const recentDataObj = {
                        "name": elementRecent.product.scientific_name,
                        "total": elementRecent.price * elementRecent.quantity,
                        "placed": elementOrder.placed,
                        "status": elementOrder.fulfillment_status.name,
                        "image": elementRecent.product.product_image[0].image_url,
                        "id": elementRecent.product_id,
                        "orderNumber": elementRecent.orderNumber
                    };
                    this.recentOrdersList.push(recentDataObj);
                }
            });
        });
    }

    /**
     * API for add medication
     */
    public addMedicationData() {
        this.loadingResponse = true;
        const medicationData = {
            "customer_id": this.userId,
            "name": this.medicationObject.medication
        };
        this.userService.addNewMedication(medicationData)
            .subscribe((response: any) => {
                this.loadingResponse = false;
                if (response.status.success) {
                    this.showMedication = false;
                    this.medicationObject.medication = null;
                } else {
                    this.showMedication = true;
                }
            });
    }

    public showNewMedication() {
        this.showMedication = true;
    }

    ngOnDestroy() {
        this.showMedication = false;
    }

    public reorderProduct(product){
        this.userService.reorderProduct(product);
    }

}
