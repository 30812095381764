import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {CartService} from "@modules/cart/services";
import {HttpHeaders, HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {CartProduct} from "@modules/cart/models/cart-product.model";
import {AddressService} from "./address.service";
import {Observable, BehaviorSubject, Subject} from "rxjs";

@Injectable({
    providedIn: "root"
})
export class CheckoutService {
    private POST_ORDER_URL =
        "/orders/place";
    private headers: HttpHeaders;
    private billingAddress: any;
    private counseling: boolean = false;
    private insuranceOption: any;
    private shippingAddress: any;
    private shippingOption: any;
    private heartlandToken: any;
    private heartlandData: any;
    private payment: any;
    private card: any;
    private recaptchaToken: string;

    private piwikid: string = '';
    private mkwid: string = '';

    private shippingStateErrorProducts: any;
    public shippingStateError: BehaviorSubject<any> = new BehaviorSubject(false);

    private errorMessage = '';

    readonly rootUrl = sessionStorage.getItem("location");
    public checkoutStep = 1;

    constructor(private http: HttpClient,
                private router: Router,
                private cartService: CartService) {

        const savedCartData = sessionStorage.getItem("cart_details");
        if (savedCartData) {
            const data = JSON.parse(savedCartData);
            if (data) {
                if (data.checkoutStep) {
                    this.checkoutStep = data.checkoutStep;
                }
                if (data.billingAddress) {
                    this.billingAddress = data.billingAddress;
                }
                if (data.insuranceOption) {
                    this.insuranceOption = data.insuranceOption;
                }
                if (data.shippingAddress) {
                    this.shippingAddress = data.shippingAddress;
                }
                if (data.shippingOption) {
                    this.shippingOption = data.shippingOption;
                }
                if (data.payment) {
                    this.payment = data.payment;
                }
                /*
                if (data.heartlandToken) {
                    this.heartlandToken = data.heartlandToken;
                }
                if (data.heartlandData) {
                    this.heartlandData = data.heartlandData;
                }
                */

                if (data.shippingStateErrorProducts) {
                    this.shippingStateErrorProducts = data.shippingStateErrorProducts;
                }
            }
        }

        this.headers = new HttpHeaders();
        this.headers.append("Access-Control-Allow-Origin", "*");
        this.headers.append("Access-Control-Allow-Headers", "*");
        this.headers.append(
            "Access-Control-Allow-Methods",
            " GET, POST, DELETE, PUT, HEAD, OPTIONS"
        );
        this.headers.append("Request Method", "POST, GET, PUT, DELETE, OPTIONS, HEAD");
        this.headers.append(
            "Content-Type",
            "application/json, application/x-www-form-urlencoded, multipart/form-data, text/plain, charset=UTF-8"
        );
    }

    /**
     * Set the cart error
     * @param {string} error
     */
    public setErrorMessage(error: string) {
        this.errorMessage = error;
    }

    /**
     * get the cart error
     * @returns {string}
     */
    public getErrorMessage() {
        return this.errorMessage;
    }

    /**
     * Set counseling
     * @param counseling - Boolean
     */
    public setCounseling(counselingValue: any) {
        this.counseling = counselingValue;
        this.saveCartDetails();
    }

    /**
     * Get counseling
     */
    public getCounseling() {
        return this.counseling;
    }

    /**
     * Set billing address
     * @param address - Address
     */
    public setBillingAddress(address: any) {
        this.billingAddress = address;
        this.saveCartDetails();
    }

    /**
     * Get billing address
     */
    public getBillingAddress() {
        return this.billingAddress;
    }

    /**
     * Set Insurance
     */
    public setInsuranceOption(insurance: any) {
        this.insuranceOption = insurance;
        this.saveCartDetails();
    }

    /**
     * Get Insurance
     */
    public getInsuranceOption() {
        return this.insuranceOption;
    }

    /**
     * Set shipping address
     * @param address - Address
     */
    public setShippingAddress(address: any) {
        this.shippingAddress = address;
        this.saveCartDetails();
    }

    /**
     * Get shipping address
     */
    public getShippingAddress() {
        return this.shippingAddress;
    }

    /**
     * Set shipping option
     * @param option Shipping option
     */
    public setShippingOption(option: any) {
        this.shippingOption = option;
        this.saveCartDetails();
    }

    /**
     * Get shipping option
     */
    public getShippingOption() {
        return this.shippingOption;
    }

    public setShippingStateErrorProducts(products: any) {
        this.shippingStateErrorProducts = products;
        this.saveCartDetails();
    }

    public setShippingStateError(value) {
        if (value) {
            this.shippingStateError.next(value);
        } else {
            this.shippingStateError.next(false);
        }
        this.saveCartDetails();
    }

    public getShippingStateErrorProducts() {
        return this.shippingStateErrorProducts;
    }

    public getShippingStateErrorProduct(productId) {
        if (this.shippingStateErrorProducts) {
            return this.shippingStateErrorProducts.filter(product => product === productId);
        }
        return false;
    }

    /**
     * Set Heartland token
     * @param data
     */
    public setHeartlandToken(data: any) {
        /*
        this.card.number = data.last_four;
        this.card.type = data.card_type && data.card_type.charAt(0).toUpperCase() + data.card_type.slice(1);
        this.saveCreditCard = data.save_credit_card;
        this.addNewCard = data.add_new_card;
        */
        this.heartlandData = data;
        if (data) {
            this.heartlandToken = data.token_value;
            this.heartlandData.card_type = data.card_type && data.card_type.charAt(0).toUpperCase() + data.card_type.slice(1);
        }
        else {
            this.heartlandToken = null;
        }

        this.saveCartDetails();
    }

    public setPayment(payment: any) {
        this.payment = payment;
        this.saveCartDetails();
    }

    public getPayment() {
        return this.payment;
    }

    public setCard(card: any) {
        console.log(card);
        this.card = {
            id: card.id,
            card_type: card.card_type,
            exp_month: card.exp_month,
            exp_year: card.exp_year,
            last_four_digits: card.last_four_digits
        };
        this.saveCartDetails();
    }

    public getCard() {
        return this.card;
    }

    /**
     * Get/Set Piwikid and Mkwid
     */
    public getPiwikid() {
        return this.piwikid;
    }

    public getMkwid() {
        return this.mkwid;
    }

    public setPiwikidAndMkwid(piwikid: string, mkwid: string) {
        if (piwikid) {
            this.piwikid = piwikid;
        }
        if (mkwid) {
            this.mkwid = mkwid;
        }
        this.saveCartDetails();
    }

    public getRecaptchaToken() {
        return this.recaptchaToken;
    }

    public setRecaptchaToken(token) {
        this.recaptchaToken = token;
    }

    /**
     * Get Heartland token
     */
    public getHeartlandToken() {
        return this.heartlandData;
    }

    public getHeartlandData() {
        return this.heartlandData;
    }

    /**
     * Move to next step
     * @param step - Current step index
     */
    public nextStep(step: number): Promise<boolean> | Observable<any> {
        this.checkoutStep = step;
        this.saveCartDetails();

        switch (this.checkoutStep) {
            case 1: {
                return this.router.navigate(["/checkout/billing"]);
            }
            case 2: {
                return this.router.navigate(["/checkout/shipping"]);
            }
            case 3: {
                return this.router.navigate(["/checkout/payment"]);
            }
            case 4: {
                return this.router.navigate(["/checkout/review"]);
            }
            case 5: {
                return this.createOrder();
            }
        }
    }

    public setStep(step: number) {
        this.checkoutStep = step;
        this.saveCartDetails();
    }

    /**
     * Create order
     */
    private createOrder(): Observable<any> {
        this.errorMessage = '';
        const promocode = this.cartService.getPromoCode();
        const products = this.cartService.getProducts();
        const subtotalPrice = this.cartService
            .getProducts()
            .map(product => product.price)
            .reduce((f, s) => f + s, 0);
        const shippingAmount = this.shippingOption.price;
        const discountPrice = this.cartService.getDiscountValue();
        const totalPrice = this.cartService.getTotal();

        //console.log('am ca payment', this.payment);

        const data = {
            /*
            token: this.heartlandToken,
            save_credit_card: this.heartlandData ? this.heartlandData.save_credit_card : false,
            new_card: this.heartlandData ? this.heartlandData.add_new_card : false,
            card_info: {
                exp_month: this.heartlandData ? this.heartlandData.exp_month : '',
                exp_year: this.heartlandData ? this.heartlandData.exp_year : '',
                number: this.heartlandData ? this.heartlandData.last_four : '',
                type: this.heartlandData ? this.heartlandData.card_type : ''
            },
            */
            payment: this.payment,
            customer_id: sessionStorage.getItem("userId"),
            products: products.map(product => {
                return {
                    product: product.product.id,
                    variant: product.productVariant != null ? product.productVariant.id : null,
                    price: product.pricePerItem,
                    total: product.price,
                    quantity: product.quantity,
                    medication_testing: product.product.hasOwnProperty('medication_testing') ? product.product.medication_testing.id : null
                };
            }),
            promocode : promocode != null ? promocode.id : '',
            billing_address: this.billingAddress.id,
            shipping_address: this.shippingAddress.id,
            insurance: this.insuranceOption,
            shipping_carrier: this.shippingOption.id,
            notes: "",
            subtotal_price: subtotalPrice,
            discount: discountPrice,
            total_price: totalPrice,
            counseling: this.counseling,
            piwikid: this.piwikid,
            mkwid: this.mkwid,
            recaptchaToken: this.recaptchaToken
        };

        //console.log(data);
        return this.http
            .post(this.rootUrl + this.POST_ORDER_URL, data, {headers: this.headers})
            .pipe(map(response => {
                return response;
            }));
    }

    public savedCreditCards() {
        return this.http
            .get(this.rootUrl + "/customers/saved-credit-cards", {headers: this.headers})
            .pipe(map(response => {
                return response;
            }));
    }

    public saveCreditCard(data, recaptchaToken = '') {
        const dataToSend = {
            address_id: this.billingAddress.id,
            card_type: data.card_type,
            exp_month: data.exp_month,
            exp_year: data.exp_year,
            last_four_digits: data.last_four,
            token: data.token_value,
            recaptchaToken: recaptchaToken
        };

        return this.http
            .post(this.rootUrl + "/customers/payment-methods/add", dataToSend, {headers: this.headers})
            .pipe(map(response => {
                return response;
            }));
    }

    

    public saveCartDetails() {
        const dataToSave = {
            checkoutStep: this.checkoutStep,
            billingAddress: this.billingAddress,
            insuranceOption: this.insuranceOption,
            shippingAddress: this.shippingAddress,
            shippingOption: this.shippingOption,
            //heartlandToken: this.heartlandToken,
            //heartlandData: this.heartlandData,
            payment: this.payment,
            card: this.card,
            shippingStateErrorProducts: this.shippingStateErrorProducts,
            piwikid: this.piwikid,
            mkwid: this.mkwid
        };
        sessionStorage.setItem("cart_details", JSON.stringify(dataToSave));
    }

    public clearCart() {
        this.checkoutStep = 1;
        this.billingAddress = null;
        this.insuranceOption = null;
        this.shippingAddress = null;
        this.shippingOption = null;
        //this.heartlandToken = null;
        //this.heartlandData = null;
        this.payment = null;
        this.card = null;
        this.shippingStateErrorProducts = null;
        this.errorMessage = '';
        this.saveCartDetails();
    }

}
