var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, AfterViewInit, OnDestroy, ElementRef, DoCheck } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Http } from "@angular/http";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../../shared/user.service";
import { ValidationService } from "../../shared/validation.service";
import drugList from "../../drug-list";
import { map } from "rxjs/operators";
import { FormatService } from "@services/format.service";
import { environment } from "../../../environments/environment";
var SignupComponent = /** @class */ (function () {
    function SignupComponent(formBuilder, userService, http, router, activatedRoute) {
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.http = http;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.rootUrl = sessionStorage.getItem("location");
        this.title = "app";
        this.loginerror = false;
        this.submitted = false;
        this.loading = false;
        this.desired_meds_val = "";
        this.desired_meds_rel = "";
        this.disableForm = false;
        this.message = {};
        this.products = [];
        this.filteredProducts = [];
        this.loadingResponse = false;
        this.displayTooltip = false;
        this.tooltipTop = 0;
        this.tooltipLeft = 0;
        this.showPassword = false;
        this.getReferredOptions();
    }
    SignupComponent.prototype.getReferredOptions = function () {
        var _this = this;
        this.refrredOptions = [];
        this.refrredOptions.push({ label: "Please select", value: "" });
        this.http
            .get(this.rootUrl + "/referred-by")
            .pipe(map(function (response) { return response.json(); }))
            .subscribe(function (response) {
            if (response.status.success) {
                response.data.forEach(function (value) {
                    _this.refrredOptions.push({
                        label: value.name,
                        value: value.id
                    });
                });
            }
        });
    };
    SignupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.http
            .get("https://api.ipify.org/?format=json")
            .pipe(map(function (response) { return response.json(); }))
            .subscribe(function (response) {
            if (response) {
                _this.ip = response.ip;
            }
        });
        this.createForm();
        this.products = drugList.map(function (product) { return product.scientific_name; });
        this.filteredProducts = this.products;
        this.innerWidth = window.innerWidth;
        if (window.innerWidth < 600) {
            document.getElementById("signup").scrollIntoView(true);
        }
    };
    SignupComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.routeSubscription = this.activatedRoute.queryParams.subscribe(function (params) {
            if (parseInt(params["caprx"], 10) === 1) {
                _this.signupForm.get("is_capitalrx").setValue("yes");
            }
        });
        if (environment.production && typeof dataLayer !== 'undefined' && dataLayer && typeof dataLayer.push === 'function') {
            setTimeout(function () {
                dataLayer.push({ 'event': 'signUp' });
            });
        }
    };
    SignupComponent.prototype.ngOnDestroy = function () {
        if (this.productsSubscription) {
            this.productsSubscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    };
    /**
     * Initiates the form group
     */
    SignupComponent.prototype.createForm = function () {
        this.signupForm = this.formBuilder.group({
            first_name: ["", Validators.required],
            last_name: ["", Validators.required],
            email: ["", [Validators.required, ValidationService.emailValidator]],
            password: ["", [Validators.required, ValidationService.passwordValidator]],
            phone: ["", [Validators.required, ValidationService.phoneValidator]],
            zip: ["", [Validators.required, Validators.pattern('[0-9]{5}(-[0-9]{4})?')]],
            desired_meds: ["", {
                    updateOn: "change",
                }],
            referred_by: ["", {
                    updateOn: "change",
                    validators: Validators.required
                }],
            piwikid: "",
            mkwid: "",
            is_capitalrx: ["no", { updateOn: "change" }],
            /*
            purchase_new : [false, {
                updateOn: "change",
                validators : [Validators.required, ValidationService.interestedInValidator]
            }],
            test_existing : [false, {
                updateOn: "change",
                validators : [Validators.required, ValidationService.interestedInValidator]
            }],
             */
            agree: [false, {
                    updateOn: "change",
                    validators: [Validators.required, ValidationService.termsValidator]
                }],
        }, { updateOn: "blur" });
    };
    SignupComponent.prototype.onInterestChange = function () {
        var purchaseNewControl = this.signupForm.get('purchase_new');
        var testExistingControl = this.signupForm.get('test_existing');
        if (purchaseNewControl.value) {
            testExistingControl.setValidators(null);
        }
        else {
            testExistingControl.setValidators([Validators.required, ValidationService.interestedInValidator]);
        }
        testExistingControl.updateValueAndValidity();
        if (testExistingControl.value) {
            purchaseNewControl.setValidators(null);
        }
        else {
            purchaseNewControl.setValidators([Validators.required, ValidationService.interestedInValidator]);
        }
        purchaseNewControl.updateValueAndValidity();
    };
    SignupComponent.prototype.ngDoCheck = function () {
        var _this = this;
        /**
         * Update piwikid and mkwid fields on the model when they get changed from external sources (eg: ad script)
         */
        setTimeout(function () {
            var piwikid = _this.piwikid.nativeElement.getAttribute('value');
            if (_this.signupForm.get('piwikid').value !== piwikid) {
                _this.signupForm.get('piwikid').setValue(piwikid);
            }
            var mkwid = _this.mkwid.nativeElement.getAttribute('value');
            if (_this.signupForm.get('mkwid').value !== mkwid) {
                _this.signupForm.get('mkwid').setValue(mkwid);
            }
        });
    };
    SignupComponent.prototype.toggleShowPassword = function () {
        this.showPassword = !this.showPassword;
    };
    /**
     * Registers event on GTM
     */
    SignupComponent.prototype.gtag_report_conversion = function (url) {
        if (url === void 0) { url = undefined; }
        var callback = function () {
            if (typeof (url) !== "undefined") {
                window.location = url;
            }
        };
        if (environment.production && gtag) {
            gtag('event', 'conversion', {
                'send_to': 'AW-1070593910/KlXlCJTK35QBEPbuv_4D',
                'event_callback': callback
            });
        }
        return false;
    };
    /**
     * Displays tooltip on mouse position
     */
    SignupComponent.prototype.showTooltip = function (event) {
        this.displayTooltip = true;
        this.tooltipTop = event.pageY + 30;
        this.tooltipLeft = event.pageX - 203;
    };
    /**
     * Hides tooltip
     */
    SignupComponent.prototype.hideTooltip = function () {
        this.displayTooltip = false;
    };
    /**
     * Filters products based on passed query event
     * @param event - Filter query event
     */
    SignupComponent.prototype.filterProducts = function (event) {
        var query = event.query;
        this.filteredProducts = this.filterProduct(query, this.products);
    };
    /**
     * Filters passed products based on passed query
     * @param query - Condition to filter by
     * @param products - Products to filter
     */
    SignupComponent.prototype.filterProduct = function (query, products) {
        var filtered = [];
        products.forEach(function (product) {
            return product.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
                filtered.push(product);
        });
        if (!filtered.length) {
            filtered.push(query);
        }
        return filtered;
    };
    /**
     * Checkes if selected key is a number
     * @param event - Key event
     */
    SignupComponent.prototype.onlyNumberKey = function (event) {
        return event.charCode === 8 || event.charCode === 0
            ? null
            : (event.charCode >= 48 && event.charCode <= 57) || (event.charCode === 45);
    };
    /**
     * Signup form submit, creates new user
     */
    SignupComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var controlKey, personalInfo;
            var _this = this;
            return __generator(this, function (_a) {
                if (!this.loadingResponse) {
                    if (!this.signupForm.valid) {
                        for (controlKey in this.signupForm.controls) {
                            this.signupForm.controls[controlKey].markAsTouched();
                            this.signupForm.controls[controlKey].markAsDirty();
                        }
                        return [2 /*return*/];
                    }
                    this.loadingResponse = true;
                    this.submitted = true;
                    personalInfo = this.signupForm.value;
                    if (this.signupForm.get("desired_meds") && this.signupForm.get("desired_meds").value) {
                        personalInfo.desired_meds = this.signupForm.get("desired_meds").value.join(',');
                    }
                    personalInfo.phone = FormatService.formatPhoneForSave(this.signupForm.get('phone').value);
                    this.userService
                        .userSignupStepone(__assign({ ip: this.ip }, personalInfo))
                        .subscribe(function (reginfo) {
                        _this.loadingResponse = false;
                        _this.gtag_report_conversion();
                        if (reginfo.status.success) {
                            _this.userService
                                .userAuthentication(reginfo.data.email, _this.signupForm.value.password)
                                .subscribe(function (result) {
                                if (result.status.success) {
                                    if (environment.production && typeof dataLayer !== 'undefined' && dataLayer && typeof dataLayer.push === 'function') {
                                        setTimeout(function () {
                                            dataLayer.push({ 'event': 'signUpValid' });
                                        });
                                    }
                                    _this.userService.setToken(result);
                                    _this.router.navigate(["/personal-info"]);
                                }
                                else {
                                    _this.router.navigateByUrl("/signup");
                                }
                            }, function (err) {
                            });
                        }
                        else {
                            _this.loadingResponse = false;
                            _this.message = reginfo.status.errors;
                            if (reginfo.status.message) {
                                Object.assign(_this.message, { generic: reginfo.status.message });
                            }
                        }
                    }, function (err) {
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    return SignupComponent;
}());
export { SignupComponent };
